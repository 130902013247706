<template>
  <nav
    :class="`${$classPrefix}breadcrumb`"
    class="no-separator"
    aria-label="breadcrumbs"
  >
    <ul class="">
      <Step
        v-for="(step, index) in steps"
        :key="index"
        :step="index+1"
        :label="step.label"
      />
    </ul>
  </nav>
</template>

<script>
import Step from './Step'

export default {
  name: "Steps",
  props: {
    steps: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: {
    Step
  },
}
</script>

<style>
</style>

