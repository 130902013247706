import axiosInstance from '@/helpers/axios'

import { CancelToken } from 'axios'

let getSpaceToken = null

export const cancelGetSpaceTokenRequest = () => {
  if (getSpaceToken) {
    getSpaceToken.cancel('Request cancelled')
  }
}

export const getSpace = (spaceId) => {
  cancelGetSpaceTokenRequest()
  getSpaceToken = CancelToken.source()
  return axiosInstance.get(
    `/space/${spaceId}`, {
    cancelToken: getSpaceToken.token
  }
  )
}