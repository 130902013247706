<template>
  <div class="is-inline">
    <label :class="`${$classPrefix}checkbox is-unselectable`">
      <input
        type="checkbox"
        :value="fieldValue"
        :checked="checked"
        @input="onChange"
        :disabled="disabled"
        :required="required"
      >
      <i18n
        v-if="i18nPath"
        :path="i18nPath"
      >
        <template v-slot:action>
          <slot name="labelAction"></slot>
        </template>
      </i18n>
      <span
        v-else-if="label"
        v-text="label"
      ></span>
      <span
        v-if="isRequired"
        class="has-text-danger"
      >*</span>
    </label>
    <p v-if="error">
      <span :class="`${$classPrefix}help is-danger`">
        {{ error }}
      </span>
    </p>
  </div>
</template>

<script>
import BaseFormInputField from './BaseFormInputField'

export default {
  name: 'Checkbox',
  extends: BaseFormInputField,

  props: {
    i18nPath: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean],
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  created() {
    this.fieldValue = this.value
  },
  computed: {

  },
  methods: {
    change(e) {
      this.fieldValue = typeof (this.value) === 'boolean' ?
        e.target.checked
        :
        (e.target.checked ? this.value : '')

      this.$emit('input', this.fieldValue)
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  &.has-input-right {
    span {
      order: -1;
      margin-right: 5px;
    }
  }
  &:not(.has-input-right) {
    span {
      margin-left: 5px;
    }
  }
}
</style>