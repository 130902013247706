/**
 * Import API
 */
import Vue from 'vue'
import '@/filters/dateTimeFilters'
import { getAutoCompleteItems } from '@/providers/autocomplete'
import { getMeetingTypes, getSearchByKey } from '@/providers/search'
import { getOpeningHours } from '@/providers/openinghours'
import { getWidgetStateByKey } from '@/providers/widget'

/**
 * Declare Variable
 */
const state = {
  autoCompleteItems: [],
  timeslots: [],
  widgetState: null,
  leadSource: '',
  preSelectedLocationId: 0,
  preSelectedMeetingType: 0,
  widgetKey: '',
  widgetInitialized: false
}

const getters = {
  customStyle: state => {
    return state.widgetState.Styling ? JSON.parse(state.widgetState.Styling) : null
  },
  widgetLocationId: state => state.widgetState.LocationId || 0,
  channel: (state) => { return state.widgetState ? state.widgetState : null },
  channelId: (state) => { return state.widgetState.ChannelId ?? 0 },
  source: (state) => { return state.widgetState.leadSource },
  showPrices: state => state.ShowPrices,
  showSearchInput: state => state.widgetState.ShowSearchInput,
  showCountrySelect: state => state.widgetState.ShowCountrySelect,
  showBookingDetailsStep: state => state.widgetState.ShowBookingDetailsStep,
  authOptions: state => {
    return {
      type: state.widgetState.AuthType,
      showLoginRegister: (state.widgetState.AuthType === 0 || state.widgetState.AuthType === 1) || false,
      showNoLogin: (state.widgetState.AuthType === 0 || state.widgetState.AuthType === 2) || false
    }
  }
}

const mutations = {
  setAutocompleteItems: (state, data) => {
    state.autoCompleteItems = data
  },

  setChannel: (state, data) => {
    state.channel = data
  },

  setWidgetInitialized: (state, data) => {
    state.widgetInitialized = data
  },

  setWidgetState: (state, data) => {
    state.widgetState = data
  },

  setWidgetKey: (state, data) => {
    state.widgetKey = data
  },

  setMeetingtypes: (state, data) => {
    state.widgetState.Meetingtypes = data
  },

  setNrOpenLocations: (state, data) => {
    state.widgetState.OpeningHour.NrOpenLocations = data
  },

  setOpeningHours: (state, data) => {
    state.widgetState.OpeningHour = data
  },

  setTimeslots: (state, data) => {
    state.timeslots = data
  },

  setWidgetLeadSource: (state, source) => {
    state.leadSource = source
  },

  setWidgetStateLocationId: (state, newState) => {
    state.preSelectedLocationId = newState
  },

  setPreSelectedMeetingType: (state, newState) => {
    state.preSelectedMeetingType = newState
  }
}

const actions = {
  async getWidgetState({ commit, dispatch, rootGetters, rootState, state }) {
    let searchObj = rootGetters['searchStore/search']

    // Load widget state data
    await getWidgetStateByKey(
      state.widgetKey,
      rootGetters['searchStore/SearchKey']
    ).then((response) => {
      if (response.status === 200) {
        let widgetState = response.data
        let maxSteps = rootState.searchStore.maxSteps
        if (!widgetState.ShowOptionsStep) {
          --maxSteps
        }
        if (!widgetState.ShowBookingDetailsStep) {
          --maxSteps
        }
        commit('searchStore/setMaxSteps', maxSteps, { root: true })
        
        if (state.preSelectedMeetingType) {
          widgetState.PreSelectedMeetingtype = state.preSelectedMeetingType
        }

        commit('setWidgetState', widgetState)
        searchObj.WidgetId = widgetState.Id
        searchObj.ChannelId = widgetState.ChannelId
        searchObj.LocationId = widgetState.LocationId
        searchObj.MeetingtypeId = widgetState.PreSelectedMeetingtype
        searchObj.SpaceId = widgetState.SpaceId

        dispatch('searchStore/setSearch', searchObj, { root: true })

        dispatch('processOpeningHours', {
          startDate: searchObj.StartDate,
          startMinutes: searchObj.StartMinutes,
          endMinutes: searchObj.EndMinutes,
          openingHour: widgetState.OpeningHour,
          updateSearchStore: true
        })
      }
    })

    // Get location data of the pre selected location on initialization widget.
    if (state.preSelectedLocationId) {
      commit('searchStore/setSearchLocationId', state.preSelectedLocationId, { root: true })
      let meetingTypes = await dispatch('getMeetingtypesData')
      await dispatch('searchStore/setMeetingtypeId', meetingTypes.data[0], { root: true })
    }
  },

  setWidgetKey: (context, widgetKey) => {
    context.commit('setWidgetKey', widgetKey)
  },

  getSearchData: async ({ dispatch, rootGetters }) => {
    getSearchByKey(rootGetters['searchStore/SearchKey']).then((response) => {
      if (response.status === 200) {
        dispatch('searchStore/setSearch', response.data, { root: true })
      }
    })
  },

  getMeetingtypesData: async ({ commit, rootGetters }) => {
    let searchObj = rootGetters['searchStore/search']
    let result = []
    await getMeetingTypes({
      countryId: searchObj.CountryId,
      locationId: searchObj.LocationId,
      searchTerm: searchObj.SearchTerm,
      channelId: searchObj.ChannelId,
    }).then((response) => {
      if (response.status == 200) {
        commit('setMeetingtypes', response.data)
        result = response
      }
    })
    return result
  },

  getOpeningHoursData: async ({ rootGetters }, startDate = null) => {
    let searchObj = rootGetters['searchStore/search']
    let d = startDate ?? searchObj.StartDate
    d = Vue.options.filters.dateObjectIsoDateString(d)
    const results = getOpeningHours({
      date: d,
      countryId: searchObj.CountryId,
      channelId: searchObj.ChannelId,
      locationId: searchObj.LocationId,
      meetingtypeId: searchObj.MeetingtypeId,
    })
    return results
  },

  processOpeningHours: async ({ commit, dispatch, rootGetters }, { startDate, startMinutes, endMinutes, openingHour, updateSearchStore = false }) => {
    let searchObj = rootGetters['searchStore/search']

    // Search start and end minutes settings
    let _startMinutes = startMinutes
    let _endMinutes = endMinutes

    // Option min and max minutes settings
    let minMinutes = openingHour.MinMinutes
    let maxMinutes = openingHour.MaxMinutes
    
    // Check if date is today or earlier
    let dayDiff = Vue.options.filters.dayDiff(new Date(startDate))
    
    // Other settings
    let currentMinutes = new Date().getHours() * 60 + new Date().getMinutes()
    let minutesSteps = 15

    if (searchObj.MeetingtypeId === 1) {
      minutesSteps = 30
    }

    let rounded = Math.ceil(currentMinutes / minutesSteps) * minutesSteps
    
    if (dayDiff <= 0) {
      if (rounded < openingHour.MinMinutes) {
        rounded = openingHour.MinMinutes
        minMinutes = openingHour.MinMinutes
      } else {
        minMinutes = rounded
      }

        // Update start minuts
        if (_startMinutes < rounded) {
          _startMinutes = rounded
        }

        // Update end minutes
        _endMinutes = _startMinutes + 4 * 60

        if (_endMinutes > openingHour.MaxMinutes) {
          _endMinutes = maxMinutes
        }

        if (1440 - _startMinutes > 60) {
          // Go to next day
          // TODO: Get next day data
        }
    } else {
      if (searchObj.Key === '') {
        _startMinutes = 540
        _endMinutes = 1020
      }

      if (_startMinutes < minMinutes) {
        _startMinutes = minMinutes
      }
      if (_endMinutes < _startMinutes || _endMinutes > maxMinutes) {
        _endMinutes = maxMinutes
      }
    }

    let timeslots = []
    for (var i = minMinutes; i <= maxMinutes; i = i + minutesSteps) {
      timeslots.push(i)
    }

    if (updateSearchStore) {
      searchObj.StartMinutes = _startMinutes
      searchObj.EndMinutes = _endMinutes
        dispatch('searchStore/setSearch', searchObj, { root: true })
    }

    commit('setTimeslots', timeslots)

    return {
      startMinutes: _startMinutes,
      endMinutes: _endMinutes
    }
  },

  getAutocompleteData: async ({ commit, rootGetters }) => {
    let searchObj = rootGetters['searchStore/search']

    if (searchObj.SearchTerm.length > 2) {
      getAutoCompleteItems({
        q: searchObj.SearchTerm,
        channelId: searchObj.ChannelId,
        countryId: searchObj.CountryId,
        locationId: searchObj.LocationId,
        meetingtypeId: searchObj.MeetingtypeId,
      }).then((response) => {
        if (response.status === 200) {
          commit('setAutocompleteItems', response.data)
        }
      })
    } else {
      commit('setAutocompleteItems', [])
    }
  },

  resetAutoCompleteData: async ({ commit }) => {
    commit('setAutocompleteItems', [])
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
