import Vue from 'vue'

Vue.filter('getEventTypeName', (eventType) => {
  let name = ''

  switch (eventType) {
    case 1:
      name = 'Meeting'
      break
    case 2:
      name = 'Training'
      break
    case 3:
      name = 'Brainstorm session'
      break
    case 4:
      name = 'Event'
      break
    case 5:
      name = 'Reception'
      break
    case 6:
      name = 'Network lunch'
      break
    case 7:
      name = 'Product launch'
      break
    case 8:
      name = 'Presentation'
      break
  }
  return name
})
