<template>
  <div>
    <BaseForm
      :once="false"
      v-if="cart"
      ref="form"
      class="BookingDetailsForm"
    >
      <div :class="`${$classPrefix}columns is-multiline`">
        <div :class="`${$classPrefix}column is-full`">
          <div v-if="cart.MeetingTypeId !== 2">
            <RequiredField
              class="input-reservationName"
              v-model="Name"
              name="meetingname"
              :maxLength="50"
              :label="$t('Components.ReservationDetails.ReservationForm.Label_NameOfMeeting')"
            />

            <BaseFieldTemplate :label="$t('Components.ReservationDetails.ReservationForm.MeetingType_1.Label_Remarks')">
              <template v-slot:fields>
                <div v-html="$t('Components.ReservationDetails.ReservationForm.MeetingType_1.Message_Remarks')"></div>
                <TextareaField
                  v-model="remarks"
                  label=""
                  @blur="updateCartComment"
                />
              </template>
            </BaseFieldTemplate>
          </div>

          <div v-else>
            <Message>
              {{ $t('Components.ReservationDetails.ReservationForm.MeetingType_2.Message_Focus') }}
            </Message>

            <RequiredField
              v-model="Name"
              name="workingOn"
              :label="$t('Components.ReservationDetails.ReservationForm.MeetingType_2.Label_Focus')"
            />
          </div>
        </div>
      </div>
    </BaseForm>
    <div v-if="!showMobileFooterBar">
      <hr />
      <NextButton
        :class="[`${$classPrefix}button is-success is-pulled-right`, { 'is-loading': isBusy }]"
        v-text="$t('General.Text_NextStep')"
      />
    </div>
  </div>
</template>

<script>
import BaseForm from '@/components/UI/Form/BaseForm'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import RequiredField from '@/components/UI/Form/RequiredField'
import TextareaField from '@/components/UI/Form/TextareaField'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'
import NextButton from '@/components/Navigation/NextButton'

/**
 * A computed factory for the form models that access the Cart store
 */
function mapFormModels(models = []) {
  return models.reduce((obj, model) => {
    let prop = model.prop
    obj[prop] = {
      get() {
        return this.cart[prop] || model.default
      },
      set(value) {
        let obj = {}
        obj[prop] = value
        this.saveCartBeforeNextStep()
        this.runSilentValidation()
        this.setCartProperties({ properties: obj })
      },
    }
    return obj
  }, {})
}

export default {
  components: {
    BaseForm,
    BaseFieldTemplate,
    RequiredField,
    TextareaField,
    Message,
    NextButton
  },

  data() {
    return {
      remarks: ''
    }
  },
  computed: {
    ...mapGetters('authStore', ['profile']),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapState('cartStore', ['cart']),
    ...mapState('searchStore', ['selectedEventType']),
    ...mapState(['viewportMode', 'showMobileFooterBar']),

    ...mapFormModels([
      { prop: 'Name', default: '' },
    ])
  },

  async created() {
    await this.disableNextStep()
  },

  mounted() {
    if (this.cart) {
      this.runSilentValidation()
      if (this.cart.Comments.length) {
        this.remarks = this.$store.getters['cartStore/Cart'].Comments[0].Text
      }
    }
  },

  methods: {
    ...mapActions('cartStore', ['saveCart']),
    ...mapMutations('cartStore', ['setCartProperties', 'setCartComment']),
    ...mapMutations('searchStore', [
      'disableNextStep',
      'enableNextStep',
      'saveCartBeforeNextStep',
    ]),

    // Comment
    updateCartComment() {
      this.setCartComment(
        this.$options.filters.stripHtml(this.remarks, false, true)
      )
    },

    runSilentValidation() {
      this.$refs.form.runValidation({ silent: true })
      if (this.$refs.form.isValid()) {
        this.enableNextStep()
      } else {
        this.disableNextStep()
      }
    }
  },
}
</script>
