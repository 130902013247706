import axiosInstance from '@/helpers/axios'

let getAvailabilityBySearchKeyCancel = null

// export const getAvailability = ({
//   startDate,
//   endDate,
//   channelId = 0,
//   countryId = 0,
//   locationId = 0,
//   searchTerm = '',
//   meetingtypeId = 1,
//   seats = 1,
//   startTime = 0,
//   endTime = 0,
//   settingId = 0,
//   voucherId = 0,
// } = {}) => {
//   return axiosInstance.get(`availability`, {
//     params: {
//       q: searchTerm,
//       channel: channelId,
//       country: countryId,
//       location: locationId,
//       meetingtype: meetingtypeId,
//       seats: seats,
//       startdate: startDate,
//       starttime: startTime,
//       enddate: endDate,
//       endtime: endTime,
//       setting: settingId,
//       isbackend: false,
//       voucher: voucherId,
//     },
//   })
// }

/**
 * Get availability by search key
 * @param {String} key 
 */
// export const getAvailabilityBySearchKey = ({ SearchKey }) => {
//   getAvailabilityBySearchKeyCancel &&
//     getAvailabilityBySearchKeyCancel('Request cancelled')
//   return axiosInstance.get(`availability/${SearchKey}`, {
//     cancelToken: new axiosInstance.CancelToken(function executor(c) {
//       getAvailabilityBySearchKeyCancel = c
//     }),
//   })
// }

export const getAvailabilityBySearchKey = ({ SearchKey }) => {
  getAvailabilityBySearchKeyCancel && getAvailabilityBySearchKeyCancel('Request cancelled')
  return axiosInstance.get(`${process.env.VUE_APP_API_BASE_URL}/api/v2/availability/${SearchKey}`, {
    cancelToken: new axiosInstance.CancelToken(function executor(c) {
      getAvailabilityBySearchKeyCancel = c
    }),
  })
}

export const getSpaceAlternativeDays = (
  spaceId,
  channelId,
  meetingtypeId,
  startDate,
  endDate,
  startMinutes,
  endMinutes,
  seats,
  settingId,
  cartId
) => {
  return axiosInstance.get(`availability/space/${spaceId}/period`, {
    params: {
      channel: channelId,
      meetingtype: meetingtypeId,
      seats: seats,
      startDate: startDate,
      startMinutes: startMinutes,
      endDate: endDate,
      endMinutes: endMinutes,
      setting: settingId,
      cartId: cartId,
    },
  })
}

export const selectSpace = ({
  SearchId = null,
  LocationId = null,
  Hash = null,
} = {}) => {
  return axiosInstance.post(`availability/select`, {
    SearchId,
    LocationId,
    Hash,
  })
}
