<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TermsForm from './TermsForm.vue'

export default {
  name: 'BookingTerms',
  extends: TermsForm,

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapGetters('cartStore', ['cartTerms']),

    terms() {
      if (typeof this.cartTerms?.Terms.Languages === 'undefined') {
        return null
      }
      let cartTerms = this.cartTerms.Terms.Languages.find(
        (l) => l.Language === this.cart.Language
      )
      return cartTerms
    }
  },

  watch: {
    hasAgreed(val) {
      this.setAgreedToCartTerms(val)
    },

    cart: {
      deep: true,
      immediate: true,
      handler: function() {
        this.setAgreedToCartTerms(this.hasAgreed)
        this.hasAgreed = this.cart.CartTerms.Accepted
      }
    },
  },

  beforeCreate() {
  },

  created() {
    // this.hasAgreed = this.cart.CartTerms.Accepted
    this.linkAgreeWithTerms = 'Components.BookingTerms.Link_AgreeWithTerms'
    this.modalTitle = this.$t('Components.BookingTerms.Modal_Title')
    this.initializeTerms()
  },

  methods: {
    ...mapActions('cartStore', ['setCartTerms', 'setAgreedToCartTerms']),
    initializeTerms() {
      if (!this.terms) { return }
      this.modalTitle += ` (${this.cartTerms.Terms.Version})`
      this.description = this.cartTerms.Terms.Text
      this.version = this.cartTerms.Terms.Version
      if (this.cartTerms.Terms.CancelRules && this.cartTerms.Terms.CancelRules.length) {
        this.description += this.buildCancellationRulesText(this.cartTerms.Terms.CancelRules)
      }
    },

    buildCancellationRulesText(rules) {
      let output = `<hr/><div class="${this.$classPrefix}title is-3">${this.$t('Components.BookingTerms.Header_Cancellation')}</div>`
      let table = `<table class="${this.$classPrefix}table is-fullwidth is-bordered">
        <thead>
          <tr>
            <th class="has-text-centered">${this.$t('Components.BookingTerms.TableHeader_DaysTillBooking')}</th>
            <th class="has-text-centered">${this.$t('Components.BookingTerms.TableHeader_GroupSize')}</th>
            <th class="has-text-centered">${this.$t('Components.BookingTerms.TableHeader_Percentage')}</th>
          </tr>
        </thead>
        <tbody>`
      rules = rules.sort((a, b) => {
        if (a.HoursUntilStart > b.HoursUntilStart && a.MaxSeats > b.MaxSeats) return 1
        if (a.HoursUntilStart < b.HoursUntilStart && a.MaxSeats < b.MaxSeats) return -1
      })
      rules.forEach(rule => {
        table += `<tr>
          <td class="has-text-centered">${this.hoursToDay(rule.HoursUntilStart, true)}</td>
          <td class="has-text-centered">${rule.MinSeats} - ${rule.MaxSeats}</td>
          <td class="has-text-centered">${rule.Percentage}%</td>
        </tr>`
      });
      table += '</tbody></table>'
      output += table
      return output
    },

    hoursToDay(hours, onlyDayFormat = false) {
      if (hours <= 48 && !onlyDayFormat) {
        // return in hours
        return this.$tc('Components.BookingTerms.Label_Hour', hours)
      }
      return this.$tc('Components.BookingTerms.Label_Days', Math.floor(hours / 24))
    }
  },
}
</script>