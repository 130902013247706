import Vue from 'vue'

Vue.directive('longpress', {
  bind: function(el, binding) {
    //, vNode

    // Make sure expression provided is a function
    if (typeof binding.value.handler !== 'function') {
      // Fetch name of component
      // const compName = vNode.context.name
      // // pass warning to console
      // let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
      // if (compName) {
      //   warn += `Found in component '${compName}' `
      // }
      // console.warn(warn)
    }

    // Define variable
    let pressTimer = null
    let cycles = null

    // Optional handler to stop the longpress
    let endHandler = binding.value.endHandler || false

    // Define funtion handlers
    // Create timeout ( run function after 600ms )
    let start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return
      }

      cycles = 0

      if (pressTimer === null) {
        pressTimer = setTimeout(handler, 600)
      }
    }

    // Cancel Timeout
    let cancel = () => {
      // Check if timer has a value or not
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
    // Run Function
    const handler = (e) => {
      binding.value.handler(e)
      cycles++

      if (!endHandler || !endHandler()) {
        pressTimer = setTimeout(
          handler,
          cycles < 5 ? 200 : cycles < 10 ? 150 : 100
        )
      } else {
        cancel()
      }
    }

    // Add Event listeners
    el.addEventListener('mousedown', start)
    el.addEventListener('touchstart', start)
    // Cancel timeouts if this events happen
    el.addEventListener('click', cancel)
    el.addEventListener('mouseout', cancel)
    el.addEventListener('touchend', cancel)
    el.addEventListener('touchcancel', cancel)
  },
})
