<template>
  <div class="BookingWizard">
    <Loader v-if="isSearching" />

    <template v-else>
      <MainControls />

      <div :class="`${$classPrefix}columns ResultContainer is-multiline`">
        <div :class="`${$classPrefix}column loginControlOverlap`">
          <Overlap v-if="hasOverlap" />
          <NoAvailability v-else-if="!availableLocations.length" />
          <MainContent v-else />
        </div>

        <Sidebar
          v-if="showSidebar"
          :class="`${$classPrefix}column`"
          class="is-hidden-mobile column-sidebar"
        />
      </div>
    </template>
  </div>
</template>

<script>
import NoAvailability from '@/components/SearchResults/NoAvailability'
import Overlap from '@/components/SearchResults/Overlap'
import Sidebar from '@/components/Layout/Sidebar'
import MainControls from '@/components/Layout/MainControls'
import MainContent from '@/components/Layout/MainContent'

import Loader from '@/components/UI/Loader'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Wizard',

  components: {
    NoAvailability,
    Overlap,
    Sidebar,
    MainControls,
    MainContent,
    Loader,
  },

  computed: {
    ...mapState('availabilityStore', ['hasOverlap']),
    ...mapState(['countries', 'showMobileFooterBar']),
    ...mapGetters('availabilityStore', ['isSearching', 'availableLocations']),
    ...mapGetters('searchStore', ['LastStep']),

    showSidebar() {
      return (
        (!this.showMobileFooterBar && this.availableLocations.length) ||
        (!this.hasOverlap && !this.LastStep === 1)
      )
    },
  },

  async beforeDestroy() {
    this.showModalReady = this.showModal
    this.deleteCart()
    this.clearStoreData()
  },

  methods: {
    ...mapActions('cartStore', ['deleteCart']),
    ...mapActions('searchStore', ['resetSearch']),
    ...mapActions('billingStore', ['clearStoreData'])
  },
}
</script>
