import axiosInstance from '@/helpers/axios'

let getLocationsToken = null

/**
 * Get locations
 * @param {Object} params 
 */
export const getLocations = async (params) => {
  if (getLocationsToken) {
    await getLocationsToken.cancel('Request cancelled')
  }
  getLocationsToken = await axiosInstance.CancelToken.source()

  return await axiosInstance.get(`Location`, {
    params,
    cancelToken: getLocationsToken.token
  })
}

export const getLocation = (
  LocationId
) => {
  return axiosInstance.get(`Location/${LocationId}`)
}

export const getLocationImages = ({
  LocationId
}) => {
  return axiosInstance.get(`Location/${LocationId}/images`)
}

export const getLocationLogo = (
  LocationId
) => {
  return axiosInstance.get(`Location/${LocationId}/logo`)
}

export const getActiveLocationManagers = ({
  LocationId
}) => {
  return axiosInstance.get(`Location/${LocationId}/manager/active`)
}