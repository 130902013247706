<script>
import BaseField from './BaseBulmaField'

export default {
  name: 'RequiredField',
  extends: BaseField,

  props: {
    required: {
      type: Boolean,
      default: true
    }
  }
}
</script>
