<template>
    <div
      v-if="drawerReady"
      :class="`${$classPrefix}widget ${$classPrefix}drawer-placeholder`"
    >
  <transition
    name="fade"
    mode="out-in"
  >
      <div
        v-if="showDrawer"
        :key="'showDrawer' + Number(showDrawer)"
        :class="`${$classPrefix}drawer`"
        class="ui-drawer-component"
      >
        <div
          class="ui-drawer-background"
          :class="{'is-active' : showDrawer}"
          @click="closeDrawer()"
        ></div>
        <div
          class="drawer"
          :class="[position, unsetFillModeCssClass, animateClass]"
        >
          <header class="drawer-head has-padding">
            <p
              v-if="!hideTitle"
              class="drawer-title is-align-items-flex-start"
            >
              <span>{{ drawerTitle }}</span>
            </p>
            <button
              @click="closeDrawer()"
              class="delete ml-2"
              aria-label="close"
            ></button>
          </header>
          <div :class="`${$classPrefix}drawer-body has-padding`">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    drawerTitle: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false
    },
    position: {
      type: String,
      default: 'right'
    },
    showDrawer: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      slideDrawer: this.showDrawer,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
      drawerReady: false
    }
  },

  computed: {
    animateClass() {
      let cls = 'animate__animated animate__slide'
      cls = cls + (this.slideDrawer ? 'In' : 'Out')
      cls = cls + this.position.charAt(0).toUpperCase() + this.position.slice(1)
      return cls
    }
  },
  
  mounted() {
    // Append modal to body
    document.body.appendChild(this.$el)
    this.drawerReady = true
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }
    if (this.$el.parentNode) {
      this.$el.removeChild(this.$el)
    }
  },

  methods: {
    closeDrawer() {
      this.slideDrawer = false
      this.unsetFillModeCssClass = ''
      this.animateTimer = setTimeout(this.onClickClose, 400)
    },
  }
}
</script>
