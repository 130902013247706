<template>
  <div class="SpaceOptionAmountControls">
    <button
      v-if="
         !isSelected && !isDisabled
      "
      class="is-success is-fullwidth"
      :class="[`${$classPrefix}button`, { 'is-loading': isSavingOptionData }]"
      @click="handleAddOptionToCart"
      v-text="$t('General.Text_Add')"
    ></button>

    <div v-if="isDisabled">
      <span
        v-if="option.IsPP"
        v-text="$t('App.AvailableOptions.Text_AddedRequiredOption', {
        persons: $tc('App.AvailableOptions.Text_Persons', amount)
      })"
      ></span>
      <span
        v-if="!option.IsPP"
        v-text="$t('App.AvailableOptions.Text_Added', {
        items: $tc('App.AvailableOptions.Text_Items', amount)
      })"
      ></span>
    </div>

    <div
      v-if="isSelected && !isRequired && !isDisabled"
      :class="`${$classPrefix}field has-addons`"
    >
      <div :class="`${$classPrefix}control`">
        <button
          type="submit"
          :class="`${$classPrefix}button is-success`"
          :disabled="isMinimalValue"
          @click="handleReduceAmount"
          v-longpress="{
            handler: handleReduceAmount,
            endHandler: isMinimalValueHandler,
          }"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fal', 'minus']" />
          </span>
        </button>
      </div>
      <div :class="`${$classPrefix}control`">
        <input
          type="number"
          size="3"
          maxlength="3"
          step="1"
          min="0"
          :class="`${$classPrefix}input has-text-centered`"
          v-model.number="amount"
        />
      </div>
      <div :class="`${$classPrefix}control`">
        <button
          type="submit"
          :class="`${$classPrefix}button is-success`"
          :disabled="isMaxValue"
          @click="handleIncreaseAmount"
          v-longpress="{
            handler: handleIncreaseAmount,
            endHandler: isMaxValueHandler,
          }"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fal', 'plus']" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSavingOptionData: false,
    }
  },
  computed: {
    ...mapGetters('cartStore', ['optionAmountById', 'Seats', 'Cart']),
    amount: {
      get() {
        return this.optionAmountById({ OptionId: this.option.OptionId })
      },
      set(value) {
        this.option.IsSelected = value > 0

        this.updateOptionAmount({
          OptionId: this.option.OptionId,
          Amount: value,
          // TODO: This does not include min / max amount checks... move to VUEX !!
          TotalPrice: this.calculateOptionTotalPrice({
            amount: value,
          }),
        })
      },
    },
    /**
     * Control Status
     */
    isDisabled() {
      // This kind of required item does not allow the user to change the amount
      return this.isSelected && this.option.RequiredItem === 2
    },
    isRequired() {
      return this.option.RequiredItem === 2
    },
    isSelected() {
      let result = false

      for (let i = 0; i < this.Cart.Options.length; i++) {
        this.Cart.Options.map((option) => {
          if (option.OptionId === this.option.OptionId) {
            result = true
          }
        })
      }
      return result
    },

    isMinimalValue() {
      return this.amount === 0
    },
    isMaxValue() {
      return (
        (this.option.MaxAmount !== 0 &&
          this.amount === this.option.MaxAmount) ||
        (this.option.MaxPP !== 0 &&
          this.amount === this.option.MaxPP * this.Seats)
      )
    },
  },
  methods: {
    ...mapActions('cartStore', [
      'saveCartOption',
      'deleteCartOption',
      'updateOptionAmount',
    ]),
    ...mapActions('locationmanagers', ['updateManagerMessage']),

    ...mapMutations('cartStore', [
      'setOptionAmount',
      'increaseBusyState',
      'decreaseBusyState',
    ]),

    calculateOptionTotalPrice({ amount } = {}) {
      amount = amount || this.amount
      let priceTotal = amount * this.option.PricePerItem
      if (this.option.MaxTotal > 0 && priceTotal > this.option.MaxTotal) {
        priceTotal = this.option.MaxTotal
      }
      return priceTotal
    },
    handleIncreaseAmount() {
      this.amount = this.amount + 1
    },
    handleReduceAmount() {
      this.amount = this.amount - 1
    },
    isMinimalValueHandler() {
      return this.isMinimalValue
    },
    isMaxValueHandler() {
      return this.isMaxValue
    },

    async handleAddOptionToCart() {
      this.isSavingOptionData = true
      let TotalPrice = this.calculateOptionTotalPrice()

      this.updateManagerMessage(
        'You can change the quantity of the amenity using the + and - buttons.'
      )

      // If the option is not currently selected, make sure the amount is at least 1
      if (!this.option.IsSelected && this.amount < 1) {
        this.amount = 1
      }

      this.increaseBusyState()
      await this.saveCartOption({
        Amount: this.amount,
        TotalPrice,
        OptionId: this.option.OptionId,
        IsSelected: this.option.IsSelected
      })

      this.option.IsSelected = true
      // this.$store.dispatch('saveCart', response.data)

      this.isSavingOptionData = false
      this.decreaseBusyState()
    },
    async handleDeleteOptionFromCart() {
      this.isSavingOptionData = true

      this.increaseBusyState()
      await this.deleteCartOption({
        OptionId: this.option.OptionId,
      })
      this.option.IsSelected = false

      this.isSavingOptionData = false
      this.decreaseBusyState()
    },
  },
}
</script>

<style lang="scss">
.SpaceOptionAmountControls {
  display: flex;
  justify-content: flex-end;

  .#{$prefix}field {
    flex-grow: 1;
    flex-shrink: 1;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .#{$prefix}input {
    -moz-appearance: textfield;
    min-width: 54px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  > button {
    text-align: center;
  }
}
</style>
