var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LocationSpaceWS"},[_c('figure',{staticClass:"LocationSpaceWS__image has-background-grey-light",style:({
      backgroundImage: `url('${_vm.$options.filters.getLocationImage(
        _vm.space.SpaceImage,
        _vm.selectedLocationId,
        '640'
      )}')`,
    }),on:{"click":_vm.showSpaceInformation}}),_c('div',{staticClass:"LocationSpaceWS__detail_price"},[_c('div',{staticClass:"LocationSpaceWS__details has-padding"},[_c('div',{class:`is-size-5 has-text-weight-bold has-margin-bottom`,domProps:{"innerHTML":_vm._s(_vm.space.SpaceName)}}),(_vm.upsellItems.length)?_c('UpsellTemplate',{staticClass:"has-margin-bottom",attrs:{"upsellItems":_vm.upsellItems}}):_vm._e(),(_vm.includedOptions.length)?_c('div',{staticClass:"has-margin-bottom"},[_c('div',{staticClass:"has-text-weight-bold"},[_vm._v("Inclusief:")]),_c('UpsellTemplate',{attrs:{"upsellItems":_vm.includedOptions}})],1):_vm._e(),(_vm.showSocialCaptial)?_c('div',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.$t('Components.LocationCard.Text_SocialCapital'))+" ")]):_vm._e()],1),_c('div',{staticClass:"LocationSpaceWS__PriceWrapper has-background-info-light has-padding"},[_c('div',{staticClass:"has-text-centered"},[(_vm.widgetState.ShowPrices)?_c('div',{staticClass:"LocationCard__price has-margin-bottom"},[_c('span',{attrs:{"alt":_vm.pricingMethod,"title":_vm.pricingMethod}},[_c('strong',{staticClass:"is-size-5",domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.toCurrency(_vm.space.Price, 'nl-NL', _vm.space.CurrencyIso)
              )}}),_vm._v(" "+_vm._s(_vm.pricingMethodShortner))]),(!_vm.hideTotalPrice)?_c('div',{staticClass:"LocationCard__priceExplanation is-size-7 has-text-grey",domProps:{"textContent":_vm._s(
              `${_vm.$options.filters.toCurrency(
                _vm.space.PriceTotal,
                'nl-NL',
                _vm.space.CurrencyIso
              )} total`
            )}}):_vm._e()]):_vm._e(),(_vm.showSocialCaptial)?_c('div',{staticClass:"has-margin-bottom"},[_c('figure',{staticClass:"image image is-32x32 is-inline-flex"},[_c('SocialCapital')],1)]):_vm._e(),_c('button',{staticClass:"LocationCard__button is-fullwidth is-success",class:`${_vm.$classPrefix}button`,domProps:{"textContent":_vm._s(_vm.$t('General.Text_Book'))},on:{"click":function($event){return _vm.handleClick(_vm.space.Settings[0])}}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }