const state = {
  modalsOpen: 0
}

const getters = {
    areModalsOpen: state => !!state.modalsOpen 
}

const mutations = {
    increaseModelsOpen: (state) => {
      state.modalsOpen = state.modalsOpen + 1
    },
    decreaseModelsOpen: async (state) => {
      state.modalsOpen = Math.max(0, state.modalsOpen - 1)
    },
}

const actions = {
  openModal: ({ commit }, targetElement) => {
    commit('increaseModelsOpen')
    // Append modal to body
    document.body.appendChild(targetElement)
    // Body tag
    document.body.style.overflow = 'hidden'
    // HTML tag
    document.documentElement.style.overflow = 'hidden'
  },

  closeModal: async ({ state, commit }, targetElement) => {
    await commit('decreaseModelsOpen')

    if (targetElement.parentNode) {
      targetElement.parentNode.removeChild(targetElement)
    }

    if (!state.modalsOpen) {
      // Body tag
      document.body.style.overflow = ''
      // HTML tag
      document.documentElement.style.overflow = ''
    }
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
