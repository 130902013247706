<template>
  <div class="login-fields-component">
    <div
      :class="`${$classPrefix}title`"
      class="is-4 "
      v-html="$t('Components.ForgotLoginRegister.Login.Main_title')"
    ></div>

    <Message
      type="is-danger"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </Message>

    <div
      class="has-margin-bottom"
      :class="`${$classPrefix}content`"
      v-text="explanationText"
    ></div>

    <Form
      class="has-margin-bottom"
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <EmailField
        inputIcon=""
        :label="$t('Components.ForgotLoginRegister.General.Label_EmailAddress')"
        required
      />
      <PasswordField
        :label="$t('Components.ForgotLoginRegister.General.Label_Password')"
        inputIcon=""
      />
      <BaseFieldTemplate label="">
        <template v-slot:fields>
          <div :class="`${$classPrefix}level is-mobile`">
            <div :class="`${$classPrefix}level-left`"></div>
            <div :class="`${$classPrefix}level-right`">
              <div :class="`${$classPrefix}level-item`">
                <button
                  :class="[`${$classPrefix}button`, { 'is-loading': isProcessing }]"
                  type="submit"
                  class="is-success has-margin-left"
                  v-html="$t('Components.ForgotLoginRegister.Login.Button_Inloggen')"
                ></button>
              </div>
            </div>
          </div>
        </template>
      </BaseFieldTemplate>
    </Form>
    <hr />
    <div>
      <div class="has-margin-bottom">
        {{ textForgotPassword }}
        <a
          class="is-clickable"
          @click="handleAuthNavigate({ page: 'forgot' })"
          v-html="$t('Components.ForgotLoginRegister.Login.Link_ForgotPassword')"
        ></a>
      </div>
      <div>
        {{ textNoAccount }}
        <a
          :class="`is-clickable`"
          @click="handleAuthNavigate({ page: 'registration' })"
          v-html="$t('Components.ForgotLoginRegister.Login.Link_NoAccount')"
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/UI/Form/BaseForm'
import EmailField from '@/components/UI/Form/EmailField'
import PasswordField from '@/components/UI/Form/PasswordField'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import Message from '@/components/UI/Message'
import encryptString from '@/mixins/encryptString.js'

import authProvider from '@/providers/auth'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  components: {
    Form,
    BaseFieldTemplate,
    EmailField,
    PasswordField,
    Message,
  },

  mixins: [encryptString],

  data() {
    return {
      FailCode: false,
      isProcessing: false,
    }
  },

  computed: {
    ...mapGetters('searchStore', ['ChannelId']),

    errorMessage() {
      if (this.FailCode === 404) {
        return this.$t('Components.ForgotLoginRegister.Login.Message_Error404')
      } else if (this.FailCode === 403) {
        return this.$t('Components.ForgotLoginRegister.Login.Message_Error403')
      } else if (this.FailCode) {
        return this.$t('Components.ForgotLoginRegister.Login.Message_ErrorUnknown')
      }
      return ''
    },

    explanationText() {
      let txt = ''

      switch (this.ChannelId) {
        case 1:
          break
        case 1031:
          txt = this.$t('Components.Login.Channel_1031.Text_Explanation')

          break
        default:
          break
      }

      return txt
    },

    textForgotPassword() {
        let txt = this.$t('Components.ForgotLoginRegister.Login.Text_ForgotPassword')

        switch (this.ChannelId) {
          case 1:
            break
          case 1031:
            txt = this.$t('Components.ForgotLoginRegister.Channel_1031.Login.Text_ForgotPassword')

            break
          default:
            break
        }

        return txt
      },

      textNoAccount() {
        let txt = this.$t('Components.ForgotLoginRegister.Login.Text_NoAccount')

        switch (this.ChannelId) {
          case 1:
            break
          case 1031:
            txt = this.$t('Components.ForgotLoginRegister.Channel_1031.Login.Text_NoAccount')

            break
          default:
            break
        }

        return txt
      },
  },

  methods: {
    ...mapMutations('authStore', ['goToAuthPage', 'setProfileToken', 'setProfileKey']),
    ...mapActions('authStore', ['fireSignInEvent']),
    onStatusChange({ status }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'COMPLETED':
          this.isProcessing = false
          break
      }
    },

    handleAuthNavigate({ page }) {
      return this.goToAuthPage({ page })
    },

    handleSubmit({ formData, component }) {
      this.FailCode = false

      return new Promise((resolve, reject) => {
        let encryptedPassword = this.encryptPassword(formData.password)
        return authProvider.authorizeUser({
            Email: formData.email,
            Password: encryptedPassword,
          })
          .then((response) => {
            if (response.data.StatusCode === 1) {
              this.setProfileToken({ profileToken: response.data.ProfileToken })
              this.setProfileKey({ profileKey: response.data.ProfileKey })
              this.fireSignInEvent()

              this.$scrollTo({
                el: null,
                container: '#wizard-scroll-container',
                duration: 500,
              })
              resolve({ response: 'OK', component })
            }

            // Not Found
            else {
              if (
                response.data.StatusCode === -1 ||
                response.data.StatusCode === -3
              ) {
                this.FailCode = 404
              }

              // Not yet activated
              else if (response.data.StatusCode === -2) {
                this.FailCode = 403
              }

              reject({ response: 'FAIL', component })
              throw new Error('fail')
            }
          })
          .catch(() => {
            // console.log(err)
            // Ensure the failcode is not false at this point
            if (this.FailCode === false) {
              this.FailCode = true
            }

            reject({ response: 'FAIL', component })
          })
      })
    },
  },
}
</script>