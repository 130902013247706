<template>
  <div
    v-if="cart"
    :class="`${$classPrefix}cart has-text-left`"
  >
    <DrawerPackageDetail
      v-if="selectedPackage !== null"
      :showDrawer="selectedPackage !== null"
      :onClickClose="hideDrawer"
      :selectedPackage="selectedPackage"
    />
    <!-- <p class="subtitle is-4">
      <span>Expires in: {{ msToTime(expiresOn) }}</span>
    </p> -->

    <div
      :class="`${$classPrefix}title`"
      v-text="$t('App.Cart.Title_YourReservation')"
    ></div>

    <div class="section-cart ">
      <div :class="`${$classPrefix}row`">
        <span :class="`${$classPrefix}cart-label`">{{$t('App.Cart.Text_Location')}}:</span>
        <span :class="`${$classPrefix}cart-value`">{{
          cart.LocationName
        }}</span>
      </div>
    </div>

    <hr
      class="has-margin-top has-margin-bottom"
      v-if="nonZeroOptions.length"
    />

    <div
      v-for="space in cart.Spaces"
      :key="space.Id"
      class="section-cart section-settings"
    >
      <div :class="`${$classPrefix}row`">
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_Space') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value`">{{ space.SpaceName }}</span>
      </div>

      <div :class="`${$classPrefix}row`">
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_Date') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value`">
          {{ cart.StartDate | longDateFormat($i18n.locale) }}
        </span>
      </div>

      <div :class="`${$classPrefix}row`">
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_Time') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value`">
          {{ space.StartMinutes | minutesToTime }} -
          {{ space.EndMinutes | minutesToTime }}
        </span>
      </div>

      <div
        v-if="cart.MeetingTypeId === 1 && mDisplayMode === 'full'"
        :class="`${$classPrefix}row`"
      >
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_Setting') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value`">{{
          space.SettingId | getSettingName($i18n.locale)
        }}</span>
      </div>

      <div
        v-if="cart.MeetingTypeId !== 2 && showPrices && mDisplayMode === 'full'"
        :class="`${$classPrefix}row`"
      >
        <span :class="`${$classPrefix}cart-label`">
          <span
            v-if="space.CalculationType === 'PerHourPerSeat'"
            v-text="$t('App.Cart.Text_Seats') + ':'"
          ></span>
          <span
            v-if="space.CalculationType === 'PerHour'"
            v-text="$t('App.Cart.Text_People') + ':'"
          ></span>
        </span>
        <span :class="`${$classPrefix}cart-value`">{{ space.Seats }}</span>
      </div>

      <div
        v-if="mDisplayMode === 'full' && showPrices && !packageSelected"
        :class="`${$classPrefix}row ${$classPrefix}row-totals`"
      >
        <span :class="`${$classPrefix}cart-label`">
          <span
            v-if="space.CalculationType === 'PerHourPerSeat'"
            v-text="$t('App.Cart.Text_PerHourPerSeat') + ':'"
          ></span>
          <span
            v-if="space.CalculationType === 'PerDayPartOnly'"
            v-text="$t('App.Cart.Text_PerDayPartOnly') + ':'"
          ></span>
          <span
            v-if="space.CalculationType === 'PerDayPartPerPerson'"
            v-text="$t('App.Cart.Text_DayPartPerPerson') + ':'"
          ></span>
        </span>
        <span :class="`${$classPrefix}cart-value`">
          <span>
            {{
              calculateDisplayPrice(space.PricePerSeat, space.TaxPercentage)
                | toCurrency('nl-NL', cart.CurrencyIso)
            }}
          </span>
        </span>
      </div>

      <div
        v-if="showPrices && !packageSelected"
        :class="
          `${$classPrefix}row ${$classPrefix}row-totals has-text-weight-bold`
        "
      >
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_SubTotal') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value`">
          <span>
            {{
              calculateDisplayPrice(space.PriceTotal, space.TaxPercentage)
                | toCurrency('nl-NL', cart.CurrencyIso)
            }}
          </span>
        </span>
      </div>
    </div>

    <!-- Voucher -->
    <div
      v-if="cart.VoucherId > 0"
      :class="`${$classPrefix}row`"
    >
      <hr class="has-margin-top has-margin-bottom" />
      <div
        :class="`${$classPrefix}row has-text-weight-bold`"
        v-text="titleAppliedVoucher"
      ></div>
      <div class="section-cart section-voucher">
        <div
          v-if="cart.Voucher.Type !== 'Package'"
          :class="`${$classPrefix}row`"
          v-text="cart.VoucherName"
        ></div>

        <div
          v-if="cart.Voucher.Type === 'Package'"
          :class="`${$classPrefix}row`"
        >
          <span class="cart-amount-option">
            <span :class="`${$classPrefix}amount`">{{ cart.TotalSeats }}x
            </span>
            <span :class="`${$classPrefix}option`"><a @click="showInformation">{{ cart.VoucherName }}</a></span>
          </span>
          <span
            v-if="showPrices"
            :class="`${$classPrefix}price`"
          >
            <span :class="`${$classPrefix}cart-value`">
              {{
                cart.Voucher.VoucherPriceExclTax
                  | toCurrency('nl-NL', cart.CurrencyIso)
              }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <!-- OPTIONS -->
    <hr
      class="has-margin-top has-margin-bottom"
      v-if="nonZeroOptions.length"
    />

    <div v-if="mDisplayMode === 'short' && nonZeroOptions.length">
      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`">
          <div>
            <strong>{{ nonZeroOptions.length }}</strong>
            {{ $tc('App.Cart.Text_Options', nonZeroOptions.length) }}
          </div>
        </div>
        <div :class="`${$classPrefix}level-right`">
          <a
            class="has-icon"
            @click="setDisplayMode"
          >
            <span :class="`${$classPrefix}icon`">
              <font-awesome-icon :icon="['fal', 'chevron-down']" />
            </span>
            <span v-html="$t('General.Text_ShowOptions')"></span>
          </a>
        </div>
      </div>
    </div>
    <TransitionExpand>
      <div
        v-if="mDisplayMode === 'full'"
        class="section-cart section-options"
      >
        <div
          v-if="cart.VoucherId > 0"
          :class="`${$classPrefix}row has-margin-bottom`"
        >
          <span
            class="cart-label has-text-weight-bold"
            v-text="$t('App.Cart.Title_SelectedOptions')"
          ></span>
        </div>
        <div
          v-for="option in nonZeroOptions"
          :key="option.Id"
        >
          <span :class="`${$classPrefix}row`">
            <span class="cart-amount-option">
              <span
                v-if="showAmountOrPrice(option)"
                :class="`${$classPrefix}amount`"
              >{{ option.Amount }}x
              </span>
              <span
                v-else
                :class="`${$classPrefix}amount`"
              > &nbsp;</span>
              <span :class="`${$classPrefix}option`">{{
                option.OptionName
              }}</span>
            </span>
            <span
              v-if="showPrices && showAmountOrPrice(option)"
              :class="`${$classPrefix}price`"
            >
              <span
                :class="`${$classPrefix}cart-value`"
                v-if="!option.IsPackage"
              >
                {{
                  calculateDisplayPrice(
                    option.PriceTotal,
                    option.TaxPercentage
                  ) | toCurrency('nl-NL', cart.CurrencyIso)
                }}
              </span>
            </span>
          </span>
        </div>

        <div
          :class="
            `${$classPrefix}row ${$classPrefix}row-totals has-text-weight-bold`
          "
          v-if="nonZeroOptions.length && showPrices"
        >
          <span
            :class="`${$classPrefix}cart-label`"
            v-text="$t('App.Cart.Text_SubTotal') + ':'"
          ></span>
          <span :class="`${$classPrefix}cart-value ${$classPrefix}cart-price`">
            <span v-if="!showPriceInclTax">
              {{ optionsTotalExcl | toCurrency('nl-NL', cart.CurrencyIso) }}
            </span>
            <span v-if="showPriceInclTax">
              {{ optionsTotalIncl | toCurrency('nl-NL', cart.CurrencyIso) }}
            </span>
          </span>
        </div>
      </div>
    </TransitionExpand>

    <hr class="has-margin-top has-margin-bottom" />

    <div
      v-if="showPrices"
      class="section-cart section-totals"
    >
      <div :class="`${$classPrefix}row ${$classPrefix}row-totals`">
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_TotalExclTax') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value ${$classPrefix}cart-price`">
          <span class="has-text-left">
            {{ cart.TotalExclTax | toCurrency('nl-NL', cart.CurrencyIso) }}
          </span>
        </span>
      </div>

      <div :class="
          `${$classPrefix}row ${$classPrefix}row-totals has-text-weight-bold`
        ">
        <span
          :class="`${$classPrefix}cart-label`"
          v-text="$t('App.Cart.Text_TotalInclTax') + ':'"
        ></span>
        <span :class="`${$classPrefix}cart-value ${$classPrefix}cart-price`">
          <span class="has-text-left">
            {{ cart.TotalInclTax | toCurrency('nl-NL', cart.CurrencyIso) }}
          </span>
        </span>
      </div>
    </div>

    <div
      v-if="displayMode === 'short' && showPrices"
      class="has-text-centered has-margin-top"
    >
      <a
        @click="setDisplayMode"
        :class="`${$classPrefix}button ${$classPrefix}cart-toggle`"
        class="is-small is-rounded"
      >
        <span :class="`${$classPrefix}icon`">
          <font-awesome-icon :icon="[
              'fal',
              mDisplayMode === 'full' ? 'chevron-up' : 'chevron-down',
            ]" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import TransitionExpand from '@/components/TransitionExpand'

import DrawerPackageDetail from '@/components/Options/AvailablePackages/DrawerPackageDetail'

export default {
  name: 'Cart',

  components: {
    TransitionExpand,
    DrawerPackageDetail,
  },

  props: {
    displayMode: {
      default: 'short',
      type: String,
    },

    showOnlyCart: {
      type: Boolean,
      default: false,
    },

    showPriceInclTax: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      expiresOn: 0,
      mDisplayMode: this.displayMode,
      timer: null,
      selectedPackage: null,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('widgetStore', ['widgetState']),
    ...mapGetters('searchStore', ['LastStep']),
    ...mapState('searchStore', ['maxSteps']),

    titleAppliedVoucher() {
      if (this.cart.VoucherId === 0) {
        return ''
      }

      if (this.cart.Voucher.Type !== 'Package') {
        return this.$t('App.Cart.Text_VoucherApplied', {
          type: this.$t(`App.Cart.Label_VoucherType${this.cart.Voucher.Type}`),
        })
      }
      if (this.cart.Voucher.Type === 'Package') {
        return this.$t('App.Cart.Text_SelectPackage')
      }
      return ''
    },

    /**
     * Do not show options which had their amount reduced to 0
     */
    nonZeroOptions() {
      return this.cart.Options.filter((option) => option.Amount > 0)
    },

    optionsTotalExcl() {
      return this.cart.Options.reduce((total, option) => {
        if (!this.optionInPackage(option)) {
          return total + option.PriceTotal
        }
        return total
      }, 0)
    },

    optionsTotalIncl() {
      return this.cart.Options.reduce((total, option) => {
        return total + option.PriceTotal * ((100 + option.TaxPercentage) / 100)
      }, 0)
    },

    packageSelected() {
      let result = false

      if (this.cart.Voucher && this.cart.Voucher.Type === 'Package') {
        result = true
      }

      return result
    },

    spacePriceInPackage() {
      let result = false

      if (this.cart && this.cart.Voucher && this.cart.Voucher.Prices) {
        result = true
      }

      return result
    },

    showPrices() {
      let result = true

      if (!this.widgetState.ShowPrices) {
        result = false
      }

      return result
    },
  },

  created() {
    this.activateCountDown()
  },

  watch: {
    LastStep: function(step) {
      if (step === this.maxSteps - 1 || step === this.maxSteps) {
        this.mDisplayMode = 'full'
      }
    },
  },

  beforeDestroy() { },

  methods: {
    ...mapMutations('cartStore', ['setCart']),
    ...mapActions('cartStore', ['deleteCart', 'removeVoucherFromCart']),
    ...mapActions('searchStore', ['goToStep']),
    ...mapActions('billingStore', ['clearStoreData']),

    calculateDisplayPrice(price, taxPercentage) {
      if (this.showPriceInclTax) {
        price = price * ((100 + taxPercentage) / 100)
      }

      return price
    },

    showInformation() {
      this.setShowDrawer(this.cart.Voucher)
    },

    setShowDrawer(data) {
      this.selectedPackage = data
    },

    hideDrawer() {
      this.selectedPackage = null
    },

    optionInPackage(option) {
      let result = false

      if (
        this.cart &&
        this.cart.Voucher &&
        this.cart.Voucher.Type === 'Package'
      ) {
        result =
          this.cart.Voucher.Options.findIndex(
            (o) => o.OptionId === option.OptionId
          ) > -1
      }
      return result
    },

    showAmountOrPrice(option) {
      let result = true
      if (
        this.cart &&
        this.cart.Voucher &&
        this.cart.Voucher.Type === 'Package' &&
        option.InVoucher
      ) {
        result = false
      }

      if (option.InVoucher && (this.cart.Voucher && this.cart.Voucher.Type !== 'Package')) {
        result = true
      }

      return result
    },

    activateCountDown() {
      this.timer = window.setInterval(this.countdown, 1000)
    },

    countdown() {
      let self = this
      let expiresOn = 0

      if (self.cart) {
        let expireDateTime =
          self.cart.CreatedOn + 60000 * self.cart.MinutesToExpire
        expiresOn = expireDateTime - new Date().getTime()
        self.expiresOn = expiresOn
      }

      self.expiresOn = expiresOn - 1000

      if (self.expiresOn <= 0 && self.cart) {
        self.reset()
      }
    },

    msToTime(s) {
      let ms = s % 1000
      s = (s - ms) / 1000
      let secs = s % 60
      s = (s - secs) / 60
      let mins = s % 60
      let hrs = (s - mins) / 60

      return (
        ('0' + hrs).slice(-2) +
        ':' +
        ('0' + mins).slice(-2) +
        ':' +
        ('0' + secs).slice(-2)
      )
    },

    reset() {
      window.clearInterval(this.timer)

      this.deleteCart()
      this.clearStoreData()

      this.goToStep({
        step: 1,
      })
    },

    setDisplayMode() {
      let mode = this.mDisplayMode

      if (mode === 'short') {
        this.mDisplayMode = 'full'
      }

      if (mode === 'full') {
        this.mDisplayMode = 'short'
      }
    },
  },
}
</script>
