<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <!-- Available locations -->
    <LocationCards
      key="locations"
      class="ResultList"
      v-if="LastStep === 1"
    />

    <!-- List op spaces and configurations -->
    <LocationSpaces
      key="spaces"
      class="ResultList"
      v-else-if="LastStep === 2"
    />

    <!-- Options -->
    <OptionLists
      key="options"
      class="ResultList"
      v-else-if="showOptionsStep"
    />

    <!-- Booking details form -->
    <ReservationForm
      key="reservationForm"
      class="ResultList"
      v-else-if="showReservationForm"
    />

    <!-- Accounts Contains
    - Login / Register / Reset password
    - Company selector steps
     -->
    <AccountFlow
      key="AccountFlow"
      class="ResultList"
      v-else-if="showAccountFlow"
    />
    <div
      key="confirmation"
      v-else
    >
      <ConfirmationController />
    </div>
  </transition>
</template>

<script>
import LocationCards from '@/components/SearchResults/LocationCards'
import LocationSpaces from '@/components/LocationSpaces/LocationSpaces'
import OptionLists from '@/components/Options/OptionLists'
import ReservationForm from '@/components/ReservationDetails/ReservationForm'
import AccountFlow from '@/components/InvoiceDetails/AccountFlow'
import ConfirmationController from '@/components/Confirmation/ConfirmationController'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MainContent',
  components: {
    LocationCards,
    LocationSpaces,
    OptionLists,
    ReservationForm,
    AccountFlow,
    ConfirmationController
  },
  computed: {
    ...mapState('widgetStore', ['widgetState']),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapGetters('searchStore', ['LastStep']),

    showOptionsStep() {
      return this.LastStep === 3 && this.widgetState.ShowOptionsStep
    },

    showReservationForm() {
      return (this.LastStep === 4 && this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
        || (this.LastStep === 3 && !this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
    },

    showAccountFlow() {
      return (this.LastStep === 5 && this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
        || (this.LastStep === 4 && (
          (!this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep) || (this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep))
        )
        || (this.LastStep === 3 && !this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep)
    },
  },
}
</script>

<style lang="scss">
.ResultList {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // min-width: 60%;
  // max-width: 60%;

  @media (max-width: 930px) {
    width: 100%;
  }

  // .LocationCard {
  //   margin-bottom: 30px;
  //   width: calc(100% / 2 - 15px);

  //   &:nth-child(odd) {
  //     margin-right: 15px;
  //   }
  // }
}
</style>
