<template>
  <div>
    <Message>
      <div
        :class="`${$classPrefix}content`"
        v-html="$t(`Components.Overlap.Meetingtype_${currentSearch.MeetingtypeId}.Message_Overlap`)"
      ></div>
    </Message>

    <SearchWidget v-if="LastStep !== 1" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'
import SearchWidget from '@/components/SearchWidget'
export default {
  name: 'overlap',

  components: {
    Message,
    SearchWidget
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapGetters('searchStore', ['isFinished', 'LastStep', 'search']),
  },
}
</script>

<style lang="scss" scoped>
</style>