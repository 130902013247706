var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:"sfPopupBackground",class:`${_vm.$classPrefix}widget ${_vm.$classPrefix}sfPopup-background`}),_c('div',{key:"sfPopup",ref:_vm.refName,class:`${_vm.$classPrefix}widget ${_vm.$classPrefix}sfPopup ${_vm.nrOfColumnsCssClass}`},[_c('div',{class:`${_vm.$classPrefix}card`},[_c('div',{class:`${_vm.$classPrefix}card-content`},[_c('div',{class:`has-text-weight-bold is-size-5 has-margin-bottom-half`,domProps:{"textContent":_vm._s(_vm.$t('Components.SearchFormPopup.Main_Title'))}}),(_vm.showResetLink)?_c('div',{staticClass:"has-margin-bottom-half"},[_c('a',{domProps:{"textContent":_vm._s(_vm.$t('Components.SearchFormPopup.Link_clearCoutriesPlaces'))},on:{"click":function($event){return _vm.resetCountryAndCitySelection()}}})]):_vm._e(),_c('div',{class:`${_vm.$classPrefix}columns is-mobile is-gapless`},[(_vm.showCountrySelect)?_c('div',{class:`${_vm.$classPrefix}column`},[_c('div',{class:`${_vm.$classPrefix}list list-countries`},[_c('div',{key:`c_notSet`,class:[
                  `${_vm.$classPrefix}list-item is-unselectable`,
                  { 'is-active': _vm.countryCode === '' },
                ],domProps:{"textContent":_vm._s(_vm.$t('Components.SearchFormPopup.Option_AllCountries'))},on:{"click":function($event){return _vm.setCountry()}}}),_vm._l((_vm.countries),function(country){return _c('div',{key:`c_${country.Id}_${country.CountryCode}`,staticClass:"country-item",class:[
                  `${_vm.$classPrefix}list-item is-unselectable`,
                  { 'is-active': _vm.countryCode === country.CountryCode },
                ],domProps:{"textContent":_vm._s(country.Name)},on:{"click":function($event){return _vm.setCountry(country)}}})})],2)]):_vm._e(),(_vm.showSearchInput)?_c('div',{class:`${_vm.$classPrefix}column`},[_c('nav',{class:`${_vm.$classPrefix}panel-locations is-shadowless`},[_c('div',{class:`${_vm.$classPrefix}panel-block is-paddingless`},[_c('div',{class:`${_vm.$classPrefix}control`},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchTerm),expression:"searchTerm",modifiers:{"trim":true}}],ref:"searchInput",class:`${_vm.$classPrefix}input`,attrs:{"autocomplete":"off","aria-autocomplete":"both","type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.searchTerm)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value.trim()},function($event){return _vm.delay(_vm.getAutoCompleteItems, 200)}],"blur":function($event){return _vm.$forceUpdate()}}})])]),(_vm.autoCompleteItems.length)?_c('div',{class:`${_vm.$classPrefix}list panel-list-items`},[_c('transition-group',{attrs:{"name":"fadd","mode":"out-in"}},[_c('div',{key:"searchAll",class:[
                      `${_vm.$classPrefix}list-item is-unselectable`,
                      { 'is-active': _vm.isSelectedAcOption({ Type: -1 }) },
                    ],domProps:{"textContent":_vm._s(_vm.$t('Components.SearchFormPopup.Link_clearPlaces'))},on:{"click":function($event){return _vm.resetCitySelection()}}}),_vm._l((_vm.autoCompleteItems),function(acOption){return _c('div',{key:`a_${acOption.Result}`,class:[
                      `${_vm.$classPrefix}list-item is-unselectable`,
                      { 'is-active': _vm.isSelectedAcOption(acOption) },
                    ],domProps:{"innerHTML":_vm._s(
                      !acOption.Id
                        ? _vm.$t(
                            'Components.SearchFormPopup.Label_AllLocationIn',
                            { city: acOption.Result }
                          )
                        : acOption.Result
                    )},on:{"click":function($event){[_vm.selectAutoCompleteOption(acOption), _vm.apply()]}}})})],2)],1):_vm._e(),(!_vm.autoCompleteItems.length && !_vm.isFirstLoad)?_c('Message',{staticClass:"has-margin-top"},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)]):_vm._e()])]),_c('footer',{class:`${_vm.$classPrefix}card-footer`},[_c('a',{class:`${_vm.$classPrefix}card-footer-item`,domProps:{"textContent":_vm._s(_vm.$t('General.Text_Cancel'))},on:{"click":function($event){return _vm.hide()}}}),_c('a',{class:[` ${_vm.$classPrefix}card-footer-item has-text-weight-bold`],domProps:{"textContent":_vm._s(_vm.$t('General.Text_Apply'))},on:{"click":function($event){return _vm.apply()}}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }