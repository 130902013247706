<template>
  <div class="LocationSpaces">
    <DrawerLocationSpaceDetail
      v-if="selectedLocationSpace !== null"
      :showDrawer="selectedLocationSpace !== null"
      :onClickClose="hideDrawer"
      :selectedSpace="selectedLocationSpace"
    />

    <template v-if="currentSearch.MeetingtypeId !== 2">
      <LocationSpaceMS
        :class="`${$classPrefix}box`"
        v-for="space in spaces"
        :key="space.SpaceId"
        :space="space"
        @showDrawer="setShowDrawer"
      />
    </template>
    <template v-else>
      <LocationSpaceWS
        :class="`${$classPrefix}box`"
        v-for="space in spaces"
        :key="space.SpaceId"
        :space="space"
        @showDrawer="setShowDrawer"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import DrawerLocationSpaceDetail from './DrawerLocationSpaceDetail'
import LocationSpaceMS from './LocationSpaceMS'
import LocationSpaceWS from './LocationSpaceWS'

export default {
  name: 'LocationSpaces',
  components: {
    DrawerLocationSpaceDetail,
    LocationSpaceMS,
    LocationSpaceWS,
  },

  data() {
    return {
      drawerType: '',
      selectedLocationSpace: null,
      showAllSpaces: false,
    }
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapGetters('availabilityStore', ['selectedLocation']),
    ...mapGetters('searchStore', [
      'search',
      'selectedSetting',
      'selectedEventType',
    ]),
    ...mapState('widgetStore', ['widgetState']),

    highlightedSpace() {
      let self = this
      let space = null

      if (self.spaces && self.spaces.length > 0) {
        let sortedSpaces = self.spaces.sort(self.compareValues('M2'))
        if (sortedSpaces && sortedSpaces.length > 0) {
          space = sortedSpaces[0]
        }
      }

      return space
    },

    spaces() {
      if (!this.selectedLocation) {
        return []
      }
      /**
       * Location Spaces are actually space-setting combinations
       * One space may occur multiple times with different seating settings
       * We go through the list and group the space-setting combinations by actual spaces
       * The various seating options are added as `alternatives`
       **/
      let spaces = this.selectedLocation.Spaces

      // Filter spaces on event type
      if (this.selectedEventType > 0) {
        spaces = spaces.filter((s) =>
          s.EventTypes.includes(this.selectedEventType)
        )
      }

      // Filter spaces on configuration
      if (this.selectedSetting > 0) {
        spaces = spaces.filter(
          (s) => s.SettingId === this.selectedSetting
        )
      }

      if (!this.showAllSpaces) {
        spaces = spaces.filter((s) => s.SpaceAvailable)
      }

      return spaces.reduce((spaces, SpaceSetting) => {
        // Check if the space is already listed
        let index = spaces.findIndex(
          (space) => space.SpaceId === SpaceSetting.SpaceId
        )

        // If the space was not listed yet, add it
        if (index === -1) {
          index =
            spaces.push({
              CalculationType: SpaceSetting.SpacePrice
                ? SpaceSetting.SpacePrice.CalculationType
                : null,
              CurrencyIso: SpaceSetting.CurrencyIso || '',
              Features: SpaceSetting.Features || [],
              IncludedOptions: SpaceSetting.IncludedOptions || [],
              M2: SpaceSetting.M2,
              Price: SpaceSetting.Price,
              PriceTotal: SpaceSetting.PriceTotal,
              Settings: [],
              SpaceId: SpaceSetting.SpaceId,
              SpaceName: SpaceSetting.SpaceName,
              SpaceDescription: SpaceSetting.SpaceDescription,
              SpaceImage: SpaceSetting.SpaceImage,
              Warnings: SpaceSetting.Warnings,
            }) - 1
        }
        // Add the setting
        if (!spaces[index].Price && SpaceSetting.Price) {
          spaces[index].Price = SpaceSetting.Price
          spaces[index].PriceTotal = SpaceSetting.PriceTotal
        }
        // Add the setting
        let _settingNotExist = spaces[index].Settings.find(sSetting => sSetting.SettingId === SpaceSetting.SettingId)
        if (typeof _settingNotExist === 'undefined') {
          spaces[index].Settings.push(SpaceSetting)
        }
        return spaces
      }, [])
    },
  },

  methods: {
    setShowDrawer(data) {
      this.selectedLocationSpace = data
    },

    hideDrawer() {
      this.selectedLocationSpace = null
    },

    compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return order === 'desc' ? comparison * -1 : comparison
      }
    },
  },
}
</script>

<style lang="scss">
.LocationDetailsHeader {
  margin-bottom: 45px;
}
</style>
