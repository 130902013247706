<template>
  <UiComponentDrawer
    v-if="showDrawer"
    :drawerTitle="selectedOption.OptionName"
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <div class="wrapper-images">
        <figure class="image is-3by2 has-background-grey-lighter">
          <img
            src="@/assets/images/spacer1_1.gif"
            :style="{
              backgroundImage: `url('${$options.filters.getLocationImage(
                selectedOption.OptionImage,
                selectedOption.LocationId,
                '640'
              )}')`,
            }"
          >
        </figure>
      </div>
      <div :class="`${$classPrefix}content`" v-html="selectedOption.OptionDescription"></div>
    </template>
  </UiComponentDrawer>
</template>

<script>
import UiComponentDrawer from '@/components/UI/Drawer'

export default {
  name: 'drawer-location-option-detail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false
    },
    position: {
      type: String,
      default: 'right'
    },
    showDrawer: {
      type: Boolean,
      default: false
    },

    // props for content
    selectedOption: {
      type: Object,
      required: true
    }
  },

  components: {
    UiComponentDrawer
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.wrapper-images {
  margin-top: -$gap;
  margin-right: -$gap;
  margin-bottom: $gap;
  margin-left: -$gap;
}
</style>