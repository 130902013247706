<template>
  <transition-group
    name="fade"
    mode="out-in"
  >
    <div
      v-for="(video, index) in media"
      :key="'m_' + index"
      :class="`${$classPrefix}card`"
    >
      <div :class="`${$classPrefix}card-content`">
        <component
          v-if="getComponentType(video)"
          v-bind:is="getComponentType(video)"
          :src="video.VideoUrl"
        />
      </div>
    </div>
  </transition-group>
</template>

<script>
import VimeoEmbed from '@/components/VideoEmbeds/VimeoEmbed'
import YouTubeEmbed from '@/components/VideoEmbeds/YoutubeEmbed'
export default {
  name: 'location-media',

  props: {
    media: {
      type: Array,
      default: () => { return [] }
    }
  },

  components: {
    VimeoEmbed,
    YouTubeEmbed
  },

  methods: {
    getComponentType(videoSource) {
      let component = ''

      switch (videoSource.VideoProvider) {
        case 'Vimeo':
          component = 'VimeoEmbed'
          break;
        case 'Youtube':
          component = 'YouTubeEmbed'
          break;
      }
      return component
    }
  }
}
</script>

<style lang="scss" scoped>
</style>