<template>
  <div
    class="barContainer is-inline-flex has-background-grey-light"
    :style="`width: ${size * 5}px; height: ${size}px`"
  >
    <div class="iconBar is-inline-flex">
      <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
      >
        <path d="M16,0l0,16l-16,0l0,-16l16,0Zm-8,0c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z" />
      </svg>

      <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
      >
        <path d="M16,0l0,16l-16,0l0,-16l16,0Zm-8,0c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z" />
      </svg>

      <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
      >
        <path d="M16,0l0,16l-16,0l0,-16l16,0Zm-8,0c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z" />
      </svg>

      <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
      >
        <path d="M16,0l0,16l-16,0l0,-16l16,0Zm-8,0c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z" />
      </svg>

      <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
      >
        <path d="M16,0l0,16l-16,0l0,-16l16,0Zm-8,0c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z" />
      </svg>
    </div>
    <div
      class="scoreBar has-background-success"
      :style="`width: ${score}%;`"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24
    },
    score: {
      type: Number,
      default: 0
    }
  }
}
</script>


<style lang="scss" scoped>
.barContainer {
  flex-wrap: nowrap;
  position: relative;

  .iconBar {
    flex-wrap: nowrap;
    position: absolute;
    z-index: 1;
  }

  .scoreBar {
    position: absolute;
    height: 100%;
    z-index: 0;
  }
}
</style>
