<template>
  <div class="AvailablePackages">
    <DrawerPackageDetail
      v-if="selectedPackage !== null"
      :showDrawer="selectedPackage !== null"
      :onClickClose="hideDrawer"
      :selectedPackage="selectedPackage"
    />

    <AvailablePackage
      v-for="(availablePackage, index) in availablePackages"
      :availablePackage="availablePackage"
      :key="'p_'+index"
      :class="`${$classPrefix}box`"
      @showDrawer="setShowDrawer"
    />
  </div>
</template>

<script>
import DrawerPackageDetail from './DrawerPackageDetail'
import AvailablePackage from './AvailablePackage'

import { mapGetters } from 'vuex'

export default {
  name: 'AvailablePackages',

  components: {
    DrawerPackageDetail,
    AvailablePackage
  },

  data() {
    return {
      selectedPackage: null
    }
  },

  computed: {
    ...mapGetters('cartStore', ['availablePackages', 'CartKey', 'isBusy']),
  },

  methods: {
    setShowDrawer(data) {
      this.selectedPackage = data
    },

    hideDrawer() {
      this.selectedPackage = null
    }
  },
}
</script>

<style lang="scss">
.AvailablePackages {
  // display: none;
  animation: fadeEffect 1s; /* Fading effect takes 500ms second */
}
/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
