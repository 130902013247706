import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

let messages = {}

/**
 * Deep object merge
 * @param  {...any} objects 
 */
function deepMerge(...objects) {
  const isObject = (obj) => obj && typeof obj === 'object'

  function deepMergeInner(target, source) {
    Object.keys(source).forEach((key) => {
      const targetValue = target[key]
      const sourceValue = source[key]

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue)
      } else {
        target[key] = sourceValue
      }
    });

    return target
  }

  if (objects.length < 2) {
    throw new Error('deepMerge: this function expects at least 2 objects to be provided')
  }

  if (objects.some(object => !isObject(object))) {
    throw new Error('deepMerge: all values should be of type "object"')
  }

  const target = objects.shift()
  let source = {}

  while ((source = objects.shift())) {
    deepMergeInner(target, source)
  }

  return target
}

/**
 * Transform path to nested object
 * @param {array} path 
 * @param {any} value 
 */
function sourcePathToObject(path, value) {
  let temp = {}
  let object = temp

  for (var i = 0; i < path.length; i++) {
    if (i === path.length - 1) {
      temp = temp[path[i]] = value
    } else {
      temp = temp[path[i]] = {}
    }
  }

  return object
}

/**
 * Get all context of the json resource files in the main and subfolders of lang
 * Read more about require.context: https://webpack.js.org/guides/dependency-management/
 */
function loadLocaleMessages() {
  const resource = require.context('./locales', true, /\.json$/i)

  // const messages = {}
  resource.keys().forEach((key) => {
    // Remove ./ at the begin and .extension at the end of string and replace all forward slashes with a dot.
    let path = key.replace(/(^\.\/+|\.[^.]+$)/gm, '').replace(/\//g, '.')

    // Update messages
    for (const [locale, obj] of Object.entries(resource(key))) {
      let content = sourcePathToObject(path.split('.'), obj)
      messages[locale] = Object.prototype.hasOwnProperty.call(messages, locale) ?
        deepMerge(messages[locale], content)
        :
        content

      content = null
    }
    path = null
  })
  return messages
}

/**
 * I18n config
 */
export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
})
