<template>
  <span :class="[`${$classPrefix}icon`, size]">
    <component v-bind:is="mySvg"></component>
  </span>
</template>

<script>
export default {
  props: {
    settingId: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  },

  data() {
    return {
    }
  },

  computed: {
    mySvg() {
      return () => import(`@/components/UI/Settings/Setting${this.settingId}.vue`)
    }
  }
}
</script>

<style lang="scss" scoped>
object {
  width: 100%;
}
</style>
