<template>
  <div :class="`${$classPrefix}content`" v-html="description"></div>
</template>

<script>
export default {
  name: 'location-description',

  props: {
    descriptions: {
      type: Array,
      default: () => { return [] }
    }
  },

  data() {
    return {
      description: ''
    }
  },

  created() {
    this.getText()
  },

  methods: {
    getText() {
      let languageId = this.$i18n.locale === 'nl' ? 52 : 65
      let output = this.descriptions.find(d => d.LanguageId === languageId)
      if (!output) {
        output = this.descriptions.find(d => d.LanguageId === 65)
      }
      this.description = output.Description
    }
  }
}
</script>

<style lang="scss" scoped>
</style>