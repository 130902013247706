<template>
  <div
    ref="searchWidget"
    class="searchWidget"
    :class="[
      widgetDeviceClass,
      addFieldClass().replace('-field', ''),
      ,`is-${nrOfFields}-fields`
    ]"
  >
    <form autocomplete="off">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="widgetInitialized"
          :class="[`${$classPrefix}columns is-mobile is-gapless is-multiline`, { 'is-4' : nrOfFields === 3}]"
        >

          <!-- Meeting type selector -->
          <div
            v-if="showMeetingTypeSelector"
            :class="[`column-meetingTypeSelect ${$classPrefix}column`, { 'is-4' : nrOfFields === 3}]"
          >
            <div
              class="field-types"
              :class="[`${$classPrefix}field`, addFieldClass()]"
            >
              <div
                :class="`${$classPrefix}control`"
                class="has-icons-left"
              >
                <div :class="`${$classPrefix}select`">
                  <select
                    v-model.number="meetingtypeId"
                    class="is-shadowless"
                  >
                    <option
                      v-for="(meetingtype, index) in widgetState.Meetingtypes"
                      :key="index"
                      :selected="
                      Number(meetingtypeId) === Number(meetingtype)
                    "
                      :value="meetingtype"
                      v-html="$t(`App.SearchWidget.BookingTypes.${meetingtype}`)"
                    ></option>
                  </select>
                </div>
                <span
                  :class="`${$classPrefix}icon`"
                  class="is-small is-left"
                >
                  <font-awesome-icon :icon="['fad', 'chair']" />
                </span>
              </div>
            </div>
          </div>

          <!-- Search -->
          <div
            v-if="showSearchInput"
            :class="`column-searchInput ${$classPrefix}column`"
          >
            <div class="field-search">
              <div
                :class="`${$classPrefix}control`"
                class="has-icons-left"
              >
                <SearchInput
                  :placeholder="$t('App.SearchWidget.Placeholder_SearchTerm')"
                  @action="actionSearchTermUpdate()"
                />
                <span
                  :class="`${$classPrefix}icon`"
                  class="is-small is-left"
                >
                  <font-awesome-icon :icon="['fad', 'map-marker-alt']" />
                </span>
              </div>
            </div>
          </div>

          <!-- Date time selector -->
          <div :class="[`column-dates ${$classPrefix}column`, { 'is-3' : nrOfFields === 3}]">
            <div
              class="field-date"
              :class="[`${$classPrefix}field`, addFieldClass()]"
            >
              <div
                :class="`${$classPrefix}control`"
                class="has-icons-left"
              >
                <DateTimeInput
                  :key="`${currentSearchHash}DateSelector${search.StartMinutes}${search.EndMinutes}`"
                  :dates="dates"
                  :startMinutes="search.StartMinutes"
                  :endMinutes="search.EndMinutes"
                  :mode="datePickerMode"
                  dateFormat="shortDateFormat"
                  @action="updateDateTime"
                />
                <span
                  :class="`${$classPrefix}icon`"
                  class="is-small is-left"
                >
                  <font-awesome-icon :icon="['fad', 'calendar-alt']" />
                </span>
              </div>
            </div>
          </div>

          <!-- Number of people -->
          <div
            v-show="meetingtypeId === 1"
            :class="[`column-nrOfPeople ${$classPrefix}column`, { 'is-2' : nrOfFields === 3}]"
          >
            <div
              class="field-nrOfPeople"
              :class="[`${$classPrefix}field`, addFieldClass()]"
            >
              <div
                :class="[
                  `${$classPrefix}control`,
                  { 'is-danger': validateNrOfPeopleFailed },
                ]"
                class="has-icons-left"
              >
                <input
                  type="number"
                  autocomplete="off"
                  aria-autocomplete="both"
                  :class="[`${$classPrefix}input`, {'is-danger': !validNrSeats}]"
                  v-model.number="seats"
                  :disabled="meetingtypeId === 2"
                  @input="delay(() => {doSearch(searchImmediately)}, 400)"
                />
                <span
                  :class="`${$classPrefix}icon`"
                  class="is-small is-left"
                >
                  <font-awesome-icon :icon="['fad', 'user-check']" />
                </span>
              </div>
            </div>
          </div>

          <!-- Search button -->
          <div
            v-if="!searchImmediately"
            :class="[`column-button ${$classPrefix}column`, { 'is-3' : nrOfFields === 3}]"
          >
            <div class="field-button">
              <a
                :class="[
                `${$classPrefix}button`,
                {
                  'is-loading': isSavingSearch,
                  'is-success': !disabledSearchCall || isSavingSearch,
                  'is-disabled': disabledSearchCall || !isSavingSearch,
                },
              ]"
                :disabled="disabledSearchCall"
                @click="startSearch()"
                v-html="labelSearchButton"
              ></a>
            </div>
          </div>
        </div>
      </transition>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import EventBus from '@/eventbus/EventBus'
import hash from 'object-hash'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

import DateTimeInput from '@/components/UI/Form/DateTimeInput'
import SearchInput from '@/components/UI/Form/SearchInput'

export default {
  props: {
    searchImmediately: {
      type: Boolean,
      default: false
    }
  },

  components: {
    DateTimeInput,
    SearchInput
  },

  data() {
    return {
      datepickerPrefix: new Date().getTime(),
      currentDate: new Date(),
      showDatePicker: false,
      timer: null,
      isSavingSearch: false,
      isLoading: false,
      widgetWidth: 0,
      validNrSeats: true
    }
  },

  /**
   * Computed
   */
  computed: {
    ...mapGetters('searchStore', ['LastStep']),
    ...mapState('widgetStore', ['widgetState', 'preSelectedLocationId', 'widgetInitialized']),
    ...mapState('searchStore', ['search', 'currentSearch']),
    ...mapState('widgetStore', [
      'autoCompleteItems',
      'meetingtypeIds',
      'timeslots',
    ]),

    currentSearchHash() {
      return 'sw_' +
        (this.currentSearch ? this.currentSearch.MeetingtypeId + this.currentSearch.StartDate + this.currentSearch.StartMinutes + this.currentSearch.EndMinutes
          :
          'notSet')
    },

    showSearchInput() {
      return this.widgetState
        && (
          this.widgetState.ShowCountrySelect
          || this.widgetState.ShowSearchInput
        )
        && !this.preSelectedLocationId
    },

    showMeetingTypeSelector() {
      return this.widgetState && this.widgetState.ShowMeetingtypeSelect
    },

    meetingtypeId: {
      get() {
        return this.search.MeetingtypeId
      },
      async set(value) {
        this.isLoading = true
        await this.setMeetingtypeId(value)
        this.isLoading = false
        this.doSearch(this.searchImmediately)
      },
    },

    seats: {
      get() {
        return this.search.Seats
      },
      set(value) {
        this.validNrSeats = !this.widgetState
          ? true
          : !(value < this.widgetState.MinSeats)
        this.setSeats(value)
      },
    },

    dates() {
      return this.meetingtypeId !== 3 ?
        this.$options.filters.dateObjectIsoDateString(this.search.StartDate)
        :
        {
          start: this.$options.filters.dateObjectIsoDateString(this.search.StartDate),
          end: this.$options.filters.dateObjectIsoDateString(this.search.StartDate)
        }
    },

    datePickerMode() {
      return this.meetingtypeId !== 3 ? 'single' : 'range'
    },

    widgetDeviceClass() {
      let cssClass = 'is-mobile'
      if (this.widgetWidth >= 973) {
        cssClass = 'is-desktop'
      } else if (this.widgetWidth >= 768) {
        cssClass = 'is-tablet'
      } else if (this.widgetWidth > 520) {
        cssClass = 'is-mobile'
      } else {
        cssClass = 'is-compact'
      }
      return cssClass
    },

    nrOfFields() {
      let counter = 2 // Date and number of people field
      if (this.showSearchInput) {
        counter++
      }
      if (this.showMeetingTypeSelector) {
        counter++
      }
      if (this.meetingtypeId === 2) {
        counter--
      }
      return counter
    },

    validateNrOfPeopleFailed() {
      if (this.meetingtypeId === 1 && Number(this.search.Seats) === 0) {
        return true
      }
      return false
    },

    disabledSearchCall() {
      if (
        (this.widgetState && (this.widgetState.OpeningHour.NrOfLocations === 0 && this.meetingtypeId !== 3))
        || this.search.StartMinutes >= this.search.EndMinutes
        || this.validateNrOfPeopleFailed
        || this.isLoading
        || !this.validNrSeats
      ) {
        return true
      }
      return false
    },

    labelSearchButton() {
      return this.$t(`App.SearchWidget.Button_Search[${1}]`)
    }
  },

  mounted() {
    let self = this

    EventBus.$on('windowResizeListener', this.addWidgetClass)
    this.addWidgetClass()
    let intervaCounter = 1;
    let interval = setInterval(() => {
      self.addWidgetClass()
      if (intervaCounter > 2) {
        clearInterval(interval)
      }
      intervaCounter++
    }, 250);
  },

  beforeDestroy() {
    EventBus.$off('windowResizeListener', this.addWidgetClass)
    this.restoreCurrentSearch()
  },

  methods: {
    ...mapMutations('widgetStore', ['setOpeningHours']),
    ...mapActions('widgetStore', [
      'getOpeningHoursData',
      'getMeetingtypesData'
    ]),

    ...mapMutations('searchStore', [
      'setSeats',
      'setSearchDetails',
    ]),
    ...mapActions('searchStore', [
      'setDateTime',
      'setMeetingtypeId',
      'saveSearchData'
    ]),

    /**
     * Delay an action function
     */
    delay(callback, ms) {
      let self = this
      if (self.timer) {
        clearTimeout(self.timer)
      }

      self.timer = setTimeout(() => {
        callback.call(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    /**
     * Add field type class
     */
    addFieldClass() {
      if (this.meetingtypeId === 0) {
        return ''
      }
      let arr = {
        1: 'is-meetingspace-field',
        2: 'is-workspace-field',
        3: 'is-deskspace-field',
      }
      return arr[this.meetingtypeId]
    },

    /**
     * Add widget type class
     */
    addWidgetClass() {
      if (this.$refs.searchWidget) {
        this.widgetWidth = this.$refs.searchWidget.offsetWidth
      }
    },

    /**
     * Update opening hours and meeting type when search term is changes
     */
    async actionSearchTermUpdate() {
      this.isLoading = true
      await this.loadOpeningHoursData()
      await this.getMeetingtypesData()
      this.isLoading = false
      this.doSearch(this.searchImmediately)
    },

    async loadOpeningHoursData() {
      await this.getOpeningHoursData()
        .then(response => {
          this.setOpeningHours(response.data)
        })
    },

    startSearch() {
      if (this.disabledSearchCall) { return }
      this.doSearch(true)
    },

    /**
     * Search for available locations
     */
    async doSearch(allowSearch) {
      if (!allowSearch || !this.validNrSeats) {
        return false
      }

      this.isSavingSearch = true
      await this.saveSearchData()
      this.isSavingSearch = false
    },

    /**
     * Restore search when user choose to hide search form 
     * without doing a search request
     */
    async restoreCurrentSearch() {
      if (this.currentSearch) {
        this.currentSearch.LastStep = this.LastStep || 1
        this.setSearchDetails(this.$objectHelper.cleanSource(this.currentSearch))
      }
    },

    /**
     * Update search date and time values
     */
    updateDateTime(val) {
      this.setDateTime(val)
      this.doSearch(this.searchImmediately)
    }
  },
}
</script>
