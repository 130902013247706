<template>
  <article
    :class="`${$classPrefix}media`"
    class="media-comments"
  >
    <div class="media-left">
      <p class="image is-32x32">
        <img
          src="@/assets/images/spacer1_1.gif"
          class="is-round has-shadow"
          :style="{
            backgroundImage: `url(${$options.filters.getProfileImage(
              mComment.ProfileImage,
              mComment.ProfileId,
              38
            )})`,
          }"
        />
      </p>
    </div>
    <div :class="`${$classPrefix}media-content`">
      <div :class="`${$classPrefix}content`">
        <div class="has-text-grey postDetailBar is-size-7">
          {{ mComment.CreatedOn | dateTimeStampToDate | longDateFormat }} -
          {{ mComment.CreatedOn | timeFromDateTimeStamp }}
        </div>
        <div class="is-size-7">
          <strong>{{ mComment.ProfileName }},</strong>
          <span :class="{ 'is-deleted': mComment.IsDeleted }">{{
            mComment.Text
          }}</span>
        </div>

        <comment-item
          v-for="comment in filterComments(mComment.Id)"
          :key="comment.Id"
          :commentObj="comment"
          :comments="mComments"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'

import CommentItem from '@/components/Comments/CommentItem'

export default {
  components: {
    'comment-item': CommentItem,
  },

  props: {
    commentObj: null,
    comments: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  data() {
    return {
      commentText: '',
      mComment: this.$props.commentObj,
      mComments: this.$props.comments,
      showCommentInput: false,
    }
  },

  computed: mapGetters({
    isLoggedIn: 'getIsLoggedIn',
  }),

  methods: {
    filterComments(parentId) {
      return this.mComments.filter((c) => c.ParentId === parentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>
