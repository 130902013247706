var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDrawer)?_c('UiComponentDrawer',{staticClass:"drawerPackageDetail",attrs:{"drawerTitle":"","showDrawer":_vm.showDrawer,"onClickClose":_vm.onClickClose,"position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.selectedPackage.Image)?_c('div',{staticClass:"wrapper-images"},[_c('figure',{staticClass:"image is-3by2 has-background-grey-lighter"},[_c('img',{style:({
            backgroundImage: `url('${_vm.$options.filters.getLocationImage(
              _vm.selectedPackage.Image,
              _vm.locationId,
              '640'
            )}')`,
          }),attrs:{"src":require("@/assets/images/spacer1_1.gif")}})])]):_vm._e(),_c('div',{class:`${_vm.$classPrefix}title is-4`,domProps:{"textContent":_vm._s(_vm.name)}}),_c('div',{class:`${_vm.$classPrefix}content has-margin-bottom-x2`,domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('div',{class:`${_vm.$classPrefix}title is-5`,domProps:{"textContent":_vm._s('Inbegrepen voorzieningen')}}),_vm._l((_vm.includedOptions),function(optionData){return _c('div',{key:optionData.OptionId,staticClass:"optionDetail is-flex",class:`${_vm.$classPrefix}box`},[_c('div',[_c('figure',{staticClass:"image is-4by3 has-margin-right has-background-grey-light",style:({
            backgroundImage: `url('${_vm.$options.filters.getLocationImage(
              optionData.Image,
              optionData.LocationId,
              '160'
            )}')`
          })})]),_c('div',[_c('div',{class:`has-text-weight-bold`,domProps:{"textContent":_vm._s(_vm.getOptionName(optionData))}})])])})]},proxy:true}],null,false,3514931416)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }