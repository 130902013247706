var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.reviews),function(rev,index){return _c('div',{key:index,class:`${_vm.$classPrefix}column is-half-mobile is-one-third-fullhd`},[_c('div',{class:`${_vm.$classPrefix}box`},[_c('ui-review-score',{staticClass:"has-text-white",attrs:{"score":rev.Score,"size":16}}),_c('div',[_c('span',[_c('figure',{staticClass:"image is-32x32"},[_c('img',{staticClass:"is-round has-shadow",style:({
                backgroundImage: `url(${_vm.$options.filters.getProfileImage(
                  rev.ProfileImage,
                  rev.ProfileId,
                  38
                )})`,
              }),attrs:{"src":require("@/assets/images/spacer1_1.gif")}})])]),_c('span',[_vm._v(_vm._s(rev.ProfileName))]),_c('span',{staticClass:"has-margin-left has-text-grey postDetailBar is-size-7"},[_vm._v(_vm._s(_vm._f("longDateFormat")(_vm._f("parseIsoDateStringToDate")(rev.AnsweredOn))))])]),_vm._l((rev.Comments),function(comment){return _c('comment-item',{key:comment.Id,attrs:{"commentObj":comment,"comments":rev.Comments}})})],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }