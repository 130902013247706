<template>
  <UiComponentDrawer
    v-if="showDrawer"
    drawerTitle=""
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
    class="drawerPackageDetail"
  >
    <template v-slot:content>
      <div
        v-if="selectedPackage.Image"
        class="wrapper-images"
      >
        <figure class="image is-3by2 has-background-grey-lighter">
          <img
            src="@/assets/images/spacer1_1.gif"
            :style="{
              backgroundImage: `url('${$options.filters.getLocationImage(
                selectedPackage.Image,
                locationId,
                '640'
              )}')`,
            }"
          >
        </figure>
      </div>
      <div
        :class="`${$classPrefix}title is-4`"
        v-text="name"
      ></div>
      <div
        :class="`${$classPrefix}content has-margin-bottom-x2`"
        v-html="description"
      ></div>

      <div
        :class="`${$classPrefix}title is-5`"
        v-text="'Inbegrepen voorzieningen'"
      ></div>
      <div
        v-for="(optionData) in includedOptions"
        :key="optionData.OptionId"
        :class="`${$classPrefix}box`"
        class="optionDetail is-flex"
      >
        <div>
          <figure
            class="image is-4by3 has-margin-right has-background-grey-light"
            :style="{
              backgroundImage: `url('${$options.filters.getLocationImage(
                optionData.Image,
                optionData.LocationId,
                '160'
              )}')`
            }"
          >
          </figure>
        </div>
        <div>
          <div
            :class="`has-text-weight-bold`"
            v-text="getOptionName(optionData)"
          ></div>
        </div>
      </div>
    </template>
  </UiComponentDrawer>
</template>

<script>
import UiComponentDrawer from '@/components/UI/Drawer'

import { mapState } from 'vuex'

export default {
  name: 'DrawerPackageDetail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false
    },
    position: {
      type: String,
      default: 'right'
    },
    showDrawer: {
      type: Boolean,
      default: false
    },

    // props for content
    selectedPackage: {
      type: Object,
      required: true
    }
  },

  components: {
    UiComponentDrawer,
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),

    locationId() {
      return this.cart.LocationId
    },

    name() {
      let name = this.selectedPackage.Name

      let text = this.selectedPackage.Descriptions.find(
        (t) => t.LanguageId === this.cart.LanguageId
      )
      if (text) {
        name = text.Name
      }

      return name
    },
    description() {
      let description = ''

      let text = this.selectedPackage.Descriptions.find(
        (t) => t.LanguageId === this.cart.LanguageId
      )
      if (text) {
        description = text.Text
      }

      return description
    },
    hasDescription() {
      return this.description !== ''
    },

    includedOptions() {
      return this.selectedPackage.Options.filter(o => o.RequiredItem > -1)
    },
  },

  created() {
  },

  methods: {
    getOptionName(data) {
      let name = data.Name

      /**
       * TODO: Description missing in API
       */

      if (data.Descriptions) {
        let text = data.Descriptions.find(
          (t) => t.LanguageId === this.cart.LanguageId
        )
        if (text) {
          name = text.Name
        }
      }

      return name
    },

    getOptionUsps() {
      let output = []

      /**
       * TODO: Features missing in API
       */

      return output
    }
  }
}
</script>

<style lang="scss" scoped>
.drawerPackageDetail {
  .wrapper-images {
    margin-top: -$gap;
    margin-right: -$gap;
    margin-bottom: $gap;
    margin-left: -$gap;
  }

  .optionDetail {
    & > div:nth-child(1) {
      max-width: 120px;
      width: 100%;
    }
    & > div:nth-child(2) {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
</style>