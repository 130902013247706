<template>
  <div class="login-fields-component">
    <div class="resetPassword-fields-component">
      <div
        :class="`${$classPrefix}title`"
        class="is-4 "
        v-html="$t('Components.ForgotLoginRegister.Forgot.Main_Title')"
      ></div>

      <Message
        class="is-danger"
        v-if="errorMessage"
      >{{errorMessage}}</Message>

      <div :class="`${$classPrefix}content`">
        <p v-html="$t('Components.ForgotLoginRegister.Forgot.Text_Info')"></p>
      </div>

      <Form
        :onSubmitCallback="handleSubmit"
        @onStatusChange="onStatusChange"
      >
        <EmailField
          :label="$t('Components.ForgotLoginRegister.General.Label_EmailAddress')"
          @validated="onEmailValidated"
        />

        <div :class="`${$classPrefix}level is-mobile`">
          <div :class="`${$classPrefix}level-left`">
          </div>

          <div :class="`${$classPrefix}level-right`">
            <div :class="`${$classPrefix}level-item`">
              <button
                :disabled="emailInvalid"
                :class="[`${$classPrefix}button`, {
                  'is-success': !emailInvalid,
                  'is-disabled': emailInvalid,
                  'is-loading': isProcessing,
                }]"
                type="submit"
              >
                <span v-html="$t('Components.ForgotLoginRegister.Forgot.Button_SendCode')"></span>
              </button>
            </div>
          </div>
        </div>
      </Form>
      <hr />
      <div>
        <a
          :class="`has-icon`"
          @click="handleAuthNavigate({ page: 'login' })"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fal', 'chevron-left']" />
          </span>
          <span v-html="$t('Components.ForgotLoginRegister.General.Link_BackToLogin')"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/UI/Form/BaseForm'
import EmailField from '@/components/UI/Form/EmailField'
import Message from '@/components/UI/Message'

import authProvider from '../../providers/auth'

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ResetRequest',
  components: {
    Form,
    EmailField,
    Message,
  },
  data() {
    return {
      emailInvalid: false,
      responseStatusCode: 0,
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters('searchStore', ['ChannelId']),
    errorMessage() {
      let message = ''
      if (this.emailInvalid) {
        message = this.$t('Components.ForgotLoginRegister.Forgot.Message_InvalidEmailAddress')
      } else if (this.responseStatusCode === -1) {
        message = this.$t('Components.ForgotLoginRegister.Forgot.Message_EmailAddressNotFound')
      } else if (this.responseStatusCode === -500) {
        message = this.$t('Components.ForgotLoginRegister.Forgot.Message_ResponseError')
      }
      return message
    }
  },

  methods: {
    ...mapMutations('authStore', ['goToAuthPage']),
    handleAuthNavigate({ page }) {
      return this.goToAuthPage({ page })
    },
    onStatusChange({ status }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'COMPLETED':
          this.isProcessing = false
          this.goToAuthPage({ page: 'set-new-password' })
          break
      }
    },

    onEmailValidated({ value, valid }) {
      this.emailInvalid = !valid || value === ''
    },

    handleSubmit({ formData, component }) {
      let self = this

      return new Promise((resolve, reject) => {
        if (formData.email === '') {
          reject({ response: 'FAIL', component })
          return
        }

        authProvider
          .resetPassword({ Email: formData.email, ChannelId: this.ChannelId, Language: this.$i18n.locale })
          .then((response) => {
            self.responseStatusCode = response.data.StatusCode
            if (response.data.StatusCode === 200) {
              resolve({ response: "OK", component });
            } else {
              reject({ response: 'FAIL', component })
            }
          })
          .catch(() => {
            self.responseStatusCode = -500
            reject({ response: 'FAIL', component })
          })
      });
    },
  },
}
</script>
