import axiosInstance from '@/helpers/axios'

let saveCartCancel = null

export const getCartByCartKey = (CartKey) => {
  return axiosInstance.get(`cart/${CartKey}`)
}

export const getCartBySearchKey = (SearchKey) => {
  return axiosInstance.get(`cart/search/${SearchKey}`)
}

export const deleteCart = (CartKey) => {
  return axiosInstance.delete(`cart/${CartKey}`)
}

export const saveCart = ({ cart }) => {
  saveCartCancel && saveCartCancel('New request fired')

  return axiosInstance
    .put(`cart/${cart.CartKey}`, cart, {
      cancelToken: new axiosInstance.CancelToken(function executor(c) {
        saveCartCancel = c;
      })
    })
    .catch((err) => {
      return err
    })
}

export const finalizeCart = async ({ CartKey }) => {
  return axiosInstance.post(`cart/${CartKey}/finalize`, {
    CartKey,
  })
}

export const saveCartSpace = ({ CartKey, space }) => {
  return axiosInstance.post(`cart/${CartKey}/space`, {
    CartKey,
    Id: space.Id,
    SpaceId: space.SpaceId,
    SettingId: space.SettingId,
    Seats: space.Seats,
    StartDate: space.StartDate,
    StartMinutes: space.StartMinutes,
    EndDate: space.EndDate,
    EndMinutes: space.EndMinutes,
    CurrencyId: space.CurrencyId,
    PricePerSeat: space.PricePerSeat,
    PriceTotalExclTax: space.PriceTotal,
    TaxId: space.TaxId,
    Crc: space.Crc,
  })
}

export const deleteCartSpace = ({ CartKey, id }) => {
  return axiosInstance.delete(`cart/${CartKey}/space/${id}`)
}

/// Company and State were missing, added them. ////
export const saveCartInvoiceAddress = ({ CartKey, invoiceAddress }) => {
  return axiosInstance.post(`cart/${CartKey}/invoiceaddress`, {
    Company: invoiceAddress.Company,
    Address: invoiceAddress.Address,
    PostalCode: invoiceAddress.PostalCode,
    City: invoiceAddress.City,
    Country: invoiceAddress.Country,
    State: invoiceAddress.State,
    SendTo: invoiceAddress.SendTo,
    Email: invoiceAddress.Email,
    PoNumber: invoiceAddress.PoNumber,
    PhoneNumber: invoiceAddress.PhoneNumber,
  })
}

export const saveCartProfile = (cartKey, profileId) => {
  return axiosInstance.post(`cart/${cartKey}/profile`, {
    ProfileId: profileId,
  })
}

export const deleteCartProfile = (cartKey) => {
  return axiosInstance.delete(`cart/${cartKey}/profile`)
}
