<template>
  <div class="invoiceAddressController">
    <!--
      !! Reservering op naam van bedrijf of profiel mag nog niet actief !!
      !! Deze setting wordt namelijk nog niet opgeslagen !!

       <base-field-template
      v-if="cart.MeetingTypeId !== 2"
      label="Reserving mag gefactureerd worden op naam van:"
      class="mb-5"
    >
      <template v-slot:fields>
        <div :class="`${$classPrefix}tabs is-toggle`">
          <ul>
            <li :class="{'is-active': selectedTabIndex === 0 }">
              <a @click="setSelectedTabIndex(0)">
                <span>Mijn bedrijf</span>
              </a>
            </li>
            <li :class="{'is-active': selectedTabIndex === 1 }">
              <a @click="setSelectedTabIndex(1)">
                <span>Mij persoonlijk</span>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </base-field-template> -->
    <div
      :class="`${$classPrefix}title is-3`"
      v-text="mainTitle"
    ></div>

    <div :class="`${$classPrefix}box`" class="box-invoiceDetails is-paddingless">

      <billing-carousel
        v-if="cart.MeetingTypeId !== 2 && selectedTabIndex === 0 && isReadyLoadingBillingInfos"
        class="companies-carousel mb-4"
      />

      <div class="has-padding-left has-padding-right has-padding-bottom">
        <!-- CONTROLS -->
        <transition-expand>
          <div v-if="cart.MeetingTypeId !== 2 && billingInfo.Id !== 0">
            <div class="formControls is-top is-flex is-align-items-center is-justify-content-end mb-4">
              <template v-if="billingFormStateIsPreview && !isSaving">
                <a
                  @click="editBilling"
                  class="has-icon is-unselectable"
                >
                  <span :class="`${$classPrefix}icon`">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                  <span v-text="$t('General.Text_EditInvoice')"></span>
                </a>

                <a
                  @click="deleteBilling"
                  class="has-text-danger has-icon ml-4 is-unselectable"
                >
                  <span :class="`${$classPrefix}icon`">
                    <font-awesome-icon :icon="['fad', 'trash']" />
                  </span>
                  <span v-text="$t('General.Text_Delete')"></span>
                </a>
              </template>

              <template v-if="!billingFormStateIsPreview || isSaving">
                <template v-if="!isBusy && !changesSaved">
                  <a
                    @click="restore"
                    class="has-icon ml-4 is-unselectable"
                  >
                    <span :class="`${$classPrefix}icon`">
                      <font-awesome-icon
                        :icon="['fa', 'share']"
                        flip="horizontal"
                      />
                    </span>
                    <span v-text="$t('General.Text_Cancel')"></span>
                  </a>
                </template>

                <a
                  key="saveTop"
                  v-if="!changesSaved"
                  @click="updateBilling"
                  class="has-icon ml-4 is-unselectable"
                >
                  <span :class="[`${$classPrefix}icon`, {'is-loading': isBusy}]">
                    <font-awesome-icon :icon="['fal', 'check']" />
                  </span>
                  <span v-text="$t('General.Text_Save')"></span>
                </a>
                <span
                  v-else
                  key="savedTop"
                  :class="`${$classPrefix}tag is-success is-light has-icon ml-4 is-unselectable`"
                >
                  <span :class="`${$classPrefix}icon`">
                    <font-awesome-icon :icon="['fal', 'check']" />
                  </span>
                  <span v-text="$t('General.Text_Saved')"></span>
                </span>
              </template>
            </div>
          </div>
        </transition-expand>

        <!-- FORM -->
        <invoice-address-form
          ref="invoiceAddressForm"
          :isCompanyBilling="selectedTabIndex === 0"
          :isPreviewMode="billingFormStateIsPreview"
        />

        <!-- CONTROLS -->
        <transition-expand>
          <div v-if="cart.MeetingTypeId !== 2">
            <div
              class="formControls is-align-items-center mt-5 mb-2"
              :class="cart.MeetingTypeId !== 2 && billingInfo.Id !== 0 ? 'is-justify-content-end' : 'is-justify-content-center'"
            >
              <template v-if="billingInfo.Id === 0">
                <a
                  :class="[`${$classPrefix}button is-success`, {'is-loading': isBusy}]"
                  :disabled="isBusy"
                  @click="createBilling"
                  v-text="$t('Components.InvoiceDetails.InvoiceAddressForm.Button_SaveNewInvoice')"
                ></a>
              </template>
            </div>
          </div>
        </transition-expand>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
// import BaseFieldTemplate from '../UI/Form/BaseFieldTemplate.vue'
import BillingCarousel from './BillingCarousel.vue'
import InvoiceAddressForm from './InvoiceAddressForm.vue'
import TransitionExpand from '../TransitionExpand.vue'

export default {
  name: 'invoiceAddressController',

  components: {
    BillingCarousel,
    // BaseFieldTemplate, 
    InvoiceAddressForm,
    TransitionExpand,
  },

  data() {
    return {
      isLoading: true,
      selectedTabIndex: 0,
      watch_billing: null,
      selectedBillingId: 0,
      changesSaved: false,
      isSaving: false
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('billingStore', ['billingInfo', 'billingFormStateIsPreview', 'isReadyLoadingBillingInfos']),
    ...mapGetters('cartStore', ['isBusy']),

    isPaidReservation() {
      return !!(
        this.cart.MeetingTypeId !== 2 ||
        (this.cart.MeetingTypeId === 2 &&
          (this.cart.TotalExclTax || this.cart.VoucherId))
      )
    },

    mainTitle() {
      return this.isPaidReservation
        ? this.$t(
          'Components.InvoiceDetails.InvoiceAddressForm.Header_BillingDetails'
        )
        : this.$t(
          'Components.InvoiceDetails.InvoiceAddressForm.Header_ContactInformation'
        )
    },
  },

  async created() {
    if (this.cart.MeetingTypeId !== 2) {
      await this.getBillingInfos()
    }
  },

  mounted() {
    this.watch_billing = this.$watch('billingInfo', this.watchBillingSelector, {
      deep: true,
      immediate: true,
    })
  },

  beforeDestroy() {
    // Unwatch billing info
    if (this.watch_billing) {
      this.watch_billing()
    }
  },

  methods: {
    ...mapActions('billingStore', ['clearSelectedBilling', 'createBillingInfo', 'deleteBillingInfo', 'updateBillingInfo', 'getBillingInfos']),
    ...mapActions('cartStore', ['setInvoiceAddress']),
    ...mapMutations('billingStore', ['setBillingFormStateIsPreview']),
    ...mapMutations('cartStore', ['updateInvoiceAddressDetails']),

    async watchBillingSelector(val) {
      if (this.selectedBillingId === val.Id) { return }
      this.selectedBillingId = val.Id
      const FORM_RESPONSE = await this.$refs.invoiceAddressForm.runSilentValidation(true)
      this.setBillingFormStateIsPreview(!!FORM_RESPONSE.isValid)

      // Update cart invoice data
      await this.updateInvoiceAddressDetails({ billingInfo: FORM_RESPONSE.formData })
    },

    /**
     * Edit billing data
     */
    editBilling() {
      this.setBillingFormStateIsPreview(false)
    },

    /**
     * Restore selected billing info
     */
    restore() {
      this.$refs.invoiceAddressForm.restore()
      this.setBillingFormStateIsPreview(true)
    },

    /**
     * Validate billing data and process the data in cart
     */
    async processFormData(validateSilence = true) {
      const FORM_RESPONSE = await this.$refs.invoiceAddressForm.runSilentValidation(validateSilence)

      // Stop processing
      if (!FORM_RESPONSE.isValid) { return FORM_RESPONSE }

      // Update billing before adding billing address to cart when it's no a workspace booking 
      if (this.cart.MeetingTypeId !== 2) {
        await this.updateBilling()
      }

      // Update cart with selected billing address
      await this.setInvoiceAddress({ billingInfo: FORM_RESPONSE.formData })

      // Return form data
      return FORM_RESPONSE
    },

    /**
     * Create billing info
     */
    async createBilling() {
      if (this.isBusy) { return }

      // Get Form state and data
      const FORM_RESPONSE = await this.$refs.invoiceAddressForm.runSilentValidation(false)

      // Stop when form data is not valid
      if (!FORM_RESPONSE.isValid) { return }

      /**
       * Store createBillingInfo action will create a new billing address,
       * and load user billing addresses
       */
      this.createBillingInfo(FORM_RESPONSE.formData)

      // Update cart invoice data
      await this.updateInvoiceAddressDetails({ billingInfo: FORM_RESPONSE.formData })
    },

    /**
     * Update selected billing
     */
    async updateBilling() {
      let self = this

      if (this.isBusy) { return }

      // Get Form state and data
      const FORM_RESPONSE = await this.$refs.invoiceAddressForm.runSilentValidation(false)

      // Stop when form data is not valid
      if (!FORM_RESPONSE.isValid) { return }

      // Update is saving status
      this.isSaving = true

      // Save changes
      await this.updateBillingInfo(FORM_RESPONSE.formData)

      // Set form state back to preview
      this.setBillingFormStateIsPreview(true)

      // Update cart invoice data
      await this.updateInvoiceAddressDetails({ billingInfo: FORM_RESPONSE.formData })

      this.changesSaved = true
      let t = setTimeout(() => {
        // Updated changes saved status
        self.changesSaved = false

        // Update is saving status
        self.isSaving = false
        clearTimeout(t)
      }, 3000)
    },

    /**
     * Delete selected billing address
     */
    async deleteBilling() {
      if (!this.selectedBillingId && this.isBusy) { return }
      this.deleteBillingInfo(this.selectedBillingId)
    }
  },
}
</script>