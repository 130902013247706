import Vue from 'vue'

Vue.filter('toCurrency', function(value, culture = 'nl-NL', currency = 'EUR') {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})
