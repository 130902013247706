import Vue from 'vue'
import App from './App.vue'
import './utils/polyfill-custom-event'
import store from './store'
import './modules/fontAwesome/fontAwesome.config'
import i18nConfig from './modules/i18n/i18n.config'

Vue.config.productionTip = false

// Filters
import './filters/currencyFilters'
import './filters/dateTimeFilters'
import './filters/imageFilters'
import './filters/eventTypeFilter'
import './filters/settingNameFilter'
import './filters/stringFilters'

// Helpers
import * as objectHelper from './helpers/objectHelper'
import * as stringHelper from '@/helpers/stringHelper'
Vue.prototype.$objectHelper = objectHelper
Vue.prototype.$stringHelper = stringHelper

// Class name prefix
Vue.prototype.$classPrefix = 'c_lab-bw-'

// Directives
import './directives/longpress'

// Widget Setup
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// scroll effect
let VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: '#wizard-scoll-container',
})

// Translation
export const i18n = i18nConfig

/**
 * We use an eventhandler for fleeting state changes, such as hover effects that influence other components
 */
Vue.prototype.$EventBus = new Vue()

/**
 * We use Vuex Store to persist state changes of a more permanent / significant nature
 */
App.store = store

/**
 * We use i18n for text translation implementation
 */
App.i18n = i18n

Vue.customElement('c-lab-bookingwidget', App)
