import axiosInstance from '@/helpers/axios'

let getAutoCompleteItemsToken = null

export const getAutoCompleteItems = async (params) => {
  if (getAutoCompleteItemsToken) {
    await getAutoCompleteItemsToken.cancel('Request cancelled')
  }
  getAutoCompleteItemsToken = await axiosInstance.CancelToken.source()

  return await axiosInstance.get(`autocomplete/location`, {
    params,
    cancelToken: getAutoCompleteItemsToken.token
  })
}
