<template>
  <div
    v-if="showModalReady"
    :class="`${$classPrefix}widget ${$classPrefix}modal-placeholder`"
  >
    <WizardModalWrapper
      :modalSize="modalSize"
      :hideStandardHeader="true"
      modalTitle=""
      :onCloseFunc="close"
      :showModal="showModalReady"
      :class="`${$classPrefix}modal-wizard`"
    >
      <template v-slot:customHeader>
        <div
          :class="`${$classPrefix}modal-card-head`"
          class="customHeader has-background-white"
        >
          <div class="row-navigation">
            <SearchContext />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <Wizard id="wizard-scroll-container" />
      </template>
      <template v-slot:footer>
        <MobileFooter v-if="cart && showMobileFooterBar" />
      </template>
    </WizardModalWrapper>
  </div>
</template>

<script>
import WizardModalWrapper from './UI/WizardModalWrapper'
import SearchContext from '@/components/Layout/SearchContext'
import Wizard from '@/components/Wizard'
import MobileFooter from '@/components/Layout/MobileFooter'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'modal-wizard',

  props: {
    hideStandardHeader: {
      type: Boolean,
      default: true,
    },

    modalTitle: {
      type: String,
      default: '',
    },

    /**
     * Sizes are:
     * - dynamic (size will be determent by content)
     * - small (width is set to auto)
     * - normal (is bulma default)
     * - large (1024)
     * - extra-large (1200)
     */
    modalSize: {
      type: String,
      default: 'normal',
    },

    onCloseFunc: {
      type: Function,
      default: function() { },
      required: false,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    WizardModalWrapper,
    SearchContext,
    Wizard,
    MobileFooter,
  },

  data() {
    return {
      hideCartSidebar: false,
      showModalReady: false,
    }
  },

  computed: {
    ...mapState(['viewportMode', 'showMobileFooterBar']),
    ...mapState('searchStore', ['cleanSearchSrc']),
    ...mapState('cartStore', ['cart']),
  },

  created() {
  },

  mounted() {
    this.openModal(this.$el)
    this.showModalReady = this.showModal
  },

  async beforeDestroy() {
    this.showModalReady = false
    this.resetAvailability()
    this.setSearchDetails(this.$objectHelper.cleanSource(this.cleanSearchSrc))
    this.setSearchLanguage(this.$i18n.locale)
    this.getWidgetState()
    this.closeModal(this.$el)
  },

  methods: {
    ...mapActions('modalStore', ['openModal', 'closeModal']),
    ...mapActions('availabilityStore', ['resetAvailability']),
    ...mapMutations('searchStore', ['setSearchDetails', 'setSearchLanguage']),
    ...mapActions('widgetStore', [
      'setWidgetKey',
      'getWidgetState',
      'getOpeningHoursData'
    ]),

    close() {
      this.onCloseFunc()
    },
  },
}
</script>
