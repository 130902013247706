<template>
  <div class="LocationSpaceWS">
    <figure
      @click="showSpaceInformation"
      class="LocationSpaceWS__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          space.SpaceImage,
          selectedLocationId,
          '640'
        )}')`,
      }"
    ></figure>
    <div class="LocationSpaceWS__detail_price">
      <div class="LocationSpaceWS__details has-padding">
        <div
          :class="`is-size-5 has-text-weight-bold has-margin-bottom`"
          v-html="space.SpaceName"
        ></div>

        <UpsellTemplate
          class="has-margin-bottom"
          v-if="upsellItems.length"
          :upsellItems="upsellItems"
        />

        <div
          v-if="includedOptions.length"
          class="has-margin-bottom"
        >
          <div class="has-text-weight-bold">Inclusief:</div>
          <UpsellTemplate :upsellItems="includedOptions" />
        </div>

        <div
          v-if="showSocialCaptial"
          class="is-size-7"
        >
          {{ $t('Components.LocationCard.Text_SocialCapital') }}
        </div>
      </div>

      <div class="LocationSpaceWS__PriceWrapper has-background-info-light has-padding">
        <div class="has-text-centered">
          <div
            v-if="widgetState.ShowPrices"
            class="LocationCard__price has-margin-bottom"
          >
            <span
              :alt="pricingMethod"
              :title="pricingMethod"
            >
              <strong
                class="is-size-5"
                v-html="
                  $options.filters.toCurrency(space.Price, 'nl-NL', space.CurrencyIso)
                "
              ></strong>
              {{ pricingMethodShortner }}</span>
            <div
              v-if="!hideTotalPrice"
              class="LocationCard__priceExplanation is-size-7 has-text-grey"
              v-text="
                `${$options.filters.toCurrency(
                  space.PriceTotal,
                  'nl-NL',
                  space.CurrencyIso
                )} total`
              "
            ></div>
          </div>
          <div
            v-if="showSocialCaptial"
            class="has-margin-bottom"
          >
            <figure class="image image is-32x32 is-inline-flex">
              <SocialCapital />
            </figure>
          </div>
          <button
            @click="handleClick(space.Settings[0])"
            :class="`${$classPrefix}button`"
            class="LocationCard__button is-fullwidth is-success"
            v-text="$t('General.Text_Book')"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpsellTemplate from '@/components/UI/UpsellTemplate'
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import SocialCapital from '@/components/UI/Icons/SocialCapital'

export default {
  name: 'LocationSpace',
  components: {
    UpsellTemplate,
    SocialCapital
  },
  props: {
    /**
     * The Space object
     */
    space: {
      type: Object,
      required: true,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // State flags
      showDetails: false,
      horizontal: true,
      // Title width flags
      longTitle: false,
      fullWidthTitle: false,
    }
  },
  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapState('widgetStore', ['widgetState']),
    ...mapState('availabilityStore', ['selectedLocationId']),
    ...mapGetters('searchStore', ['ChannelId', 'LastStep', 'DisableNextButton']),
    ...mapGetters('cartStore', ['isBusy']),

    title() {
      return this.space.SpaceName
    },
    hideTotalPrice() {
      return this.currentSearch.MeetingtypeId === 2
    },
    showSocialCaptial() {
      return (
        this.currentSearch.MeetingtypeId === 2 && this.ChannelId === 1 && !this.space.PriceTotal
      )
    },

    /**
     * The pricing method is always the same for all spaces in a location
     */
    pricingMethod() {
      if (!this.space.SpacePrice) { return null }

      switch (this.space.SpacePrice.CalculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeat')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnly')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPerson')
      }

      return null
    },

    pricingMethodShortner() {
      if (!this.space.SpacePrice) { return null }

      switch (this.space.SpacePrice.CalculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeatShortner')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnlyShortner')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPersonShortner')
      }

      return null
    },

    upsellItems() {
      let output = this.space.Features.map((el) => el.Body)
      return output
    },

    includedOptions() {
      let output = this.space.IncludedOptions.map((el) => el.OptionName)
      return output
    },

    /**
     * The space description, if it has one
     */
    description() {
      return this.space.SpaceDescription
    },
    /**
     * The details element is provided with additional classes in the mounted lifecycle method based on the length of the title
     */
    detailClassList() {
      return {
        'LocationSpace__details--long': this.longTitle,
        'LocationSpace__details--full': this.fullWidthTitle,
      }
    },
  },

  mounted() {
    // Once mounted, perform a two stepped check regarding the width of the title.
    // If the height exceeds 23px once, the m2 value is moved to the 2nd line.
    // If the height still exceedds 23px after the redraw, the 'more info' button is also moved
    // Note that even if there is no 'more info', this mechanism will still work great for just m2
    // this.longTitle = this.$refs.title.clientHeight > 23
    // this.$nextTick(() => {
    //   this.fullWidthTitle = this.$refs.title.clientHeight > 23
    // })
    // // If there is only one Setting, let it make good use of the available space
    // if (this.space.settings.length === 1) {
    //   this.horizontal = true
    // }
  },

  methods: {
    ...mapActions('searchStore', ['goToStep']),
    ...mapActions('cartStore', ['createCart']),
    ...mapMutations('availabilityStore', ['setSelectedSpaceAndSetting']),
    ...mapMutations('searchStore', ['disableNextStep', 'enableNextStep']),
    ...mapMutations('cartStore', ['increaseBusyState', 'decreaseBusyState']),

    /**
     * Select the Space & Setting combination
     */
    handleClick(setting) {
      if (this.isBusy || this.DisableNextButton) { return }

      this.setSelectedSpaceAndSetting({
        SpaceId: setting.SpaceId,
        SettingId: setting.SettingId,
      })

      this.createCart()

      this.enableNextStep()
      this.goToStep({
        step: this.LastStep + 1
      })
      this.disableNextStep()

      this.enableNextStep()
    },
    /**
     * Show / hide the description
     */
    handleToggle() {
      this.showDetails = !this.showDetails
    },

    showSpaceInformation() {
      this.$emit('showDrawer', this.space)
    },
  },
}
</script>
