<template>
  <div class="MainControls">
    <DrawerFilters
      v-if="drawerType === 'drawerFilters'"
      :showDrawer="drawerType === 'drawerFilters'"
      :onClickClose="hideDrawer"
      class="drawer-filters"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="LastStep === 1">
        <div
          v-if="hasOverlap"
          class="is-size-4 has-text-weight-bold"
          v-html="$t(`Components.Overlap.Meetingtype_${currentSearch.MeetingtypeId}.Main_Title`)"
        ></div>
        <div
          v-else-if="!availableLocations.length"
          v-html="$t(`Components.NoAvailability.Meetingtype_${currentSearch.MeetingtypeId}.Main_Title`)"
          class="is-size-4 has-text-weight-bold"
          :class="`${$classPrefix}title mb-0`"
        ></div>
        <div
          v-else
          :class="`filters ${$classPrefix}level is-mobile has-margin-bottom-half`"
        >
          <div :class="`${$classPrefix}level-left`">
            <SearchControls class="MainControls__searchControlsFilters" />
          </div>
          <div :class="`is-hidden-tablet ${$classPrefix}level-right`">
            <a
              :class="`is-hidden-tablet ${$classPrefix}button is-small`"
              @click="showDrawer('drawerFilters')"
            >
              <span :class="`${$classPrefix}icon`">
                <font-awesome-icon :icon="['fad', 'sliders-h']" />
              </span>
              <span v-text="$t('General.Text_Filter')"></span>
            </a>
          </div>
        </div>
      </div>

      <div
        class="MainControls__instructions"
        key="instructions"
        v-else
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            class="filters"
            v-if="LastStep === 2 && selectedLocation"
          >
            <div :class="`level-filters has-margin-bottom-half ${$classPrefix}level is-mobile`">
              <div :class="`${$classPrefix}level-left has-padding-right`">
                <div
                  v-text="$tc(step2TitleKey, selectedLocation.Spaces, {name: locationName})"
                  class="is-size-4 has-text-weight-bold"
                ></div>
              </div>
              <div
                v-if="currentSearch.MeetingtypeId === 1"
                :class="`is-hidden-tablet ${$classPrefix}level-right`"
              >
                <a
                  :class="`is-hidden-tablet ${$classPrefix}button is-small`"
                  @click="showDrawer('drawerFilters')"
                >
                  <span :class="`${$classPrefix}icon`">
                    <font-awesome-icon :icon="['fad', 'sliders-h']" />
                  </span>
                  <span v-text="$t('General.Text_Filter')"></span>
                </a>
              </div>
            </div>

            <FilterOverview
              v-if="currentSearch.MeetingtypeId === 1"
              class="is-inline-flex is-hidden-mobile SearchControls__filter-wrapper"
              selectBoxSize="is-small"
            />
          </div>
          <div v-if="showOptionsStep">
            <span
              class="has-margin-right is-size-4 has-text-weight-bold"
              v-text="$t('App.MainControls.Header_ChooseOptions')"
            ></span>
          </div>
          <div v-if="showReservationForm">
            <span
              class="has-margin-right is-size-4 has-text-weight-bold"
              v-text="$t('App.MainControls.Header_BookingInfo')"
            ></span>
          </div>
          <div v-if="showAccountFlow">
            <span
              v-if="!isLoggedIn && authOptions.type === 0"
              :class="`is-size-4 has-text-weight-bold`"
              v-text="$t('Components.AuthFlow.Main_Title')"
            ></span>
            <!-- log in instruction, benefit or header? -->

            <InvoiceControls />
          </div>
          <div v-if="showLastStep">
            <span
              class="has-margin-right is-size-4 has-text-weight-bold"
              v-text="$t('App.MainControls.Header_BookingConfirmation')"
            ></span>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SearchControls from '@/components/SearchResults/SearchControls'
import InvoiceControls from '@/components/InvoiceDetails/InvoiceControls'
import DrawerFilters from '@/components/SearchResults/DrawerFilters'
import FilterOverview from '@/components/SearchResults/FilterOverview'

export default {
  name: 'MainControls',
  components: {
    SearchControls,
    InvoiceControls,
    DrawerFilters,
    FilterOverview,
  },

  data() {
    return {
      drawerType: ''
    }
  },

  computed: {
    ...mapState(['showMobileFooterBar']),
    ...mapState('availabilityStore', ['hasOverlap']),
    ...mapState('widgetStore', ['widgetState', 'maxSteps']),
    ...mapState('searchStore', ['currentSearch']),

    ...mapGetters('availabilityStore', ['selectedLocation', 'availableLocations']),
    ...mapGetters('searchStore', ['MeetingtypeId', 'LastStep']),
    ...mapGetters('authStore', ['isLoggedIn']),
    ...mapGetters('widgetStore', ['authOptions']),

    locationName() {
      return this.selectedLocation.Name
    },

    step2TitleKey() {
      return this.MeetingtypeId !== 2 ? 'App.MainControls.Header_ChooseASpace' : 'App.MainControls.Header_ChooseAWorkspace'
    },

    showOptionsStep() {
      return this.LastStep === 3 && this.widgetState.ShowOptionsStep
    },

    showReservationForm() {
      return (this.LastStep === 4 && this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
        || (this.LastStep === 3 && !this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
    },

    showAccountFlow() {
      return (this.LastStep === 5 && this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep)
        || (this.LastStep === 4 && (
          (!this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep) || (this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep))
        )
        || (this.LastStep === 3 && !this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep)
    },

    showLastStep() {
      return this.LastStep >= this.maxSteps
    }
  },

  methods: {
    hideDrawer() {
      this.drawerType = ''
    },
    showDrawer(type = '') {
      this.drawerType = type
    }
  },
}
</script>
