<template>
  <div :class="`${$classPrefix}datepicker`">
    <input
      v-if="showInput"
      type="text"
      readonly="readonly"
      aria-readonly="true"
      autocomplete="off"
      aria-autocomplete="both"
      :ref="inputId"
      :class="`${$classPrefix}input`"
      :id="inputId"
      :value="datePresentation"
      @click="showHideInput()"
      @focus="removeFocusFromInput"
    />
    <DatePickerPopup
      v-if="popupShown"
      :ref="refDatePickerPopup"
      :key="mode"
      :mode="mode"
      :dates="mDates"
      :startMinutes="mStartMinutes"
      :endMinutes="mEndMinutes"
      :target="inputId"
      :use24HourTimeSlot="use24HourTimeSlot"
      @update="update"
      @hide="hideDatePicker()"
      v-click-outside.bubble="{ exclude: [inputId], handler: 'hideDatePicker' }"
    />
  </div>
</template>

<script>
import DatePickerPopup from '@/components/UI/DatePickerPopup'

export default {
  name: 'date-input',

  props: {
    dates: {
      type: [Date, String, Object],
      default: function() {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },
    dateFormat: {
      type: String,
      default: 'shortDateFormat',
    },
    startMinutes: {
      type: Number,
      default: 540,
    },
    endMinutes: {
      type: Number,
      default: 960,
    },
    showInput: {
      type: Boolean,
      default: true,
    },
    inputClasses: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    iconClasses: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'single',
    },

    use24HourTimeSlot: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DatePickerPopup,
  },

  data() {
    return {
      inputId: `dpInput${new Date().getTime()}`,
      refDatePickerPopup: `popupWrapper${new Date().getTime()}`,
      popupShown: false,
      handleOutsideClick: null,
      mDates: this.$objectHelper.cleanSource(this.dates),
      mStartMinutes: this.startMinutes,
      mEndMinutes: this.endMinutes,
    }
  },

  computed: {
    datePresentation() {
      let output = ''
      if (this.mode === 'single') {
        output = this.$options.filters.dateFormat(
          this.mDates,
          this.$i18n.locale,
          this.dateFormat
        )
        output += `  ${this.$options.filters.minutesToTime(
          this.mStartMinutes
        )} - ${this.$options.filters.minutesToTime(this.mEndMinutes)}`
      } else {
        output = `${this.$options.filters.dateFormat(
          this.mDates.start,
          this.$i18n.locale,
          this.dateFormat
        )} - ${this.$options.filters.dateFormat(
          this.mDates.end,
          this.$i18n.locale,
          this.dateFormat
        )}`
      }
      return output
    },
  },

  methods: {
    changeMode(mode) {
      if (typeof this.$refs[this.refDatePickerPopup] !== 'undefined') {
        this.$refs[this.refDatePickerPopup].resetBeforeDestroy()
      }
      this.mode = mode
    },

    /**
     * Remove focus from input field
     */
    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    showHideInput() {
      this.popupShown = !this.popupShown
    },

    hideDatePicker() {
      this.popupShown = false
    },

    doApplyBeforeClosing() {
      this.$refs[this.refDatePickerPopup].applyNewDates()
    },

    // Update dates
    update(val) {
      this.mDates = val.dates
      this.mStartMinutes = val.startMinutes
      this.mEndMinutes = val.endMinutes
      this.$emit('action', {
        startDate: val.dates.start ? val.dates.start : val.dates,
        endDate: val.dates.end ? val.dates.end : val.dates,
        startMinutes: val.startMinutes,
        endMinutes: val.endMinutes,
      })
    },
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        vnode.context.handleOutsideClick = (e) => {
          e.stopPropagation()

          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value

          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false

          if (typeof exclude !== 'undefined') {
            exclude.forEach((refName) => {
              // We only run this code if we haven't detected
              // any excluded element yet
              if (!clickedOnExcludedEl) {
                // Get the element using the reference name
                let excludedEl = vnode.context.$refs[refName]
                // See if this excluded element
                // is the same element the user just clicked on
                if (typeof excludedEl !== 'undefined') {
                  clickedOnExcludedEl = excludedEl.contains(e.target)
                }
              }
            })

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
              // If the clicked element is outside the dialog
              // and not the button, then call the outside-click handler
              // from the same component this directive is used in
              vnode.context[handler]()
            }
          }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', vnode.context.handleOutsideClick)
        // document.addEventListener('touchstart', vnode.context.handleOutsideClick)
      },

      unbind(el, binding, vnode) {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', vnode.context.handleOutsideClick)
        // document.removeEventListener('touchstart', vnode.context.handleOutsideClick)
      },
    },
  },
}
</script>
