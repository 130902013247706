<template>
  <UiComponentDrawer
    v-if="showDrawer"
    :drawerTitle="location.Name"
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <div class="wrapper-images">
        <ImageCarousel
          v-if="location.Images"
          :images="location.Images"
        />
      </div>
      <div :class="`${$classPrefix}tabs`">
        <ul>
          <li
            @click="switchContentView(0)"
            :class="{ 'is-active': viewTypeIndex === 0 }"
          >
            <a>{{ $t('App.LocationDrawer.Tab_Label-Contact') }}</a>
          </li>
          <li
            @click="switchContentView(1)"
            :class="{ 'is-active': viewTypeIndex === 1 }"
          >
            <a>{{ $t('App.LocationDrawer.Tab_Label-Description') }}</a>
          </li>
          <li
            v-if="location.Videos && location.Videos.length"
            @click="switchContentView(2)"
            :class="{ 'is-active': viewTypeIndex === 2 }"
          >
            <a>{{ $t('App.LocationDrawer.Tab_Label-Media') }}</a>
          </li>
          <!-- <li
            v-if="location.ReviewCount"
            @click="switchContentView(3)"
            :class="{ 'is-active': viewTypeIndex === 3 }"
          >
            <a>{{ $t('App.LocationDrawer.Tab_Label-Reviews') }}</a>
          </li> -->
        </ul>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          v-if="activeComponent"
          :key="activeComponent.key"
          :is="activeComponent.component"
          v-bind="activeComponent.props"
        />
      </transition>
    </template>
  </UiComponentDrawer>
</template>

<script>
import axios from 'axios'
import ContactDetails from '@/components/Location/ContactDetails'
import Description from '@/components/Location/Description'
import Loader from '@/components/UI/Loader'
import ImageCarousel from '@/components/Location/ImageCarousel'
import Media from '@/components/Location/Media'
import ReviewList from '@/components/Location/ReviewList'
import UiComponentDrawer from '@/components/UI/Drawer'
import { getLocation, getLocationLogo } from '../../providers/location'
import { getLocationOpeningHoursDate } from '../../providers/openinghours'
import { mapState } from 'vuex'

export default {
  name: 'drawer-location-detail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },

    // props for content
    predefinedLocation: {
      type: Object,
      required: true,
    },

    meetingtypeId: {
      type: Number,
      required: true,
    },

    /**
     * View types
     * 0 = Contact details
     * 1 = Description
     * 2 = Media
     */
    preDefinedViewType: {
      type: Number,
      default: 0,
    },
  },

  components: {
    ContactDetails,
    Description,
    ImageCarousel,
    Loader,
    Media,
    ReviewList,
    UiComponentDrawer,
  },

  data() {
    return {
      viewTypeIndex: this.preDefinedViewType,
      location: this.predefinedLocation,
      logo: null,
      openingHour: null,
      isLoading: true,
      activeComponent: {
        component: 'Loader',
        key: 'loader',
        props: {},
      },
    }
  },

  computed: {
    ...mapState('searchStore', ['currentSearch'])
  },

  created() {
    this.startup()
  },

  methods: {
    startup() {
      let self = this
      axios
        .all([
          getLocation(this.location.LocationId),
          getLocationLogo(this.location.LocationId),
          getLocationOpeningHoursDate({
            locationId: this.location.LocationId,
            meetingtypeId: this.currentSearch.MeetingtypeId,
            startDate: this.currentSearch.StartDate,
            endDate: this.currentSearch.EndDate,
          }),
        ])
        .then(
          axios.spread((locatonResponse, logoResponse, openingHourResponse) => {
            if (locatonResponse.status === 200) {
              self.location = { ...self.location, ...locatonResponse.data }
            }
            if (logoResponse.status === 200) {
              self.logo = logoResponse.data
            }
            if (openingHourResponse.status === 200) {
              self.openingHour = openingHourResponse.data
            }
          })
        )
        .finally(() => {
          self.isLoading = false
          self.switchContentView(self.viewTypeIndex)
        })
    },

    switchContentView(index = 0) {
      this.viewTypeIndex = index
      switch (index) {
        case 0:
          this.activeComponent = {
            component: 'ContactDetails',
            key: 'contactDetails',
            props: {
              location: this.location,
              logo: this.logo,
              openingHour: this.openingHour,
            },
          }
          break

        case 1:
          this.activeComponent = {
            component: 'Description',
            key: 'description',
            props: {
              descriptions: this.location.Descriptions,
            },
          }
          break

        case 2:
          this.activeComponent = {
            component: 'Media',
            key: 'media',
            props: {
              media: this.location.Videos,
            },
          }
          break

        case 3:
          this.activeComponent = {
            component: 'ReviewList',
            key: 'reviews',
            props: {
              locationId: this.location.LocationId,
              meetingtypeId: this.meetingtypeId,
              showComments: true,
            },
          }
          break

        default:
          this.activeComponent = {
            component: 'Loader',
            key: 'loader',
            props: {},
          }
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-images {
  margin-top: -$gap;
  margin-right: -$gap;
  margin-bottom: $gap;
  margin-left: -$gap;
}
</style>
