<template>
  <div v-if="profile" class="InvoiceControls">
    <transition name="fade" mode="out-in">
      <div :class="`${$classPrefix}is-align-items-center`" class="is-flex logoutControls" key="myCompanies">
        <div class="mr-3">
          <b class="has-text-weight-bold">{{ $t('App.InvoiceControls.Text_Welkom', { name: '' }).replace(/\s/g, '') }},</b><br/>
          {{ FirstName }}
        </div>
        <span>
          <a
            v-if="!userIsLoggedInOnMainSite"
            :class="[`${$classPrefix}button is-small is-success has-margin-left`]"
            @click="logoff"
            v-text="$t('General.Button_LogOff')"
          ></a>
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getCookie } from '@/helpers/browserStorage'

export default {
  name: 'InvoiceControls',

  data() {
    return {
      userIsLoggedInOnMainSite: getCookie('lt') !== '',
    }
  },

  computed: {
    ...mapState('authStore', ['profile']),
    /**
     * The first name of the logged in user
     */
    FirstName() {
      return this.profile.FirstName
    },
  },
  methods: {
    ...mapActions('authStore', ['SignOut']),

    logoff() {
      this.SignOut()
    },
  },
}
</script>
