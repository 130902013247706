var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LocationCard",class:_vm.classList,on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseOut}},[(_vm.showLocationImage)?_c('figure',{staticClass:"LocationCard__image has-background-grey-light",style:({
      backgroundImage: `url('${_vm.$options.filters.getLocationImage(
        _vm.location.Image,
        _vm.location.LocationId,
        '640'
      )}')`,
    }),on:{"click":function($event){return _vm.showDrawer('drawerLocationDetail', _vm.location, 0)}}}):_vm._e(),_c('div',{staticClass:"LocationCard__detail_price"},[_c('div',{staticClass:"LocationCard__details has-padding"},[_c('div',{class:[`has-text-weight-bold`, !_vm.compact ? 'is-size-4' : ''],domProps:{"innerHTML":_vm._s(_vm.name)}}),_c('p',{staticClass:"has-margin-bottom"},[(_vm.interactive)?_c('a',{staticClass:"link-more-info has-icon",on:{"click":_vm.handleLocationOnMap}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'map-marker-alt']}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.address)}})]):_c('span',{staticClass:"has-icon"},[(!_vm.compact)?_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'map-marker-alt']}})],1):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.address)}})])]),(_vm.upsellItems.length)?_c('UpsellTemplate',{staticClass:"has-margin-bottom",attrs:{"upsellItems":_vm.upsellItems}}):_vm._e(),(_vm.showRating && _vm.score > 0)?_c('div',{staticClass:"LocationCard__review",on:{"click":function($event){return _vm.showDrawer('drawerLocationDetail', _vm.location, 0)}}},[_c('span',{class:`${_vm.$classPrefix}tag is-medium ${
              _vm.score > 0 ? 'is-info' : 'is-light has-text-white'
            }`,domProps:{"innerHTML":_vm._s(_vm.score > 0 ? _vm.score : 0)}}),_c('span',{staticClass:"is-size-7 has-text-grey"},[(_vm.score > 0)?[_vm._v(_vm._s(_vm.$t('Components.LocationCard.Text_AverageFrom'))+" "),_c('br')]:_vm._e(),_vm._v(" "+_vm._s(_vm.$tc( 'Components.LocationCard.Text_Reviews', _vm.location.ReviewCount, { score: _vm.location.ReviewCount } )))],2)]):_vm._e()],1),(!_vm.selectedLocationId || this.LastStep === 1)?_c('div',{staticClass:"LocationCard__reviewPrice has-background-info-light has-padding"},[_c('div',{staticClass:"has-text-centered"},[(_vm.showPricing)?_c('div',{staticClass:"LocationCard__price"},[_c('span',{attrs:{"alt":_vm.pricingMethod,"title":_vm.pricingMethod}},[_vm._v(_vm._s(_vm.$t('Components.LocationCard.Text_From'))+" "),_c('strong',{staticClass:"is-size-5",domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.toCurrency(_vm.lowestPrice, 'nl-NL', 'EUR')
              )}}),_vm._v(" "+_vm._s(_vm.pricingMethodShortner))]),(!_vm.hideTotalPrice)?_c('div',{staticClass:"LocationCard__priceExplanation is-size-7",domProps:{"textContent":_vm._s(
              _vm.$t('Components.LocationCard.Text_PriceTotal', {
                price: _vm.$options.filters.toCurrency(
                _vm.lowestPriceTotal,
                'nl-NL',
                'EUR'
              )}))}}):_vm._e(),_c('div',{staticClass:"is-size-7",domProps:{"innerHTML":_vm._s(_vm.$t('Components.LocationCard.Text_ExcludingOptions'))}})]):_vm._e(),_c('button',{staticClass:"LocationCard__button is-fullwidth is-success",class:[`${_vm.$classPrefix}button`, { 'is-small': _vm.compact }, {'is-loading': _vm.showButtonLoader }],domProps:{"textContent":_vm._s(_vm.buttonText)},on:{"~click":function($event){return _vm.handleSelectLocationClick.apply(null, arguments)}}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }