import axiosInstance from '@/helpers/axios'

let saveSearchCancel = null

export const getSearchByKey = (searchKey) => {
  return axiosInstance.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/search/${searchKey}`
  )
}

export const saveSearch = (search) => {
  saveSearchCancel && saveSearchCancel('Request cancelled')
  return axiosInstance.post(`search`, search, {
    cancelToken: new axiosInstance.CancelToken(function executor(c) {
      saveSearchCancel = c;
    })
  })
}

export const getMeetingTypes = (params) => {
  return axiosInstance.get(`search/meetingtype`, {
    params,
  })
}

export const setSearchLastStep = ({ SearchKey, step }) => {
  return axiosInstance.put(`search/${SearchKey}/step/${step}`, {
    SearchKey,
  })
}
