<template>
  <div>
    <Modal
      v-if="showModal"
      :showModal="showModal"
      :onCloseFunc="hideModal"
      :modalTitle="modalTitle"
      modalSize="large"
    >
      <template v-slot:customHeader></template>
      <template v-slot:content>
        <div
          :class="`${$classPrefix}content`"
          v-html="description"
        ></div>
      </template>
      <template v-slot:footer>
        <div v-html="'Version: ' + version"></div>
      </template>
    </modal>

    <div :class="{'has-text-grey' : isBusy}">
      <Checkbox
        :name="name"
        v-model="hasAgreed"
        :i18nPath="textAgreeWithTerms"
        :validationRules="validationRules"
        required
        :disabled="isBusy || disabled"
        :errorMessages="errorMessages"
      >
        <template v-slot:labelAction>
          <a
            @click.prevent="setShowTerms"
            v-text="$t(linkAgreeWithTerms)"
          ></a>
        </template>
      </Checkbox>
    </div>
    <TransitionExpand>
      <div
        :class="`${$classPrefix}box`"
        class="has-margin-top termstext"
        v-if="showTerms"
      >
        <div
          class="is-italic has-margin-top"
          v-html="description"
        ></div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import Checkbox from '@/components/UI/Form/Checkbox'
import TransitionExpand from '@/components/TransitionExpand'
import { mapGetters } from 'vuex'
import Modal from '../UI/Modal.vue'
export default {
  props: {

    /**
     * Behaviour
     */
    showOnPage: {
      type: Boolean,
      default: true
    },

    /**
     * Form
     */
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
  },

  components: {
    Checkbox,
    TransitionExpand,
    Modal,
  },

  data() {
    return {
      modalTitle: '',
      description: '',
      errorMessages: {
        hasAgreed: this.$t('Components.ForgotLoginRegister.Register.Message_TermsNotAgreed')
      },
      hasAgreed: false,
      showTerms: false,
      version: '',
      showModal: false,
      initReady: true,
      disabled: false,
      textAgreeWithTerms: 'Components.ForgotLoginRegister.Register.Text_AgreeWithTerms',
      linkAgreeWithTerms: 'Components.ForgotLoginRegister.Register.Link_AgreeWithTerms'
    }
  },

  computed: {
    ...mapGetters('cartStore', ['isBusy']),

    validationRules() {
      let rules = {}
      if (this.required) {
        rules = {
          hasAgreed(val) {
            return val === true
          }
        }
      }
      return rules
    }
  },

  methods: {
    setShowTerms() {
      this.showOnPage ?
        this.showTerms = !this.showTerms
        :
        this.showModal = true
    },

    hideModal() {
      this.showModal = false
    }
  },
}
</script>

<style lang="scss" scoped>
.termstext {
  max-height: 200px;
  min-height: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>