<template>
  <transition-group
    name="fade"
    mode="out-in"
    tag="div"
    class="tagsarea is-grouped is-grouped-multiline"
    :class="[`${$classPrefix}field`, { 'is-borderless' : inputBorderless }]"
  >
    <div
      v-for="(tag, index) in tags"
      :key="'t_'+index"
      :class="`${$classPrefix}control`"
    >
      <div :class="`${$classPrefix}tags has-addons`">
        <span :class="`${$classPrefix}tag is-link`">{{tag}}</span>
        <a
          @click="removeTag(index)"
          :class="`${$classPrefix}tag is-delete`"
        ></a>
      </div>
    </div>
    <span
      key="tagInput"
      class="wrapper-input"
    >
      <input
        ref="tagInput"
        type="text"
        v-model="tagInput"
        @keyup.enter="addTag"
        @blur="addTag"
        :class="`${$classPrefix}input input-tag`"
        placeholder="Add tag"
      />
      <a
        @click="addTag"
        key="addTagButton"
        class="is-small is-success"
        :class="[`${$classPrefix}button`, {'is-success': tagInput, 'is-disabled': !tagInput }]"
        v-html="$t('General.Text_AddTag')"
      ></a>
    </span>
  </transition-group>
</template>

<script>
import BaseFormInputField from './BaseFormInputField'
export default {
  name: 'TagInput',
  extends: BaseFormInputField,

  props: {
    name: {
      type: String,
      default: 'tags'
    },
    inputBorderless: {
      type: Boolean,
      default: false
    },
    setFocusInput: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoadingTagsFromQuestion: false,
      tagInput: '',
      tags: [],
      tagInputPlaceHolder: 'Add tag',
      timer: null
    }
  },

  created() {
    this.tags = this.fieldValue.split(/[,.\*+-/_#]/)
  },

  computed: {},

  watch: {},

  methods: {
    /**
     * Add tag to tags array
     */
    addTag() {
      if (this.tagInput) {
        let text = this.$options.filters.stripHtml(this.tagInput)
        let arr = text.split(/[.\*+-/_#]/)
        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.tags.push(arr[i].trim())
          }
        }
        arr = []
        text = ''
        this.tagInput = ''
        this.$refs.tagInput.focus()
        this.$emit(
          'input',
          this.$options.filters.stripHtml(this.tags.join(','))
        )
      }
    },

    /**
     * Remove tag from array
     */
    removeTag(index) {
      this.tags.splice(index, 1)
      this.$emit('input', this.$options.filters.stripHtml(this.tags.join(',')))
    },

    /**
     * Check if tag already exists in array
     */
    tagAlreadyAdded(tag) {
      var index = this.tags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.tagsarea {
  border: $control-border-width solid $input-border-color;
  padding: $gap;
}

.#{$prefix}field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 80px;
  height: auto;
  margin-bottom: 0 !important;

  &:last-child {
    margin-bottom: $gap;
  }

  .#{$prefix}tags {
    .tag {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-tag {
      min-width: 140px;
      width: 140px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
