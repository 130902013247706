import axiosInstance from '@/helpers/axios'

export const getCartPackages = (cartKey) => {
  return axiosInstance.get(`cart/${cartKey}/package`)
}

export const applyVoucherToCart = (cartKey, voucherCode) => {
  return axiosInstance.put(`voucher/cart/${cartKey}/apply`, {
    Code: voucherCode,
  })
}

export const removeVoucherFromCart = (cartKey, voucherId) => {
  return axiosInstance.put(`voucher/cart/${cartKey}/revert`, { VoucherId: voucherId})
}
