<template>
  <a @click="goOneStepBack">
    <span :class="`${$classPrefix}icon is-small`">
      <font-awesome-icon :icon="['fal', 'chevron-left']" />
    </span>
    <span>{{ label }}</span>
  </a>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'BackButton',
  props: {
    label: {
      type: String,
      default: 'back',
    },
  },
  computed: {
    ...mapGetters('searchStore', ['LastStep']),
    ...mapState('widgetStore', ['widgetState',]),


  },
  methods: {
    ...mapActions('searchStore', ['goToStep']),


    goOneStepBack() {
      this.goToStep({ step: this.LastStep - 1 })
    },
  },
}
</script>
