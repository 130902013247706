import axios from '@/helpers/axios'

export default {
  getBookingTerms({ channelId, locationId, language }) {
    return axios.get(`/Term/booking/latest`, {
      params: {
        locationId,
        channelId,
        language
      },
    })
  },

  getPrivacyTerms: async ({ channelId, locationId, language }) => {
    return axios.get(`/Term/privacy/latest`, {
      params: {
        locationId,
        channelId,
        language
      },
    })
  }
}
