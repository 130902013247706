<template>
  <div>
    <div
      :class="`${$classPrefix}title`"
      class="is-4 "
      v-html="$t('Components.ForgotLoginRegister.SetNewPassword.Mail_Title')"
    >
    </div>
    <Message
      class="is-danger"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </Message>

    <Form
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <div :class="`${$classPrefix}content`">
        <p v-html="$t('Components.ForgotLoginRegister.SetNewPassword.Text_Notification', {
            email: '',
          })"></p>
      </div>

      <PasswordField
        name="password1"
        :label="$t('Components.ForgotLoginRegister.SetNewPassword.Label_Password')"
        @validated="onPasswordValidated"
      />
      <PasswordField
        name="password2"
        :label="$t('Components.ForgotLoginRegister.SetNewPassword.Label_RepeatPassword')"
        @validated="onPasswordValidated"
      />
      <BaseFieldTemplate
        :label="$t('Components.ForgotLoginRegister.SetNewPassword.Label_ConfirmationCode')"
        :required="true"
      >
        <template v-slot:fields>
          <RequiredField
            class="is-hidden"
            name="code"
            :value="code"
            @validated="onCodeValidated"
          />
          <div :class="`${$classPrefix}level is-inline-flex input-codes is-mobile`">
            <div :class="`${$classPrefix}level-item`">
              <input
                type="text"
                ref="codePart1"
                @keyup="checkCodePart1"
                size="2"
                max="2"
                maxlength="2"
                name="codePart1"
                :class="[`${$classPrefix}input has-text-centered`, { 'is-danger': failCode === -1 || failCode === 400 || passwordsNotEqual }]"
              />
            </div>
            <div :class="`${$classPrefix}level-item`">
              <font-awesome-icon :icon="['fal', 'minus']" />
            </div>
            <div :class="`${$classPrefix}level-item`">
              <input
                type="text"
                ref="codePart2"
                @keyup="checkCodePart2"
                size="2"
                max="2"
                maxlength="2"
                name="codePart2"
                :class="[`${$classPrefix}input has-text-centered`, { 'is-danger': failCode === -1 || failCode === 400 || passwordsNotEqual }]"
              />
            </div>
            <div :class="`${$classPrefix}level-item`">
              <font-awesome-icon :icon="['fal', 'minus']" />
            </div>
            <div :class="`${$classPrefix}level-item`">
              <input
                type="text"
                ref="codePart3"
                @keyup="checkCodePart3"
                size="2"
                max="2"
                maxlength="2"
                name="codePart3"
                :class="[`${$classPrefix}input has-text-centered`, { 'is-danger': failCode === -1 || failCode === 400 || passwordsNotEqual}]"
              />
            </div>
          </div>
          <p v-if="errorCodeMessage">
            <span :class="`${$classPrefix}help is-danger`">
              {{ errorCodeMessage }}
            </span>
          </p>
        </template>
      </BaseFieldTemplate>

      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`">
        </div>

        <div :class="`${$classPrefix}level-right`">
          <div :class="`${$classPrefix}level-item`">
            <button
              :disabled="passwordsNotEqual"
              :class="[`${$classPrefix}button`, {
                  'is-success': !passwordsNotEqual,
                  'is-disabled': passwordsNotEqual,
                  'is-loading': isProcessing,
                }]"
              type="submit"
            >
              <span v-html="$t('Components.ForgotLoginRegister.SetNewPassword.Button_Save')"></span>
            </button>
          </div>
        </div>
      </div>
    </Form>
    <hr />
    <div>
      {{ $t('Components.ForgotLoginRegister.SetNewPassword.Text_NoMailReceived') }}
      <a
        @click="goToAuthPage({ page: 'forgot' })"
        v-html="$t('Components.ForgotLoginRegister.SetNewPassword.Link_NoMailReceived')"
      ></a>
    </div>
  </div>
</template>

<script>
import Form from '@/components/UI/Form/BaseForm'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import RequiredField from '@/components/UI/Form/RequiredField'
import PasswordField from '@/components/UI/Form/PasswordField'
import Message from '@/components/UI/Message'
import authProvider from '../../providers/auth'
import encryptString from '@/mixins/encryptString'
import { mapMutations } from 'vuex'

export default {
  name: 'set-new-password',

  components: {
    BaseFieldTemplate,
    RequiredField,
    Form,
    Message,
    PasswordField
  },

  mixins: [encryptString],

  data() {
    return {
      failCode: 0,
      passwordsNotEqual: false,
      isProcessing: false,
      code: '',
      errorCodeMessage: ''
    }
  },

  computed: {
    errorMessage() {
      if (this.failCode === -1) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode-1')
      } else if (this.failCode === -2) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode-2')
      } else if (this.failCode === 400) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode400')
      } else if (this.failCode === 429) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode429')
      } else if (this.failCode === 500) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode500')
      } else if (this.failCode === 501) {
        return this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorCode501')
      }
      return ''
    }
  },

  methods: {
    ...mapMutations('authStore', ['goToAuthPage']),

    handleAuthNavigate({ page }) {
      return this.goToAuthPage({ page })
    },

    onStatusChange({ status }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'COMPLETED':
          this.goToAuthPage({ page: 'forgot-confirm' })
          break
      }
    },

    onCodeValidated() {
      this.errorCodeMessage = ''
      if (this.code === '') {
        this.errorCodeMessage = this.$t('App.Text_InputRequired')
      } else if (this.failCode === -1) {
        this.errorCodeMessage = this.$t('Components.ForgotLoginRegister.SetNewPassword.Message_ErrorConfirmationCode')
      }
    },

    onPasswordValidated({ value, valid }) {
      this.passwordsNotEqual = !valid && this.password_1 !== value
    },

    handleSubmit({ formData, component }) {
      return new Promise((resolve, reject) => {
        this.failCode = 0
        if (formData.password1 !== formData.password2) {
          this.failCode = 501
          return reject({ response: 'FAIL', component })
        }
        
        let encryptedPassword = this.encryptPassword(formData.password2)

        authProvider.changePassword({ token: '', password: encryptedPassword, pin: formData.code })
          .then(response => {
            this.failCode = response.data ? 0 : response.data
            resolve({ response: "OK", component });
          })
          .catch(e => {
            this.failCode = e.response.status
            reject({ response: 'FAIL', component })
          })
      });
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    checkCodePart3() {
      this.updateCode()
    },
    updateCode() {
      let output = ''
      output += this.$refs.codePart1 ? this.$refs.codePart1.value.trim() : ''
      output += this.$refs.codePart2 ? this.$refs.codePart2.value.trim() : ''
      output += this.$refs.codePart3 ? this.$refs.codePart3.value.trim() : ''
      this.code = output
    }
  }
}
</script>

<style lang="scss" scoped>
</style>