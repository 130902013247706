<template>
  <div>
    <span
      v-if="!shownInDrawer"
      :class="`${$classPrefix}icon`"
    >
      <font-awesome-icon :icon="['fad', 'sliders-h']" />
    </span>
    <div
      v-if="shownInDrawer"
      :class="`has-text-weight-bold has-margin-bottom-half`"
      v-html="$t('App.DrawerFilters.Label_Configuration')"
    ></div>
    <SpaceConfigurationFilter
      :selectBoxSize="selectBoxSize"
      class="has-margin-right-half"
      :class="{ 'has-margin-bottom' : shownInDrawer}"
    />
    <div
      v-if="shownInDrawer"
      :class="`has-text-weight-bold has-margin-bottom-half`"
      v-html="$t('App.DrawerFilters.Label_EventTypes')"
    ></div>
    <EventTypeFilter
      :selectBoxSize="selectBoxSize"
      :class="{ 'has-margin-bottom' : shownInDrawer}"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import EventTypeFilter from '@/components/SearchResults/EventTypeFilter'
import SpaceConfigurationFilter from '@/components/SearchResults/SpaceConfigurationFilter'

export default {
  components: {
    EventTypeFilter,
    SpaceConfigurationFilter,
  },

  props: {
    shownInDrawer: {
      type: Boolean,
      default: false
    },

    selectBoxSize: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('availabilityStore', ['processedLocations']),
    ...mapState('searchStore', ['selectedEventType', 'selectedSetting']),

    // availableSettings() {
    //   let settings = []

    //   for (let i = 0; i < this.processedLocations.length; i++) {
    //     let location = this.processedLocations[i]

    //     for (let j = 0; j < location.Spaces.length; j++) {
    //       let space = location.Spaces[j]

    //       let settingIndex = settings.findIndex(
    //         (s) => s.SettingId === space.SettingId
    //       )
    //       if (settingIndex === -1) {
    //         settings.push({
    //           SettingId: space.SettingId,
    //           Name: space.SettingName,
    //           Count: 1,
    //         })
    //       } else {
    //         settings[settingIndex].Count++
    //       }
    //     }
    //   }

    //   return settings
    // },

    // settingName() {
    //   return this.availableSettings.find(
    //     (as) => as.SettingId === this.selectedSetting
    //   ).Name
    // },
  },

  created() { },

  methods: {
    ...mapMutations('searchStore', [
      'setSelectedEventType',
      'setSelectedSetting',
    ]),

    resetEventType() {
      this.setSelectedEventType(0)
    },

    resetSetting() {
      this.setSelectedSetting(0)
    },
  },
}
</script>

<style></style>
