<template>
  <div
    v-if="filteredLocations && filteredLocations.length > 0"
    class="LocationMap"
    :class="classList"
    ref="map"
  >
    <MapBox
      class="MapBox"
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :options="options"
      @load="onMapLoaded"
    />
    <div
      ref="popup"
      class="MapBox__popup"
    >
      <LocationCard
        v-if="popupLocation"
        :location="popupLocation"
        :compact="true"
        :showRating="false"
        :interactive="true"
      />
    </div>
    <!-- <div
      ref="userlocation"
      class="MapBox__user"
    >
      <font-awesome-icon
        v-if="userLnglat"
        :icon="['fal', 'location']"
      />
    </div> -->
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'

import MapBox from '@/components/MapBox'
import LocationCard from '@/components/SearchResults/LocationCard'

import timeout from '@/mixins/timeout'
import { mapGetters } from 'vuex'

import pinIcon from '@/assets/images/mapBoxPinIcon.png'
import mapBoxSelectedLocationPinIcon from '@/assets/images/mapBoxSelectedLocationPinIcon.png'

export default {
  name: 'LocationMap',

  props: {
    markerIcons: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MapBox,
    LocationCard,
  },

  mixins: [timeout],

  data() {
    return {
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapReady: false,

      // The Location object of the location shown in the popup
      popupLocation: null,
      // A reference to the mapbox popup object
      popup: null,
      // The internal hover event tracker
      internalHoveredLocationId: null,
      /**
       * This is used to store the id of the location of which the card is being hovered upon by the user
       */
      hoveredLocation: null,
      /**
       * The user lng lat. Only available upon user action
       */
      userLnglat: null,
      filterLocationTimer: null,
    }
  },

  computed: {
    ...mapGetters('availabilityStore', [
      'processedLocations',
      'selectedLocation',
      'selectedSpace',
    ]),
    ...mapGetters('searchStore', ['search', 'selectedEventType']),
    ...mapGetters('searchStore', ['search', 'selectedSetting']),
    ...mapGetters('searchStore', ['LastStep']),

    filteredLocations() {
      let locations = []
      if (this.LastStep === 2) {
        locations.push(this.selectedLocation)
      } else {
        locations = this.processedLocations
      }

      if (this.selectedEventType > 0) {
        let locsA = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounterA = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.EventTypes.includes(this.selectedEventType)) {
              spaceCounterA++
            }
          }

          if (spaceCounterA > 0) {
            locsA.push(location)
          }
        }

        locations = locsA
      }

      if (this.selectedSetting > 0) {
        let locsB = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounterB = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.SettingId === this.selectedSetting) {
              spaceCounterB++
            }
          }

          if (spaceCounterB > 0) {
            locsB.push(location)
          }
        }

        locations = locsB
      }

      return locations
    },

    /**
     * Determine the map bounds based on the coordinates of the locations being shown
     */
    bounds() {
      return this.filteredLocations.reduce(
        (bounds, location) =>
          bounds.extend([location.Longitude, location.Latitude]),
        new mapboxgl.LngLatBounds()

      )
    },

    /**
     * Supplies the mapbox options.
     */
    options() {
      return {
        bounds: this.bounds, fitBoundsOptions: { padding: 50 }, maxZoom: 15
      }
    },

    /**
     * Combines the internal hover event with the external hover event
     */
    locationBeingHovered() {
      return this.hoveredLocation || this.internalHoveredLocationId
    },

    /**
     * The classes applied to the main container
     */
    classList() {
      return {
        'LocationMap--small': this.selectedLocation && this.LastStep !== 1,
        'LocationMap--hide': this.selectedSpace,
      }
    },
  },

  watch: {
    filteredLocations(val) {
      if (this.filterLocationTimer) {
        clearTimeout(this.filterLocationTimer)
      }
      this.filterLocationTimer = setTimeout(() => {
        if (this.$store.$map !== null) {
          //Clear layers if present
          if (this.$store.$map.getLayer('clusters')) {
            this.$store.$map.removeLayer('clusters')
          }
          if (this.$store.$map.getLayer('cluster-count')) {
            this.$store.$map.removeLayer('cluster-count')
          }
          if (this.$store.$map.getLayer('locations')) {
            this.$store.$map.removeLayer('locations')
          }
          if (this.$store.$map.getSource('clusters')) {
            this.$store.$map.removeSource('clusters')
          }
          if (this.$store.$map.getSource('cluster-count')) {
            this.$store.$map.removeSource('cluster-count')
          }
          if (this.$store.$map.getSource('locations')) {
            this.$store.$map.removeSource('locations')
          }



          // Clean up previous popup
          if (this.popup && this.popup.isOpen()) {
            this.popup.remove()
            this.popup.off('close', this.resetPopupLocationDetails)
            this.resetPopupLocationDetails()
          }

          // Show available locations within bounds of the screen/map size.
          if (this.LastStep !== 2) {
            let bounds = val.reduce(
              (bounds, location) =>
                bounds.extend([location.Longitude, location.Latitude]),
              new mapboxgl.LngLatBounds()
            )
            this.$store.$map.fitBounds(bounds, {
              padding: 50,
            })
          }

          //Add layers
          if (!this.$store.$map.getLayer('locations')) {
            this.$store.$map.addLayer(this.generateLocationsGeoJSON())
          }
          if (!this.$store.$map.getLayer('clusters')) {
            this.$store.$map.addLayer(this.generateClustersGeoJSON())
          }
          if (!this.$store.$map.getLayer('cluster-count')) {
            this.$store.$map.addLayer(this.generateClusterCount())
          }
        }
        clearTimeout(this.filterLocationTimer)
      }, 10)
    },

    /**
     * The manner by which the dominant hovered location info changes determines how the
     */
    locationBeingHovered(newValue, oldValue) {
      if (newValue !== null) {
        this.applyHoverEffectToFeature({
          active: newValue !== null,
          id: newValue || oldValue,
        })
      }
    },
    /**
     * When the user location becomes available we add the location on the map, and re-fit the map to the bounds including the user location
     */
    // userLnglat() {
    //   if (this.userLnglat && this.$store.$map) {
    //     // this.addUserMarker()

    //     let bounds = this.bounds.extend(this.userLnglat)
    //     this.$store.$map.fitBounds(bounds, {
    //       padding: 50,
    //     })
    //   }
    // },
    /**
     * If the selected Location changes flyto and resize actions may be required.
     * If no location was previously selected, the MapBox decreases in size, and flies to the selected location
     * If a location is deselected, the MapBox increases in size. If a popup happens to be opened, we fly to that location
     * If the location changes from one to another, we simply fly to the new location
     */

    LastStep(currentStep) {
      if (currentStep === 2) {
        if (this.popupLocation !== null) {
          this.resetPopup()
        }

        this.setTimeout(() => {
          this.$store.$map.resize()
          if (this.selectedLocation !== null) {
            this.$store.$map.flyTo({
              center: [this.selectedLocation.Longitude, this.selectedLocation.Latitude],
              offset: [0, 0],
              zoom: 15,
            })
          }
        }, 300)
      }
    },

    selectedLocation(selectedLocation) {
      if (!this.$store.$map) {
        return
      }

      if (selectedLocation !== null) {
        this.switchToSelectedLocationLayer()

      } else {
        this.switchToSearchLocationLayer()
      }

      // Going from one to another selected location => imediate fly to
      if (selectedLocation !== null && this.LastStep === 1) {
        this.$nextTick(() => {
          this.$store.$map.flyTo({
            center: [selectedLocation.Longitude, selectedLocation.Latitude],
            offset: [0, 200],
            zoom: 15
          })
        })

        // Otherwise 'resize' the canvas after the resize animation (delay of 300 ms) and then fly to
        // either the selectedLocation, or the location of the opened pop-up if applicable.
      } else {
        this.$nextTick(() => {
          // Close any open open modals if we've got an active location
          if (this.popupLocation !== null) {
            this.resetPopup()
          }

          this.setTimeout(() => {
            this.$store.$map.resize()

            // If we have an active location, go to that location
            // Note: in this case the MapBox canvas shrunk to small size and we went to step 2
            if (selectedLocation !== null) {
              this.$store.$map.flyTo({
                center: [selectedLocation.Longitude, selectedLocation.Latitude],
                offset: [0, 0],
                zoom: 15,
              })
            }

          }, 300) // CSS animation takes 300ms
        })
      }
    },

    selectedSpace(selectedSpace) {
      if (selectedSpace === null) {
        if (this.selectedLocation) {
          this.$store.$map.flyTo({
            center: [
              this.selectedLocation.Longitude,
              this.selectedLocation.Latitude,
            ],
            offset: [0, 0],
          })
        }
      }
    },
  },

  created() {
    this.$EventBus.$on('location.card.show', this.focusOnLocation)
    this.$EventBus.$on('location.card.hover', this.setHoverState)
  },
  mounted() {
  },
  beforeDestroy() {
    // Clear the map reference
    this.$store.$map = null

    this.$EventBus.$off('location.card.show', this.focusOnLocation)
    this.$EventBus.$off('location.card.hover', this.setHoverState)
  },

  methods: {
    setHoverState({ LocationId }) {
      this.hoveredLocation = LocationId
    },
    // setUserLnglat({ lnglat }) {
    //   this.userLnglat = lnglat
    // },
    /**
     * When the MapBox map is loaded we add the locations layer, and attach some event handlers
     */
    onMapLoaded(event) {
      // Note: This MapBox object should not be made reactive
      this.$store.$map = event.map
      this.$EventBus.$on('location.card.show', this.focusOnLocation)

      // Check for the markerIcons prop. If true, load images and add them to the layers where needed.
      if (this.markerIcons) {
        // Add the location markers (icons, no SVG or FAS allowed) as a layer, loading the images first before adding them.
        event.map.loadImage(pinIcon, function(error, image) {
          if (error) throw error;
          event.map.addImage('location', image);
        })
        event.map.loadImage(mapBoxSelectedLocationPinIcon, function(error, image) {
          if (error) throw error;
          event.map.addImage('selectedlocation', image);
        })
      }

      // Add geolocate control to the map so the user can share his location.
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })

      event.map.addControl(geolocate)

      // Setting lonlat for the user
      geolocate.on('geolocate', (e) => {
        let arr = [e.coords.longitude, e.coords.latitude];
        let ll = mapboxgl.LngLat.convert(arr);

        // emitting the userLnglat to set it in the LocationCards component
        let self = this
        self.$EventBus.$emit('location.map.userLnglat', ll)
      });


      // Add nav controls for zoom and exclude compass
      let nav = new mapboxgl.NavigationControl({ showCompass: false });
      event.map.addControl(nav, 'top-right');

      // Adding layers for locations, seleted location, cluster and cluster-count.
      event.map.addLayer(this.generateLocationsGeoJSON())
      event.map.addLayer(this.generateSelectedLocationGeoJSON())
      event.map.addLayer(this.generateClustersGeoJSON())
      event.map.addLayer(this.generateClusterCount())

      // Show the right cursor on hover, and pass on information about the location being hovered
      // The emit is also listened to by the LocationCards component to scroll to the hovered LocationCard.
      event.map.on('mouseenter', 'locations', (e) => {
        event.map.getCanvas().style.cursor = 'pointer'
        this.internalHoveredLocationId = e.features[0].properties.LocationId
        this.$EventBus.$emit('location.map.hover', {
          LocationId: e.features[0].properties.LocationId,
        })

      })
      event.map.on('mouseleave', 'locations', () => {
        event.map.getCanvas().style.cursor = ''
        this.internalHoveredLocationId = null
        this.$EventBus.$emit('location.map.hover', { LocationId: null })
      })

      // When a cluster is clicked, zoom into that cluster
      event.map.on('click', 'clusters', function(e) {
        let features = event.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        let clusterId = e.features[0].properties.cluster_id;
        event.map.getSource('locations').getClusterExpansionZoom(
          clusterId,
          function(err, zoom) {
            if (err) return;

            event.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      // When a marker is clicked, show the popup
      event.map.on('click', 'locations', (e) => {
        if (e.features[0].properties.LocationId) {
          this.focusOnLocation({
            LocationId: e.features[0].properties.LocationId,
          })
        }
      })

      if (this.selectedLocation !== null) {
        this.switchToSelectedLocationLayer()

      }
    },
    switchToSelectedLocationLayer() {
      if (this.$store.$map.getLayer('locations') && this.LastStep !== 1) {
        this.$store.$map.setLayoutProperty('locations', 'visibility', 'none')
      }

      if (this.$store.$map.getLayer('locations') && this.LastStep === 1) {
        this.$store.$map.setLayoutProperty('locations', 'visibility', 'visible')
      }

      if (this.$store.$map.getLayer('selectedlocation')) {
        // Set data in the selected location layer
        this.$store.$map.getSource('selectedlocation').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              id: this.selectedLocation.LocationId,
              properties: {
                LocationId: this.selectedLocation.LocationId,
              },
              geometry: {
                type: 'Point',
                coordinates: [
                  this.selectedLocation.Longitude,
                  this.selectedLocation.Latitude,
                ],
              },
            },
          ],
        })

        this.$store.$map.setLayoutProperty('selectedlocation', 'visibility', 'visible')
      }
    },

    switchToSearchLocationLayer() {
      if (this.$store.$map.getLayer('selectedlocation')) {
        // Clear the selected location layer
        this.$store.$map.getSource('selectedlocation').setData({
          type: 'FeatureCollection',
          features: [],
        })
      }
      if (this.$store.$map.getLayer('locations')) {
        this.$store.$map.setLayoutProperty('locations', 'visibility', 'visible')
      }
    },

    /**
     * Create the GeoJSON by going through the location objects
     */
    generateLocationsGeoJSON() {
      let json = {}

      // Create custom markers with icons if the markerIcons prop is true
      if (this.markerIcons) {
        json = {
          id: 'locations',
          type: 'symbol', // symbol
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            clusterMinPoints: 2, // Minimum points to form a cluster (default set to 2)
          },
          layout: {
            'icon-image': 'location',
            'icon-allow-overlap': true,
            'icon-size': 0.20
          },
        }
      }
      // Paint dots instead of custom markers (used by default)
      else {
        json = {
          id: 'locations',
          type: 'circle', // symbol
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
            cluster: true,
            clusterMaxZoom: 13, // Max zoom to cluster points on
            clusterRadius: 40, // Radius of each cluster when clustering points (defaults to 50)
            clusterMinPoints: 2, // Minimum points to form a cluster (default set to 2)
          },
          paint: {
            'circle-color': '#11b4da',
            'circle-radius': 5,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#02BAFF'
          },
        }
      }

      let features = []
      this.filteredLocations.forEach((location) => {
        if (this.selectedLocation === null || location.LocationId !== this.selectedLocation.LocationId) {
          features.push({
            type: 'Feature',
            id: location.LocationId,
            properties: {
              LocationId: location.LocationId,
            },
            geometry: {
              type: 'Point',
              coordinates: [location.Longitude, location.Latitude],
            },
          })
        }
      })

      json.source.data.features = features

      return json
    },

    generateClustersGeoJSON() {
      return {
        id: 'clusters',
        type: 'circle',
        source: 'locations',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#95C21E',  /// green for point count under 10
            10,
            '#95C21E', // green for point count under 20 (previously blue -> '#02BAFF')
            20,
            '#95C21E' // green for point count >= 20
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10, // 10px for point count above 5
            5,
            15, // 15px for point count above 10
            10,
            20 // 20px for point count above 25
          ]
        }
      }
    },

    generateClusterCount() {
      return {
        id: 'cluster-count',
        type: 'symbol',
        source: 'locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': "#ffffff",
        },
      }
    },

    generateSelectedLocationGeoJSON() {
      // Create custom markers with icons if the markerIcons prop is true
      if (this.markerIcons) {
        return {
          id: 'selectedlocation',
          type: 'symbol', // symbol
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          layout: {
            'icon-image': 'selectedlocation',
            'icon-allow-overlap': true,
            'icon-size': 0.20
          },
        }
      }
      // Paint dots instead of custom markers (used by default)
      else {
        return {
          id: 'selectedlocation',
          type: 'circle', // symbol
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          paint: {
            'circle-color': '#95C31E',
            'circle-radius': 6,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#95C31E'
          },
        }
      }
    },
    /**
     * Update the state of the location being hovered.
     *  This triggers a paint condition to change the color of the feature on the map
     *  The method is disconnected from the actual source of the hover event
     */
    applyHoverEffectToFeature({ active, id }) {
      if (!this.$store.$map) {
        return
      }

      // Ignore hover state on the selectedLocation
      if (this.selectedLocation && this.selectedLocation.LocationId === id) {
        return
      }

      this.$store.$map.setFeatureState(
        {
          source: 'locations',
          id,
        },
        {
          hovering: active,
        }
      )
    },
    /**
     * Update the state of the location currently / previously selected.
     *  This triggers a paint condition to change the color of the feature on the map
     */
    applySelectedEffectToFeature({ active, id }) {
      if (!this.$store.$map) {
        return
      }

      this.$store.$map.setFeatureState(
        {
          source: 'locations',
          id,
        },
        {
          selected: active,
        }
      )
    },


    /**
     * Set the clicked location as popup content & navigate to the marker location
     */
    focusOnLocation({ LocationId }) {
      let content = this.$refs['popup']
      let location = this.filteredLocations.find(
        (location) => location.LocationId === LocationId
      )

      if (!location) {
        return
      }


      // By clicking a marker, push that location into the selectedlocation layer.
      if (this.$store.$map.getLayer('selectedlocation')) {
        // Set data in the selected location layer
        this.$store.$map.getSource('selectedlocation').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              id: location.LocationId,
              properties: {
                LocationId: location.LocationId,
              },
              geometry: {
                type: 'Point',
                coordinates: [
                  location.Longitude,
                  location.Latitude,
                ],
              },
            },
          ],
        })
      }

      // Clean up previous popup
      if (this.popup && this.popup.isOpen()) {
        this.popup.remove()
        this.popup.off('close', this.resetPopupLocationDetails)
        this.resetPopupLocationDetails()
      }

      let zoom = this.$store.$map.getZoom()
      this.$store.$map.flyTo({
        center: [location.Longitude, location.Latitude],
        offset: [0, this.selectedLocation && this.LastStep === 2 ? 0 : 200],
        zoom: zoom < 15 || zoom > 15 ? 15 : zoom,
      })

      // Add a small delay before adding the card to the pop-up, to avoid jumpy visuals
      this.setTimeout(() => (this.popupLocation = location), 100)

      this.popup = new mapboxgl.Popup({
        maxWidth: '70%',
        closeOnCLick: true,
      })
        .setLngLat([location.Longitude, location.Latitude])
        .setDOMContent(content)
        .addTo(this.$store.$map)

      this.popup.once('close', this.resetPopupLocationDetails)
    },
    /**
     * Reset the pop-up to initial state
     */
    resetPopup() {
      if (this.popup && this.popup.isOpen()) {
        this.popup.remove()
        this.popup.off('close', this.resetPopupLocationDetails)
        this.resetPopupLocationDetails()
      }
    },
    /**
     * Reset the location details reference that is used to fill the popup
     */
    resetPopupLocationDetails() {
      this.popupLocation = null
    },
    /**
     * Add a location pin to indicate the user's position
     * Note: only added upon user request and after permission has been granted
     */
    // addUserMarker() {
    //   new mapboxgl.Marker(this.$refs.userlocation)
    //     .setLngLat(this.userLnglat)
    //     .addTo(this.$store.$map)
    // },
  },
}
</script>

<style lang="scss">
.LocationMap {
  width: 100%;
  height: 700px;
  transition: height 0.3s ease;
  backface-visibility: hidden; /** triggers hardware acceleration */

  // Position sticky only works with new browsers. We have a js polyfill for older browsers
  position: relative;
  position: sticky;
  top: -#{($gap) * 2};

  &.#{$prefix}box {
    padding: 0;
  }

  &--small {
    height: 212px;
    position: relative;
    top: 0;
  }

  &--hide {
    height: 0;
  }

  .LocationCard.LocationCard {
    margin: 0;
  }

  .LocationMapControls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
  }

  .MapBox {
    height: 100%;
    width: 100%;

    .mapboxgl-popup {
      width: 400px;
    }
    .mapboxgl-popup-content {
      padding: 0;
    }
    .mapboxgl-popup-close-button {
      background-color: white;
      z-index: 1;
      font-size: 18px;
    }

    &__popup {
      .LocationCard {
        border: none;
      }
    }
    &__user {
      font-size: 20px;
      color: red;
      overflow: visible;

      svg {
        border-radius: 50%;
        margin: 10px;
        height: 20px;
        width: 20px;
        overflow: visible;
        backface-visibility: hidden; /** triggers hardware acceleration */

        box-shadow: 0 0 0 0 rgb(255, 0, 0);
        transform: scale(1);
        animation: pulse 2s infinite;
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
}
</style>
