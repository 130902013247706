<template>
  <li :class="classList">
    <font-awesome-icon
      v-if="step !== 1"
      :icon="['far','angle-right']"
      size="sm"
      class="has-text-grey"
    />
    <a
      @click.prevent="handleClick"
      :class="[{ 'has-text-grey': step > LastStep || LastStep === maxSteps }, {'is-disabled': LastStep === maxSteps} ]"
      :disabled="LastStep === maxSteps"
    >{{ label }}</a>
  </li>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'Step',
  props: {
    step: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('searchStore', ['maxSteps']),
    ...mapGetters('searchStore', ['LastStep']),
    isDisabled() {
      return this.LastStep < this.step
    },
    classList() {
      return {
        'is-completed is-primary': this.LastStep > this.step,
        'is-active': this.isDisabled,
        'is-current': this.LastStep === this.step
      }
    }
  },
  methods: {
    ...mapActions('searchStore', ['goToStep']),
    handleClick() {
      if (this.step >= this.LastStep || this.LastStep === this.maxSteps) {
        return false
      }
      this.goToStep({
        step: this.step
      })
    }
  }
}
</script>

<style lang="scss">
.is-disabled a,
.is-disabled a:hover {
  color: lighten(#3c3c3b, 40%);
  border-bottom-color: #dbdbdb;
  cursor: default;
}
.is-current {
  font-weight: bold;
}
</style>
