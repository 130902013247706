<template>
  <div :class="[`${$classPrefix}select`, selectBoxSize]">
    <select
      v-model="mSelectedSetting"
      class="is-shadowless"
    >
      <option
        v-for="(setting, index) in availableSettings"
        :key="index"
        :value="setting.SettingId"
        :selected="setting.SettingId === mSelectedSetting"
      >{{ setting.Name }}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'space-configuration-filter',

  components: {},

  props: {
    selectBoxSize: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('availabilityStore', [
      'processedLocations',
      'selectedLocation',
    ]),
    ...mapGetters('searchStore', ['search', 'selectedSetting']),

    mSelectedSetting: {
      get() {
        return this.selectedSetting
      },
      set(value) {
        this.setSelectedSetting(value)
      },
    },

    availableSettings() {
      let settings = []

      let locations = []
      if (this.selectedLocation) {
        locations.push(this.selectedLocation)
      } else {
        locations = this.processedLocations
      }

      if (typeof locations === 'undefined') { return [] }

      for (let i = 0; i < locations.length; i++) {
        let location = locations[i]
        let locSpaces = location.Spaces.filter((s) => s.SpaceAvailable)

        for (let j = 0; j < locSpaces.length; j++) {
          let space = locSpaces[j]

          // Break for current for loop when setting ID is 0
          if (space.SettingId === 0) {
            break
          }

          // Else continue current loop
          let settingIndex = settings.findIndex(
            (s) => s.SettingId === space.SettingId
          )
          if (settingIndex === -1) {
            settings.push({
              SettingId: space.SettingId,
              Name: space.SettingName,
              Count: 1,
            })
          } else {
            settings[settingIndex].Count++
          }
        }
      }

      let options = settings.map((setting) => {
        return {
          SettingId: setting.SettingId,
          Name:
            this.$t(`Settings.Label_Setting${setting.SettingId}`) +
            ' (' +
            this.$tc('General.Text_NrOfSpaces', setting.Count) +
            ')',
        }
      })

      options.unshift({
        SettingId: 0,
        Name: this.$t('App.DrawerFilters.SelectOption_FilterConfiguratie'),
      })

      return options
    },
  },

  created() { },

  methods: {
    ...mapMutations('searchStore', ['setSelectedSetting']),
  },
}
</script>

<style></style>
