/**
 * Import Dependency
 */
import { sortAscending } from '@/helpers/objectHelper'
import profileProvider from '@/providers/profile'

/**
 * Declare Variable
 */
const BILLING_INFO_TEMPLATE = {
    Address: '',
    City: '',
    Company: '',
    CompanyId: 0,
    Country: '',
    Email: '',
    Id: 0,
    PhoneNumber: '',
    PoNumber: '',
    PostalCode: '',
    ProfileId: 0,
    SendTo: '',
    VatNumber: '',
  }


const state = {
  billingInfos: [],
  billingIsvalid: false,
  isReadyLoadingBillingInfos: false,
  billingInfo: { ...BILLING_INFO_TEMPLATE },
  billingInfoTemplate: { ...BILLING_INFO_TEMPLATE },
  billingFormStateIsPreview: true
}

const getters = {
}

const mutations = {
  /**
   * Update billingInfos load state
   */
  setBillingInfosLoadState: (state, newState) => {
    state.isReadyLoadingBillingInfos = newState
  },

  /**
   * Update billingInfos page result
   */
  setBillingInfos: (state, { billingInfos }) => {
    state.billingInfos = billingInfos
  },

  setBillingInfo: (state, newState) => {
    state.billingInfo = newState
  },

  setBillingIsValidState: (state, newState) => {
    state.billingIsvalid = newState
  },

  setBillingFormStateIsPreview: (state, newState) => {
    state.billingFormStateIsPreview = newState
  },
}

const actions = {
  /**
   * Clear store data
   */
  clearStoreData({ commit }) {
    commit('setBillingInfo', { ...BILLING_INFO_TEMPLATE })
    commit('setBillingInfos', { billingInfos: [] })
    commit('setBillingInfosLoadState', false)
    commit('setBillingIsValidState', false)
    commit('setBillingFormStateIsPreview', false)
  },

  /**
   * Clear selected billing from store and cart
  */
  clearSelectedBilling({ commit, dispatch }) {
    commit('cartStore/increaseBusyState', null, { root: true })

    commit('setBillingInfo', { ...BILLING_INFO_TEMPLATE })
    dispatch('cartStore/setInvoiceAddress', { billingInfo: BILLING_INFO_TEMPLATE }, { root: true })

    commit('cartStore/decreaseBusyState', null, { root: true })
  },

  /**
   * Create billing info and load billings
   * @param { Object } billingInfo 
   */
  createBillingInfo: async ({ commit, dispatch }, billingInfo) => {
    let response = await profileProvider.createBillingInfo(billingInfo)

    // Load user billings
    await dispatch('getBillingInfos')
    
    if (response && response.status === 200) { 
      commit('setBillingInfo', response.data)
    }
  },

  /**
   * Update billing info and update cart invoice
   * @param {Object} billingInfo 
   */
  updateBillingInfo: async ({ commit, dispatch }, billingInfo) => {
    commit('cartStore/increaseBusyState', null, { root: true })

    let response = await profileProvider.updateBilling(billingInfo)
    commit('setBillingInfo', billingInfo)

    commit('cartStore/decreaseBusyState', null, { root: true })
    
    dispatch('getBillingInfos')
    return response
  },

  /**
   * Delete selected billing
   */
  deleteBillingInfo: async ({ dispatch }, id) => {
    await profileProvider.deleteBilling(id)
    
    // Get profile billing addresses
    await dispatch('getBillingInfos')

    // Clear cart invoice address data
    dispatch('clearSelectedBilling')
  },

  /**
   * Get profile billing addresses
   */
  getBillingInfos: async ({ commit }) => {
    commit('cartStore/increaseBusyState', null, { root: true })

    let response = await profileProvider.getBilling()
    if (
      response &&
      response.status &&
      response.status === 200 &&
      response.data
    ) {
      commit('setBillingInfos', {
        billingInfos: sortAscending(response.data, 'Company'),
        MorePages: response.data.MorePages,
      })
    }
    commit('setBillingInfosLoadState', true)

    commit('cartStore/decreaseBusyState', null, { root: true })
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
