<template>
  <div>
    <div v-if="!registerSucceed" ref="registerForm" class="registration-from">
      <div
        :class="`${$classPrefix}title`"
        class="is-4"
        v-html="$t('Components.ForgotLoginRegister.Register.Main_Title')"
      ></div>

      <div class="has-margin-bottom" :class="`${$classPrefix}content`" v-html="explanationText"></div>

      <Message type="is-danger" v-if="formError">
        <ul class="is-marginless">
          <li
            v-if="!emailAlreadyExists"
            v-html="
              $t(
                'Components.ForgotLoginRegister.Register.Message_NotAllFilledIn'
              )
            "
          ></li>
          <li
            v-if="emailAlreadyExists"
            v-html="
              $t('Components.ForgotLoginRegister.Register.Message_EmailExists')
            "
          ></li>
        </ul>
      </Message>

      <Form
        ref="form"
        :onSubmitCallback="handleSubmit"
        @onStatusChange="onStatusChange"
      >
        <RequiredField
          name="firstName"
          :label="$t('Components.ForgotLoginRegister.Register.Label_FirstName')"
          required
        />

        <RequiredField
          name="lastName"
          :label="$t('Components.ForgotLoginRegister.Register.Label_LastName')"
          required
        />

        <PhoneNumberField
          :required="isPhoneNumberRequired"
          :label="$t('Components.ForgotLoginRegister.Register.Label_Phone')"
        />

        <EmailField
          name="email"
          :label="
            $t('Components.ForgotLoginRegister.General.Label_EmailAddress')
          "
          :validationRules="emailValidation"
          :errorMessages="emailErrorMessages"
          :inputSizeClass="emailAlreadyExists ? 'is-danger' : ''"
          required
        />

        <PasswordField
          name="password"
          :validateOnInput="true"
          :showAllOpenErrors="true"
          :validationRules="passwordValidationRules"
          :errorMessages="passwordErrorMessages"
        />

        <BaseFieldTemplate v-if="isWorkspaceBooking">
          <template v-slot:fields>
            <TagsField />
          </template>
        </BaseFieldTemplate>
        <BaseFieldTemplate class="has-margin-bottom">
          <template v-slot:fields>
            <PrivacyTerms
              name="privacyTerms"
              :showOnPage="false"
              :required="true"
              @termsData="processTerms"
            />
          </template>
        </BaseFieldTemplate>
      </Form>
      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`"></div>
        <div :class="`${$classPrefix}level-right`">
          <div :class="`${$classPrefix}level-item`">
            <a
              :class="[`${$classPrefix}button`, { 'is-loading': isProcessing }]"
              class="is-success"
              @click="createProfile"
              v-html="
                $t('Components.ForgotLoginRegister.Register.Button_Register')
              "
            >
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <a :class="`has-icon`" @click="handleAuthNavigate({ page: 'login' })">
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fal', 'chevron-left']" />
          </span>
          <span
            v-html="
              $t('Components.ForgotLoginRegister.General.Link_BackToLogin')
            "
          ></span>
        </a>
      </div>
    </div>
    <AuthPinValidationForm
      v-if="registerSucceed"
      key="AuthPinValidationForm"
      :verificationKey="verificationKey"
      :email="registerEmailAddress"
    />
  </div>
</template>

<script>
import Form from "@/components/UI/Form/BaseForm";
import RequiredField from "@/components/UI/Form/RequiredField";
import EmailField from "@/components/UI/Form/EmailField";
import PasswordField from "@/components/UI/Form/PasswordField";
import PhoneNumberField from "@/components/UI/Form/PhoneNumberField";
import BaseFieldTemplate from "@/components/UI/Form/BaseFieldTemplate";
import TagsField from "@/components/UI/Form/TagsField";
import Message from "@/components/UI/Message";
import PrivacyTerms from "../Terms/PrivacyTerms";
import { required, email, minLength } from "vuelidate/lib/validators";
import AuthPinValidationForm from "./AuthPinValidationForm";
import encryptString from '@/mixins/encryptString'
import profileProvider from "@/providers/profile";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "Registration",
  components: {
    BaseFieldTemplate,
    Form,
    RequiredField,
    EmailField,
    PasswordField,
    PhoneNumberField,
    TagsField,
    Message,
    PrivacyTerms,
    AuthPinValidationForm,
  },

  mixins: [encryptString],

  data() {
    return {
      isCheckin: false,
      terms: null,
      formError: false,
      isProcessing: false,
      emailAlreadyExists: false,
      profileCheckTimeout: null,
      emailErrorMessages: {
        required: this.$t("App.Text_InputRequired"),
        email: this.$t("App.Text_NotValidEmail"),
        isUnique: "",
      },
      registerSucceed: false,
      registerEmailAddress: "",
      verificationKey: "",
      passwordErrorMessages: {
        required: this.$t("General.Form.InputErrors.Required"),
        minLength: this.$t("General.Form.InputErrors.minLength", {
          minLength: 8,
        }),
        uppercaseCharacter: this.$t(
          "General.Form.InputErrors.uppercaseCharacter"
        ),
        numberCharacter: this.$t("General.Form.InputErrors.numberCharacter"),
      },
    };
  },
  computed: {
    ...mapGetters("searchStore", ["ChannelId"]),
    /**
     * Whether the booking is for a single workspace or room
     */
    isWorkspaceBooking() {
      return false; // TODO: get info from Cart object
    },
    /**
     * The phone field is only required for meeting room bookings
     */
    isPhoneNumberRequired() {
      return !this.isWorkspaceBooking;
    },

    passwordValidationRules() {
      let rules = {};

      rules["required"] = required();
      rules["minLength"] = minLength(8);
      rules["uppercaseCharacter"] = (val) => {
        return /(?=.*[A-Z])/.test(val);
      };
      rules["numberCharacter"] = (val) => {
        return /(?=.*\d)/.test(val);
      };

      return rules;
    },

    explanationText() {
      let text = "";

      switch (this.ChannelId) {
        case 1:
          break;
        case 1031:
          text = this.$t("Components.Register.Channel_1031.Text_Explanation");

          break;
        default:
          break;
      }

      return text;
    },
  },

  created() {
    // Define email validation rules
    this.emailValidation = {
      required,
      email,
      // async isUnique(value) {
      //   let self = this

      //   if (value.$dirty === '' || !this.$v.fieldValue.email || value === '') {
      //     return true
      //   }

      //   let isUnique = await new Promise((resolve) => {
      //     if (self.profileCheckTimeout) {
      //       clearTimeout(self.profileCheckTimeout)
      //     }

      //     self.profileCheckTimeout = setTimeout(() => {
      //       profileProvider.checkEmail({ email: value })
      //         .then((response) => {
      //           if (response.status === 200) {
      //             if (response.data.length > 0) {
      //               this.error = this.$t('App.Text_EmailExists')
      //               resolve(false)
      //             } else {
      //               // this.error = this.$v.fieldValue.$error
      //               this.error = false
      //               resolve(true)
      //             }
      //           }
      //         })
      //         .catch(() => {
      //           resolve(true)
      //         })
      //         .finally(() => {
      //         })
      //     }, 1000)
      //   })

      //   return isUnique
      // }
    };
  },

  methods: {
    ...mapMutations("authStore", ["setProfile", "goToAuthPage"]),

    // Do form submittion
    createProfile() {
      if (this.$refs.form) {
        this.$refs.form.submitForm();
      }
    },

    processTerms(data) {
      this.terms = data;
    },

    // Do action for current form status
    onStatusChange({ status, form }) {
      switch (status) {
        case "READY":
          this.isProcessing = false;
          break;
        case "VALIDATING":
          this.isProcessing = true;
          break;
        case "VALID":
          this.formError = !form.isValid();
          break;
        case "INVALID":
          this.formError = form.isValid();
          break;
        case "COMPLETED":
          this.isProcessing = false;
          break;
      }
    },

    // Page navigation
    handleAuthNavigate({ page }) {
      return this.goToAuthPage({ page });
    },

    // Handle form submittion
    handleSubmit({ formData, component }) {
      let self = this;
      return new Promise((resolve, reject) => {
        let encryptedPassword = self.encryptPassword(formData.password)
        profileProvider.createProfile({
            FirstName: formData.firstName,
            LastName: formData.lastName,
            Email: formData.email,
            Phone: formData.phone,
            Password: encryptedPassword,
            Tags: formData.tags || "",
            TermsId: this.terms.Id || 0,
            HasAgreedToTerms: !!formData.privacyTerms,
            ChannelId: this.ChannelId,
            LocalePrefix: this.$i18n.locale,
          })
          .then((response) => {
            if (response.data.StatusCode === 200) {
              // self.setProfile({ profile: response.data.Profile })
              self.registerEmailAddress = formData.email;
              self.registerSucceed = true;
              self.emailAlreadyExists = false;
              self.verificationKey = response.data.VerificationKey;
              self.scrollToTop();
              resolve({ response: "OK", component });
            } else {
              self.scrollToTop();
              reject({ response: "FAIL", component });
            }
          })
          .catch((e) => {
            if (
              e.response &&
              (e.response.status === 403 || e.response.status === 409)
            ) {
              this.emailAlreadyExists = true;
            }
            self.scrollToTop();
            this.formError = true;
            reject({ response: "FAIL", component });
          });
      });
    },

    scrollToTop() {
      this.$scrollTo({
        el: null,
        container: "#wizard-scroll-container",
        duration: 500,
      });
    },
  },
};
</script>
