<template>
  <button
    @click="onClickCustomFunc ? onClickCustomFunc() : goOneStepForward()"
    :disabled="DisableNextButton"
  >
    <slot>
      <span v-html="$t('General.Text_NextStep')"></span>
      <span class="icon is-small">
        <font-awesome-icon :icon="['fal', 'chevron-right']" />
      </span>
    </slot>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NextButton',

  props: {
    onClickCustomFunc: {
      type: Function,
      default: null
    }
  },

  computed: {
    ...mapGetters('searchStore', ['LastStep', 'DisableNextButton']),
  },
  methods: {
    ...mapActions('searchStore', ['goToStep']),

    goOneStepForward() {
      this.goToStep({ step: this.LastStep + 1 })
    },
  },
}
</script>
