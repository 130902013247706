<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <NoAuthLoginForm
      v-if="!verificationKey"
      key="NoAuthLoginForm"
      @callbackAction="authLoginUpdate"
    />
    <AuthPinValidationForm
      v-else
      key="AuthPinValidationForm"
      :verificationKey="verificationKey"
      :email="authEmail"
      @callbackAction="verifiedUpdate"
    />
  </transition>
</template>

<script>
import NoAuthLoginForm from './NoAuthLoginForm'
import AuthPinValidationForm from './AuthPinValidationForm'
import { mapMutations } from 'vuex'
export default {
  name: 'NoAuthLoginFlow',

  components: {
    NoAuthLoginForm,
    AuthPinValidationForm,
  },

  data() {
    return {
      verificationKey: '',
      authEmail: '',
      userAgreedToTerms: false
    }
  },

  created() {
  },

  methods: {
    ...mapMutations('cartStore', []),

    authLoginUpdate(data) {
      this.verificationKey = data.authVerificationKey
      this.authEmail = data.authEmail
      this.userAgreedToTerms = data.agreeToTerms
    },

    verifiedUpdate(data) {
      if (data.verifiedSuccess && this.userAgreedToTerms) {
        // Update cart terms state
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>