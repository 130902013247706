<template>
  <div class="noAvailability">
    <Message>
      <div
        :class="`${$classPrefix}content`"
        v-html="
          $t(
            `Components.NoAvailability.Meetingtype_${MeetingtypeId}.Message_NoResults`
          )
        "
      ></div>
    </Message>
    <TenderForm v-if="MeetingtypeId === 1" :preSelectedLocation="preSelectedLocation" />
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import Message from '@/components/UI/Message'
import TenderForm from '@/components/Tender/TenderForm.vue'
import { mapGetters } from 'vuex'
import { mapState } from 'vuex/dist/vuex.common.js';

export default {
  name: 'no-availability',

  components: {
    Message,
    TenderForm,
  },

  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState('availabilityStore', ['locations']),
    ...mapGetters('searchStore', ['MeetingtypeId']),
    ...mapGetters('widgetStore', ['widgetLocationId']),
    ...mapGetters('availabilityStore', ['selectedLocation']),

    preSelectedLocation() {
      let _selectedLocation = false

      // Get selected data
      if(this.selectedLocation) {
        _selectedLocation = this.selectedLocation
      }

      // Get location data based of widgetLocationId when selectedLocation is null or false
      if(!_selectedLocation && this.widgetLocationId && this.locations.length !== 0) {
        _selectedLocation = this.locations.find(
          (location) => location.LocationId === this.widgetLocationId
        ) || false
      }
      
      // Map data to tender selected location object and return data
      if(_selectedLocation) {
        return {
          Id: _selectedLocation.LocationId,
          Address: _selectedLocation.Address,
          City: _selectedLocation.City,
          Name: _selectedLocation.Name,
          Image: _selectedLocation.Image,
          Phone: _selectedLocation.Phone,
          Email: _selectedLocation.Email,
        }
      }

      return false 
    }
  },

  created() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.noAvailability {
  height: 100%;
}
</style>
