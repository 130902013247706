<template>
  <UiComponentDrawer
    v-if="showDrawer"
    :drawerTitle="spaceName"
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <div class="wrapper-images">
        <ImageCarousel
          v-if="space.Images"
          :images="space.Images"
          :locationId="space.LocationId"
        />
      </div>
      <div
        class="has-margin-bottom-x2"
        :class="`${$classPrefix}content`"
        v-html="description"
      ></div>
      
      <template v-if="currentSearch.MeetingtypeId === 1">
      <div
        class="is-size-5 has-text-weight-bold"
        v-text="$t('App.DrawerLocationSpaceDetail.Title_Configuration')"
      ></div>

      <div
        :class="`${$classPrefix}columns`"
        class="wrapper-settings is-mobile is-multiline"
      >
        <!-- <div :class="`${$classPrefix}column is-full`">
          <div
            class="is-size-5 has-text-weight-bold"
            v-text="$t('App.DrawerLocationSpaceDetail.Title_Configuration')"
          ></div>
        </div> -->
        <div
          :class="`${$classPrefix}column`"
          class="is-one-third has-text-centered"
          v-for="(setting, index) in space.Settings"
          :key="index"
        >
          <SvgSetting
            :key="'rsId'+setting.SettingId"
            aria-hidden="true"
            :settingId="setting.SettingId"
            size="is-large"
          />
          <div class="has-text-weight-bold">{{ $t(`Settings.Label_Setting${setting.SettingId}`) }}</div>
          {{ minMaxSeats(setting) }} {{ $t('App.DrawerLocationSpaceDetail.Text_Persons').toLowerCase() }}
        </div>
      </div>
      </template>  
    </template>
  </UiComponentDrawer>
</template>

<script>
import ImageCarousel from '@/components/Location/ImageCarousel'
import UiComponentDrawer from '@/components/UI/Drawer'
import { getSpace } from '../../providers/space'
import SvgSetting from '@/components/UI/Settings/SvgSetting'
import { mapState } from 'vuex'

export default {
  name: 'drawer-location-space-detail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },

    // props for content
    selectedSpace: {
      type: Object,
      required: true,
    },
  },

  components: {
    ImageCarousel,
    UiComponentDrawer,
    SvgSetting,
  },

  data() {
    return {
      currentLanguageId: this.$i18n.locale === 'nl' ? 52 : 65,
      space: this.selectedSpace,
    }
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    spaceName() {
      return this.space.Descriptions
        ? this.space.Descriptions.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Name
        : this.selectedSpace.SpaceName
    },

    description() {
      return this.space.Descriptions
        ? this.space.Descriptions.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Description
        : ''
    },
  },

  created() {
    this.getSpaceData()
  },

  methods: {
    getSpaceData() {
      let self = this
      getSpace(this.space.SpaceId).then((response) => {
        self.space = response.data
      })
    },

    /**
     * Format the seating information based on the information that is available
     */
    minMaxSeats(setting) {
      return `${setting.MinSeats} - ${setting.MaxSeats}`
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-images {
  margin-top: -$gap;
  margin-right: -$gap;
  margin-bottom: $gap;
  margin-left: -$gap;
}
</style>
