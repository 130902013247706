/**
 * Import Dependency
 */

/**
 * Import API
 */
import { getActiveLocationManagers } from '@/providers/location'

/**
 * Declare Variable
 */
const state = {
  managersByLocation: [],
  managerMessage: '',
}

const getters = {
  managerMessage: (state) => state.managerMessage,

  /**
   * Get the active location manager for the selected location
   * If no location is selected, or no manager details are available (yet) the return value is null
   */
  activeManager: (state, getters, rootState, rootGetters) => {
    let LocationId = rootGetters['availabilityStore/selectedLocationId']
    if (!LocationId) {
      return null
    }

    let managerOfLocation = state.managersByLocation.find(
      (manager) => manager.LocationId === LocationId
    )
    return managerOfLocation ? managerOfLocation : null
  },
}
const actions = {
  updateManagerMessage: async ({ commit }, message) => {
    commit('setManagerMessage', message)
  },

  getActiveLocationManager: async ({ commit, getters, rootGetters }) => {
    let LocationId = rootGetters['availabilityStore/selectedLocationId']

    // No point in making a call without input
    if (!LocationId) {
      return // Maybe throw an error?
    }

    // No need to asks the API for data we already have
    if (getters.activeManager) {
      return
    }

    let response = await getActiveLocationManagers({
      LocationId,
    })

    if (response.status === 200 && response.data) {
      commit('addLocationManager', {
        manager: response.data,
      })

      let message =
        'Hi, I am ' +
        response.data.Name +
        '. Let me know if I can help you with anything!'

      commit('setManagerMessage', message)
    } else {
      // TODO: Error handling
    }
  },
}
const mutations = {
  /**
   * Store the location manager details (which includes the LocationId)
   */
  addLocationManager: (state, { manager }) => {
    state.managersByLocation.push(manager)
  },

  setManagerMessage: (state, data) => {
    state.managerMessage = data
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
