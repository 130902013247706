<template>
  <div>
    <div
      v-if="filteredLocations.length > 1"
      class="SearchControls"
      key="sorting"
    >
      <div
        v-text="title"
        class="is-size-4 has-text-weight-bold"
      ></div>
      <div class="has-margin-top-half is-hidden-mobile">
        <div class="is-inline-flex SearchControls__sort-wrapper has-margin-right-x2">
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fas', 'sort']" />
          </span>
          <a
            :class="[
              `${$classPrefix}button is-small`,
              { 'is-success': getSortBy === 'location' },
            ]"
            @click="setSortBy({ sortBy: 'location' })"
            v-text="$t('App.SearchControls.Link_Nearness')"
          ></a>
          <a
            :class="[
              `${$classPrefix}button is-small`,
              { 'is-success': getSortBy === 'price' },
            ]"
            @click="setSortBy({ sortBy: 'price' })"
            v-text="$t('App.SearchControls.Link_LowestPrice')"
          ></a>
        </div>
        <FilterOverview
          v-if="currentSearch.MeetingtypeId === 1"
          class="is-inline-flex SearchControls__filter-wrapper"
          selectBoxSize="is-small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import FilterOverview from '@/components/SearchResults/FilterOverview'

export default {
  name: 'SearchControls',

  components: {
    FilterOverview,
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapGetters('availabilityStore', ['getSortBy', 'selectedLocation']),
    ...mapGetters('availabilityStore', ['processedLocations']),
    ...mapGetters('searchStore', [
      'search',
      'selectedSetting',
      'selectedEventType',
    ]),

    filteredLocations() {
      let locations = this.processedLocations

      if (this.selectedEventType.length > 0) {
        locations = locations.filter((l) =>
          l.EventTypes.includes(this.selectedEventType)
        )
      }

      if (this.selectedSetting > 0) {
        let l = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounter = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.SettingId === this.selectedSetting) {
              spaceCounter++
            }
          }

          if (spaceCounter > 0) {
            l.push(location)
          }
        }

        locations = l
      }

      return locations
    },

    locationName() {
      return this.selectedLocation.Name
    },

    title() {
      return this.$tc(
        'App.SearchControls.Text_LocationsFound',
        this.filteredLocations.length
      )
    },
  },
  methods: {
    ...mapMutations('availabilityStore', ['setSortBy']),
  },
}
</script>

<style lang="scss"></style>
