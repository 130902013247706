var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-invoiceAddressForm"},[_c('BaseForm',{ref:"form"},[(_vm.cart.MeetingTypeId !== 2)?_c('div',{class:`${_vm.$classPrefix}columns is-gapless is-multiline is-mobile`},[_c('div',{class:`${_vm.$classPrefix}column is-half`},[(_vm.isCompanyBilling)?_c('RequiredField',{attrs:{"name":"CompanyName","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_CompanyName'
            ),"required":_vm.cart.MeetingTypeId !== 2,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode || _vm.billingInfoData.CompanyId > 0,"readonly":_vm.isPreviewMode || _vm.billingInfoData.CompanyId > 0},model:{value:(_vm.billingInfoData.Company),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "Company", $$v)},expression:"billingInfoData.Company"}}):_vm._e()],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('BaseBulmaField',{key:'po' + _vm.isBusy,attrs:{"name":"PoNumber","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_PONumber'
            ),"required":false,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode,"readonly":_vm.isPreviewMode},model:{value:(_vm.billingInfoData.PoNumber),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "PoNumber", $$v)},expression:"billingInfoData.PoNumber"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('RequiredField',{attrs:{"name":"Address","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_Address'
            ),"required":_vm.cart.MeetingTypeId !== 2,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode,"readonly":_vm.isPreviewMode},model:{value:(_vm.billingInfoData.Address),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "Address", $$v)},expression:"billingInfoData.Address"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('RequiredField',{attrs:{"name":"PostalCode","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_PostalCode'
            ),"required":_vm.cart.MeetingTypeId !== 2,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode,"readonly":_vm.isPreviewMode},model:{value:(_vm.billingInfoData.PostalCode),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "PostalCode", $$v)},expression:"billingInfoData.PostalCode"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('RequiredField',{attrs:{"name":"City","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_City'
            ),"required":_vm.cart.MeetingTypeId !== 2,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode,"readonly":_vm.isPreviewMode},model:{value:(_vm.billingInfoData.City),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "City", $$v)},expression:"billingInfoData.City"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('RequiredField',{attrs:{"name":"Country","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_Country'
            ),"required":_vm.cart.MeetingTypeId !== 2,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode,"readonly":_vm.isPreviewMode},model:{value:(_vm.billingInfoData.Country),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "Country", $$v)},expression:"billingInfoData.Country"}})],1)]):_vm._e(),_c('div',{class:`${_vm.$classPrefix}columns is-gapless is-multiline is-mobile`},[_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('RequiredField',{attrs:{"required":_vm.isPaidReservation,"name":"SendTo","label":_vm.labelSendTo,"disabled":_vm.isBusy && _vm.cart.MeetingTypeId !== 2,"isStatic":_vm.isPreviewMode || _vm.cart.MeetingTypeId === 2,"readonly":_vm.isPreviewMode || _vm.cart.MeetingTypeId === 2},model:{value:(_vm.billingInfoData.SendTo),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "SendTo", $$v)},expression:"billingInfoData.SendTo"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('EmailField',{attrs:{"required":_vm.isPaidReservation,"name":"Email","label":_vm.labelInvoiceEmail,"disabled":_vm.isBusy && _vm.cart.MeetingTypeId !== 2,"isStatic":_vm.isPreviewMode || _vm.cart.MeetingTypeId === 2,"readonly":_vm.isPreviewMode || _vm.cart.MeetingTypeId === 2},model:{value:(_vm.billingInfoData.Email),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "Email", $$v)},expression:"billingInfoData.Email"}})],1),_c('div',{class:`${_vm.$classPrefix}column is-half-mobile is-half`},[_c('PhoneNumberField',{attrs:{"name":"PhoneNumber","label":_vm.$t(
              'Components.InvoiceDetails.InvoiceAddressForm.Label_PhoneNumber'
            ),"required":_vm.isPaidReservation,"disabled":_vm.isBusy,"isStatic":_vm.isPreviewMode && _vm.cart.MeetingTypeId !== 2,"readonly":_vm.isPreviewMode && _vm.cart.MeetingTypeId !== 2},model:{value:(_vm.billingInfoData.PhoneNumber),callback:function ($$v) {_vm.$set(_vm.billingInfoData, "PhoneNumber", $$v)},expression:"billingInfoData.PhoneNumber"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }