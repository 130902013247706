<template>
  <div class="LocationCards">
    <DrawerLocationDetail
      v-if="drawerType === 'drawerLocationDetail'"
      :showDrawer="drawerType === 'drawerLocationDetail'"
      :onClickClose="hideDrawer"
      :meetingtypeId="search.MeetingtypeId"
      :preDefinedViewType="preDefinedViewType"
      :predefinedLocation="selectedLocation"
    />

    <div :class="`${$classPrefix}columns is-multiline`">
      <template v-for="location in filteredLocations">
        <div
          v-if="!location.Warnings.length"
          :key="location.LocationId"
          :class="[`${$classPrefix}column is-full`, classList]"
        >
          <LocationCard
            class="container-locationCard"
            :location="location"
            :ref="`ref` + location.LocationId"
            @showDrawerType="setShowDrawerType"
            :showPricing="widgetState.ShowPrices"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import DrawerLocationDetail from '@/components/Location/DrawerLocationDetail'
import LocationCard from './LocationCard'

export default {
  name: 'LocationCards',

  components: {
    // NoAvailability,
    LocationCard,
    DrawerLocationDetail,
  },

  data() {
    return {
      showSideDrawer: false,
      selectedLocation: null,
      drawerType: '',
      preDefinedViewType: 0,
    }
  },

  computed: {
    ...mapGetters('availabilityStore', ['processedLocations', 'selectedLocationId', 'userLnglat', 'getSortBy']),
    ...mapGetters('searchStore', [
      'search',
      'selectedSetting',
      'selectedEventType',
    ]),

    ...mapState('widgetStore', ['widgetState']),


    isSelected() {
      let result = false
      let selected = 'ref' + this.selectedLocationId
      let ref = 'ref' + location.locationId
      if (ref === selected) {
        result = true
      }
      return result
    },

    classList() {
      return [{
        'LocationCard--selected': this.isSelected,
      }]
    },

    filteredLocations() {
      let locations = this.processedLocations

      if (this.userLnglat !== null && this.getSortBy === 'location') {

        let myLong = this.userLnglat.lng;
        let myLat = this.userLnglat.lat;

        for (let index = 0; index < locations.length; index++) {
          var R = 6371; // Radius of the earth in km
          var dLat = this.deg2rad(locations[index].Latitude - myLat);  // deg2rad below in methods
          var dLon = this.deg2rad(locations[index].Longitude - myLong);
          var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(myLat)) * Math.cos(this.deg2rad(locations[index].Latitude)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          var d = R * c; // Distance in km
          locations[index].distance = d;
        }
        return locations.sort(
          (locationA, locationB) =>
            locationA.distance - locationB.distance
        )
      }

      if (this.selectedEventType > 0) {
        let locsA = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounterA = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.EventTypes.includes(this.selectedEventType)) {
              spaceCounterA++
            }
          }

          if (spaceCounterA > 0) {
            locsA.push(location)
          }
        }

        locations = locsA
      }

      if (this.selectedSetting > 0) {
        let locsB = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounterB = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.SettingId === this.selectedSetting) {
              spaceCounterB++
            }
          }

          if (spaceCounterB > 0) {
            locsB.push(location)
          }
        }

        locations = locsB
      }
      return locations
    },
  },

  created() {
    // this.$EventBus.$on('location.map.hover', this.scrollTo)
    this.$EventBus.$on('location.map.userLnglat', this.assignUserLnglat)
  },

  beforeDestroy() {
    // this.$EventBus.$off('location.map.hover', this.scrollTo)
    this.$EventBus.$off('location.map.userLnglat', this.assignUserLnglat)
  },

  methods: {
    ...mapMutations('availabilityStore', ['setUserLnglat']),

    setShowDrawerType(data) {
      this.selectedLocation = data.location
      this.drawerType = data.drawerType
      this.preDefinedViewType = data.preDefinedViewType
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },

    assignUserLnglat(userLnglat) {
      this.setUserLnglat(userLnglat)
    },

    scrollTo({ LocationId }) {
      this.$nextTick(() => {
        let reference = 'ref' + LocationId
        if (this.$refs[reference]) {
          this.$refs[reference][0].$el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 1)
    },

    hideDrawer() {
      this.drawerType = ''
        ; (this.selectedLocation = null), (this.preDefinedViewType = 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.container-locationCard {
  height: 100%;
}

.LocationCard--selected {
  background-color: #ebf9ff;
}
</style>
