import axiosInstance from '@/helpers/axios'

import axios from 'axios'

const CancelToken = axios.CancelToken
let source = null

export const getCartOptions = ({ CartKey }) => {
  return axiosInstance.get(`cart/${CartKey}/option`)
}

export const saveCartOption = ({
  CartKey,
  OptionId,
  Amount,
  CurrencyId,
  PricePerItem,
  PriceTotal,
  Crc,
  TaxId,
}) => {
  if (source) {
    source.cancel()
  }

  source = CancelToken.source()

  return axiosInstance
    .post(
      `cart/${CartKey}/option`,
      {
        OptionId,
        Amount,
        CurrencyId,
        PricePerItem,
        PriceTotal,
        Crc,
        TaxId,
      },
      {
        cancelToken: source.token,
      }
    )
    .catch((err) => {
      // TODO: Handle status

      if (axios.isCancel(err)) {
        // TODO: Remove console.
        //console.log("request cancelled")
      }
    })
    .finally(() => {
      source = null
    })
}

export const deleteCartOption = ({ CartKey, OptionId }) => {
  return axiosInstance.delete(`cart/${CartKey}/option/${OptionId}`)
}
