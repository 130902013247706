<template>
  <div :class="`${$classPrefix}search`">
    <input
      type="text"
      readonly="readonly"
      aria-readonly="true"
      autocomplete="off"
      aria-autocomplete="both"
      :placeholder="placeholder"
      :ref="inputId"
      :class="`${$classPrefix}input`"
      :id="inputId"
      :value="searchPresentation"
      @click="showHidePopup()"
      @focus="removeFocusFromInput"
    />
    <SearchFormPopup
      v-if="popupShown"
      :ref="`${inputId}popupWrapper`"
      :target="inputId"
      :placeholder="placeholder"
      @hide="hidePopup()"
      v-click-outside.bubble="{ exclude: [inputId], handler: 'hidePopup'}"
    />
  </div>
</template>

<script>
import SearchFormPopup from '@/components/UI/SearchFormPopup'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'searchInput',

  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },

  components: {
    SearchFormPopup
  },

  data() {
    return {
      inputId: `sInput${new Date().getTime()}`,
      popupShown: false,
      currentSearchTerm: '',
      currentCountryCode: ''
    }
  },

  computed: {
    ...mapState('searchStore', ['search']),
    ...mapState('countryStore', ['countries']),

    searchPresentation() {
      let output = this.currentSearchTerm
      let country = this.countries.find(c => c.CountryCode === this.currentCountryCode)
      if (typeof (country) !== 'undefined') {
        output += this.currentSearchTerm ? ` (${country.Name})` : country.Name
      }

      return output
    },
  },

  watch: {
    'search.SearchTerm': {
      immediate: true,
      handler: function(searchTerm) {
        this.currentSearchTerm = searchTerm
      }
    },

    'search.CountryCode': {
      immediate: true,
      handler: function(countryCode) {
        let country = this.countries.find(c => c.CountryCode === countryCode)
        this.currentCountryCode = typeof country !== 'undefined' ? country.CountryCode : ''
        this.setCountryCode(typeof country !== 'undefined' ? country.CountryCode : '')
      }
    }
  },

  created() {
    // let country = this.countries.find(c => c.CountryCode === this.search.CountryCode)
    //     if (typeof (country) !== 'undefined') {
    //       this.setCountryCode(country.CountryCode ?? '')
    //       this.currentCountryCode = country.CountryCode
    //     }
  },

  methods: {
    ...mapMutations('searchStore', ['setCountryCode']),

    /**
   * Remove focus from input field
   */
    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    /**
     * Show or hide popup
     */
    showHidePopup() {
      this.popupShown = !this.popupShown
    },

    /**
     * Show or hide popup
     */
    hidePopup() {
      this.popupShown = false
      if (this.search.SearchTerm !== this.currentSearchTerm || this.search.CountryCode !== this.currentCountryCode) {
        this.$emit('action', null)
        this.currentSearchTerm = this.search.SearchTerm
        this.currentCountryCode = this.search.CountryCode
      }
    },
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        vnode.context.handleOutsideClick = (e) => {
          e.stopPropagation()

          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value

          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false

          if (typeof (exclude) !== 'undefined') {
            exclude.forEach(refName => {
              // We only run this code if we haven't detected
              // any excluded element yet
              if (!clickedOnExcludedEl) {
                // Get the element using the reference name
                let excludedEl = vnode.context.$refs[refName]
                // See if this excluded element
                // is the same element the user just clicked on
                if (typeof (excludedEl) !== 'undefined') {
                  clickedOnExcludedEl = excludedEl.contains(e.target)
                }
              }
            })

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
              // If the clicked element is outside the dialog
              // and not the button, then call the outside-click handler
              // from the same component this directive is used in
              vnode.context[handler]()
            }
          }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', vnode.context.handleOutsideClick)
        // document.addEventListener('touchstart', vnode.context.handleOutsideClick)
      },

      unbind(el, binding, vnode) {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', vnode.context.handleOutsideClick)
        // document.removeEventListener('touchstart', vnode.context.handleOutsideClick)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>