<template>
  <div :class="`${$classPrefix}field`">
    <div
      v-if="!hideLabel"
      :class="`${$classPrefix}field-label`"
      class="is-normal"
    >
      <label
        ref="label"
        :for="labelId"
        :class="`${$classPrefix}label`"
      >
        {{ label }}<span
          v-if="isRequired"
          class="has-text-danger"
        >*</span>
      </label>
    </div>
    <div :class="`${$classPrefix}field-body`">
      <div :class="`${$classPrefix}field`">
        <div
          :class="[`${$classPrefix}control`, { 'has-icons-left': inputIcon, 'has-no-error': !error }]"
          class="has-icons-right"
        >
          <input
            :value="fieldValue"
            :type="type"
            :class="[`${$classPrefix}input`, inputSizeClass, { 'is-danger': error }, { 'is-static' : isStatic}]"
            :placeholder="placeolder"
            :disabled="disabledStatus"
            :readonly="readonly"
            :id="inputId"
            :maxlength="maxLength"
            autocomplete="none"
            aria-autocomplete="both"
            @blur="onBlur"
            @keyup="onChange"
            @focus="onFocus"
            @input="onInput"
          />
        </div>
        <transition-expand>
          <!-- <p v-if="error">
            <span :class="`${$classPrefix}help is-danger`">
              {{ error }}
            </span>
          </p> -->
          <span
            v-if="!Array.isArray(error)"
            :class="`${$classPrefix}help is-danger`"
            v-html="error"
          ></span>
          <div
            v-else
            :class="`${$classPrefix}help is-danger`"
          >
            <div
              v-for="(message, index) in error"
              :key="`e${index}`"
              v-html="message"
            ></div>
          </div>
        </transition-expand>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionExpand from '../../TransitionExpand'
import BaseFormInputField from './BaseFormInputField'

export default {
  name: 'BaseBulmaField',
  extends: BaseFormInputField,

  components: {
    TransitionExpand
  },

  props: {
    maxLength: {
      type: Number,
      default: 524288
    },

    labelAllowTextWrap: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      type: 'text',
      inputIcon: null
    }
  }
}
</script>