<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      :key="'modalContent' + Number(showModal)"
      :class="[`${$classPrefix}modal`, { 'is-active': showModal }]"
    >
      <div :class="`${$classPrefix}modal-background`"></div>
      <div
        :ref="'modal' + modalRefNamePrefix"
        :class="[
          `${$classPrefix}modal-card`,
          'modal-card-' + modalSize,
          unsetFillModeCssClass,
          animateClass
        ]"
      >
        <div :ref="'modalHeader' + modalRefNamePrefix">
          <template v-if="hideStandardHeader">
            <slot name="customHeader"></slot>
          </template>
          <div
            v-else
            :class="`${$classPrefix}modal-card-head`"
          >
            <p
              v-if="!hideHeader"
              :class="`${$classPrefix}modal-card-title`"
            >
              {{ modalTitle }}
            </p>
            <button
              @click="closeModal()"
              class="delete"
              aria-label="close"
            ></button>
          </div>
        </div>

        <div
          :ref="'modalFooter' + modalRefNamePrefix"
          :class="`${$classPrefix}modal-card-body`"
          id="wizard-scoll-container"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <slot name="content"></slot>
          </transition>
        </div>
        <div :class="`${$classPrefix}modal-cart-foot`">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from '@/eventbus/EventBus'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'WizardModalWrapper',

  props: {
    hideStandardHeader: {
      type: Boolean,
      default: true
    },

    modalTitle: {
      type: String,
      default: ''
    },

    /**
     * Sizes are:
     * - dynamic (size will be determent by content)
     * - small (width is set to auto)
     * - normal (is bulma default)
     * - large (1024)
     * - extra-large (1200)
     */
    modalSize: {
      type: String,
      default: 'normal'
    },

    onCloseFunc: {
      type: Function,
      default: function() { },
      required: false
    },

    showModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalRefNamePrefix: new Date().getTime(),
      modalAnimation: this.showModal,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
    }
  },

  computed: {
    ...mapState(['showMobileFooterBar']),
    ...mapState('cartStore', ['cart']),
    animateClass() {
      let cls = 'animated fade'
      cls = cls + (this.modalAnimation ? 'In' : 'Out')
      cls = cls + ' faster'
      return cls
    }
  },

  watch: {
    cart: function(val) {
      if (val) {
        let t = setTimeout(() => {
          this.getModalPropeties()
          clearTimeout(t)
        }, 0)
      }
    }
  },

  mounted() {
    EventBus.$on('windowResizeListener', this.getModalPropeties)
    this.getModalPropeties()
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }
    EventBus.$off('windowResizeListener', this.getModalPropeties)
  },

  methods: {
    ...mapMutations(['setShowMobileFooterBarState']),
    closeModal() {
      this.unsetFillModeCssClass = ''
      this.modalAnimation = false
      this.animateTimer = setTimeout(this.onCloseFunc, 0)
    },

    getModalPropeties() {
      let modalProperties = this.$refs[`modal${this.modalRefNamePrefix}`]
      let modalHeaderProperties = this.$refs[`modalHeader${this.modalRefNamePrefix}`]
      let modalFooterProperties = this.$refs[`modalFooter${this.modalRefNamePrefix}`]
      EventBus.$emit('modalPropertyListener', {
        modal: modalProperties.getBoundingClientRect(),
        modalHeader: modalHeaderProperties.getBoundingClientRect(),
        modalFooter: modalFooterProperties.getBoundingClientRect(),
      })

      if (modalProperties.getBoundingClientRect().width >= 900) {
        if (this.showMobileFooterBar) {
          this.setShowMobileFooterBarState(false)
        }
      } else if (!this.showMobileFooterBar) {
        this.setShowMobileFooterBarState(true)
      }

      modalProperties = null
      modalHeaderProperties = null
      modalFooterProperties = null
    }
  }
}

</script>