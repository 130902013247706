<template>
  <div>
    <SearchContext class="mb-5" />

    <Wizard />
  </div>
</template>

<script>
import SearchContext from '@/components/Layout/SearchContext'
import Wizard from '@/components/Wizard'

export default {
  name: 'page-wizard',
  components: {
    SearchContext,
    Wizard
  },
}
</script>

<style lang="scss" scoped>
</style>