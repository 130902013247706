/**
 * Import Dependency
 */
import Vue from 'vue'
import '@/filters/dateTimeFilters'
import * as objectHelper from '@/helpers/objectHelper'
let VueScrollTo = require('vue-scrollto')

/**
 * Import API
 */
import { setSearchLastStep, saveSearch } from '@/providers/search'

/**
 * Declare Variable
 */
const state = {
  /**
   * Based on the conditions of a step navigation forward may be cancelled
   * (e.g. a required field is empty)
   */
  disableNextStep: false,

  /**
   * If true disable search button
   */
  isSearching: false,

  /**
   * If this is positive, a user action to navigate to another step first triggers a save cart call
   */
  saveCartBeforeNextStep: false,

  // Search model mirrors API response
  search: {
    Cart: null,
    Id: 0,
    Key: '',
    ChannelId: 1,
    CountryId: 0,
    CountryCode: '',
    LocationId: 0,
    LanguageId: 65,
    MeetingtypeId: 1,
    StartDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
    StartMinutes: 0,
    EndDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
    EndMinutes: 0,
    Seats: 1,
    LastStep: 1,
    MadeReservation: 0,
    MachineKey: '',
    SearchTerm: '',
    Source: '',
  },

  // This object is only for use on startup.
  cleanSearchSrc: {
    Cart: null,
    Id: 0,
    Key: '',
    ChannelId: 1,
    CountryId: 0,
    CountryCode: '',
    LocationId: 0,
    LanguageId: 65,
    MeetingtypeId: 1,
    StartDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
    StartMinutes: 0,
    EndDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
    EndMinutes: 0,
    Seats: 1,
    LastStep: 1,
    MadeReservation: 0,
    MachineKey: '',
    SearchTerm: '',
    Source: '',
  },

  maxSteps: 6,
  currentSearch: null,
  selectedEventType: 0,
  selectedSetting: 0,
  showResultInModal: false,
}

const getters = {
  isSearching: (state) => state.isSearching,
  search: (state) => state.search,
  countryId: (state) => state.CountryId ?? 0,
  countryCode: (state) => state.CountryCode ?? '',
  searchTerm: (state) => state.SearchTerm ?? '',
  SearchId: (state) => (state.search ? state.search.Id : null),
  SearchKey: (state) => (state.search ? state.search.Key : null),
  ChannelId: (state) => (state.search ? state.search.ChannelId : null),
  LastStep: (state) => (state.search ? state.search.LastStep : null),
  MeetingtypeId: (state) => (state.search ? state.search.MeetingtypeId : 0),
  DisableNextButton: (state) => !!state.disableNextStep,
  isFinished: (state) => (state.search ? state.search.LastStep === 6 : false),
  searchDates: (state) => {
    return {
      start: Vue.options.filters.dateObjectIsoDateString(
        state.search.StartDate
      ),
      end: Vue.options.filters.dateObjectIsoDateString(state.search.EndDate),
    }
  },
  selectedEventType: (state) => state.selectedEventType,
  selectedSetting: (state) => state.selectedSetting,
}

const mutations = {
  setShowResultInModal: (state, newState) => {
    state.showResultInModal = newState
  },

  // Set search state
  setIsSearching: (state, data) => {
    state.isSearching = data
  },

  // Set search language
  setSearchLanguage: (state, newState) => {
    state.search.LanguageId = newState === 'nl' ? 52 : 65
  },

  // Set seach term
  setSearchTerm: (state, newState) => {
    state.search.SearchTerm = newState
  },

  // Set revert search
  setCurrentSearch: (state, newState) => {
    state.currentSearch = newState
  },

  // Set start minutes
  setStartMinutes: (state, newState) => {
    state.search.StartMinutes = newState
  },

  // Set end date
  setEndDate: (state, newState) => {
    state.search.EndDate = newState
  },

  // Set seats
  setSeats: (state, newState) => {
    state.search.Seats = newState
  },

  /**
   * Set the Search model from the API
   * The complete object is updated, which means all observables will be recalculated.
   * This helps detect timeouts and helps keep tabs in sync
   */
  setSearchDetails: (state, data) => {
    state.search = data
  },

  // Set search key
  setSearchKey: (state, data) => {
    state.search.Key = data
  },

  // Set search key
  setSource: (state, data) => {
    state.search.Source = data
  },

  setSearchLocationId: async (state, newState) => {
    state.search.LocationId = newState
  },

  setCountryId: async (state, newState) => {
    state.search.CountryId = newState
  },

  setCountryCode: async (state, newState) => {
    state.search.CountryCode = newState
  },

  setSelectedEventType: async (state, data) => {
    state.selectedEventType = data
  },

  setSelectedSetting: async (state, data) => {
    state.selectedSetting = data
  },

  setMaxSteps: (state, newState) => {
    state.maxSteps = newState
  },

  setLastStep: (state, newState) => {
    state.search.LastStep = newState
  },

  /**
   * Disable / Enable navigation to the next step
   */
  disableNextStep: (state) => (state.disableNextStep = true),
  enableNextStep: (state) => (state.disableNextStep = false),
  saveCartBeforeNextStep: (state) => (state.saveCartBeforeNextStep = true),
}

const actions = {
  /**
   * Navigate to a specific step
   */
  goToStep: async ({ commit, state, dispatch }, { step }) => {
    if (document.getElementById('wizard-scroll-container')) {
      VueScrollTo.scrollTo({
        el: null,
        container: '#wizard-scroll-container',
        duration: 300,
      })
    }
    // Don't bother making a call
    if (!state.search.Key || step < 1 || step > state.maxSteps) {
      return
    }

    // Do not navigate forward if the next step is disabled
    if (state.disableNextStep && step > state.search.LastStep) {
      return
    }

    // We need to keep the cart up to date before we continue (in either direction)
    if (state.saveCartBeforeNextStep) {
      await dispatch('cartStore/saveCart', null, {
        root: true,
      })
      state.saveCartBeforeNextStep = false
    }

    // When going to the search page, clear the selected location
    if (step <= 1) {
      dispatch('availabilityStore/setSelectedLocation', null, { root: true })
    }

    // Clear selected space and setting
    if (step === 2) {
      commit(
        'availabilityStore/setSelectedSpaceAndSetting',
        {
          SpaceId: null,
          SettingId: null,
        },
        { root: true }
      )
    }

    // Delete options
    if (step === 3) {
      commit('cartStore/setOptions', { data: null }, { root: true })
    }

    // Update last step store data
    commit('setLastStep', step)
    // We don't want to fire save setLastStep to API to prevent incomplete stats
    if (step !== state.maxSteps) {
      setSearchLastStep({
        SearchKey: state.search.Key,
        step,
      })
    }

    // Finalize cart
    if (step === state.maxSteps) {
      let finalizeResonse = await dispatch('cartStore/finalizeCart', null, {
        root: true,
      })
      commit('cartStore/setReservation', finalizeResonse.data, { root: true })
    }

    // Clear company and cart
    if (step <= 2) {
      //  || step === state.maxSteps
      dispatch('billingStore/clearStoreData', null, { root: true })
      await dispatch('cartStore/deleteCart', null, {
        root: true,
      })

      commit('searchStore/enableNextStep', null, { root: true })
    }
  },

  resetSearch: async ({ commit, dispatch, rootState, rootGetters }) => {
    let widgetState = rootState.widgetStore.widgetState
    let leadSource = rootState.widgetStore.leadSource
    let preSelectedLocationId = rootState.widgetStore.preSelectedLocationId
    let searchState = rootState.searchStore.currentSearch
    let languageId = searchState.LanguageId

    await dispatch('setSearch', {
      Id: 0,
      Key: '',
      ChannelId: widgetState.ChannelId,
      CountryId: 0,
      CountryCode: '',
      LocationId: !preSelectedLocationId
        ? widgetState.LocationId
        : preSelectedLocationId,
      LanguageId: languageId,
      MeetingtypeId: searchState.MeetingtypeId
        ? searchState.MeetingtypeId
        : widgetState.PreSelectedMeetingtype,
      StartDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
      StartMinutes: 0,
      EndDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
      EndMinutes: 0,
      Seats: 1,
      LastStep: 1,
      MadeReservation: 0,
      WidgetId: widgetState.Id,
      MachineKey: rootGetters['authStore/getMachineToken'] ?? '',
      SearchTerm: '',
      Source: leadSource,
    })

    await dispatch(
      'widgetStore/processOpeningHours',
      {
        startDate: Vue.options.filters.dateObjectIsoDateString(new Date()),
        startMinutes: widgetState.PreSelectedMeetingtype === 3 ? 540 : 0,
        endMinutes: widgetState.PreSelectedMeetingtype === 3 ? 1020 : 0,
        openingHour: widgetState.OpeningHour,
        updateSearchStore: true,
      },
      { root: true }
    )
    commit('cartStore/setReservation', null, { root: true })
    commit('setCurrentSearch', null)
  },

  saveSearchData: async ({ state, commit, dispatch, rootGetters }) => {
    // Show loader
    commit('setIsSearching', true)

    // Delete cart
    await dispatch('cartStore/deleteCart', null, { root: true })

    let searchObj = objectHelper.cleanSource(state.search)
    // Reset search
    searchObj.Id = 0
    searchObj.Key = ''
    searchObj.MadeReservation = 0
    searchObj.MachineKey = rootGetters['authStore/getMachineToken']
    //searchObj.Source = rootGetters['widgetStore/source']

    // If deskspace set time to fixed values
    if (searchObj.MeetingtypeId === 3) {
      searchObj.StartMinutes = 540
      searchObj.EndMinutes = 1020
    }

    // If workspace and no seats then set to 1
    if (searchObj.MeetingtypeId === 2 && Number(searchObj.Seats) === 0) {
      searchObj.Seats = 1
    }

    // Save search data
    return await saveSearch(searchObj)
      .then((response) => {
        if (response.status === 200) {
          response.data.CountryCode = searchObj.CountryCode
          commit('setSearchDetails', objectHelper.cleanSource(response.data))
          commit('setCurrentSearch', objectHelper.cleanSource(response.data))
          dispatch('availabilityStore/getAvailability', null, { root: true })
        }
      })
      .catch(() => {
        commit('setIsSearching', false)
      })
      .finally(() => {
        commit('setIsSearching', false)
      })
  },

  setSearch: async ({ commit }, data) => {
    // If deskspace set time to fixed values
    if (data.MeetingtypeId === 3) {
      data.StartMinutes = 540
      data.EndMinutes = 1020
    }

    // If workspace and no seats then set to 1
    if (data.MeetingtypeId === 2 && Number(data.Seats) === 0) {
      data.Seats = 1
    }

    // Fire custom event
    let searchCustomEvent = new CustomEvent('cb-w-searchData', {
      detail: data,
    })
    document.dispatchEvent(searchCustomEvent)
    searchCustomEvent = null

    // Store search data
    commit('setSearchDetails', data)
  },

  /**
   * Update date and time
   */
  setDateTime: (
    { state, commit },
    { startDate, endDate, startMinutes, endMinutes }
  ) => {
    let searchObj = state.search
    searchObj.StartDate = startDate
    searchObj.EndDate = endDate
    searchObj.StartMinutes = startMinutes
    searchObj.EndMinutes = endMinutes
    commit('setSearchDetails', searchObj)
  },

  /**
   * Set new meeting type value
   * and:
   * - Get acive countries based on meeting type;
   * - Get opening hours based on meeting type; and
   * - process opening hours
   */
  setMeetingtypeId: async ({ state, commit, dispatch }, meetingtypeId) => {
    let searchObj = state.search
    searchObj.MeetingtypeId = meetingtypeId
    searchObj.Seats = 1
    commit('setSearchDetails', searchObj)

    // Fire custom event
    let searchCustomEvent = new CustomEvent('cb-w-searchData', {
      detail: searchObj,
    })
    document.dispatchEvent(searchCustomEvent)
    searchCustomEvent = null

    // Get active countries
    await dispatch('countryStore/getActiveCountries', meetingtypeId, {
      root: true,
    })
    let response = await dispatch(
      'widgetStore/getOpeningHoursData',
      searchObj.StartDate,
      { root: true }
    )

    // Process opening hours
    await dispatch(
      'widgetStore/processOpeningHours',
      {
        startDate: searchObj.StartDate,
        startMinutes: searchObj.StartMinutes,
        endMinutes: searchObj.EndMinutes,
        openingHour: response.data,
        updateSearchStore: true,
      },
      { root: true }
    )

    // Fire custom event
    let wizardCustomEvent = new CustomEvent('s2mSearchData', {
      detail: state.search,
    })
    document.dispatchEvent(wizardCustomEvent)
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
