<template>
  <article class="LocationOption">
    <figure
      @click="showInformation"
      class="LocationOption__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          option.OptionImage,
          option.LocationId,
          '640'
        )}')`,
      }"
    ></figure>
    <div class="LocationOption__detail_price">
      <div class="LocationOption__details has-padding">
        <div
          @click="showInformation"
          :class="`title-option is-size-5 has-text-weight-bold`"
          v-html="name"
        ></div>
        <UpsellTemplate
          class="has-margin-bottom"
          v-if="upsellItems.length"
          :upsellItems="upsellItems"
        />
        <a
          @click="showInformation"
          class="has-icon is-info"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              size="lg"
            />
          </span>
          <span v-text="$t('General.Text_MoreInfo')"></span>
        </a>
      </div>
      <div class="LocationOption__price_control has-background-info-light has-padding has-text-centered">
        <div
          v-if="widgetState.ShowPrices"
          class="has-margin-bottom"
        >
          <span v-text="$t('App.AvailableOptions.Text_PerItem') + ':'"></span>
          <span class="has-text-weight-bold">
            {{ option.PricePerItem | toCurrency('nl', option.CurrencyIso) }}
          </span>
          <span class="has-margin-left price-total">
            <span v-text="$t('App.AvailableOptions.Text_Total') + ':'"></span>
            <span class="has-text-weight-bold">
              {{ totalPrice | toCurrency('nl', option.CurrencyIso) }}
            </span>
          </span>
        </div>
        <AvailableOptionAmountControls
          class="LocationOption__pricing__control has-margin-bottom"
          :option="option"
        />
        <template v-if="isSelected">
          <div
            v-if="widgetState.ShowPrices && isSelected"
            class="has-margin-bottom price-total-bottom"
          >
            <span v-text="$t('App.AvailableOptions.Text_Total') + ':'"></span>
            <span class="has-text-weight-bold">
              {{ totalPrice | toCurrency('nl', option.CurrencyIso) }}
            </span>
          </div>
          <div>
            <a
              @click="handleDeleteOptionFromCart"
              class="has-icon has-text-danger"
              v-if="isSelected && !isRequired"
            >
              <span :class="`${$classPrefix}icon`">
                <font-awesome-icon :icon="['fad', 'trash']" />
              </span>
              <span v-html="$t('General.Text_Remove')"></span>
            </a>
          </div>
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import AvailableOptionAmountControls from './AvailableOptionAmountControls'
import UpsellTemplate from '@/components/UI/UpsellTemplate'

export default {
  name: 'AvailableOption',

  components: {
    AvailableOptionAmountControls,
    UpsellTemplate,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showExtraInformation: false,
    }
  },

  computed: {
    ...mapState('widgetStore', ['widgetState']),
    ...mapGetters('cartStore', ['optionAmountById', 'Cart']),
    Amount() {
      return this.optionAmountById({ OptionId: this.option.OptionId })
    },
    name() {
      return this.option.OptionName
    },
    description() {
      return this.option.OptionDescription
    },
    hasDescription() {
      return this.option.OptionDescription !== ''
    },

    totalPrice() {
      if (!this.isSelected && !this.isRequired) {
        return '0.00'
      }
      return this.calculateOptionTotalPrice()
    },

    isRequired() {
      return this.option.RequiredItem === 2
    },
    isSelected() {
      let result = false

      for (let i = 0; i < this.Cart.Options.length; i++) {
        this.Cart.Options.map((option) => {
          if (option.OptionId === this.option.OptionId) {
            result = true
          }
        })
      }
      return result
    },

    upsellItems() {
      let output = this.option.Features.map((el) => el.Body)
      return output
    },
  },

  methods: {
    ...mapActions('cartStore', ['deleteCartOption']),
    ...mapMutations('cartStore', [
      'setOptionAmount',
      'increaseBusyState',
      'decreaseBusyState',
    ]),
    calculateOptionTotalPrice() {
      let priceTotal = this.Amount * this.option.PricePerItem
      if (this.option.MaxTotal > 0 && priceTotal > this.option.MaxTotal) {
        priceTotal = this.option.MaxTotal
      }
      return priceTotal
    },

    handleToggleExtraInformation() {
      this.showExtraInformation = !this.showExtraInformation
    },

    showInformation() {
      this.$emit('showDrawer', this.option)
    },

    async handleDeleteOptionFromCart() {
      this.increaseBusyState()
      await this.deleteCartOption({
        OptionId: this.option.OptionId,
      })
      this.option.IsSelected = false

      this.decreaseBusyState()
    },
  },
}
</script>
