<template>
  <div :class="`${$classPrefix}columns is-mobile is-multiline`">
    <div
      class="wrapper-contactDetails"
      :class="[`${$classPrefix}column`, logo ? 'is-two-thirds' : 'is-full']"
    >
      <div class="wrapper-addres wrapper-with-icon has-margin-bottom">
        <span :class="`${$classPrefix}icon`">
          <font-awesome-icon
            :icon="['fad', 'map-marker-alt']"
            size="lg"
          />
        </span>
        <div>
          <p v-html="location.Address"></p>
          <p>
            <span
              v-if="location.Postalcode"
              v-html="location.Postalcode + ' '"
            ></span>
            <span v-html="location.City"></span>
          </p>
          <p
            v-if="location.Coutry"
            v-html="location.Country"
          ></p>
        </div>
      </div>
      <div class="wrapper-openingHours wrapper-with-icon has-margin-bottom">
        <span :class="`${$classPrefix}icon`">
          <font-awesome-icon
            :icon="['fad', 'clock']"
            size="lg"
          />
        </span>
        <div
          v-for="(item, index) in openingHour"
          :key="'op_' + index"
        >
          {{printOpeningHours(item)}}
        </div>
      </div>
      <div class="wrapper-contact">
        <div class="row-contact">
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon
              :icon="['fad', 'phone-alt']"
              size="lg"
            />
          </span>
          <div v-html="location.Phone"></div>
        </div>
        <div class="row-contact">
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon
              :icon="['fad', 'envelope']"
              size="lg"
            />
          </span>
          <div v-html="location.Email"></div>
        </div>
      </div>
    </div>
    <div
      v-if="logo"
      :class="`${$classPrefix}column is-one-third`"
    >
      <figure class="image-logo image">
        <img :src="$options.filters.getLocationImage(
            logo.Image,
            logo.LocationId,
            '160'
          )">
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'location-contact-details',

  props: {
    location: {
      type: Object,
      required: true
    },
    logo: {
      type: Object,
      default: null
    },
    openingHour: {
      type: Array,
      default: () => { return [] }
    }
  },

  methods: {
    printOpeningHours(openingHour) {
      let output = 'Contact us'
      if (openingHour.Open) {
        output = `
        ${this.$options.filters.minutesToTime(openingHour.OpenMinutes)} - ${this.$options.filters.minutesToTime(openingHour.CloseMinutes)}`
      }
      return output
    }
  }
}
</script>

<style lang="scss" scoped>
.image-logo {
  max-width: 140px;
}
.wrapper-with-icon {
  display: flex;
  .#{$prefix}icon {
    margin-right: 5px;
  }
  div {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.wrapper-contact {
  .row-contact {
    display: flex;
    .#{$prefix}icon {
      margin-right: 5px;
    }
    div {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
</style>