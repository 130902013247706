<template>
  <div>
    <div :class="`${$classPrefix}field`">
      <label
        ref="label"
        :for="labelId"
        :class="`${$classPrefix}label`"
      >
        {{ label }}
      </label>
      <em v-if="tagline">
        {{ tagline }}
      </em>
    </div>

    <div
      :class="`${$classPrefix}field`"
      class="is-grouped is-grouped-multiline"
    >
      <div
        v-for="(tag, index) in tags"
        :key="index"
        :class="`${$classPrefix}control`"
      >
        <div class="tags has-addons">
          <span class="tag is-cyan is-medium"> {{ tag }} &nbsp;</span>
          <a
            class="tag is-delete is-medium"
            @click="removeTag({ index })"
          ></a>
        </div>
      </div>
      <div
        :class="`${$classPrefix}control`"
        class="control-tag-input"
      >
        <div
          :class="`${$classPrefix}field`"
          class="has-addons"
        >
          <input
            v-model="tagInput"
            :id="inputId"
            @blur="addTag"
            @keyup.enter="addTag"
            type="text"
            autocomplete="off"
            :class="`${$classPrefix}input is-3`"
            placeholder="Add tag"
          />
          <a
            :class="`${$classPrefix}button is-primary`"
            @click="addTag"
          >
            <font-awesome-icon :icon="['fal', 'plus']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFormInputField from './BaseFormInputField'

export default {
  name: 'TagsField',
  extends: BaseFormInputField,
  props: {
    name: {
      type: String,
      default: 'tags'
    },
    tagline: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tags: [],
      tagInput: ''
    }
  },

  created() {
    this.tagInput = this.fieldValue
    this.addTag()
  },

  watch: {
    // Connects tags mechanism to parent component
    tags(newValue) {
      this.setValue({ value: newValue })
      this.$emit('input', this.getValue())
    }
  },
  methods: {
    // Overrides parent method
    getValue() {
      return Array.isArray(this.fieldValue)
        ? this.fieldValue.join(',')
        : this.fieldValue
    },

    removeTag({ index }) {
      this.tags.splice(index, 1)
    },

    addTag() {
      let tag = this.tagInput.trim()
      if (tag) {
        let arr = this.tag.split(/[.\*+-/_#]/)
        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.tags.push(arr[i].trim())
          }
        }
        arr = []

        this.tagInput = ''
        this.$refs.tagInput.focus()
        this.$emit('tagAdded', tag)
      }
      // let tag = this.tagInput.trim()
      // if (tag !== '' && !this.tagAlreadyAdded({ tag })) {
      //   this.tags.push(tag)
      // }

      // this.tagInput = ''
    },

    tagAlreadyAdded({ tag }) {
      var index = this.tags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>
