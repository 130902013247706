<template>
  <UiComponentDrawer
    ref="drawerFilters"
    v-if="showDrawer"
    :drawerTitle="$t('App.DrawerFilters.Main_Title')"
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <!-- <div :class="`${$classPrefix}title is-4`">Filter spaces</div> -->
      <FilterOverview
        v-if="MeetingtypeId === 1"
        :shownInDrawer="true"
        class="has-margin-bottom SearchControls__filter-wrapper"
      />
      <hr />
      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`">
          <a
            :class="`has-text-danger`"
            @click="resetFilters"
          >
            <span :class="`${$classPrefix}icon`">
              <font-awesome-icon :icon="['fad', 'trash']" />
            </span>
            <span v-html="$t('App.DrawerFilters.Label_ClearFilters')"></span>
          </a>
        </div>
        <div :class="`${$classPrefix}level-right`">
          <a
            :class="`${$classPrefix}button is-success`"
            @click="hideDrawer"
            v-html="$t('App.DrawerFilters.Button_Done')"
          ></a>
        </div>
      </div>
    </template>
  </UiComponentDrawer>
</template>

<script>
import UiComponentDrawer from '@/components/UI/Drawer'
import FilterOverview from '@/components/SearchResults/FilterOverview'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'drawer-filter',

  components: {
    UiComponentDrawer,
    FilterOverview
  },

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false,
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('searchStore', ['MeetingtypeId']),
  },
  methods: {
    ...mapMutations('searchStore', ['setSelectedSetting', 'setSelectedEventType']),
    hideDrawer() {
      this.$refs.drawerFilters.closeDrawer()
    },

    resetFilters() {
      this.setSelectedSetting(0)
      this.setSelectedEventType(0)
      this.hideDrawer()
    }
  },
}
</script>

<style lang="scss" scoped>
</style>