import axiosInstance from '@/helpers/axios'

let getLocationReviewsToken = null

export const cancelRequests = () => {
  if (getLocationReviewsToken) {
    getLocationReviewsToken.cancel('Request cancelled')
  }
}

export const getLocationReviews = async (data) => {
  await cancelRequests()
  getLocationReviewsToken = axiosInstance.CancelToken.source()
  return axiosInstance.get(`review`, {
    cancelToken: getLocationReviewsToken.token,
    params: data,
  })
}
