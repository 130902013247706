import Vue from 'vue'

let sizes = {
  160: '160x120_',
  320: '320x240_',
  640: '640x480_',
}

let virtualManagerPhotoSize = {
  '80': '80x80_',
  '160': '160x160_',
  '240': '240x240_',
}

let profilePhotoSize = {
  38: '_38x38',
  84: '_84x84',
  120: '_120x120',
  150: '_150x150',
  300: '_300x300',
}

/**
 * Get profile image
 */
Vue.filter('getProfileImage', (filename = '', profileId = 0, size = null) => {
  let s3ProfileBucket = process.env.VUE_APP_S3_AWS_PROFILE_BUCKET

  if (filename === '' || !profileId === 0) {
    return '@/assets/images/noImage.png'
  }

  if (size === null) {
    // Original photo size
    return `https://${s3ProfileBucket}.s3.amazonaws.com/${filename}`
  } else {
    // Get the scaled photo
    let filenameNoExtension = filename.substring(0, filename.lastIndexOf('.'))

    let position = Number(filename.indexOf('.'))
    let imageLength = Number(filename.length)
    let imageExtension = filename.substring(position, imageLength)
    return `https://${s3ProfileBucket}.s3.amazonaws.com/${profileId}_${filenameNoExtension}${profilePhotoSize[size]}${imageExtension}`
  }
})

/**
 * Get location image
 */
Vue.filter('getLocationImage', (filename = '', locationId = 0, size = null) => {
  if (filename === '' || locationId === 0) {
    return '/static/img/placeholder.png'
  }
  if (size === null || size === 0) {
    // Original photo size
    return `${
      process.env.VUE_APP_AZURE_STORAGE_URL
    }/${locationId.toString()}/${filename}`
  } else {
    return getSizedJPGVersion(locationId.toString(), filename, sizes[size])
  }
})

/**
 * Get location virtual manager
 */
Vue.filter('getVirtualManagerImage', (filename = '', locationId = 0, size) => {
  if (filename === '' || locationId === 0) {
    return '@/assets/images/noImage.png'
  }

  if (size === void 0) {
    // Original photo size
    return (
      process.env.VUE_APP_AZURE_STORAGE_URL +
      '/' +
      locationId.toString() +
      '/' +
      filename
    )
  } // Get the scaled photo
  else {
    return getSizedJPGVersion(
      locationId.toString(),
      filename,
      virtualManagerPhotoSize[size]
    )
  }
})

function getSizedJPGVersion(prefix = '', filename = '', size = '') {
  if (prefix !== '') {
    prefix = '/' + prefix
  }

  let filenameNoExtension = filename.substring(0, filename.lastIndexOf('.'))

  let position = Number(filename.indexOf('.'))
  let imageLength = Number(filename.length)
  let imageExtension = filename.substring(position, imageLength)

  return `${process.env.VUE_APP_AZURE_STORAGE_URL}${prefix}/${size}${filenameNoExtension}${imageExtension}`
}
