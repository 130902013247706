<template>
  <div class="wrapper-carousel">
    <transition
      name="fade"
      mode="out-in"
      :duration="100"
      appear
    >
      <figure
        :key="'im' + imageIndex"
        class="image is-3by2 has-background-grey-lighter"
      >
        <img
          src="@/assets/images/spacer1_1.gif"
          :style="{
              backgroundImage: `url('${$options.filters.getLocationImage(
                mImages[imageIndex].Image,
                (mImages[imageIndex].LocationId ? mImages[imageIndex].LocationId : locationId),
                '640'
              )}')`,
            }"
        >
      </figure>
    </transition>
    <template v-if="mImages.length > 1">
      <a
        class="stepNav prev"
        @click="stepNav('<')"
      >
        <span :class="`${$classPrefix}icon is-large`">
          <font-awesome-icon
            :icon="['far', 'angle-left']"
            size="2x"
          /></span></a>
      <a
        class="stepNav next"
        @click="stepNav('>')"
      >
        <span :class="`${$classPrefix}icon is-large`">
          <font-awesome-icon
            :icon="['far', 'angle-right']"
            size="2x"
          /></span></a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'location-images',

  props: {
    images: {
      type: Array,
      required: true
    },
    locationId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      imageIndex: 0,
      mImages: []
    }
  },

  created() {
    this.mImages = this.images.slice().sort(item => item.IsDefault ? -1 : 1)
  },

  methods: {
    stepNav(action) {
      let updatedIndex = this.imageIndex
      if (action === '>') {
        updatedIndex++
      } else {
        --updatedIndex
      }

      if (updatedIndex < 0) {
        updatedIndex = this.mImages.length - 1
      } else if (updatedIndex >= this.mImages.length) {
        updatedIndex = 0
      }
      this.imageIndex = updatedIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-carousel {
  position: relative;
  display: flex;
  align-items: center;

  figure {
    flex-grow: 1;
  }

  .stepNav {
    z-index: 100;
    position: absolute;
    background: rgba(#ffff, $alpha: 0.5);
    color: $black;

    &.next {
      right: 0;
    }
  }
}
</style>