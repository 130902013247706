<template>
  <div class="billing-carousel">
    <carousel-3d
      :key="'carousel' + billingSlides.length"
      ref="carousel"
      :inverse-scaling="0"
      :perspective="0"
      :animationSpeed="300"
      :scaling="0"
      :display="5"
      :space="250"
      :width="250"
      :height="120"
      :disable3d="true"
      :border="0"
      :loop="true"
      class="carousel-3d-container has-margin-bottom"
      :controls-visible="billingSlides.length > 3"
      :controls-width="20"
      :controls-height="50"
      :on-main-slide-click="onSelect"
      @before-slide-change="onBeforeSlideChange"
      @after-slide-change="onAfterSlideChange"
      :count="billingSlides.length"
    >
      <slide
        v-for="(billingItem, i) in billingSlides"
        :index="i"
        :key="'cs' + i"
        :clickable="billingItem.Id !== -1"
        class="carousel-3d-slide has-text-centered has-background-white"
        :class="[{
            'is-active':
              currentIndex === i,
          }, {'is-disabled': billingItem.Id === -1}]"
      >
        <div class="slide-content">
          <div v-if="billingItem.Id">
            <p class="is-size-5-mobile has-text-weight-bold">
              {{ billingItem.Company }}
            </p>
            <p class="is-size-7-mobile">{{ billingItem.Address }}</p>
            <p class="is-size-7-mobile">
              {{ billingItem.PostalCode }}, {{ billingItem.City }}
            </p>
            <p class="is-size-7-mobile">{{ billingItem.Country }}</p>
          </div>
          <div
            v-if="billingItem.Id === 0"
            class="has-text-weight-bold is-size-5"
            v-text="$t('Components.InvoiceDetails.InvoiceAddressForm.Tile_NewInvoiceAddress')"
          ></div>
        </div>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'billing-carousel',

  components: {
    Carousel3d,
    Slide,
  },

  data() {
    return {
      selectdBillingInfo: { ...this.$store.state.billingStore.billingInfoTemplate },
      newBillingInfo: { ...this.$store.state.billingStore.billingInfoTemplate },
      currentIndex: 0
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('billingStore', ['billingInfo', 'billingInfos']),
    ...mapState('authStore', ['profile']),

    billingSlides() {
      let output = [...[], ...this.billingInfos]
      output.push(this.newBillingInfo)
      let i = output.length
      let placeholder = { ...{}, ...this.newBillingInfo }
      placeholder.Id = -1

      while (i < 3) {
        output.push(placeholder)
        i++
      }
      return output
    }
  },

  watch: {
    'billingInfo': {
      handler: function(val) {
        // Do nothing when the ID are the same
        if (val.Id === this.selectdBillingInfo.Id) { return }

        if (typeof this.$refs.carousel !== 'undefined') {
          // Reinitialize carousel
          this.$refs.carousel.computeData(true)

          // Find index of chosen billing info
          let _currentIndex = 0
          for (const index in this.billingSlides) {
            if (this.billingSlides[Number(index)].Id === val.Id) {
              _currentIndex = Number(index)
              break;
            }
          }

          // Navigate carousel to the selected billing info
          this.$refs.carousel.goSlide(_currentIndex)
        }
      }
    },
  },

  created() {
    this.selectdBillingInfo = this.billingSlides[0]
    this.setCurrentBillingInfo()
  },

  methods: {
    ...mapMutations('billingStore', ['setBillingInfo']),
    ...mapActions('billingStore', ['clearSelectedBilling']),

    onBeforeSlideChange(index) {
      if (this.billingSlides[Number(index)].Id === -1) {
        // Stay on current slide when billing Id is -1
        this.$refs.carousel.goSlide(this.currentIndex);
        return
      }

      this.currentIndex = index = Number(index)
      this.selectdBillingInfo = this.billingSlides[index]
      this.setCurrentBillingInfo()
    },

    onAfterSlideChange() {
    },

    onSelect() {
    },

    async setCurrentBillingInfo() {
      if (this.selectdBillingInfo.Id === 0) {
        // Clear billing info store
        // this.clearSelectedBilling()
      }

      this.selectdBillingInfo.ProfileId = this.profile.Id
      this.setBillingInfo({ ...this.selectdBillingInfo })
    },
  },
}
</script>
