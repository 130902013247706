import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v${process.env.VUE_APP_API_BASE_VERSION}/`,
  timeout: 60000,
  timeoutErrorMessage: 'API takes longer then expected. Please, try again.',
  headers: {
    'Content-type': 'application/json',
    apiToken: process.env.VUE_APP_API_KEY
  }
});

instance.CancelToken = axios.CancelToken

instance.interceptors.request.use(function(config) {
  // Apply JSON.stringify to the data of all `post` & `put` calls
  if ((config.method === 'post' || config.method === 'put') && config.data) {
    config.data = JSON.stringify(config.data)
  }

  // Set headers if not set explicitly
  if (!config.headers.profileToken) {
    config.headers.profileToken = store.getters['authStore/getProfileToken']
  }
  if (!config.headers.machineToken) {
    config.headers.machineToken = store.getters['authStore/getMachineToken']
  }

  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error)
});

export default instance