<template>
  <div class="AvailableOptions">
    <DrawerLocationOptionDetail
      v-if="selectedLocationOption !== null"
      :showDrawer="selectedLocationOption !== null"
      :onClickClose="hideDrawer"
      :selectedOption="selectedLocationOption"
    />

    <Message
      v-if="availableOptions.length === 0"
      class="is-info"
    >
      {{ $t('App.AvailableOptions.Text_LocationHasNoOptions') }}
    </Message>
    <Tabs
      v-else
      :key="'t_'+$i18n.locale"
      :showNextButton="false"
      :tabsCssClass="`${windowWidth < 600 ? 'is-small' : ''}`"
    >
      <TabPane
        v-for="tab in tabs"
        :label="tab.label"
        :icon="tab.icon"
        :key="tab.label"
      >
        <AvailableOption
          :class="`${$classPrefix}box`"
          v-for="(option, index) in tab.options.sort(function(a, b) {
            return parseFloat(a.Rank) - parseFloat(b.Rank)
          })"
          :option="option"
          :key="index"
          @showDrawer="setShowDrawer"
        />
      </TabPane>
    </Tabs>
    <div v-if="!showMobileFooterBar">
      <hr />
      <NextButton
        :class="[
          `${$classPrefix}button is-success is-pulled-right`,
          { 'is-loading': isBusy },
        ]"
        :disabled="isBusy"
        v-text="$t('General.Text_NextStep')"
      />
    </div>
  </div>
</template>

<script>
import AvailableOption from './AvailableOption'
import DrawerLocationOptionDetail from './DrawerLocationOptionDetail'
import Message from '@/components/UI/Message'
import Tabs from '@/components/UI/Tabs/Tabs'
import TabPane from '@/components/UI/Tabs/TabPane'
import NextButton from '@/components/Navigation/NextButton'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AvailableOptions',

  components: {
    AvailableOption,
    DrawerLocationOptionDetail,
    Tabs,
    TabPane,
    Message,
    NextButton,
  },

  data() {
    return {
      StartedLoadingOptions: false,
      selectedLocationOption: null,
    }
  },

  computed: {
    ...mapGetters('cartStore', [
      'availableOptions',
      'availableOptionsByCategory',
      'availableOptionCategories',
      'highlightedOptions',
      'CartKey',
      'isBusy',
    ]),
    ...mapState(['windowWidth', 'viewportMode', 'showMobileFooterBar']),

    // The option tabs
    tabs() {
      let tabs = []

      // If we have highlighted options, those go first.
      if (this.highlightedOptions && this.highlightedOptions.length > 0) {
        tabs.push({
          label: this.$t('App.AvailableOptions.Tab_Highlighted'),
          options: this.highlightedOptions,
        })
      }

      // Next go through the available categories
      this.availableOptionCategories.forEach((category) => {
        if (this.availableOptionsByCategory[category.CategoryName]) {
          tabs.push({
            label: this.$t(`App.OptionTabs.Label_OptionTab${category.CategoryId}`),
            options: this.availableOptionsByCategory[category.CategoryName],
          })
        }
      })

      return tabs
    },
  },

  methods: {
    setShowDrawer(data) {
      this.selectedLocationOption = data
    },

    hideDrawer() {
      this.selectedLocationOption = null
    },
  },
}
</script>
