var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.filteredLocations.length > 1)?_c('div',{key:"sorting",staticClass:"SearchControls"},[_c('div',{staticClass:"is-size-4 has-text-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}}),_c('div',{staticClass:"has-margin-top-half is-hidden-mobile"},[_c('div',{staticClass:"is-inline-flex SearchControls__sort-wrapper has-margin-right-x2"},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'sort']}})],1),_c('a',{class:[
            `${_vm.$classPrefix}button is-small`,
            { 'is-success': _vm.getSortBy === 'location' },
          ],domProps:{"textContent":_vm._s(_vm.$t('App.SearchControls.Link_Nearness'))},on:{"click":function($event){return _vm.setSortBy({ sortBy: 'location' })}}}),_c('a',{class:[
            `${_vm.$classPrefix}button is-small`,
            { 'is-success': _vm.getSortBy === 'price' },
          ],domProps:{"textContent":_vm._s(_vm.$t('App.SearchControls.Link_LowestPrice'))},on:{"click":function($event){return _vm.setSortBy({ sortBy: 'price' })}}})]),(_vm.currentSearch.MeetingtypeId === 1)?_c('FilterOverview',{staticClass:"is-inline-flex SearchControls__filter-wrapper",attrs:{"selectBoxSize":"is-small"}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }