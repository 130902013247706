/**
 * Import Dependency
 */
import { debounce } from '@/helpers/debounce'

/**
 * Delay updating the cart for 200ms. This avoids a flood of API calls of which only the last is relevant when the user is rapidly changing the amount for a given option.
 */
let debouncedUpdateAmount = debounce({
  func: ({ data, commit }) => {
    commit('increaseBusyState')
    saveCartOption(data)
      .then((response) => {
        // console.log('>>> ', response)
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          commit('setCart', {
            data: response.data,
          })
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
  },
  delay: 200,
})

/**
 * Import API
 */
import { selectSpace } from '@/providers/availability'
import {
  getCartPackages,
  applyVoucherToCart,
  removeVoucherFromCart,
} from '@/providers/voucher'

import {
  getCartOptions,
  saveCartOption,
  deleteCartOption,
} from '@/providers/option'

import {
  saveCart,
  deleteCart,
  saveCartInvoiceAddress,
  saveCartProfile,
  deleteCartProfile as deleteCartProfileProv,
  finalizeCart,
} from '@/providers/cart'

/**
 * Declare Variable
 */
const state = {
  // Search model mirrors API response
  cart: null,
  availableOptions: [],
  availablePackages: [],
  vouchers: [],

  // The number of API requests being processed
  busy: 0,
  reservation: null
}

const getters = {
  Cart: (state) => state.cart,
  CartKey: (state) => (state.cart ? state.cart.CartKey : null),
  isBusy: (state) => state.busy > 0,
  locationId: (state) => (state.cart ? state.cart.LocationId : null),
  hasCompany: (state) => (state.cart ? !!state.cart.CompanyId : false),
  companyName: (state) => state.cart.CompanyName,
  hasInvoiceAddress: (state) =>
    state.cart ? !!state.cart.InvoiceAddress : false, // TODO: set minimal requirements
  invoiceAddress: (state) => state.cart.InvoiceAddress,
  cartTerms: (state) => state.cart.CartTerms,

  /**
   * An array of all available options. This includes required and pre-selected options
   */
  availableOptions: (state) => state.availableOptions,

  availablePackages: (state) => state.availablePackages,

  /**
   * A specific option by Id
   */
  availableOptionById: (state) => ({ OptionId }) =>
    state.availableOptions.find((option) => option.OptionId === OptionId),

  /**
   * The Amount of an option, by Id
   */
  optionAmountById: (state, getters) => ({ OptionId }) => {
    let option = getters.availableOptionById({
      OptionId,
    })
    // Default to 0 if anything is missing
    if (!option || !option.Amount) {
      return 0
    }
    // If a max amount is set, enforce it
    if (option.MaxAmount !== 0) {
      return Math.min(option.Amount, option.MaxAmount)
    }
    return option.Amount
  },

  /**
   * Go through the available options, and compile a list of unique categories
   */
  availableOptionCategories: (state) =>
    state.availableOptions
      .reduce((result, option) => {
        // Ignore categories that have already been listed
        if (
          result.find((category) => category.CategoryId === option.CategoryId)
        ) {
          return result
        }

        result.push({
          CategoryId: option.CategoryId,
          CategoryName: option.CategoryName,
        })

        return result
      }, [])
      .sort((a, b) => a.CategoryId - b.CategoryId),

  /**
   * Sort the available options by category
   */
  availableOptionsByCategory: (state) =>
    state.availableOptions.reduce((result, option) => {
      if (!result.hasOwnProperty(option.CategoryName)) {
        result[option.CategoryName] = []
      }
      result[option.CategoryName].push(option)

      return result
    }, {}),

  /**
   * Get the three top ranking available options, which are to be highlighted
   */
  highlightedOptions: (state) =>
    state.availableOptions
      ? state.availableOptions
          .filter((option) => option.Rank !== 0)
          .sort((a, b) => a.Rank - b.Rank)
          //.slice(0, 10)
      : [],

  /**
   * Get the number of seats of this booking
   */
  Seats: (state) => state.cart.Seats,
}

// ACTIONS
const actions = {
  updateCart: async ({ commit, dispatch }, cart) => {
    commit('setCart', {
      data: cart,
    })

    dispatch('setCartCommentProfileId', cart.ProfileId)
  },

  /**
   * Create a cart, by selecting a Space
   */
  createCart: async ({ commit, rootGetters }) => {
    let searchId = rootGetters['searchStore/SearchId']
    let locationId = rootGetters['availabilityStore/selectedLocationId']
    let hash = rootGetters['availabilityStore/selectedSpaceHash']

    commit('increaseBusyState')

    let response = await selectSpace({
      SearchId: searchId,
      LocationId: locationId,
      Hash: hash,
    })
    if (response.status === 200 && response.data.CartKey) {
      commit('setCart', {
        data: response.data,
      })
    }
        commit('decreaseBusyState')
  },

  /**
   * Save the current cart details to the database. The returned cart object is used as new truth.
   * During this action the busy state of the interface is set, blocking certain actions
   */
  saveCart: async ({ commit, state }) => {
    if (!state.cart) { return }
    commit('increaseBusyState')
    return saveCart({
      cart: state.cart,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          commit('setCart', {
            data: response.data,
          })
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
  },

  // Delete cart
  deleteCart: async ({ commit, state }) => {
    commit('increaseBusyState')
    state.reservation = null

    if (state.cart) {
      let cartKey = state.cart.CartKey
      await deleteCart(cartKey).catch(() => { })
      commit('setCart', { data: null })

      commit('setOptions', {
        data: null,
      })
    }
    commit('decreaseBusyState')

    state.busy = 0
  },

  // Finalize cart
  finalizeCart: async ({ commit, getters }) => {
    commit('increaseBusyState')

    return await finalizeCart({
      CartKey: getters.CartKey,
    }).catch(() => {
      commit('decreaseBusyState')
    })
      .finally(() => {
        commit('decreaseBusyState')
      })
  },

  /**
   * Get available options
   */
  getAvailableOptions: async ({ commit, getters }) => {
    commit('increaseBusyState')

    let response = await getCartOptions({
      CartKey: getters.CartKey,
    })

    if (response.status === 200 && response.data) {
      commit('setOptions', {
        data: response.data,
      })
    }
    commit('decreaseBusyState')
  },

  getAvailablePackages: async ({ commit, state }) => {
    commit('increaseBusyState')

    let response = await getCartPackages(state.cart.CartKey)

    if (response.status === 200 && response.data) {
      commit('setAvailablePackages', {
        data: response.data,
      })
    }
    commit('decreaseBusyState')
  },

  /**
   * Add the option to the cart
   */
  saveCartOption: async (
    { commit, getters },
    { Amount, OptionId, TotalPrice }
  ) => {
    let option = getters.availableOptionById({
      OptionId,
    })
    if (!option) {
      return
    }
    try {
      let response = await saveCartOption(
        Object.assign(option, {
          Amount,
          TotalPrice,
          CartKey: getters.CartKey,
        })
      )
      if (response.status === 200 && response.data) {
        commit('setCart', {
          data: response.data,
        })
      }
      return response
    }
    catch { /* empty */ }
    
  },

  saveCartProfile: async ({ commit, getters }, profileId) => {
    let cartKey = getters.CartKey

    let response = await saveCartProfile(cartKey, profileId)
    if (response.status === 200 && response.data) {
      commit('setCart', {
        data: response.data,
      })
    }
    return response
  },

  deleteCartProfile: async ({ commit, getters }) => {
    let cartKey = getters.CartKey
    let response = await deleteCartProfileProv(cartKey)
    if (response.status === 200 && response.data) {
      commit('setCart', {
        data: response.data,
      })
    }
    return response
  },

  deleteCartOption: async ({ commit, getters }, { OptionId }) => {
    let response = await deleteCartOption({
      CartKey: getters.CartKey,
      OptionId,
    })
    if (response.status === 200 && response.data) {
      commit('setCart', {
        data: response.data,
      })
    }
    return response
  },

  /**
   * Update the amount for a given option. This uses a debouncer to reduce the number of API calls
   */
  updateOptionAmount: (
    { commit, getters },
    { OptionId, Amount, TotalPrice }
  ) => {
    let option = getters.availableOptionById({
      OptionId,
    })
    if (option) {
      //console.log(Amount)

      // The amount may not go below 0
      Amount = Amount < 0 ? 0 : Amount

      // The amount may never go above the max amount
      if (option.MaxAmount !== 0) {
        Amount = Math.min(option.MaxAmount, Amount)
      }

      // TODO: Check whether we need to correct this based on the API response
      commit('setOptionAmount', {
        OptionId,
        Amount,
      })

      debouncedUpdateAmount({
        data: Object.assign(option, {
          Amount,
          TotalPrice,
          CartKey: getters.CartKey,
        }),
        commit,
      })
    }
  },

  /**
   * Connect the passed company to the Cart by ID, and use the company address as the invoice address
   */
  setInvoiceAddress: async ({ commit, getters }, { billingInfo }) => {
    commit('increaseBusyState')
    
    let cart = Object.assign(getters.Cart, {
      CompanyId: billingInfo.CompanyId,
      CompanyName: billingInfo.Company,
      InvoiceAddress: {
        BillingId: billingInfo.Id,
        Company: billingInfo.Company,
        SendTo: billingInfo.SendTo,
        Email: billingInfo.Email,
        Address: billingInfo.Address,
        PostalCode: billingInfo.PostalCode,
        City: billingInfo.City,
        Country: billingInfo.Country,
        PhoneNumber: billingInfo.PhoneNumber,
        PoNumber: billingInfo.PoNumber
      },
    })

    await saveCart({
      cart,
    })
      .then((response) => {
        if (response && response.status === 200) {
          commit('setCart', {
            data: response.data,
          })
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
  },

  /**
   * Clear the connected company, and the invoice details
   */
  clearCompany: async ({ state, commit, getters }) => {
    commit('increaseBusyState')
    if (state.cart) {
      let cart = Object.assign(getters.Cart, {
        CompanyId: 0,
        CompanyName: 0,
        InvoiceAddress: {},
      })

      let response = await saveCart({
        cart,
      })
      if (response && response.status === 200) {
        commit('setCart', {
          data: response.data,
        })
      }
    }

    commit('decreaseBusyState')
  },

  /**
   * Save specifically the invoice address
   */
  saveCartInvoiceAddress: async ({ commit, getters }, { invoiceAddress }) => {
    commit('increaseBusyState')

    let response = await saveCartInvoiceAddress({
      CartKey: getters.CartKey,
      invoiceAddress,
    })
    if (response && response.status === 200) {
      commit('setCart', {
        data: response.data,
      })
    }
    commit('decreaseBusyState')
  },

  applyVoucherToCart: async ({ commit, getters }, voucherCode) => {
    commit('increaseBusyState')
    let output = null
    await applyVoucherToCart(getters.CartKey, voucherCode)
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data.Cart !== null
        ) {
          output = response
          commit('setCart', {
            data: response.data.Cart,
          })
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
    return output
  },

  removeVoucherFromCart: async ({ commit, getters }, voucherId) => {
    commit('increaseBusyState')
    let output = null

    await removeVoucherFromCart(getters.CartKey, voucherId)
      .then((response) => {
        if (response && response.status === 200) {
          output = response

          commit('setCart', {
            data: response.data,
          })
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
    return output
  },

  // Update cart language
  updateCartLanguage: async ({ state, dispatch }, locale) => {
    if (state.cart) {
      state.cart.LanguageId = locale === 'nl' ? 52 : 65
      state.cart.Language = locale === 'nl' ? 'nl' : 'en'
      await dispatch('saveCart')
    }
  },

  /**
   * TODO: Overleggen hoe deze aan te pakken
   */
  setAgreedToCartTerms: async ({ state }, value) => {
    state.cart.CartTerms.Accepted = value
  },

  setCartCommentProfileId: ({ state, commit }, profileId) => {
    if (state.cart) {
      state.cart.Comments.forEach((c) => {
        c.CreatedBy = profileId
        c.ProfileId = profileId
      })
      commit('setCart', { data: state.cart })
    }
  },
}

// MUTATIONS
const mutations = {
  /**
   * Set the Cart model from the API
   * The complete object is updated, which means all observables will be recalculated.
   * This helps detect timeouts and helps keep tabs in sync
   */
  setCart: (state, { data }) => {
    state.cart = data
  },

  // Set comment
  setCartComment: (state, text) => {
    if (state.cart.Comments.length) {
      state.cart.Comments[0].Text = text
      state.cart.Comments[0].ProfileId = state.cart.ProfileId
      state.cart.Comments[0].CreatedBy = state.cart.CreatedBy
    } else {
      state.cart.Comments.push({
        TypeId: 6,
        Text: text,
        ProfileId: state.cart.ProfileId,
        CreatedBy: state.cart.ProfileId,
      })
    }
  },

  /**
   * A complete replacement of the current options
   */
  setOptions: (state, { data }) => {
    state.availableOptions = data
  },

  setAvailablePackages: (state, { data }) => {
    state.availablePackages = data
  },

  /**
   * Set the Amount of an option
   *  Applies min / max criteria
   */
  setOptionAmount: (state, { OptionId, Amount }) => {
    let option = state.availableOptions.find(
      (option) => option.OptionId === OptionId
    )
    if (option) {
      // The amount may not go below 0
      Amount = Amount < 0 ? 0 : Amount

      // The amount may never go above the max amount
      if (option.MaxAmount !== 0) {
        Amount = Math.min(option.MaxAmount, Amount)
      }
      // Sometimes there is a max amount per person (seat)
      if (option.MaxPP !== 0) {
        Amount = Math.min(state.cart.Seats * option.MaxPP, Amount)
      }
      option.Amount = Amount
    }
  },

  setReservation: (state, newState) => {
    state.reservation = newState
  },

  /**
   * Keep track of the number of API interactions being processed
   */
  increaseBusyState: (state) => {
    state.busy = state.busy + 1
  },
  decreaseBusyState: (state) => {
    state.busy = Math.max(0, state.busy - 1)
  },

  /**
   * Set one or more Cart properties
   */
  setCartProperties: (state, { properties }) => {
    state.cart = Object.assign(state.cart, properties)
  },

  /**
   * Update the invoice address details.
   * Note: this merges the input with the existing address details
   */
  updateInvoiceAddressDetails: (state, { billingInfo }) => {
    state.cart = Object.assign(state.cart, {
      CompanyId: billingInfo.CompanyId,
      CompanyName: billingInfo.Company,
      InvoiceAddress: {
        BillingId: billingInfo.Id,
        Company: billingInfo.Company,
        SendTo: billingInfo.SendTo,
        Email: billingInfo.Email,
        Address: billingInfo.Address,
        PostalCode: billingInfo.PostalCode,
        City: billingInfo.City,
        Country: billingInfo.Country,
        PhoneNumber: billingInfo.PhoneNumber,
        PoNumber: billingInfo.PoNumber
      },
    })
  },

  updateTags: (state, newState) => {
    if (state.cart) {
      state.cart.Tags = newState
    }
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
