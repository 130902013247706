<template>
  <article class="LocationOption">
    <figure
      @click="showInformation"
      class="LocationOption__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          availablePackage.Image,
          locationId,
          '320'
        )}')`,
      }"
    ></figure>
    <div class="LocationOption__detail_price">
      <div class="LocationOption__details has-padding">
        <div
          @click="showInformation"
          :class="`title-option is-size-5 has-text-weight-bold`"
          v-html="name"
        ></div>
        <div :class="`${$classPrefix}content`">
          <div
            v-if="description"
            v-html="description"
          ></div>
          <ul v-else>
            <li
              v-for="option in availablePackage.Options"
              :key="`o_${option.OptionId}`"
              v-text="option.Name"
            ></li>
          </ul>
        </div>
        <a
          @click="showInformation"
          class="has-icon is-info"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon
              :icon="['fad', 'info-circle']"
              size="lg"
            />
          </span>
          <span v-text="$t('General.Text_MoreInfo')"></span>
        </a>
      </div>
      <div class="LocationOption__price_control has-background-info-light has-padding has-text-centered">
        <div
          v-if="widgetState.ShowPrices"
          class="has-margin-bottom"
        >
          <span class="has-text-weight-bold">
            {{
            availablePackage.VoucherPriceExclTax
              | toCurrency('nl', cart.CurrencyIso)
          }}
          </span>
          <span v-text="$t('App.AvailablePackages.Text_PerPerson')"></span>
          <div
            class="has-text-grey has-margin-bottom"
            v-text="
                `${$options.filters.toCurrency(
                  availablePackage.VoucherPriceExclTax * cart.TotalSeats,
                  'nl-NL',
                  'EUR'
                )} total`
              "
          ></div>

          <transition
            name="fade"
            mode="out-in"
          >
            <button
              key="selected"
              v-if="!isSelected"
              class="is-success is-fullwidth"
              :class="[`${$classPrefix}button`, { 'is-loading': isBusy }]"
              @click="applyVoucher"
              v-text="$t('General.Text_Add')"
            >
            </button>
            <a
              v-else
              key="removeSelected"
              @click="revertVoucher"
              class="has-icon has-text-danger"
            >
              <span :class="`${$classPrefix}icon`">
                <font-awesome-icon :icon="['fad', 'trash']" />
              </span>
              <span v-html="$t('General.Text_Remove')"></span>
            </a>
          </transition>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},

  props: {
    availablePackage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('cartStore', ['cart', 'isBusy']),
    ...mapState('widgetStore', ['widgetState']),

    locationId() {
      return this.cart.LocationId
    },

    name() {
      let name = this.availablePackage.Name

      let text = this.availablePackage.Descriptions.find(
        (t) => t.LanguageId === this.cart.LanguageId
      )
      if (text) {
        name = text.Name
      }

      return name
    },
    description() {
      let description = ''

      let text = this.availablePackage.Descriptions.find(
        (t) => t.LanguageId === this.cart.LanguageId
      )
      if (text) {
        description = text.Text
      }

      return description
    },
    hasDescription() {
      return this.description !== ''
    },

    isSelected() {
      return this.availablePackage.Id === this.cart.VoucherId
    },
  },
  methods: {
    ...mapActions('cartStore', ['applyVoucherToCart', 'removeVoucherFromCart']),

    async applyVoucher() {
      if (this.cart.VoucherId) {
        await this.removeVoucherFromCart(this.cart.VoucherId)
        this.applyVoucherToCart(this.availablePackage.Code)
      } else {
        this.applyVoucherToCart(this.availablePackage.Code)
      }
    },

    revertVoucher() {
      this.removeVoucherFromCart(this.availablePackage.Id)
    },

    showInformation() {
      this.$emit('showDrawer', this.availablePackage)
    },
  },
}
</script>

<style></style>
