var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"media-comments",class:`${_vm.$classPrefix}media`},[_c('div',{staticClass:"media-left"},[_c('p',{staticClass:"image is-32x32"},[_c('img',{staticClass:"is-round has-shadow",style:({
          backgroundImage: `url(${_vm.$options.filters.getProfileImage(
            _vm.mComment.ProfileImage,
            _vm.mComment.ProfileId,
            38
          )})`,
        }),attrs:{"src":require("@/assets/images/spacer1_1.gif")}})])]),_c('div',{class:`${_vm.$classPrefix}media-content`},[_c('div',{class:`${_vm.$classPrefix}content`},[_c('div',{staticClass:"has-text-grey postDetailBar is-size-7"},[_vm._v(" "+_vm._s(_vm._f("longDateFormat")(_vm._f("dateTimeStampToDate")(_vm.mComment.CreatedOn)))+" - "+_vm._s(_vm._f("timeFromDateTimeStamp")(_vm.mComment.CreatedOn))+" ")]),_c('div',{staticClass:"is-size-7"},[_c('strong',[_vm._v(_vm._s(_vm.mComment.ProfileName)+",")]),_c('span',{class:{ 'is-deleted': _vm.mComment.IsDeleted }},[_vm._v(_vm._s(_vm.mComment.Text))])]),_vm._l((_vm.filterComments(_vm.mComment.Id)),function(comment){return _c('comment-item',{key:comment.Id,attrs:{"commentObj":comment,"comments":_vm.mComments}})})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }