/**
 * Import Dependency
 */
import Vue from 'vue'

/**
 * Import API
 */
import { getAvailabilityBySearchKey } from '@/providers/availability'
import { checkWorkspaceOverlap } from '@/providers/reservation'

/**
 * Declare Variable
 */
const state = {
  // The location objects are based on the API response, with additionally a lowest_price attribute.
  locations: [],
  searching: true,
  hasOverlap: false,

  /**
   * Either 'location' or 'price'
   */
  sortBy: 'location',

  /**
   * Selected Location
   */
  selectedLocationId: null,

  /**
   * Selected Space & Setting combination
   */
  selectedSpaceId: null,
  selectedSettingId: null,
  userLnglat: null,
}

/**
 * Getters
 */
const getters = {
  isSearching: (state, getters, rootState, rootGetters) => {
    return (
      state.searching ||
      (rootGetters['cartStore/isBusy'] &&
        rootGetters['searchStore/LastStep'] < 3)
    )
  },
  getSortBy: (state) => state.sortBy,

  /**
   * User location
   */
  userLnglat: (state) => state.userLnglat,

  /**
   * Location lists
   */
  availableLocations: (state) => state.locations.filter((l) => l.IsAvailable),
  processedLocations: (state) => {
    let _location = state.locations.filter((l) => l.IsAvailable)

    if (state.sortBy === 'price') {
      return _location
        .slice()
        .sort(
          (locationA, locationB) =>
            locationA._LowestPrice - locationB._LowestPrice
        )
    }
    // If not sorted by price, default to sorting by location, which is provided by the api
    return _location
  },

  /**
   * Selected Location Information
   */
  hasSelectedLocation: (state) => state.selectedLocationId !== null,
  selectedLocationId: (state) => state.selectedLocationId,
  selectedLocation: (state) =>
    state.locations.find(
      (location) => location.LocationId === state.selectedLocationId
    ) || null,

  /**
   * Selected Space Information
   */
  hasSelectedSpace: (state) => state.selectedSpaceId !== null,
  selectedSpaceId: (state) => state.selectedSpaceId,
  selectedSpace: (state, getters) => {
    if (!getters.hasSelectedSpace || !getters.hasSelectedLocation) {
      return null
    }
    return (
      getters.selectedLocation.Spaces.find(
        (space) =>
          space.SpaceId === state.selectedSpaceId &&
          space.SettingId === state.selectedSettingId
      ) || null
    )
  },
  selectedSpaceHash: (state, getters) => {
    if (!getters.hasSelectedSpace || !getters.hasSelectedLocation) {
      return null
    }
    return getters.selectedSpace.Hash
  },
}

const actions = {
  /**
   * Check overlap
   */
  checkWorkspaceOverlap: async ({ commit, dispatch, rootGetters }) => {
    if (
      rootGetters['searchStore/MeetingtypeId'] === 2 &&
      rootGetters['authStore/getProfileToken']
    ) {
      let response = await checkWorkspaceOverlap({
        startDate: Vue.options.filters.dateObjectIsoDateString(
          rootGetters['searchStore/search'].StartDate
        ),
        startMinutes: rootGetters['searchStore/search'].StartMinutes,
        endMinutes: rootGetters['searchStore/search'].EndMinutes,
      }).catch((e) => {
        if (e && e.response.status === 401) {
          // User not found. Clear all user data
          dispatch('authStore/SignOut', null, { root: true })
        }
      })
      commit('setHasOverlap', Boolean(response ? response.data : false))
      return response ? response.data : false
    }
    commit('setHasOverlap', false)
    return false
  },

  /**
   * Reset availability data
   */
  resetAvailability: ({ commit }) => {
    commit('setHasOverlap', false)
    commit('setAvailableLocations', {
      data: [],
    })
    commit('setSelectedLocationId', null)
    commit('setSelectedSpaceId', null)
    commit('setSelectedSettingId', null)
  },

  /**
   * Get available locations for the search query by search key (identifier)
   */
  getAvailability: async ({ state, commit, dispatch, rootGetters }) => {
    dispatch('resetAvailability')
    state.searching = true

    // Check if user is already logged in
    await dispatch('authStore/checkProfileLoginState', null, { root: true })
    // Check for overlap when it comes to booking a workplace
    if (rootGetters['searchStore/MeetingtypeId'] === 2) {
      let response = await dispatch('checkWorkspaceOverlap')
      if (response.status === 200 && response.data) {
        commit('setIsSearching', false)
        return true
      }
    }

    let search = rootGetters['searchStore/search']
    let response = await getAvailabilityBySearchKey({
      SearchKey: search.Key,
    })

    // If successful we get the full updated search model
    if (response.status === 200 && response.data.Locations) {
      commit('setAvailableLocations', {
        data: response.data.Locations,
      })

      // If search locations found and search is LocationId > 0 preselect and go to next step
      if (response.data.Locations.length > 0 && search.LocationId > 0) {
        let locationIndex = response.data.Locations.findIndex(
          (l) => l.LocationId === search.LocationId
        )
        if (locationIndex > -1) {
          // Set selected location
          dispatch(
            'setSelectedLocation',
            response.data.Locations[locationIndex]
          )

          // Go to step 2
          await dispatch(
            'searchStore/goToStep',
            { step: 2 },
            {
              root: true,
            }
          )
        }
      }
    }
    state.searching = false
    return
  },

  /**
   * Set the selected location
   */
  setSelectedLocation: async ({ commit }, location) => {
    if (location) {
      commit('setSelectedLocationId', location.LocationId)
    } else {
      commit('setSelectedLocationId', null)
      state.selectedSettingId = null
      state.selectedSpaceId = null
    }
  },
}

const mutations = {
  setHasOverlap: (state, newState) => {
    state.hasOverlap = newState
  },

  setSelectedLocationId: (state, data) => {
    state.selectedLocationId = data
  },

  setSelectedSpaceId: (state, data) => {
    state.selectedSpaceId = data
  },

  setSelectedSettingId: (state, data) => {
    state.selectedSettingId = data
  },

  setUserLnglat: (state, newState) => {
    state.userLnglat = newState
  },

  /**
   * Set the filter
   */
  setSortBy: (state, { sortBy }) => {
    if (['location', 'price'].includes(sortBy)) {
      state.sortBy = sortBy
    }
  },
  /**
   * Store the available locations, as retrieved from the API, after computing per location the lowest price of available suitable spaces
   * Finish by updating the searching status
   */
  setAvailableLocations: (state, { data }) => {
    state.locations = data.map((location) => {
      let spaces = location.Spaces.filter((s) => s.SpaceAvailable)
      if (spaces && spaces.length > 0) {
        let lowestSpace = spaces[0]

        for (let i = 0; i < spaces.length; i++) {
          let space = spaces[i]

          if (Number(space.Price) < Number(lowestSpace.Price)) {
            lowestSpace = space
          }
        }

        location._LowestPrice = lowestSpace.Price
        location._LowestTotalPrice = lowestSpace.PriceTotal
      }

      return location
    })
    // state.searching = false
  },
  /**
   * Update the searching status
   */
  setIsSearching: (state, newState) => {
    state.searching = newState
  },

  /**
   * The Space & Setting selection are always a combination
   */
  setSelectedSpaceAndSetting: (state, { SpaceId, SettingId }) => {
    state.selectedSpaceId = SpaceId
    state.selectedSettingId = SettingId
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
