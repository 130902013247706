<template>
  <Message
    v-if="activeManager"
    class="LocationContact message-host"
  >
    <div class="message__wrapper">
      <figure v-if="activeManager.Image">
        <p class="image is-64x64 has-margin-right">
          <img
            class="is-rounded has-shadow"
            src="@/assets/images/spacer1_1.gif"
            :style="{backgroundImage: `url('${$options.filters.getVirtualManagerImage(
              activeManager.Image,
              activeManager.LocationId,
              '160'
            )}')`,
          }"
            :alt="activeManager.Name"
          />
        </p>
      </figure>
      <div class="message__wrapper__content">
        <transition
          name="message-fade"
          mode="out-in"
        >
          <div
            v-if="managerMessage"
            v-html="message"
          ></div>
        </transition>
      </div>
    </div>
    <div class="host__contact has-background-info">
      <div v-if="activeManager.Phone">
        <a
          class="has-icon has-text-white"
          :href="`tel:${activeManager.Phone}`"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fad', 'phone-alt']" />
          </span>
          <span v-html="activeManager.Phone"></span>
        </a>
      </div>

      <div v-if="activeManager.Email">
        <a
          class="has-icon has-text-white"
          :href="`mailto:${activeManager.Email}`"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fad', 'envelope']" />
          </span>
          <span v-html="activeManager.Email"></span>
        </a>
      </div>
    </div>
  </Message>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import Message from '@/components/UI/Message'

export default {
  components: {
    Message,
  },

  // props: {
  //   message: {
  //     type: String,
  //     default: '',
  //   },
  // },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapState('widgetStore', ['widgetState',]),

    ...mapGetters('locationmanagers', ['activeManager', 'managerMessage']),
    ...mapGetters('availabilityStore', ['selectedLocation']),
    ...mapGetters('searchStore', ['LastStep']),
    ...mapGetters('authStore', ['profile']),

    message() {
      let stepNumber = 0 + this.LastStep


      if (stepNumber > 2 && !this.widgetState.ShowOptionsStep && this.widgetState.ShowBookingDetailsStep) { stepNumber = stepNumber + 1 }
      if (stepNumber > 2 && !this.widgetState.ShowBookingDetailsStep && this.widgetState.ShowOptionsStep) { stepNumber = stepNumber + 1 }
      if (!this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep) { stepNumber = stepNumber + 2 }

      let resource = `Components.Sidebar.LocationContact.Meetingtype_${this.currentSearch.MeetingtypeId}.Text_Step${stepNumber}`
      if (this.LastStep === 5 || stepNumber === 5) {
        resource += this.profile ? '_LoggedIn' : '_Generic'
      }
      return this.$t(resource, { name: this.activeManager.Name })
    }
  },

  async created() {
    if (this.activeManager === null) {
      await this.getActiveLocationManager()
    }
  },

  methods: {
    ...mapActions('locationmanagers', ['getActiveLocationManager']),
  },
}
</script>

<style lang="scss">
.message-fade-enter-active {
  transition: all 0.3s ease;
}
.message-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.message-fade-enter, .message-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  opacity: 0;
}
</style>
