import Vue from 'vue'
import axiosInstance from '@/helpers/axios'

let getOpeningHoursToken = null

export const getOpeningHours = async (params) => {
  if (getOpeningHoursToken) {
    await getOpeningHoursToken.cancel('Request cancelled')
  }
  getOpeningHoursToken = await axiosInstance.CancelToken.source()
  return axiosInstance.get(`openinghour`, {
    params,
    cancelToken: getOpeningHoursToken.token
  })
}

/**
 * Get location opening hours on specific date
 * @param {Number} locationId
 * Param object fields
 * @param {String} startDate
 * @param {String} endDate
 * @param {Number} channelId
 * @param {Number} meetingtypeId
 */
export const getLocationOpeningHoursDate = ({ locationId = 0, startDate = new Date(), endDate = new Date(), meetingtypeId = 0 } = {}) => {
  return axiosInstance.get(`openinghour/location/${locationId}/date`, {
    params: {
      startDate: Vue.options.filters.dateObjectIsoDateString(startDate),
      endDate: Vue.options.filters.dateObjectIsoDateString(endDate),
      meetingtypeId: meetingtypeId,
    }
  })
}