var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SpaceOptionAmountControls"},[(
       !_vm.isSelected && !_vm.isDisabled
    )?_c('button',{staticClass:"is-success is-fullwidth",class:[`${_vm.$classPrefix}button`, { 'is-loading': _vm.isSavingOptionData }],domProps:{"textContent":_vm._s(_vm.$t('General.Text_Add'))},on:{"click":_vm.handleAddOptionToCart}}):_vm._e(),(_vm.isDisabled)?_c('div',[(_vm.option.IsPP)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('App.AvailableOptions.Text_AddedRequiredOption', {
      persons: _vm.$tc('App.AvailableOptions.Text_Persons', _vm.amount)
    }))}}):_vm._e(),(!_vm.option.IsPP)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('App.AvailableOptions.Text_Added', {
      items: _vm.$tc('App.AvailableOptions.Text_Items', _vm.amount)
    }))}}):_vm._e()]):_vm._e(),(_vm.isSelected && !_vm.isRequired && !_vm.isDisabled)?_c('div',{class:`${_vm.$classPrefix}field has-addons`},[_c('div',{class:`${_vm.$classPrefix}control`},[_c('button',{directives:[{name:"longpress",rawName:"v-longpress",value:({
          handler: _vm.handleReduceAmount,
          endHandler: _vm.isMinimalValueHandler,
        }),expression:"{\n          handler: handleReduceAmount,\n          endHandler: isMinimalValueHandler,\n        }"}],class:`${_vm.$classPrefix}button is-success`,attrs:{"type":"submit","disabled":_vm.isMinimalValue},on:{"click":_vm.handleReduceAmount}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'minus']}})],1)])]),_c('div',{class:`${_vm.$classPrefix}control`},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.amount),expression:"amount",modifiers:{"number":true}}],class:`${_vm.$classPrefix}input has-text-centered`,attrs:{"type":"number","size":"3","maxlength":"3","step":"1","min":"0"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing)return;_vm.amount=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{class:`${_vm.$classPrefix}control`},[_c('button',{directives:[{name:"longpress",rawName:"v-longpress",value:({
          handler: _vm.handleIncreaseAmount,
          endHandler: _vm.isMaxValueHandler,
        }),expression:"{\n          handler: handleIncreaseAmount,\n          endHandler: isMaxValueHandler,\n        }"}],class:`${_vm.$classPrefix}button is-success`,attrs:{"type":"submit","disabled":_vm.isMaxValue},on:{"click":_vm.handleIncreaseAmount}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'plus']}})],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }