<template>
  <div :class="[`${$classPrefix}field`]">
    <div
      :class="`${$classPrefix}field-label`"
      class="is-normal"
    >
      <label
        ref="label"
        :class="`${$classPrefix}label`"
      >
        {{ label }}
      </label>
    </div>
    <div :class="`${$classPrefix}field-body`">
      <div :class="[`${$classPrefix}field`, fieldClasses]">
        <div :class="`${$classPrefix}control`">
          <slot name="fields"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-field',

  props: {
    label: {
      type: String,
      default: ''
    },

    labelForInput: {
      type: Boolean,
      default: false
    },

    fieldClasses: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
// .is-borderless {
//   padding-left: 0;
//   border-color: transparent;
//   &:hover {
//     border-color: transparent;
//   }
// }

// .#{$prefix}field-label {
//   max-width: 150px;
//   .#{$prefix}label {
//   }
// }

// .#{$prefix}field.is-vertical {
//   .#{$prefix}label {
//     margin-bottom: 0.5em;
//   }
// }

// @media screen and (min-width: $desktop - ($gap * 2)) {
//   .#{$prefix}field.is-horizontal {
//     display: flex;
//     align-items: center;

//     .#{$prefix}field-label {
//       padding-top: unset;
//       flex-basis: 150px;
//       flex-grow: 0;
//     }
//   }
// }
@media screen and (max-width: $desktop - ($gap * 2)) {
  // .#{$prefix}field.is-horizontal {
  //   display: block;

  //   .#{$prefix}field-label {
  //     flex-basis: unset;
  //     flex-grow: unset;
  //     padding-top: 0.375em;
  //     margin-bottom: 0.5rem;
  //   }
  // }
}
</style>