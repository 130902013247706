<template>
  <div>
    <Message>
      {{ $t('Components.ReservationDetails.ReservationForm.MeetingType_2.Message_Tags') }}
    </Message>

    <BaseFieldTemplate :label="$t('Components.ReservationDetails.ReservationForm.MeetingType_2.Label_Tags')">
      <template v-slot:fields>
        <TagInput
          v-model="Tags"
          name="Tags"
        />
      </template>
    </BaseFieldTemplate>
  </div>
</template>

<script>
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import Message from '@/components/UI/Message'
import TagInput from '@/components/UI/Form/TagInput'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'BookerExpertiseForm',

  components: {
    BaseFieldTemplate,
    TagInput,
    Message
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('authStore', ['profile']),

    Tags: {
      get() {
        if (this.cart.MeetingTypeId === 2 && !this.cart.Tags) {
          this.updateTags(this.profile.Tags)
          return this.profile.Tags
        }
        return this.cart.Tags
      },
      set(val) {
        this.updateTags(val)
      },
    },
  },

  methods: {
    ...mapMutations('cartStore', ['updateTags'])
  },
}
</script>

<style lang="scss" scoped>
</style>