var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LocationSpaceMS"},[_c('figure',{ref:"locationSpaceImage",staticClass:"LocationSpaceMS__image has-background-grey-light",style:({
      backgroundImage: `url('${_vm.$options.filters.getLocationImage(
        _vm.space.SpaceImage,
        _vm.selectedLocationId,
        _vm.dynamicSize
      )}')`,
    }),on:{"click":_vm.showSpaceInformation}}),_c('div',{staticClass:"LocationSpaceMS__detail_price"},[_c('div',{staticClass:"LocationSpaceMS__details has-padding"},[_c('div',{staticClass:"LocationSpaceMS__title_size",class:{ 'has-margin-bottom': !_vm.upsellItems.length }},[_c('div',{class:`spaceName is-size-5 has-text-weight-bold`,domProps:{"innerHTML":_vm._s(_vm.space.SpaceName)}}),_c('span',{staticClass:"spaceSize",class:`${_vm.$classPrefix}tag is-info`,domProps:{"innerHTML":_vm._s(`${_vm.space.M2}m2`)},on:{"click":_vm.showSpaceInformation}})]),(_vm.upsellItems.length)?_c('UpsellTemplate',{staticClass:"has-margin-bottom",attrs:{"upsellItems":_vm.upsellItems,"hideIcon":true}}):_vm._e(),(_vm.widgetState.ShowPrices)?_c('div',{staticClass:"LocationSpaceMS__price",class:{ 'has-margin-bottom': _vm.includedOptions.length }},[_c('span',{attrs:{"alt":_vm.pricingMethod,"title":_vm.pricingMethod}},[_c('strong',{staticClass:"has-text-weight-bold is-size-5",domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.toCurrency(
                _vm.space.Price,
                'nl-NL',
                _vm.space.CurrencyIso))}}),_vm._v(" "+_vm._s(_vm.pricingMethodShortner))]),_c('div',{staticClass:"LocationSpaceMS__priceExplanation is-size-7",domProps:{"textContent":_vm._s(_vm.$t(`Components.LocationSpaces.LocationSpace.Text_PriceTotal`, {
            price: _vm.$options.filters.toCurrency(_vm.space.PriceTotal, 'nl-NL', _vm.space.CurrencyIso)
          }) + ' ' + _vm.$t(`Components.LocationSpaces.LocationSpace.Text_ExcludingOptions`).toLowerCase())}})]):_vm._e(),(_vm.includedOptions.length)?_c('div',[_c('div',{staticClass:"has-text-weight-bold"},[_vm._v("Inclusief:")]),_c('UpsellTemplate',{attrs:{"upsellItems":_vm.includedOptions}})],1):_vm._e()],1),_c('div',{staticClass:"has-background-info-light has-padding"},[_c('div',{staticClass:"has-margin-bottom has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(`Components.LocationSpaces.LocationSpace.Text_PickSetting`))+" ")]),_c('div',{staticClass:"LocationSpaceMS__settings-book"},[_c('div',{staticClass:"LocationSpaceMS__settings"},_vm._l((_vm.space.Settings),function(setting){return _c('div',{key:_vm.space.SpaceId+'setting'+setting.SettingId,staticClass:"LocationSpaceMS__settings__settingContainer"},[_c('a',{staticClass:"LocationSpaceMS__settings__setting is-inline-flex has-text-centered is-success",class:[
            `${_vm.$classPrefix}button`,
            {'is-active': _vm.chosenSetting && _vm.chosenSetting.SettingId === setting.SettingId }, 
            {'is-disabled': _vm.isBusy && !_vm.chosenSetting }, 
            {'is-loading': _vm.isBusy && _vm.chosenSetting && _vm.chosenSetting.SettingId === setting.SettingId}],on:{"click":function($event){return _vm.setChosenSetting(setting)}}},[_c('SvgSetting',{key:'lrs'+setting.SettingId,attrs:{"aria-hidden":"true","settingId":setting.SettingId,"size":"is-medium"}}),_c('div',{staticClass:"LocationSpaceMS__settings__setting__name is-size-7",domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  `Settings.Label_Setting${setting.SettingId}`
                )
              )}})],1)])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }