<template>
  <div
    class="MobileCart has-background-white"
    :style="{paddingBottom: minHeight}"
  >
    <!-- WIZARD CONTROL -->
    <div
      v-if="showNextBar"
      class="MobileCart__control has-padding has-background-white-bis"
    >
      <NextButton
        class="is-success is-pulled-right"
        :class="[`${$classPrefix}button`, { 'is-loading': isBusy }]"
        v-scroll-to="'#wizard-scoll-container'"
        v-text="buttonLabel"
      >
      </NextButton>
    </div>

    <div
      class="MobileCart__cartWrapper has-background-white"
      :style="{height: !isExpanded ? minHeight : maxHeight }"
    >
      <div
        class="MobileCart__cartWrapper__control is-mobile is-marginless"
        :class="[`${$classPrefix}level`, {'has-background-light' : isExpanded}]"
      >
        <div
          :class="`${$classPrefix}level-left`"
          class="has-text-weight-bold"
          v-text="$t('App.Cart.Title_YourReservation')"
        ></div>
        <div :class="`${$classPrefix}level-right`">
          <a
            class="has-icon"
            @click="showHide"
          >
            <span :class="[`${$classPrefix}icon`, isExpanded ? 'down' : 'up']">
              <font-awesome-icon :icon="['far', 'angle-left']" />
            </span>
            <span v-text="isExpanded ? $t('General.Text_Close') : $t('General.Text_Open')"></span>
          </a>
        </div>
      </div>
      <transition
        nam="fade"
        mode="out-in"
      >
        <div
          v-if="!isExpanded"
          key="summery"
          class="MobileCart__cartWrapper__summery"
        >
          <div
            :class="`${$classPrefix}level`"
            class="is-mobile is-marginless"
          >
            <span
              :class="`${$classPrefix}level-left`"
              v-text="$t('App.Cart.Text_TotalExclTax') + ':'"
            ></span>
            <span
              :class="`${$classPrefix}level-right`"
              class="cart-value cart-price has-text-weight-bold"
            >
              <span class="has-text-left">
                {{ cart.TotalExclTax | toCurrency('nl-NL', cart.CurrencyIso) }}
              </span>
            </span>
          </div>

          <div :class="`${$classPrefix}level is-mobile is-marginless`">
            <span
              :class="`${$classPrefix}level-left`"
              v-text="$t('App.Cart.Text_TotalInclTax') + ':'"
            ></span>
            <span
              :class="`${$classPrefix}level-right`"
              class="cart-value cart-price has-text-weight-bold"
            >
              <span class="has-text-left">
                {{ cart.TotalInclTax | toCurrency('nl-NL', cart.CurrencyIso) }}
              </span>
            </span>
          </div>
        </div>
        <Cart
          v-else
          key="detail"
          class="has-padding MobileCart__cartWrapper__detail__cart"
          displayMode="full"
          :showOnlyCart="true"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Cart from '@/components/Sidebar/Cart'
import EventBus from '@/eventbus/EventBus'
import NextButton from '@/components/Navigation/NextButton'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MobileFooter',

  components: {
    Cart,
    NextButton
  },

  data() {
    return {
      isExpanded: false,
      maxHeight: 0,
      minHeight: '101px',
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('searchStore', ['maxSteps']),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapGetters('searchStore', ['LastStep']),

    showNextBar() {
      return (this.maxSteps - this.LastStep) > 1
    },

    buttonLabel() {
      // (this.LastStep + 1) >= this.maxSteps
      return (this.LastStep + 1) === this.maxSteps ?
        this.$t('General.Button_MakeBooking') : this.$t('General.Text_NextStep')
    }
  },

  created() {
    EventBus.$on('modalPropertyListener', this.modalListener)
  },

  beforeDestroy() {
    EventBus.$off('modalPropertyListener', this.modalListener)
  },

  methods: {
    modalListener(props) {
      this.maxHeight = Math.ceil(props.modal.height - props.modalHeader.height) + 'px'
    },

    showHide() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>