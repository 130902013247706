<template>
  <div class="SearchContext has-background-info-light">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        key="wizardProcess"
        v-if="!changeSearch"
      >
        <div class="SearchContext__row has-margin-bottom">
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              key="searchField"
              v-if="LastStep <= 2"
              class="SearchContext__query"
            >
              <SearchWidget
                v-if="!showCompactSearchSummary"
                :searchImmediately="true"
              />
              <div
                v-else
                :class="`${$classPrefix}field has-addons`"
              >
                <BackButton
                  key="backButton"
                  v-if="showBackButton"
                  :label="$t('General.Text_Back')"
                  :class="`${$classPrefix}button`"
                  class="MainControls__back is-outlined"
                />
                <div :class="`${$classPrefix}control has-icons-left`">
                  <input
                    :class="`${$classPrefix}input is-fullwidth`"
                    @click="changeSearch = true"
                    type="text"
                    :value="searchQuery"
                    readonly="true"
                  />
                  <span :class="`${$classPrefix}icon is-left has-text-link-dark`">
                    <font-awesome-icon :icon="['fal', 'search']" />
                  </span>
                </div>
              </div>
            </div>
            <BackButton
              key="backButton"
              v-else-if="showBackButton"
              :label="$t('General.Text_Back')"
              :class="`${$classPrefix}button`"
              class="MainControls__back is-outlined"
            />
            <a
              v-else
              key="startOver"
              @click="changeSearch = true"
              :class="[`${$classPrefix}button is-success`, {'is-invisible' : LastStep < maxSteps || isBusy}]"
              v-text="$t('General.Button_MakeNewBooking')"
            ></a>
          </transition>
          <button
            v-if="!hideModalButton"
            :class="`${$classPrefix}button button-modal-close is-dark`"
            @click="handleClose"
          >
            <span v-text="$t('General.Text_Close')"></span>
          </button>
        </div>
        <div class="is-hidden-tablet SearchContext__row">
          <div
            class="has-text-weight-bold has-text-info"
            v-text="steps[LastStep - 1].label"
          ></div>
          <div>
            <span
              class="has-text-weight-bold"
              v-text="`${LastStep}/${this.totalSteps}`"
            ></span>
          </div>
        </div>

        <div class="is-hidden-mobile SearchContext__row">
          <Steps :steps="steps" />
        </div>
      </div>
      <div
        key="confirmation"
        v-else
      >
        <SearchWidget class="has-margin-bottom" />
        <div class="has-text-centered">
          <a
            @click="hideSearchForm"
            class="has-icon"
          >
            <span :class="`${$classPrefix}icon is-left`">
              <font-awesome-icon :icon="['far', 'angle-up']" />
            </span>
            <span v-text="$t('General.Text_Close')"></span>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventbus/EventBus'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Steps from '@/components/Navigation/Steps'
import SearchWidget from '@/components/SearchWidget'
import BackButton from '@/components/Navigation/BackButton'

export default {
  name: 'SearchContext',

  components: {
    BackButton,
    Steps,
    SearchWidget,
  },

  data() {
    return {
      changeSearch: false,
      showCompactSearchSummary: false
    }
  },

  computed: {
    ...mapState('countryStore', ['countries']),
    ...mapState('searchStore', ['maxSteps', 'currentSearch', 'showResultInModal']),
    ...mapState('widgetStore', ['widgetState']),
    ...mapGetters('searchStore', [
      'isFinished',
      'LastStep',
      'search',
      'MeetingtypeId',
    ]),
    ...mapGetters('cartStore', ['isBusy']),

    showBackButton() {
      let firstStep = this.widgetState.LocationId === 0 ? 1 : 2
      return this.LastStep > firstStep && this.LastStep < this.maxSteps
    },

    hideModalButton() {
      return !this.showResultInModal
    },

    steps() {
      let steps = [
        {
          label: this.$t('App.Steps.Label_Location'),
        },
        {
          label: this.$t('App.Steps.Label_Space'),
        },
        {
          label: this.$t('App.Steps.Label_User-information'),
        },
        {
          label: this.$t('App.Steps.Label_Confirmation'),
        },
      ]

      if (this.currentSearch.MeetingtypeId === 2) {
        steps[1] = { label: this.$t('App.Steps.Label_WorkSpaces') }
      }

      if (this.widgetState.ShowOptionsStep) {
        steps.splice(2, 0, { label: this.$t('App.Steps.Label_Options') })
      }

      if (this.widgetState.ShowBookingDetailsStep) {
        if (this.widgetState.ShowOptionsStep) {
          steps.splice(3, 0, { label: this.$t('App.Steps.Label_Booking-information') })
        } else {
          steps.splice(2, 0, { label: this.$t('App.Steps.Label_Booking-information') })
        }
      }

      return {
        1: steps,
        2: steps,
      }[this.MeetingtypeId]
    },

    totalSteps() {
      return this.steps.length
    },

    searchQuery() {
      let output = this.currentSearch.SearchTerm
      let country = this.countries.find(c => c.CountryCode === this.currentSearch.CountryCode)
      if (typeof (country) !== 'undefined') {
        output += this.currentSearch.SearchTerm ? ` (${country.Name})` : country.Name
      }

      output += output
        ? ` - ${this.$t(
          `App.SearchWidget.BookingTypes.${this.currentSearch.MeetingtypeId}`
        )}`
        : this.$t(
          `App.SearchWidget.BookingTypes.${this.currentSearch.MeetingtypeId}`
        )
      if (this.currentSearch.MeetingtypeId === 1) {
        output += ` - ${this.$tc(
          'App.SearchContext.Text_People',
          this.currentSearch.Seats
        )}`
      }
      output += ` - ${this.$options.filters.dateFormat(
        this.currentSearch.StartDate,
        this.$i18n.locale,
        'shortDateFormat'
      )}`
      if (this.currentSearch.MeetingtypeId === 3) {
        output += ` - ${this.$options.filters.dateFormat(
          this.currentSearch.EndDate,
          this.$i18n.locale,
          'shortDateFormat'
        )}`
      } else {
        output += ` - ${this.$options.filters.minutesToTime(
          this.currentSearch.StartMinutes
        )} - ${this.$options.filters.minutesToTime(
          this.currentSearch.EndMinutes
        )}`
      }
      return output
    },
  },

  watch: {
    LastStep(val) {
      if (val <= 2 && this.changeSearch) {
        this.hideSearchForm()
      }
      if (val <= 2) {
        this.changeSearch = false
      }
    },
  },

  created() {
    this.windowResizeListener()
    EventBus.$on('windowResizeListener', this.windowResizeListener)
  },

  beforeDestroy() {
    EventBus.$off('windowResizeListener', this.windowResizeListener)
  },

  methods: {
    ...mapActions('cartStore', ['deleteCart']),
    ...mapActions('searchStore', ['goToStep', 'resetSearch']),
    ...mapMutations('searchStore', ['setSearchDetails']),

    handleClose() {
      this.resetSearch()
    },

    windowResizeListener() {
      this.showCompactSearchSummary = (window.innerWidth < 950)
    },

    getSpaceName(MeetingtypeId) {
      return {
        1: this.$t('App.Label_MeetingSpace'),
        2: this.$t('App.Label_WorkSpace'),
        3: this.$t('App.Label_DeskSpace'),
      }[MeetingtypeId]
    },

    hideSearchForm() {
      this.changeSearch = false
      this.currentSearch.LastStep = this.LastStep
      this.setSearchDetails(this.currentSearch)
    },
  },
}
</script>
