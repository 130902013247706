import Vue from 'vue'

Vue.filter('getSettingName', (settingId, locale = 'en') => {
  let name = ''
  
  switch (settingId) {
    case 1:
      name = locale !== 'nl' ? 'Different' : 'Anders'
      break
    case 2:
      name = locale !== 'nl' ? 'Cabaret' : 'Cabaret'
      break
    case 3:
      name = locale !== 'nl' ? 'Carre' : 'Carre'
      break
    case 4:
      name = locale !== 'nl' ? 'Creative setting' : 'Creatieve opstelling'
      break
    case 5:
      name = locale !== 'nl' ? 'Exam' : 'Examen'
      break
    case 6:
      name = 'School'
      break
    case 7:
      name = locale !== 'nl' ? 'Circle' : 'Stoelenkring'
      break
    case 8:
      name = locale !== 'nl' ? 'Theater' : 'Theater'
      break
    case 9:
      name = locale !== 'nl' ? 'U-shape' : 'U-vorm'
      break
    case 10:
      name = 'Meeting'
      break
    case 11:
      name = 'Diner'
      break
    case 12:
      name = locale !== 'nl' ? 'Party' : 'Feest'
      break
    case 13:
      name = 'Lunch'
      break
    case 14:
      name = locale !== 'nl' ? 'Reception' : 'Receptie'
      break
    case 15:
      name = 'Buffet'
      break
    case 16:
      name = 'Sit-down'
      break
    case 17:
      name = locale !== 'nl' ? 'Fish' : 'Visgraat'
      break
    case 19:
      name = locale !== 'nl' ? 'Knowledge' : 'Kennisoverdracht'
      break
    case 20:
      name = locale !== 'nl' ? 'Conference' : 'Congres'
      break
    case 21:
      name = 'Workshop'
      break
    case 22:
      name = locale !== 'nl' ? 'Creative session' : 'Creatieve sessie'
      break
    case 23:
      name = 'Workspace'
      break
    case 24:
      name = locale !== 'nl' ? 'Block' : 'Blok'
      break
    case 25:
      name = 'Corona'
      break
    case 26:
      name = 'Lounge'
      break
    case 27:
      name = 'Go crazy'
      break
    case 28:
      name = 'Stand up'
      break
    case 29:
      name = locale !== 'nl' ? 'Board room' : 'Bestuurskamer'
      break
    case 30:
      name = locale !== 'nl' ? 'Round tables' : 'Ronde tafels'
      break
  }

  return name
})
