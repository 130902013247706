<template>
  <div class="AuthFlow">
    <div :class="`${$classPrefix}columns is-mobile is-centered`">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          v-if="authPage === 'login'"
          :class="`${$classPrefix}column is-full`"
        >
          <template v-if="authOptions.type === 0">
            <div :class="`${$classPrefix}tabs is-boxed`">
              <ul>
                <li
                  @click="tabIndex = 0"
                  :class="{'is-active' : tabIndex === 0}"
                >
                  <a>
                    <span v-text="$t('Components.AuthFlow.Tab_Login')"></span>
                  </a>
                </li>
                <li
                  @click="tabIndex = 1"
                  :class="{'is-active' : tabIndex === 1}"
                >
                  <a>
                    <span v-text="$t('Components.AuthFlow.Tab_NoLogin')"></span>
                  </a>
                </li>
              </ul>
            </div>
          </template>
          <div :class="`${$classPrefix}columns is-mobile is-centered`">
            <div
              key="login"
              v-if="(authOptions.showLoginRegister && authOptions.type !== 0) || (authOptions.type === 0 && tabIndex === 0)"
              :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
            >
              <Login :class="`${$classPrefix}box`" />
            </div>
            <div
              key="noAuth"
              v-if="(authOptions.showNoLogin && authOptions.type !== 0) || (authOptions.type === 0 && tabIndex === 1)"
              :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
            >
              <NoAuthLoginFlow :class="`${$classPrefix}box`" />
            </div>
          </div>
        </div>

        <div
          key="register"
          v-if="authPage === 'registration'"
          :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
        >
          <Registration :class="`${$classPrefix}box`" />
        </div>
        <div
          key="forgotPassword"
          v-if="authPage === 'forgot'"
          :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
        >
          <Forgot :class="`${$classPrefix}box`" />
        </div>

        <div
          key="forgotConfirm"
          v-if="authPage === 'forgot-confirm'"
          :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
        >
          <ForgotConfirm :class="`${$classPrefix}box`" />
        </div>

        <div
          key="setNewPassword"
          v-if="authPage === 'set-new-password'"
          :class="`${$classPrefix}column is-full-mobile is-two-thirds`"
        >
          <SetNewPassword :class="`${$classPrefix}box`" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Login from './Login'
import NoAuthLoginFlow from './NoAuthLoginFlow'
import Registration from './Registration'
import Forgot from './Forgot'
import SetNewPassword from './SetNewPassword'
import ForgotConfirm from './ForgotConfirm'

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

// This component encapsulates the Login, password reset & registration flows
export default {
  name: 'AuthFlow',
  components: {
    NoAuthLoginFlow,
    Login,
    Registration,
    Forgot,
    ForgotConfirm,
    SetNewPassword
  },

  data() {
    return {
      tabIndex: 0
    }
  },

  computed: {
    ...mapGetters('authStore', ['authPage', 'isLoggedIn']),
    ...mapGetters('widgetStore', ['authOptions']),
    ...mapState('widgetStore', ['widgetState']),
  },

  watch: {
    authPage: function() {
      this.$scrollTo({
        el: null,
        container: '#wizard-scroll-container',
        duration: 500,
      })
    },
  },

  async created() {
    await this.checkIfUserCookiesExists()
    if (!this.isLoggedIn) {
      this.goToAuthPage({ page: 'login' })
    }
  },
  methods: {
    ...mapMutations('authStore', ['goToAuthPage']),
    ...mapActions('authStore', ['checkUserCookies']),

    async checkIfUserCookiesExists() {
      await this.checkUserCookies()
    },
  },

  beforeDestroy() {
    this.tabIndex = 0
  },
}
</script>
