<template>
  <div :class="`${$classPrefix}field`">
    <div
      :class="`${$classPrefix}field-label`"
      class="is-normal"
    >
      <label :class="`${$classPrefix}label`">{{ label }}</label>
    </div>
    <div :class="`${$classPrefix}field-body`">
      <div :class="`${$classPrefix}field`">
        <div :class="`${$classPrefix}control`">
          <div :class="[`${$classPrefix}select`, selectorClass]">
            <select
              class="is-shadowless"
              :type="type"
              :id="inputId"
              :disabled="disabledStatus"
              autocomplete="none"
              @blur="onBlur"
              @keyup="onChange"
              @focus="onFocus"
              @input="onInput"
            >
              <option
                v-for="(option, index) in options"
                :key="index + '_' + getValue(option)"
                :value="getOptionValue(option)"
                :selected="isSelected(option)"
                v-text="getoptionText(option)"
              >
              </option>
              <slot></slot>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseField from './BaseBulmaField'

export default {
  name: 'SelectField',
  extends: BaseField,

  props: {
    options: {
      type: Array,
      default: function() {
        return []
      }
    },
    objectTextField: {
      type: String,
      default: ''
    },
    objectValueField: {
      type: String,
      default: ''
    },
    selectorClass: {
      type: String,
      default: ''
    },
    selectorType: {
      type: String,
      default: ''
    },
    selectedOption: {
      type: [String, Number],
      default: ''
    },
    validationRules: {
      type: Object,
      default: () => {
        return {}
      },
      note: 'The validation rule(s). Preset to required.'
    },
    errorMessages: {
      type: Object,
      default: () => {
        return {}
      },
      note: 'The validation error messages'
    }
  },
  data() {
    return {
      type: 'select',
      availableOptions: []
    }
  },
  computed: {
  },

  watch: {
  },
  created() {
    this.setValue({ value: this.selectedOption })
  },

  methods: {
    getoptionText(option) {
      let output = ''
      let text = this.objectTextField ? option[this.objectTextField] : option
      switch (this.selectorType) {
        case 'time':
          output = this.$options.filters.minutesToTime(text)
          break;

        default:
          output = text
          break;
      }
      return output
    },

    isSelected(option) {
      return String((this.objectValueField ? option[this.objectValueField] : option)) === String(this.fieldValue)
    },

    getOptionValue(option) {
      return this.objectValueField ? option[this.objectValueField] : option
    }
  },
}
</script>
