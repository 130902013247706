const state = () => ({
  // The number of API requests being processed
  busy: 0,
})

const getters = {
  isBusy: (state) => state.busy > 0,
}

const mutations = {
  /**
   * Keep track of the number of API interactions being processed
   */
  increaseBusyState: (state) => {
    state.busy = state.busy + 1
  },
  decreaseBusyState: (state) => {
    state.busy = Math.max(0, state.busy - 1)
  },
}

const actions = {
}


/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}