<template>
  <div class="LocationSpaceMS">
    <figure
      ref="locationSpaceImage"
      @click="showSpaceInformation"
      class="LocationSpaceMS__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          space.SpaceImage,
          selectedLocationId,
          dynamicSize
        )}')`,
      }"
    ></figure>
    <div class="LocationSpaceMS__detail_price">
      <div class="LocationSpaceMS__details has-padding">
        <div
          class="LocationSpaceMS__title_size"
          :class="{ 'has-margin-bottom': !upsellItems.length }"
        >
          <div
            :class="`spaceName is-size-5 has-text-weight-bold`"
            v-html="space.SpaceName"
          ></div>
          <span
            @click="showSpaceInformation"
            class="spaceSize"
            :class="`${$classPrefix}tag is-info`"
            v-html="`${space.M2}m2`"
          ></span>
        </div>

        <UpsellTemplate
          class="has-margin-bottom"
          v-if="upsellItems.length"
          :upsellItems="upsellItems"
          :hideIcon="true"
        />

        <div
          class="LocationSpaceMS__price"
          :class="{ 'has-margin-bottom': includedOptions.length }"
          v-if="widgetState.ShowPrices"
        >
          <span
            :alt="pricingMethod"
            :title="pricingMethod"
          >
            <strong
              class="has-text-weight-bold is-size-5"
              v-html="
                $options.filters.toCurrency(
                  space.Price,
                  'nl-NL',
                  space.CurrencyIso)"
            ></strong>
            {{ pricingMethodShortner }}</span>
          <div
            class="LocationSpaceMS__priceExplanation is-size-7"
            v-text="$t(`Components.LocationSpaces.LocationSpace.Text_PriceTotal`, {
              price: $options.filters.toCurrency(space.PriceTotal, 'nl-NL', space.CurrencyIso)
            }) + ' ' + $t(`Components.LocationSpaces.LocationSpace.Text_ExcludingOptions`).toLowerCase()"
          ></div>
        </div>

        <div v-if="includedOptions.length">
          <div class="has-text-weight-bold">Inclusief:</div>
          <UpsellTemplate :upsellItems="includedOptions" />
        </div>
      </div>

      <div class="has-background-info-light has-padding">
        <div class="has-margin-bottom has-text-weight-bold">
          {{ $t(`Components.LocationSpaces.LocationSpace.Text_PickSetting`) }}
        </div>
        <div class="LocationSpaceMS__settings-book">
          <div class="LocationSpaceMS__settings">
            <div
              v-for="setting in space.Settings"
              :key="space.SpaceId+'setting'+setting.SettingId"
              class="LocationSpaceMS__settings__settingContainer"
            >
              <a
                @click="setChosenSetting(setting)"
                class="LocationSpaceMS__settings__setting is-inline-flex has-text-centered is-success"
                :class="[
              `${$classPrefix}button`,
              {'is-active': chosenSetting && chosenSetting.SettingId === setting.SettingId }, 
              {'is-disabled': isBusy && !chosenSetting }, 
              {'is-loading': isBusy && chosenSetting && chosenSetting.SettingId === setting.SettingId}]"
              >
                <SvgSetting
                  :key="'lrs'+setting.SettingId"
                  aria-hidden="true"
                  :settingId="setting.SettingId"
                  size="is-medium"
                />
                <div
                  class="LocationSpaceMS__settings__setting__name is-size-7"
                  v-html="
                  $t(
                    `Settings.Label_Setting${setting.SettingId}`
                  )
                "
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus/EventBus'
import SvgSetting from '@/components/UI/Settings/SvgSetting'
import UpsellTemplate from '@/components/UI/UpsellTemplate'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'LocationSpace',
  components: {
    SvgSetting,
    UpsellTemplate,
  },
  props: {
    space: {
      type: Object,
      required: true,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDetails: false,
      horizontal: true,
      longTitle: false,
      fullWidthTitle: false,
      hasChosenSetting: false,
      chosenSetting: null,
      dynamicSize: '640',
    }
  },

  computed: {
    ...mapState('widgetStore', ['widgetState',]),
    ...mapState('availabilityStore', ['selectedSpaceId', 'selectedLocationId']),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapGetters('searchStore', ['LastStep', 'DisableNextButton']),

    title() {
      return this.space.SpaceName
    },
    /**
     * The M2 size of the Space. This value is hidden if 0
     */
    squareMeters() {
      return this.space.M2 === 0 ? null : this.space.M2
    },

    /**
     * The pricing method is always the same for all spaces in a location
     */
    pricingMethod() {
      if (!this.space.CalculationType) {
        return null
      }

      switch (this.space.CalculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeat')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnly')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPerson')
      }

      return null
    },

    pricingMethodShortner() {
      if (!this.space.CalculationType) {
        return null
      }

      switch (this.space.CalculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeatShortner')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnlyShortner')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPersonShortner')
      }

      return null
    },

    upsellItems() {
      let output = this.space.Features.map((el) => el.Body)
      return output
    },

    includedOptions() {
      let output = this.space.IncludedOptions.map((el) => el.OptionName)
      return output
    },

    pricePerSeat() {
      return this.space.Settings ? this.space.Settings[0].PricePerSeat : null
    },

    priceTotal() {
      return this.space.Settings ? this.space.Settings[0].PriceTotal : null
    },

    /**
     * The space description, if it has one
     */
    description() {
      return this.space.SpaceDescription
    },
    /**
     * The details element is provided with additional classes in the mounted lifecycle method based on the length of the title
     */
    detailClassList() {
      return {
        'LocationSpace__details--long': this.longTitle,
        'LocationSpace__details--full': this.fullWidthTitle,
      }
    },

    showButtonLoader() {
      return this.selectedSpaceId === this.space.SpaceId
    }
  },

  mounted() {
    EventBus.$on('windowResizeListener', this.trackImageSize)
    this.trackImageSize()
  },

  beforeDestroy() {
    EventBus.$off('windowResizeListener', this.trackImageSize)
  },

  methods: {
    ...mapActions('searchStore', ['goToStep']),
    ...mapActions('cartStore', ['createCart']),
    ...mapMutations('availabilityStore', ['setSelectedSpaceAndSetting']),
    ...mapMutations('cartStore', ['increaseBusyState', 'decreaseBusyState']),
    ...mapMutations('searchStore', ['disableNextStep', 'enableNextStep']),

    /**
     * Track image size
     */
    trackImageSize() {
      if (this.$refs.locationSpaceImage) {
        if (this.$refs.locationSpaceImage.offsetWidth > 700) {
          this.dynamicSize = 0
        } else if (
          this.$refs.locationSpaceImage.offsetWidth > 320 ||
          this.$refs.locationSpaceImage.offsetHeight > 250
        ) {
          this.dynamicSize = 640
        } else {
          this.dynamicSize = 320
        }
      }
    },

    async setChosenSetting(setting) {
      if (this.isBusy || this.DisableNextButton) { return }

      this.increaseBusyState()
      this.chosenSetting = setting

      this.setSelectedSpaceAndSetting({
        SpaceId: this.chosenSetting.SpaceId,
        SettingId: this.chosenSetting.SettingId,
      })

      await this.createCart()

      await this.goToStep({
        step: this.LastStep + 1
      })
      this.disableNextStep()

      this.decreaseBusyState()
      this.enableNextStep()
    },

    /**
     * Show / hide the description
     */
    handleToggle() {
      this.showDetails = !this.showDetails
    },

    showSpaceInformation() {
      this.$emit('showDrawer', this.space)
    },
  },
}
</script>
