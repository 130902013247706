var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.registerSucceed)?_c('div',{ref:"registerForm",staticClass:"registration-from"},[_c('div',{staticClass:"is-4",class:`${_vm.$classPrefix}title`,domProps:{"innerHTML":_vm._s(_vm.$t('Components.ForgotLoginRegister.Register.Main_Title'))}}),_c('div',{staticClass:"has-margin-bottom",class:`${_vm.$classPrefix}content`,domProps:{"innerHTML":_vm._s(_vm.explanationText)}}),(_vm.formError)?_c('Message',{attrs:{"type":"is-danger"}},[_c('ul',{staticClass:"is-marginless"},[(!_vm.emailAlreadyExists)?_c('li',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'Components.ForgotLoginRegister.Register.Message_NotAllFilledIn'
            )
          )}}):_vm._e(),(_vm.emailAlreadyExists)?_c('li',{domProps:{"innerHTML":_vm._s(
            _vm.$t('Components.ForgotLoginRegister.Register.Message_EmailExists')
          )}}):_vm._e()])]):_vm._e(),_c('Form',{ref:"form",attrs:{"onSubmitCallback":_vm.handleSubmit},on:{"onStatusChange":_vm.onStatusChange}},[_c('RequiredField',{attrs:{"name":"firstName","label":_vm.$t('Components.ForgotLoginRegister.Register.Label_FirstName'),"required":""}}),_c('RequiredField',{attrs:{"name":"lastName","label":_vm.$t('Components.ForgotLoginRegister.Register.Label_LastName'),"required":""}}),_c('PhoneNumberField',{attrs:{"required":_vm.isPhoneNumberRequired,"label":_vm.$t('Components.ForgotLoginRegister.Register.Label_Phone')}}),_c('EmailField',{attrs:{"name":"email","label":_vm.$t('Components.ForgotLoginRegister.General.Label_EmailAddress'),"validationRules":_vm.emailValidation,"errorMessages":_vm.emailErrorMessages,"inputSizeClass":_vm.emailAlreadyExists ? 'is-danger' : '',"required":""}}),_c('PasswordField',{attrs:{"name":"password","validateOnInput":true,"showAllOpenErrors":true,"validationRules":_vm.passwordValidationRules,"errorMessages":_vm.passwordErrorMessages}}),(_vm.isWorkspaceBooking)?_c('BaseFieldTemplate',{scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('TagsField')]},proxy:true}],null,false,3709961178)}):_vm._e(),_c('BaseFieldTemplate',{staticClass:"has-margin-bottom",scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('PrivacyTerms',{attrs:{"name":"privacyTerms","showOnPage":false,"required":true},on:{"termsData":_vm.processTerms}})]},proxy:true}],null,false,123183601)})],1),_c('div',{class:`${_vm.$classPrefix}level is-mobile`},[_c('div',{class:`${_vm.$classPrefix}level-left`}),_c('div',{class:`${_vm.$classPrefix}level-right`},[_c('div',{class:`${_vm.$classPrefix}level-item`},[_c('a',{staticClass:"is-success",class:[`${_vm.$classPrefix}button`, { 'is-loading': _vm.isProcessing }],domProps:{"innerHTML":_vm._s(
              _vm.$t('Components.ForgotLoginRegister.Register.Button_Register')
            )},on:{"click":_vm.createProfile}})])])]),_c('hr'),_c('div',[_c('a',{class:`has-icon`,on:{"click":function($event){return _vm.handleAuthNavigate({ page: 'login' })}}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-left']}})],1),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('Components.ForgotLoginRegister.General.Link_BackToLogin')
          )}})])])],1):_vm._e(),(_vm.registerSucceed)?_c('AuthPinValidationForm',{key:"AuthPinValidationForm",attrs:{"verificationKey":_vm.verificationKey,"email":_vm.registerEmailAddress}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }