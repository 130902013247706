<template>
  <transition-group name="fade" mode="out-in">
    <div
      key="sfPopupBackground"
      :class="`${$classPrefix}widget ${$classPrefix}sfPopup-background`"
    ></div>
    <div
      key="sfPopup"
      :ref="refName"
      :class="`${$classPrefix}widget ${$classPrefix}sfPopup ${nrOfColumnsCssClass}`"
    >
      <div :class="`${$classPrefix}card`">
        <div :class="`${$classPrefix}card-content`">
          <div
            :class="`has-text-weight-bold is-size-5 has-margin-bottom-half`"
            v-text="$t('Components.SearchFormPopup.Main_Title')"
          ></div>
          <div v-if="showResetLink" class="has-margin-bottom-half">
            <a
              @click="resetCountryAndCitySelection()"
              v-text="$t('Components.SearchFormPopup.Link_clearCoutriesPlaces')"
            ></a>
          </div>
          <div :class="`${$classPrefix}columns is-mobile is-gapless`">
            <div v-if="showCountrySelect" :class="`${$classPrefix}column`">
              <div :class="`${$classPrefix}list list-countries`">
                <div
                  :class="[
                    `${$classPrefix}list-item is-unselectable`,
                    { 'is-active': countryCode === '' },
                  ]"
                  @click="setCountry()"
                  :key="`c_notSet`"
                  v-text="$t('Components.SearchFormPopup.Option_AllCountries')"
                ></div>
                <div
                  v-for="country in countries"
                  :key="`c_${country.Id}_${country.CountryCode}`"
                  class="country-item"
                  :class="[
                    `${$classPrefix}list-item is-unselectable`,
                    { 'is-active': countryCode === country.CountryCode },
                  ]"
                  @click="setCountry(country)"
                  v-text="country.Name"
                ></div>
              </div>
            </div>

            <div v-if="showSearchInput" :class="`${$classPrefix}column`">
              <nav :class="`${$classPrefix}panel-locations is-shadowless`">
                <div :class="`${$classPrefix}panel-block is-paddingless`">
                  <div :class="`${$classPrefix}control`">
                    <input
                      ref="searchInput"
                      :class="`${$classPrefix}input`"
                      autocomplete="off"
                      aria-autocomplete="both"
                      type="text"
                      :placeholder="placeholder"
                      v-model.trim="searchTerm"
                      @input="delay(getAutoCompleteItems, 200)"
                    />
                  </div>
                </div>
                <div
                  v-if="autoCompleteItems.length"
                  :class="`${$classPrefix}list panel-list-items`"
                >
                  <transition-group name="fadd" mode="out-in">
                    <div
                      key="searchAll"
                      :class="[
                        `${$classPrefix}list-item is-unselectable`,
                        { 'is-active': isSelectedAcOption({ Type: -1 }) },
                      ]"
                      @click="resetCitySelection()"
                      v-text="$t('Components.SearchFormPopup.Link_clearPlaces')"
                    ></div>
                    <div
                      v-for="acOption in autoCompleteItems"
                      :key="`a_${acOption.Result}`"
                      :class="[
                        `${$classPrefix}list-item is-unselectable`,
                        { 'is-active': isSelectedAcOption(acOption) },
                      ]"
                      @click="[selectAutoCompleteOption(acOption), apply()]"
                      v-html="
                        !acOption.Id
                          ? $t(
                              'Components.SearchFormPopup.Label_AllLocationIn',
                              { city: acOption.Result }
                            )
                          : acOption.Result
                      "
                    ></div>
                  </transition-group>
                </div>
                <Message
                  class="has-margin-top"
                  v-if="!autoCompleteItems.length && !isFirstLoad"
                  >{{ message }}</Message
                >
              </nav>
            </div>
          </div>
        </div>
        <footer :class="`${$classPrefix}card-footer`">
          <a
            @click="hide()"
            :class="`${$classPrefix}card-footer-item`"
            v-text="$t('General.Text_Cancel')"
          >
          </a>
          <a
            @click="apply()"
            :class="[` ${$classPrefix}card-footer-item has-text-weight-bold`]"
            v-text="$t('General.Text_Apply')"
          >
          </a>
        </footer>
      </div>
    </div>
  </transition-group>
</template>

<script>
import EventBus from "@/eventbus/EventBus";
import { mapGetters, mapMutations, mapState } from "vuex";
import { getAutoCompleteItems } from "../../providers/autocomplete";
import Message from "@/components/UI/Message";
export default {
  name: "SearchFormPopup",

  props: {
    target: {
      default: null,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  components: { Message },

  data() {
    return {
      refName: `searchFormPopup${this.target}`,
      trigger: document.getElementById(this.target),
      refSearchForm: null,
      isFirstLoad: true,
      autoCompleteItems: [],
      timer: null,
      searchTerm: "",
      locationId: 0,
      countryId: 0,
      countryCode: "",
      ac_selectedOption: "",
    };
  },

  computed: {
    ...mapState("widgetStore", ["widgetState"]),
    ...mapState("searchStore", ["search"]),
    ...mapState("countryStore", ["countries"]),
    ...mapGetters("widgetStore", ["showSearchInput", "showCountrySelect"]),

    nrOfColumnsCssClass() {
      return [this.showSearchInput, this.showCountrySelect].filter(Boolean)
        .length > 1
        ? "has-multiple-columns"
        : "has-one-column";
    },

    selectedCountry() {
      return this.search.CountryCode ?? "";
    },

    showResetLink() {
      return this.showCountrySelect && this.showSearchInput;
    },

    message() {
      let meetingTypeText = this.$t(
        `App.SearchWidget.BookingTypes.${this.search.MeetingtypeId}`
      ).toLowerCase();
      let output = this.$t(
        "Components.SearchFormPopup.Message_NoLocationsBasedOnCountry",
        { type: meetingTypeText }
      );
      if (this.searchTerm) {
        output = this.$t(
          "Components.SearchFormPopup.Message_NoLocationsBasedOnSearch",
          { type: meetingTypeText }
        );
      }
      return output;
    },
  },

  created() {
    this.searchTerm = this.search.SearchTerm;
    this.locationId = this.search.LocationId;
    this.countryId = this.search.CountryId;
    this.countryCode = this.search.CountryCode;
    this.getAutoCompleteItems();
  },

  mounted() {
    let self = this;

    // Append modal to body
    document.body.appendChild(this.$el);
    let t = setTimeout(() => {
      this.$refs.searchInput && this.$refs.searchInput.focus();
      document.addEventListener("scroll", self.scrollHandler, true);
      EventBus.$on("windowResizeListener", self.scrollHandler);
      self.refSearchForm = self.$refs[self.refName];
      self.scrollHandler();
      clearTimeout(t);
    }, 75);
  },

  beforeDestroy() {
    this.resetBeforeDestroy();
  },

  methods: {
    ...mapMutations("searchStore", [
      "setCountryId",
      "setCountryCode",
      "setSearchLocationId",
      "setSearchTerm",
    ]),

    resetBeforeDestroy() {
      // Remove listeners
      removeEventListener("scroll", this.scrollHandler);
      EventBus.$off("windowResizeListener", this.scrollHandler);

      // Remove node from body
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },

    /**
     * Popup controls
     */
    apply() {
      this.setCountryId(this.countryId);
      this.setCountryCode(this.countryCode);
      this.setSearchLocationId(this.locationId);
      this.setSearchTerm(this.searchTerm);
      this.hide();
    },

    hide() {
      this.resetBeforeDestroy();
      this.$emit("hide");
    },

    resetCountryAndCitySelection() {
      this.setCountry();
    },
    resetCitySelection() {
      this.locationId = 0;
      this.searchTerm = "";
      this.getAutoCompleteItems();
    },

    /**
     * Update popup location on scroll
     */
    scrollHandler() {
      // Check if datePickerRects is not undefined. After second time opening the date picker this issue occuers
      const viewPortWidth = window.innerWidth;
      const viewPortHeight = window.innerHeight;
      const triggerRects = this.trigger.getBoundingClientRect();
      const datePickerRects = this.refSearchForm.getBoundingClientRect();
      const vSpaceGap = 5;
      const hSpaceGap = 15;

      // Position date picker above or below the input
      this.refSearchForm.style.top = `${
        triggerRects.top + triggerRects.height + vSpaceGap
      }px`;

      if (
        viewPortHeight -
          (triggerRects.top +
            triggerRects.height +
            vSpaceGap +
            datePickerRects.height) <
          0 &&
        triggerRects.top - vSpaceGap - datePickerRects.height > 0
      ) {
        // Place the date picker above input
        this.refSearchForm.style.top = `${
          triggerRects.top - vSpaceGap - datePickerRects.height
        }px`;
      }

      // Position date picker vertical in the center of the trigger element
      this.refSearchForm.style.left = `${
        triggerRects.left - datePickerRects.width / 2 + triggerRects.width / 2
      }px`;

      if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 -
          hSpaceGap <=
        0
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the left of the screen
        this.refSearchForm.style.left = `${hSpaceGap}px`;
      } else if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 +
          datePickerRects.width +
          hSpaceGap >=
        viewPortWidth
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the right of the screen
        this.refSearchForm.style.left = null;
        this.refSearchForm.style.right = `${hSpaceGap}px`;
      }
    },

    setCountry(country = null) {
      this.countryId = country ? country.Id : 0;
      this.countryCode = country ? country.CountryCode : "";
      this.searchTerm = "";
      this.locationId = 0;
      this.getAutoCompleteItems();
    },

    delay(callback, ms) {
      let self = this;

      if (self.timer) {
        clearTimeout(self.timer);
      }

      self.timer = setTimeout(() => {
        callback.call(self);
        clearTimeout(self.timer);
      }, ms || 0);
    },

    isSelectedAcOption(acOtpion = null) {
      if (
        acOtpion.Type === -1 &&
        this.searchTerm === "" &&
        this.locationId === 0
      ) {
        return true;
      }
      return acOtpion.Type === 1
        ? acOtpion.Result.trim().toLowerCase() === this.searchTerm.toLowerCase()
        : acOtpion.Id === this.locationId;
    },

    selectAutoCompleteOption(acOption = null) {
      this.locationId = 0;
      if (acOption !== null) {
        this.searchTerm = acOption.Result.trim();
        if (acOption.Type === 0) {
          this.locationId = acOption.Id;
        }
      } else {
        acOption = this.autoCompleteItems.find(
          (ac) =>
            ac.Result.trim().toLowerCase() === this.searchTerm.toLowerCase()
        );
        if (typeof acOption !== "undefined") {
          if (acOption.Type === 0) {
            this.locationId = acOption.Id;
          }
        }
      }
    },

    async getAutoCompleteItems() {
      this.locationId = 0;
      await getAutoCompleteItems({
        q: this.searchTerm,
        channelId: this.search.ChannelId,
        countryId: this.countryId,
        locationId: this.locationId,
        meetingtypeId: this.search.MeetingtypeId,
      })
        .then((response) => {
          this.autoCompleteItems = this.searchTerm
            ? response.data
            : response.data.filter((ac) => ac.Type === 1);
          this.selectAutoCompleteOption();
        })
        .finally(() => {
          this.isFirstLoad = false;
        });
    },
  },
};
</script>
