export function cleanSource(source) {
  // using native JSON functions removes reactivity
  // so we can clone an object without mutating the original source
  return JSON.parse(JSON.stringify(source))
}

/**
 * Group doubles
 * @param {array} source
 * @param {string} key
 */
export function groupDoubles(source, key) {
  return Array.from(
    source
      .reduce(
        (m, o) =>
          m.set(o[key], (m.get(o[key]) || []).concat(o)),
        new Map()
      )
      .values()
  )
}

/**
 * https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
 */
export function sortAscending(source, key) {
  if(!source.length) { return source }
  return source.sort((a,b)=> (a[key] > b[key] ? 1 : -1))
}

export function sortDecending (source, key) {
  if(!source.length) { return source }
  return source.sort((a,b)=> (a[key] < b[key] ? 1 : -1))
}