<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader
        v-if="isLoadingOptions"
        key="loader"
      />
      <div
        v-else
        key="optionLists"
      >
        <div v-if="tabs.length === 0">
          <Message class="is-info">
            {{ $t('App.AvailableOptions.Text_LocationHasNoOptions') }}
          </Message>

          <NextButton
            v-if="!showMobileFooterBar"
            :class="[
                    `${$classPrefix}button is-success is-pulled-right`,
                    { 'is-loading': isBusy },
                  ]"
            :disabled="isBusy"
            v-text="$t('General.Text_NextStep')"
          />
        </div>
        <div
          v-else
          class="TabsWrapper"
        >
          <div :class="`${$classPrefix}tabs is-boxed has-margin-bottom`">
            <ul>
              <li
                v-for="(tab, index) in tabs"
                :key="'t_' + index"
                :class="{'is-active': activeTabIndex === index}"
              >
                <a @click="activeTabIndex = index">
                  <span v-html="tab.label"></span>
                </a>
              </li>
              <li
                v-if="!showMobileFooterBar"
                class="tab-next-step"
              >
                <NextButton
                  :class="[
                    `${$classPrefix}button is-success is-pulled-right`,
                    { 'is-loading': isBusy },
                  ]"
                  :disabled="isBusy"
                  v-text="$t('General.Text_NextStep')"
                />
              </li>
            </ul>
          </div>

          <component
            :key="'tc_' + activeTabIndex"
            :is="tabs[activeTabIndex].component"
          />
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import AvailableOptions from '@/components/Options/AvailableOptions/AvailableOptions'
import AvailablePackages from '@/components/Options/AvailablePackages/AvailablePackages'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Loader from '../UI/Loader.vue'
import NextButton from '../Navigation/NextButton.vue'
import Message from '@/components/UI/Message'


export default {
  name: 'OptionLists',

  props: {
    tag: {
      type: String,
      default: 'a'
    }
  },

  components: {
    AvailableOptions,
    AvailablePackages,
    Loader,
    NextButton,
    Message

  },

  data() {
    return {
      isLoadingOptions: true,
      activeTabIndex: 0
    }
  },

  computed: {
    ...mapState(['showMobileFooterBar']),
    ...mapGetters('cartStore', ['availableOptions', 'availablePackages', 'CartKey', 'isBusy']),

    // Build tabs
    tabs() {
      let tabs = []

      // If location have options
      if (this.availableOptions.length) {
        tabs.push({
          label: this.$t('Components.OptionLists.Tab_Amenities'),
          component: AvailableOptions
        })
      }

      // If location have packages
      if (this.availablePackages.length) {
        tabs.push({
          label: this.$t('Components.OptionLists.Tab_Packages'),
          component: AvailablePackages
        })
      }

      return tabs
    }
  },

  async created() {
    // The animation that loads this component is triggered at the same time as the API call to create a cart
    // If the response is available within 300ms (the page transition time), we can move ahead immediately, otherwise we have to wait
    if (this.CartKey) {
      this.isLoadingOptions = true
      await this.getAvailableOptions()
      await this.getAvailablePackages()
      this.enableNextStep()
      this.isLoadingOptions = false
    } else {
      this.delayedGetOptions()
    }
  },

  methods: {
    ...mapMutations('searchStore', ['enableNextStep']),
    ...mapActions('cartStore', ['getAvailableOptions', 'getAvailablePackages']),

    /**
     * Get option lists as soon as the cart key is available
     */
    delayedGetOptions() {
      let unwatch = this.$watch('CartKey', async function() {
        this.isLoadingOptions = true
        await this.getAvailableOptions()
        await this.getAvailablePackages()
        this.enableNextStep()
        this.isLoadingOptions = false
        if (unwatch) {
          unwatch()
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>