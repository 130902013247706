<template>
  <div>
    <div
      :class="`${$classPrefix}title is-3`"
      v-html="$t('Components.VoucherForm.Main_Title')"
    ></div>
    <transition-expand>
      <Message
        v-if="voucherMessageCode !== null"
        :class="messageClass"
      >
        <div class="has-icon">
          <span
            v-if="voucherMessageCode === 1 || voucherMessageCode === 2"
            :class="`${$classPrefix}icon`"
          >
            <font-awesome-icon
              :icon="['far', 'check']"
              class="has-text-success"
            />
          </span>
          <span v-text="$t(`Components.VoucherForm.Message_StatusCode_${voucherMessageCode}`, { name: voucherName })"></span>
        </div>
      </Message>
    </transition-expand>

    <transition-expand>
      <div
        v-if="!cart.VoucherId"
        :class="`${$classPrefix}field has-addons`"
      >
        <div :class="`${$classPrefix}control`">
          <input
            v-model="voucherCode"
            type="text"
            :class="`${$classPrefix}input`"
            @keyup.enter="handleClick"
            :placeholder="$t('Components.VoucherForm.Placeholder_Code')"
          />
        </div>
        <div :class="`${$classPrefix}control`">
          <a
            :class="[`${$classPrefix}button`, isBusy ? 'is-light' : 'is-success']"
            @click="handleClick"
          >
            <span :class="`${$classPrefix}icon`">
              <font-awesome-icon :icon="['fal', 'plus']" />
            </span>
          </a>
        </div>
      </div>
    </transition-expand>
    <transition-expand>
      <div v-if="cart.VoucherId > 0">
        <a
          key="removeSelected"
          class="has-text-danger has-icon"
          title="remove this arrangement or voucher"
          @click="handleClick"
        >
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fad', 'trash']" />
          </span>
          <span v-text="$t('Components.VoucherForm.Link_RemoveVoucher')"></span>
        </a>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'
import TransitionExpand from '../TransitionExpand'

export default {
  name: 'VoucherForm',

  components: {
    Message,
    TransitionExpand,
  },

  data() {
    return {
      voucherMessageCode: null,
      voucherCode: '',
      timer: null,
      isProcessing: false
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapGetters('cartStore', ['isBusy']),

    voucherName() {
      return this.cart.VoucherName ?? ''
    },

    messageClass() {
      let output = 'is-info'
      if (this.voucherMessageCode === 1 || this.voucherMessageCode === 2) {
        output = 'is-success'
      } else if (this.voucherMessageCode <= 0) {
        output = 'is-warning'
      }

      return output
    }
  },

  methods: {
    ...mapActions('cartStore', ['applyVoucherToCart', 'removeVoucherFromCart', 'saveCart']),

    async handleClick() {
      if (this.isProcessing) { return }

      this.isProcessing = true
      // Add voucher
      if (!this.cart.Voucher && this.voucherCode) {
        await this.saveCart()
        let response = await this.applyVoucherToCart(this.voucherCode)
        this.voucherMessageCode = response.data.StatusCode
        this.voucherCode = ''
      }
      // Remove voucher
      else if (this.cart.Voucher) {
        await this.removeVoucherFromCart(this.cart.VoucherId)
        this.voucherMessageCode = 2
      }
      this.isProcessing = false

      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.voucherMessageCode = null
        clearTimeout(this.timer)
      }, 5000);
    }
  },
}
</script>