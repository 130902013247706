<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <BaseForm
        ref="form"
        v-if="!requestIsSent"
        key="requestForm"
        autocomplete="off"
        class="form-tender has-margin-bottom-x2"
        :onSubmitCallback="handleSubmit"
        @onStatusChange="onStatusChange"
      >
        <div :class="`${$classPrefix}columns is-multiline`">
          <div :class="`${$classPrefix}column is-half`">
            <div
              :class="`${$classPrefix}title is-4`"
              v-html="$t('Components.TenderForm.Header_BookingInformation')"
            ></div>
            <div :class="`${$classPrefix}columns is-multiline is-gapless`">
              <div :class="
                  `${$classPrefix}column is-full-tablet is-two-thirds-desktop`
                ">
                <BaseFieldTemplate :label="$t('Components.TenderForm.Label_Date')">
                  <template v-slot:fields>
                    <DateTimeInput
                      :key="`${datePickerMode}DateSelector`"
                      :dates="dates"
                      :startMinutes="currentSearch.StartMinutes"
                      :endMinutes="currentSearch.EndMinutes"
                      :mode="datePickerMode"
                      :use24HourTimeSlot="true"
                      dateFormat="longDateFormat"
                      @action="updateDateTime"
                    />
                  </template>
                </BaseFieldTemplate>
              </div>
              <div :class="`${$classPrefix}column is-one-third`">
                <RequiredField
                  :label="$t('Components.TenderForm.Label_NumberOfPeople')"
                  name="people"
                  v-model="numberOfPeople"
                />
              </div>
            </div>

            <div
              :class="`${$classPrefix}title is-4`"
              v-html="$t('Components.TenderForm.Header_Contact')"
            ></div>
            <div :class="`${$classPrefix}columns is-multiline is-gapless`">
              <div :class="`${$classPrefix}column is-one-third`">
                <RequiredField
                  name="company"
                  :label="$t('Components.TenderForm.Label_Company')"
                />
              </div>
              <div :class="`${$classPrefix}column is-one-third`">
                <RequiredField
                  name="firstName"
                  :label="$t('Components.TenderForm.Label_FirstName')"
                />
              </div>
              <div :class="`${$classPrefix}column is-one-third`">
                <RequiredField
                  name="lastName"
                  :label="$t('Components.TenderForm.Label_LastName')"
                />
              </div>
              <div :class="`${$classPrefix}column is-one-third`">
                <RequiredField
                  name="phone"
                  :label="$t('Components.TenderForm.Label_Phone')"
                />
              </div>
              <div :class="`${$classPrefix}column is-two-thirds`">
                <RequiredField
                  name="email"
                  :label="$t('Components.TenderForm.Label_EmailAddress')"
                />
              </div>
            </div>
          </div>

          <div :class="`${$classPrefix}column is-half`">
            <div
              :class="`${$classPrefix}title is-4`"
              v-html="$t('Components.TenderForm.Header_YourMeeting')"
            ></div>
            <div
              :class="`${$classPrefix}content`"
              v-html="$t('Components.TenderForm.Text_YourMeeting')"
            ></div>

            <SelectField
              class="is-shadowless"
              name="eventType"
              selectorClass="is-fullwidth"
              :label="$t('Components.TenderForm.Label_MeetingType')"
              :options="eventTypes"
              :selectedOption="0"
              objectValueField="Id"
              objectTextField="Name"
            >
              <option
                value="0"
                v-html="$t('App.EventTypes.Text_Id_0')"
              ></option>
            </SelectField>

            <BaseFieldTemplate
              :label="$t('Components.TenderForm.Label_Options')"
              class="tender-options"
            >
              <template v-slot:fields>
                <div class="wrapper-options">
                  <Checkbox
                    name="beamer"
                    :label="$t('Components.TenderForm.Label_OptionBeamer')"
                  />
                  <Checkbox
                    name="flipover"
                    :label="$t('Components.TenderForm.Label_OptionFlipover')"
                  />
                  <Checkbox
                    name="cwt"
                    :label="$t('Components.TenderForm.Label_OptionCWT')"
                  />
                  <Checkbox
                    name="lunch"
                    :label="$t('Components.TenderForm.Label_OptionLunch')"
                  />
                  <Checkbox
                    name="soda"
                    :label="$t('Components.TenderForm.Label_OptionSoda')"
                  />
                </div>
              </template>
            </BaseFieldTemplate>

            <BaseFieldTemplate :label="$t('Components.TenderForm.Label_MakeUseOfHybridMeeting')">
              <template v-slot:fields>
                <Checkbox
                  name="hybridMeeting"
                  :label="
                    $t(
                      'Components.TenderForm.Label_Checkbox-MakeUseOfHybridMeeting'
                    )
                  "
                />
              </template>
            </BaseFieldTemplate>

            <BaseFieldTemplate :label="$t('Components.TenderForm.Label_Comments')">
              <template v-slot:fields>
                <TextareaField
                  :label="` `"
                  name="comment"
                />
              </template>
            </BaseFieldTemplate>
          </div>

          <div v-if="!preSelectedLocation" :class="`${$classPrefix}column is-full`">
            <div
              :class="`${$classPrefix}title is-4`"
              v-html="$t('Components.TenderForm.Header_SelectLocations')"
            ></div>
            <div :class="`${$classPrefix}columns`">
              <div :class="`${$classPrefix}column is-half`">
                <div :class="`${$classPrefix}field has-addons`">
                  <div :class="`${$classPrefix}control is-expanded`">
                    <input
                      autocomplete="off"
                      aria-autocomplete="both"
                      :class="`${$classPrefix}input`"
                      :placeholder="
                        $t('App.SearchWidget.Placeholder_SearchTerm')
                      "
                      type="text"
                      v-model="inputSearchLocation"
                      v-on:keydown.enter.prevent="searchLocations"
                    />
                  </div>
                  <div :class="`${$classPrefix}control`">
                    <a
                      :class="`${$classPrefix}button is-success`"
                      v-html="$t('General.Text_Search')"
                      @click="searchLocations()"
                    ></a>
                  </div>
                </div>
                <div :class="`${$classPrefix}box`" class="wrapper-found-locations">
                  <ul v-if="!isLoading && !noLocationsFound">
                    <li
                      class="is-flex"
                      v-for="(location, index) in filteredLocations"
                      :key="`fl${index}`"
                    >
                      <div class="">
                        <strong v-html="location.Name"></strong>
                        <div v-html="`${location.Address}, ${location.City}`"></div>
                      </div>
                      <a
                        v-if="selectedLocations.length < 5"
                        @click="addLocationToList(location)"
                        :class="`${$classPrefix}button is-small is-success`"
                      >
                        <span :class="`${$classPrefix}icon`">
                          <font-awesome-icon :icon="['fal', 'plus']" />
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div
                    v-else-if="!isLoading && noLocationsFound"
                    v-html="$t('Components.TenderForm.Text_NoLocationsFound')"
                  ></div>
                  <Loader
                    v-else
                    :height="100"
                  />
                </div>
              </div>
              <div :class="`${$classPrefix}column is-half`">
                <div :class="`${$classPrefix}title is-4`">
                  {{ $t('Components.TenderForm.Text_Header_ChosenLocations') }}
                  <span class="has-text-danger">*</span>
                </div>
                <div class="wrapper-selected-locations box">
                  <Message
                    v-if="!selectedLocations.length"
                    :class="{ 'is-danger': showErrorNoLocationSelected }"
                  >
                    {{ $t('Components.TenderForm.Message_NoLocationSelected') }}
                  </Message>
                  <ul v-else>
                    <li
                      class="is-flex"
                      v-for="(location, index) in selectedLocations"
                      :key="`sl${index}`"
                    >
                      <div class="">
                        <strong v-html="location.Name"></strong>
                        <div v-html="`${location.Address}, ${location.City}`"></div>
                      </div>
                      <a
                        @click="removeLocationFromList(location)"
                        :class="`${$classPrefix}icon has-text-danger`"
                      >
                        <font-awesome-icon :icon="['fad', 'trash']" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div :class="`${$classPrefix}column is-full`">
            <div :class="`${$classPrefix}level is-mobile`">
              <div :class="`${$classPrefix}level-left`"></div>
              <div :class="`${$classPrefix}level-right`">
                <div :class="`${$classPrefix}level-item`">
                  <button
                    :class="[`${$classPrefix}button`, { 'is-loading': isProcessing }]"
                    :disabled="isProcessing || selectedLocations.length === 0"
                    type="submit"
                    class="is-medium is-success has-margin-left"
                  >{{ $t('Components.TenderForm.Button_SendRequest') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseForm>
      <div
        v-else
        v-html="textRequestConfirmation"
        :class="`${$classPrefix}content`"
      ></div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseForm from '@/components/UI/Form/BaseForm'
import Checkbox from '@/components/UI/Form/Checkbox'
import DateTimeInput from '@/components/UI/Form/DateTimeInput'
import RequiredField from '@/components/UI/Form/RequiredField'
import SelectField from '@/components/UI/Form/SelectField'
import TextareaField from '@/components/UI/Form/TextareaField'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import Message from '@/components/UI/Message'
import Loader from '@/components/UI/Loader'
import { getAllEventTypes } from '../../providers/global'
import { getLocations } from '../../providers/location'
import { postTender } from '../../providers/tender'

export default {
  name: 'tender-form',

  components: {
    Loader,
    BaseForm,
    BaseFieldTemplate,
    Checkbox,
    DateTimeInput,
    Message,
    RequiredField,
    SelectField,
    TextareaField,
  },

  props: {
    /**
     * preSelectedLocation
     * Note:
     * Object requires the following fields
     * {
        Id: 0,
        Address: '',
        City: '',
        Name: '',
        Image: '',
        Phone: ''
        Email: ''
      }
    */
    preSelectedLocation: {
      type: [Object, Boolean],
      default: false,
    },
  },

  data() {
    return {
      isProcessing: false,
      isLoading: false,
      startDate: '',
      endDate: '',
      startMinutes: 0,
      endMinutes: 0,
      foundLocations: [],
      selectedLocations: [],
      searchLocationsTimer: null,
      noLocationsFound: false,
      inputSearchLocation: '',
      eventTypes: [],
      requestIsSent: false,
      formData: null,
      showErrorNoLocationSelected: false,
      formError: false,
      numberOfPeople: 0,
    }
  },

  computed: {
    ...mapState('searchStore', ['search', 'currentSearch']),
    ...mapState('widgetStore', ['timeslots', 'widgetState']),
    ...mapGetters('widgetStore', ['channel']),

    dates() {
      return this.currentSearch.MeetingtypeId !== 3
        ? this.$options.filters.dateObjectIsoDateString(
          this.currentSearch.StartDate
        )
        : {
          start: this.$options.filters.dateObjectIsoDateString(
            this.currentSearch.StartDate
          ),
          end: this.$options.filters.dateObjectIsoDateString(
            this.currentSearch.StartDate
          ),
        }
    },

    datePickerMode() {
      return this.currentSearch.MeetingtypeId != 3 ? 'single' : 'range'
    },

    filteredLocations() {
      let temp = []
      temp = this.foundLocations.filter((fl) => fl.Id > 0)

      let output = []
      if (temp.length && this.selectedLocations.length) {
        for (let i = 0; i < temp.length; i++) {
          let selectedLocationIndex = this.selectedLocations.findIndex(
            (sl) => sl.Id === temp[i].Id
          )

          if (selectedLocationIndex === -1) {
            output.push(temp[i])
          }
        }
      } else {
        output = temp
      }

      return output
    },

    textRequestConfirmation() {
      if (!this.requestIsSent) {
        return ''
      } else {
        let output =
          this.selectedLocations.length === 1
            ? this.$t(
              'Components.TenderForm.Text_RequestConfirmationSelectedLocation'
            )
            : this.$t('Components.TenderForm.Text_RequestConfirmation')
        output = output
          .replace('{firstName}', this.formData.firstName)
          .replace('{listOfLocations}', this.createListofLocations())
          .replace('{channelName}', this.channel.Channel.Name)
          .replace('{hostName}', this.channel.Channel.Name)
          .replace('{locationName}', this.selectedLocations[0].Name)
          .replace('{locationPhone}', this.selectedLocations[0].Phone)
          .replace('{locationEmail}', this.selectedLocations[0].Email)
        return output
      }
    },
  },

  created() {
    this.startDate = this.currentSearch.StartDate
    this.endDate = this.currentSearch.EndDate
    this.startMinutes = this.currentSearch.StartMinutes
    this.endMinutes = this.currentSearch.EndMinutes
    this.numberOfPeople = this.currentSearch.Seats
    this.getAllEvenTypes()
  },

  mounted() {
    if (this.preSelectedLocation) {
      this.selectedLocations.push(this.preSelectedLocation)
    }
  },

  methods: {
    // Create list of locations
    createListofLocations() {
      let output = '<ul>'

      for (let i in this.selectedLocations) {
        output += `<li>
          <strong>${this.selectedLocations[i].Name}</strong><br />
          ${this.selectedLocations[i].Address}, ${this.selectedLocations[i].City}
        </li>`
      }

      output += '</ul>'
      return output
    },

    // Get all event types
    async getAllEvenTypes() {
      let self = this
      await getAllEventTypes().then((response) => {
        self.processEventTypes(response.data)
      })
    },

    // Handle form submittion
    handleSubmit({ formData }) {
      this.formData = formData
      return new Promise((resolve, reject) => {
        if (!this.selectedLocations.length) {
          this.showErrorNoLocationSelected = true
          return reject({ response: 'INVALID' })
        }

        let locationIds = []
        for (let i in this.selectedLocations) {
          locationIds.push(this.selectedLocations[i].Id)
        }

        postTender({
          ChannelId: this.widgetState.ChannelId,
          MeetingtypeId: this.currentSearch.MeetingtypeId,
          StartDate: this.startDate,
          StartMinutes: this.startMinutes,
          EndDate: this.endDate,
          EndMinutes: this.endMinutes,
          Seats: formData.people,
          Lunch: formData.lunch,
          Ctw: formData.cwt,
          Soda: formData.soda,
          Beamer: formData.beamer,
          Flipover: formData.flipover,
          SearchId: this.currentSearch.Id,
          ProfileId: 0,
          ProfileFirstName: formData.firstName,
          ProfileLastName: formData.lastName,
          ProfileEmail: formData.email,
          ProfilePhone: formData.phone,
          CompanyId: 0,
          CompanyName: formData.company,
          SearchTerm: formData.company,
          LocationIds: locationIds,
          Comment: formData.comment,
        })
          .then(() => {
            return resolve({ response: 'COMPLETED' })
          })
          .catch(() => {
            return reject({ response: 'INVALID' })
          })
          .finally()
      })
    },

    // Form submit status
    onStatusChange({ status, form }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'VALID':
          this.formError = !form.isValid()
          break
        case 'INVALID':
          this.formError = form.isValid()
          this.showErrorNoLocationSelected = Boolean(
            !this.selectedLocations.length
          )
          this.isProcessing = false
          break
        case 'COMPLETED':
          this.isProcessing = false
          this.requestIsSent = true
          break
      }
    },

    // Process event types
    processEventTypes(result) {
      result.forEach(
        (element) =>
          (element.Name = this.$t(`App.EventTypes.Text_Id_${element.Id}`))
      )
      result.sort((a, b) => {
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1
      })
      this.eventTypes = result
    },

    /**
     * Update search date and time values
     */
    updateDateTime(val) {
      this.startDate = `${val.startDate}T00:00:00`
      this.endDate = `${val.endDate}T00:00:00`
      this.startMinutes = val.startMinutes
      this.endMinutes = val.endMinutes
    },

    // Remove location from send list
    removeLocationFromList(index) {
      this.selectedLocations.splice(index, 1)
    },

    // Add location to send list
    addLocationToList(location) {
      this.showErrorNoLocationSelected = false
      if(this.selectedLocations.length < 5) {
        this.selectedLocations.push(location)
      }
    },

    // Search for locations
    async searchLocations() {
      let self = this
      this.isLoading = true
      self.noLocationsFound = false
      self.foundLocations = []

      if (!this.inputSearchLocation.trim()) {
        this.isLoading = false
        this.foundLocations = []
        return
      }

      getLocations({
        searchTerm: this.inputSearchLocation,
        channelId: this.currentSearch.ChannelId,
        meetingtypeId: this.currentSearch.MeetingtypeId,
      })
        .then((response) => {
          self.foundLocations = response.data.Results
          if (!self.foundLocations.length) {
            self.noLocationsFound = true
          }
          self.isLoading = false
        })
        .catch((e) => {
          if (e.response) {
            self.isLoading = false
          }
        })
        .finally(() => { })
    },
  },
}
</script>
