<template>
  <div :class="[`${$classPrefix}select`, selectBoxSize]">
    <select
      v-model="mSelectedEventType"
      class="is-shadowless"
    >
      <option
        :value="0"
        v-html="$t('App.DrawerFilters.SelectOption_FilterEventType')"
      ></option>
      <option
        v-for="(eventType, index) in filteredEventTypes"
        :key="index"
        :value="eventType.Id"
        :selected="eventType.Id === mSelectedEventType"
      >{{ eventType.Id | getEventTypeName }} <template v-if="!selectedLocation">
          ({{$tc('App.DrawerFilters.Label_Locations', eventType.Count)}})</template></option>
    </select>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'event-type-filter',

  components: {},

  props: {
    showNrLocations: {
      type: Boolean,
      default: true
    },
    selectBoxSize: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('availabilityStore', [
      'processedLocations',
      'selectedLocation',
    ]),
    ...mapState('searchStore', ['selectedEventType']),
    ...mapState('widgetStore', ['widgetState']),

    filteredEventTypes() {
      let eventTypes = []

      for (let i = 0; i < this.widgetState.EventTypes.length; i++) {
        let eventType = this.widgetState.EventTypes[i]

        let locations = []
        if (this.selectedLocation) {
          locations.push(this.selectedLocation)
        } else {
          locations = this.processedLocations
        }

        let locs = []

        for (let i = 0; i < locations.length; i++) {
          let location = locations[i]
          let spaceCounter = 0
          for (let j = 0; j < location.Spaces.length; j++) {
            let space = location.Spaces[j]

            if (space.EventTypes.includes(eventType)) {
              spaceCounter++
            }
          }

          if (spaceCounter > 0) {
            locs.push(location)
          }
        }

        eventTypes.push({
          Id: eventType,
          Count: locs.length,
        })
      }

      return eventTypes
    },

    mSelectedEventType: {
      get() {
        return this.selectedEventType
      },
      set(value) {
        this.setSelectedEventType(value)
      },
    },
  },

  created() { },

  methods: {
    ...mapMutations('searchStore', ['setSelectedEventType']),
  },
}
</script>
