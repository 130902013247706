import { getActiveCountries } from '../../providers/country'

const state = {
countries: []
}

const getters = {
  coutries: state => state.countries
}

const mutations = {
  setCountries: (state, newState) => {
    state.countries = newState
  }
}

const actions = {
  async getActiveCountries({ commit, rootGetters }, meetingtypeId = 0) {
    let channelId = rootGetters['widgetStore/channelId']
    
    await getActiveCountries({ channelId, meetingtypeId })
      .then(response => {
        if (response.data.length) {
        response.data = response.data.filter(c => c.Id !== 0)
        }
        commit('setCountries', response.data)
      })
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
