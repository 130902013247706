<template>
  <div>
    <div :class="`${$classPrefix}columns is-multiline`">
      <div :class="`${$classPrefix}column is-half`">
        <div
          :class="`${$classPrefix}content`"
          v-html="confirmText"
        ></div>
      </div>
      <div :class="`${$classPrefix}column is-half has-background-info-light`">
        <div
          :class="`${$classPrefix}title is-3`"
          v-html="$t('Components.Confirmation.Channel_1.MeetingType_2.Header_Passport')"
        ></div>
        <div
          :class="`${$classPrefix}content`"
          v-html="$t('Components.Confirmation.Channel_1.MeetingType_2.Text_Passport')"
        ></div>
        <div class="has-text-centered">
          <a
            :class="`${$classPrefix}button is-success`"
            :href="linkNetwork"
            target="_blank"
            v-html="$t('Components.Confirmation.Channel_1.MeetingType_2.Button_Passport')"
          ></a>
        </div>
      </div>
    </div>
    <hr class="has-background-white" />
    <div :class="`${$classPrefix}columns is-multiline`">
      <div :class="`${$classPrefix}column is-half`">
        <div
          :class="`${$classPrefix}title is-3`"
          v-html="$t('Components.Confirmation.Channel_1.MeetingType_2.Header_CoworkingExplained')"
        ></div>
        <YouTubeEmbed src="https://www.youtube-nocookie.com/embed/NWoW_UAdYfA" />
      </div>
      <div :class="`${$classPrefix}column is-half`">
        <div
          :class="`${$classPrefix}title is-3`"
          v-html="$t('Components.Confirmation.Channel_1.MeetingType_2.Header_BookedSpace')"
        ></div>
        <YouTubeEmbed src="https://www.youtube-nocookie.com/embed/4wFQdb126tE" />
      </div>

    </div>
    <hr class="has-background-white" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import YouTubeEmbed from '@/components/VideoEmbeds/YoutubeEmbed'

export default {
  name: 'confirmation-ws-channel_1',

  components: {
    YouTubeEmbed
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('cartStore', ['Cart']),
    ...mapGetters('authStore', ['profile']),
    ...mapGetters('widgetStore', ['channel']),
    ...mapGetters('locationmanagers', ['activeManager']),
    ...mapGetters('availabilityStore', ['selectedLocation']),
    confirmText() {
      if (this.profile && this.activeManager && this.selectedLocation && this.Cart) {
        let text = 'Components.Confirmation.Channel_1.MeetingType_2.Text_confirmation'

        if (this.Cart.StatusId === 0) {
          // INFO: Booking need to be accpeted by location
          text = 'Components.Confirmation.Channel_1.Text_ConfirmationLocationNeedToAccept'
        }
        
        return this.$t(text, {
          firstName: this.profile.FirstName,
          phone: this.activeManager.Phone || this.selectedLocation.Phone,
          email: this.activeManager.Email || this.selectedLocation.Email,
          hostName: this.activeManager.Name,
          locationName: this.selectedLocation.Name,
          date: this.$options.filters.dateFormat(this.Cart.StartDate, this.$i18n.locale),
          startTime: this.$options.filters.minutesToTime(this.Cart.StartMinutes)
        })
      }
      return ''
    },

    linkNetwork() {
      return this.$i18n.locale === 'nl' ?
        'https://www.seats2meet.com/nl/netwerk'
        :
        'https://www.seats2meet.com/en/network'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>