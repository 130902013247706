<template>
  <div class="TabsWrapper">
    <nav :class="[`${$classPrefix}tabs`, tabsCssClass]">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ 'is-active': getActivePaneIndex === index }"
        >
          <a @click="activateTab({ index })">
            <span
              v-if="tab.icon"
              :class="`${$classPrefix}icon is-large`"
            >
              <font-awesome-icon :icon="tab.icon" />
            </span>
            <span>{{ tab.label }}</span>
          </a>
        </li>
        <li
          v-if="showNextButton && !showMobileFooterBar"
          class="tab-next-step"
        >
          <span
            @click="goOneStepForward"
            :class="[`${$classPrefix}button is-small is-success`, { 'is-loading': isBusy }]"
            :disabled="isBusy"
            v-text="$t('General.Text_NextStep')"
          ></span>
        </li>
      </ul>
    </nav>
    <slot></slot>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Tabs',

  props: {
    tabsCssClass: {
      type: String,
      default: ''
    },
    showNextButton: {
      type: Boolean,
      default: false
    }
  },

  // Make available to child components for injection
  provide: function() {
    return {
      // Set - adds tab
      registerPane: this.registerPane,
      // Get - note: provide does not support computed
      getActivePaneIndex: this.activeTab,
    }
  },

  data() {
    return {
      /**
       *  Note: in array format, due to provide / inject specifics
       */
      activeTab: [0],
      tabs: []
    }
  },

  computed: {
    ...mapState(['showMobileFooterBar']),
    ...mapState('widgetStore', ['widgetState',]),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapGetters('searchStore', ['LastStep', 'DisableNextButton']),
    /**
     * Return the active pane index.
     *  Note: Used by the contained Pane components
     */
    getActivePaneIndex() {
      return this.activeTab[0]
    }
  },
  methods: {
    ...mapActions('searchStore', ['goToStep']),

    goOneStepForward() {
      this.goToStep({ step: this.LastStep + 1 })
    },
    /**
     * Set the active tab by index
     *  Note: in array format, due to provide / inject specifics
     */
    activateTab({ index }) {
      this.activeTab.shift()
      this.activeTab.unshift(parseInt(index));
    },
    /**
     * Register a new pane, return the tab index
     *  Note: Used by the contained Pane components in created lifecycle method
     */
    registerPane({ label, icon }) {
      return (this.tabs.push({
        label, icon
      })) - 1;
    }
  }
}
</script>