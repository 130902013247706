<template>
  <div>
    <div
      v-for="(rev, index) in reviews"
      :key="index"
      :class="`${$classPrefix}column is-half-mobile is-one-third-fullhd`"
    >
      <div :class="`${$classPrefix}box`">
        <ui-review-score
          :score="rev.Score"
          :size="16"
          class="has-text-white"
        />

        <div>
          <span>
            <figure class="image is-32x32">
              <img
                src="@/assets/images/spacer1_1.gif"
                class="is-round has-shadow"
                :style="{
                  backgroundImage: `url(${$options.filters.getProfileImage(
                    rev.ProfileImage,
                    rev.ProfileId,
                    38
                  )})`,
                }"
              /></figure>
          </span>

          <span>{{ rev.ProfileName }}</span>
          <span class="has-margin-left has-text-grey postDetailBar is-size-7">{{
            rev.AnsweredOn | parseIsoDateStringToDate | longDateFormat
          }}</span>
        </div>

        <comment-item
          v-for="comment in rev.Comments"
          :key="comment.Id"
          :commentObj="comment"
          :comments="rev.Comments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cancelRequests, getLocationReviews } from '@/providers/review.js'

import CommentItem from '@/components/Comments/CommentItem'
import UiReviewScore from '@/components/UI/ReviewScore'

export default {
  name: 'location-reviews',

  components: {
    'comment-item': CommentItem,
    'ui-review-score': UiReviewScore,
  },

  props: {
    locationId: {
      type: Number,
      default: 0,
    },

    meetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      reviews: [],
    }
  },

  computed: {},

  created() {
    this.getReviews()
  },

  beforeDestroy() {
    cancelRequests()
  },

  methods: {
    getReviews() {
      let page = 1
      let itemsPerPage = 25

      getLocationReviews({
        locationId: this.locationId,
        meetingtypeId: this.meetingtypeId,
        page: page,
        itemsPerPage: itemsPerPage,
      }).then((response) => {
        if (response.status === 200) {
          this.reviews = response.data.Results
        }
      })
    },
  },
}
</script>

<style></style>
