import axios from '@/helpers/axios'

let fetchProfileCancel = null
let createProfileCancel = null

export default {
  /**
   * Get profile billing addresses
   * @returns Array with billing addresses
   */
  async getBilling() {
    return await axios.get(`/Profile/billing`)
  },

  /**
   * Create profile billing address
   * @param {Object} billingInfo 
   * @returns created billing address
   */
  async createBillingInfo(billingInfo) {
    return await axios.post(`/Profile/billing`, billingInfo)
  },

  /**
   * Update profile billing address
   * @param {Object} billingInfo 
   * @returns Updated billing address
   */
  async updateBilling(billingInfo) {
    return await axios.put(`/Profile/billing`, billingInfo)
  },

  /**
   * Delete selected billing address
   * @param {Number} id 
   */
  async deleteBilling(id) {
    return await axios.delete(`/Profile/billing/${id}`)
  },

  /**
   * Get profile data
   * @returns Profile object
   */
  getProfile() {
    return axios.get(`/Profile`)
  },

  checkEmail({ email }) {
    return axios.get(`/Profile/check?email=${email}`)
  },

  async createProfile({
    ChannelId,
    FirstName,
    LastName,
    Email,
    Phone,
    Password,
    Tags,
    TermsId,
    HasAgreedToTerms,
    LocalePrefix,
  }) {
    createProfileCancel && createProfileCancel('Request cancelled')
    return axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/v2/profile`, {
      FirstName,
      LastName,
      Email,
      Phone,
      Password,
      Tags,
      TermsId,
      HasAgreedToTerms,
      ChannelId,
      LocalePrefix,
    },
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        createProfileCancel = c;
      })
    })
  },

  /**
   * Fetch profile verification key
   * @param {string} FirstName 
   * @param {string} LastName 
   * @param {string} Email 
   * @param {string} Language 
   * @param {Number} ChannelId 
   */
  async fetchProfile({ FirstName, LastName, Email, Language, ChannelId }) {
    fetchProfileCancel && fetchProfileCancel('Request cancelled')
    return axios.post(`/Profile/fetch`, {
      FirstName,
      LastName,
      Email,
      Language,
      ChannelId
    },
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        fetchProfileCancel = c;
      })
    })
  },

  /**
   * 
   */
}
