/**
 * Erase cookie. Includes wildcard cookie
 * @param {string} cName
 */
export function eraseCookie(cName) {
  if (getCookie(cName) !== '') {
    // TODO: Get domain via javascript

    document.cookie = cName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    document.cookie = cName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.seats2meet.com'
  }
}

/**
 * Get cookie data
 * @param {string} cName
 * @returns string
 */
export function getCookie(cName) {
  if (document.cookie.length > 0) {
    var arr = document.cookie.split(';')
    cName = cName + '='
    var value = ''
    for (var i = 0, arrLength = arr.length; i < arrLength; i++) {
      value = arr[i].trim()
      if (value.indexOf(cName) !== -1 && value.indexOf(cName) === 0) {
        value = value.substr(cName.length)
        break
      }
      value = ''
    }
    return value
  }
  return ''
}