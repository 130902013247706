<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="modalReady"
      :class="`${$classPrefix}widget ${$classPrefix}modal-placeholder`"
    >
      <div :class="[`${$classPrefix}modal`, { 'is-active': showModal }]">
        <div :class="`${$classPrefix}modal-background`"></div>
        <div
          :ref="'modal' + modalRefNamePrefix"
          :class="[
          `${$classPrefix}modal-card`,
          'modal-card-' + modalSize,
          unsetFillModeCssClass,
          animateClass
        ]"
        >
          <div :class="`${$classPrefix}modal-card-head has-background-white`">
            <p
              :class="`${$classPrefix}modal-card-title`"
              v-html="modalTitle"
            ></p>
            <button
              :class="`${$classPrefix}button is-outlined`"
              @click="close()"
              :aria-label="$t('General.Text_Close')"
            >
              <span v-text="$t('General.Text_Close')"></span>
            </button>
          </div>

          <div
            :class="`${$classPrefix}modal-card-body`"
            id="wizard-scoll-container"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <slot name="content"></slot>
            </transition>
          </div>
          <div :class="`${$classPrefix}modal-card-foot has-background-white`">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Modal',

  props: {
    modalTitle: {
      type: String,
      default: ''
    },

    /**
     * Sizes are:
     * - dynamic (size will be determent by content)
     * - small (width is set to auto)
     * - normal (is bulma default)
     * - large (1024)
     * - extra-large (1200)
     */
    modalSize: {
      type: String,
      default: 'normal'
    },

    onCloseFunc: {
      type: Function,
      default: function() { },
      required: false
    },

    showModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalRefNamePrefix: new Date().getTime(),
      modalAnimation: this.showModal,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
      modalReady: false
    }
  },

  computed: {
    animateClass() {
      let cls = 'animated fade'
      cls = cls + (this.modalAnimation ? 'In' : 'Out')
      cls = cls + ' faster'
      return cls
    }
  },

  mounted() {
    this.openModal(this.$el)
    this.modalReady = true
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }

    this.closeModal(this.$el)
  },

  methods: {
    ...mapActions('modalStore', ['openModal', 'closeModal']),
    close() {
      this.modalReady = false
      this.unsetFillModeCssClass = ''
      this.modalAnimation = false
      this.animateTimer = setTimeout(this.onCloseFunc, 0)
    },
  },
}
</script>