<script>
/**
 * The Form component acts as a logical container for all input components.
 * Input fields are provided with methods to register themselves, providing in
 * turn methods that allow the Form component to activate the validation
 * mechanism of fields and get the field values as part of the submit action.
 * In return it manages and communicates the form status to the input fields.
 *
 * It also makes available the core mechanisms available to the Pages & Page
 * components, should there be any. This allows those components to intercept
 * Field to Form interaction, and trigger form actions. The manner in which
 * this is designed, neither this Form nor the Field components have any
 * significant modifications to support Paged forms, reducing the mental
 * overhead when not working with Paged forms - which is most often the case.
 *
 * Please note that without implementing the onSubmitCallback prop, basically
 * nothing happens when a user submits the form...
 *
 *
 * IMPORTANT
 *  This component lacks design and features such as a global feedback
 *  message, a thank you page and a processing animation for while the form
 *  is being submitted. This is left out by design and should be implemented
 *  in a component that leverages this clean Form component.
 *
 */
export default {
  name: 'BaseForm',
  // Make available to child components for injection
  provide: function() {
    return {
      // Set
      registerInputField: this.registerInputField,
      unregisterInputField: this.unregisterInputField,
      // Get - note: provide does not support computed
      getFormStatus: this.status,

      // Made available to Pages & Page components
      setFormStatus: this.setFormStatus,
      runValidation: this.runValidation,
      isValid: this.isValid,
      submitForm: this.submitForm,
      getFormData: this.getFormData
    }
  },
  props: {
    once: {
      type: Boolean,
      default: true,
      note: 'Only allow this form to be submitted once'
    },
    onSubmitCallback: {
      type: Function,
      // eslint-disable-next-line
      default: ({ formData, component }) => {
        // eslint-disable-next-line
        return new Promise((resolve, reject) => {
          resolve({ response: 'OK', component })
        })
      },
      note: 'The callback that processes the form data. Must return a Promise.'
    }
  },
  data() {
    return {
      /**
       * The current form status. This information is made available to child components
       * See `setFormStatus` for available values.
       * The array format is required due to the inject / provide mechanism.
       */
      status: ['READY'],
      /**
       * All registered input fields
       */
      fields: []
    }
  },
  mounted() {
    this.$emit('ready')
  },
  methods: {
    /**
     * Change the status of the form, and emit an event about it
     */
    setFormStatus({ status }) {
      if (
        [
          'READY',
          'PRESUBMIT',
          'VALIDATING',
          'VALID',
          'INVALID',
          'PROCESSING',
          'PROCESSED',
          'COMPLETED'
        ].includes(status)
      ) {
        this.status.splice(0, 1, status)
        this.$emit('onStatusChange', { status, form: this })
      }
    },
    getFormStatus() {
      return this.status[0]
    },

    /**
     * Registering / unregistering input fields
     * Avoid duplicates. Copy over the value.
     */
    registerInputField({ name, validate, isValid, getValue, setValue }) {
      let field = this.getFieldByName({ name })
      if (field) {
        setValue({ value: field.getValue() })
        this.unregisterInputField({ name })
      }
      this.fields.push({ name, validate, isValid, getValue, setValue })
    },

    /**
     * Allows fields to be removed.
     * This is not automatically when fields are unmounted, because this happens frequently with paged forms
     */
    unregisterInputField({ name }) {
      let index = this.getFieldIndexByName({ name })
      if (index !== -1) {
        this.fields.splice(index, 1)
      }
    },

    /**
     * Validation of fields. Silent mode does not show feedback to the user.
     */
    runValidation({ silent } = { silent: false }) {
      if (!silent) {
        this.setFormStatus({ status: 'VALIDATING' })
      }
      this.fields.forEach(field => field.validate({ silent }))
      this.setFormStatus({ status: 'READY' })
    },

    isValid() {
      return this.fields.every(field => field.isValid())
    },

    /**
     * Get formData from fields
     */
    getFormData() {
      return this.fields.reduce((data, field) => {
        // This allows Page components to ignore a field
        let value = field.getValue()
        if (value !== undefined) {
          data[field.name] = value
        }
        return data
      }, {})
    },
    /**
     * Get the value of a specific field
     */
    getFieldValueByName({ name }) {
      let field = this.getFieldByName({ name })
      return field.getValue()
    },

    /**
     * Helper methods for accessing fields
     */
    getFieldIndexByName({ name }) {
      return this.fields.findIndex(field => {
        return field.name === name
      })
    },
    getFieldByName({ name }) {
      return this.fields.find(field => {
        return field.name === name
      })
    },

    /**
     * Submit DOM Event Handler
     */
    onSubmitHandler(e) {
      e.preventDefault()
      this.submitForm()
    },

    /**
     * Submit the form
     * Returns a Promise object
     */
    submitForm() {
      // Prevent repeat submit while processing
      if (this.getFormStatus() !== 'READY') {
        return
      }
      // TODO: Perhaps use this to prevent submitting a paged form that has not
      // reached the final page yet...??
      this.setFormStatus({ status: 'PRESUBMIT' })

      this.runValidation()
      if (!this.isValid()) {
        this.setFormStatus({ status: 'INVALID' })
        this.setFormStatus({ status: 'READY' })
      } else {
        this.setFormStatus({ status: 'VALID' })
        this.setFormStatus({ status: 'PROCESSING' })

        return (
          this.onSubmitCallback({
            formData: this.getFormData(),
            component: this
          })
            // eslint-disable-next-line
            .then(response => {
              this.setFormStatus({ status: 'PROCESSED' })
              this.setFormStatus({
                status: this.once ? 'COMPLETED' : 'READY'
              })
            })
            .catch(() => {
              this.setFormStatus({ status: 'INVALID' })
              this.setFormStatus({ status: 'READY' })
            })
        )
      }
    }
  },
  render(h) {
    return h(
      'form',
      {
        class: {
          BaseForm: true
        },
        attrs: {
          method: 'post',
          autocomplete: 'none',
          novalidate: true
        },
        on: {
          submit: this.onSubmitHandler
        }
      },
      this.$scopedSlots.default()
    )
  }
}
</script>
