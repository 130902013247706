<template>
  <div class="view-invoiceAddressForm">

    <BaseForm ref="form">
      <div
        v-if="cart.MeetingTypeId !== 2"
        :class="`${$classPrefix}columns is-gapless is-multiline is-mobile`"
      >
        <div :class="`${$classPrefix}column is-half`">
          <RequiredField
            v-if="isCompanyBilling"
            name="CompanyName"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_CompanyName'
              )
            "
            :required="cart.MeetingTypeId !== 2"
            v-model="billingInfoData.Company"
            :disabled="isBusy"
            :isStatic="isPreviewMode || billingInfoData.CompanyId > 0"
            :readonly="isPreviewMode || billingInfoData.CompanyId > 0"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <BaseBulmaField
            name="PoNumber"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_PONumber'
              )
            "
            v-model="billingInfoData.PoNumber"
            :required="false"
            :key="'po' + isBusy"
            :disabled="isBusy"
            :isStatic="isPreviewMode"
            :readonly="isPreviewMode"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <RequiredField
            name="Address"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_Address'
              )
            "
            :required="cart.MeetingTypeId !== 2"
            v-model="billingInfoData.Address"
            :disabled="isBusy"
            :isStatic="isPreviewMode"
            :readonly="isPreviewMode"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <RequiredField
            name="PostalCode"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_PostalCode'
              )
            "
            :required="cart.MeetingTypeId !== 2"
            v-model="billingInfoData.PostalCode"
            :disabled="isBusy"
            :isStatic="isPreviewMode"
            :readonly="isPreviewMode"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <RequiredField
            name="City"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_City'
              )
            "
            :required="cart.MeetingTypeId !== 2"
            v-model="billingInfoData.City"
            :disabled="isBusy"
            :isStatic="isPreviewMode"
            :readonly="isPreviewMode"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <RequiredField
            name="Country"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_Country'
              )
            "
            :required="cart.MeetingTypeId !== 2"
            v-model="billingInfoData.Country"
            :disabled="isBusy"
            :isStatic="isPreviewMode"
            :readonly="isPreviewMode"
          />
        </div>
      </div>

      <div :class="`${$classPrefix}columns is-gapless is-multiline is-mobile`">
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <RequiredField
            :required="isPaidReservation"
            name="SendTo"
            :label="labelSendTo"
            v-model="billingInfoData.SendTo"
            :disabled="isBusy && cart.MeetingTypeId !== 2"
            :isStatic="isPreviewMode || cart.MeetingTypeId === 2"
            :readonly="isPreviewMode || cart.MeetingTypeId === 2"
          />
        </div>

        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <EmailField
            :required="isPaidReservation"
            name="Email"
            :label="labelInvoiceEmail"
            v-model="billingInfoData.Email"
            :disabled="isBusy && cart.MeetingTypeId !== 2"
            :isStatic="isPreviewMode || cart.MeetingTypeId === 2"
            :readonly="isPreviewMode || cart.MeetingTypeId === 2"
          />
        </div>
        <div :class="`${$classPrefix}column is-half-mobile is-half`">
          <PhoneNumberField
            name="PhoneNumber"
            :label="
              $t(
                'Components.InvoiceDetails.InvoiceAddressForm.Label_PhoneNumber'
              )
            "
            :required="isPaidReservation"
            v-model="billingInfoData.PhoneNumber"
            :disabled="isBusy"
            :isStatic="isPreviewMode && cart.MeetingTypeId !== 2"
            :readonly="isPreviewMode && cart.MeetingTypeId !== 2"
          />
        </div>
      </div>
    </BaseForm>

  </div>
</template>

<script>
import BaseForm from '@/components/UI/Form/BaseForm'
import BaseBulmaField from '@/components/UI/Form/BaseBulmaField'
import EmailField from '@/components/UI/Form/EmailField'
import PhoneNumberField from '@/components/UI/Form/PhoneNumberField'
import RequiredField from '@/components/UI/Form/RequiredField'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'InvoiceAddressForm',

  props: {
    isCompanyBilling: {
      type: Boolean,
      default: false
    },

    isPreviewMode: {
      type: Boolean,
      default: false
    },
  },

  components: {
    BaseForm,
    BaseBulmaField,
    EmailField,
    PhoneNumberField,
    RequiredField,
  },

  data() {
    return {
      hideCompanyField: false,
      showMessage: false,
      watch_billing: null,
      billingInfoData: { ...this.$store.state.billingStore.billingInfoTemplate },
      restoreData: { ...this.$store.state.billingStore.billingInfoTemplate },
    }
  },

  computed: {
    ...mapGetters('cartStore', ['cart', 'isBusy']),
    ...mapGetters('searchStore', ['DisableNextButton']),
    ...mapState('authStore', ['profile']),
    ...mapState('cartStore', ['cart']),
    ...mapState('billingStore', ['billingInfos', 'billingInfo', 'billingIsvalid', 'billingInfoTemplate']),
    ...mapState(['viewportMode', 'showMobileFooterBar']),

    isPaidReservation() {
      return !!(
        this.cart.MeetingTypeId !== 2 ||
        (this.cart.MeetingTypeId === 2 && this.cart.TotalExclTax)
      )
    },

    message() {
      let message = ''
      if (!this.billingIsvalid) {
        message = this.$t(
          'Components.InvoiceDetails.InvoiceAddressForm.Message_FillInRequiredFields'
        )
      }

      return message
    },

    /**
     * Form field labels
     */
    labelSendTo() {
      return this.isPaidReservation
        ? this.$t('Components.InvoiceDetails.InvoiceAddressForm.Label_SendTo')
        : this.$t('Components.InvoiceDetails.InvoiceAddressForm.Label_Name')
    },

    labelInvoiceEmail() {
      return this.isPaidReservation
        ? this.$t(
          'Components.InvoiceDetails.InvoiceAddressForm.Label_InvoiceEmail'
        )
        : this.$t(
          'Components.InvoiceDetails.InvoiceAddressForm.Label_CompanyEmail'
        )
    },
  },

  created() {
  },

  async mounted() {
    /**
     * Watch billing info store data
     * Process new incoming billing data
     */
    this.watch_billing = this.$watch('billingInfo', this.watchBillingSelector, {
      deep: true,
      immediate: true,
    })

    if (this.cart.MeetingTypeId === 2) {
      this.billingInfoData.SendTo = this.profile.FullName
      this.billingInfoData.Email = this.profile.Email
      this.billingInfoData.PhoneNumber = this.profile.Phone
      this.setBillingInfo(this.billingInfoData)
    }
  },

  beforeDestroy() {
    // Unwatch billing info store data
    if (this.watch_billing) {
      this.watch_billing()
    }
  },

  methods: {
    ...mapMutations('billingStore', ['setBillingIsValidState', 'setBillingInfo']),
    ...mapMutations('cartStore', ['updateInvoiceAddressDetails']),

    async watchBillingSelector(val) {
      if (this.cart.MeetingTypeId === 2) { return }

      // Save a copy when user cancel his changes
      this.restoreData = { ...val }

      // Do nothing when incoming billing ID the same is as the selected billing ID
      if (this.cart.MeetingTypeId !== 2 && this.billingInfoData.Id === val.Id) { return }

      // Update local billing address
      this.billingInfoData = { ...val }

      // Validate billing address
      this.runSilentValidation(true)
    },

    /**
     * Silent validation does not show validation feedback to the user
     */
    async runSilentValidation(silent = true, showRequiredFieldMessage = false) {
      if (this.$refs.form) {
        await this.$refs.form.runValidation({ silent: silent })

        const FORMDATA_IS_VALID = this.$refs.form.isValid()
        this.showMessage = showRequiredFieldMessage

        // Update store billing valid state
        this.setBillingIsValidState(FORMDATA_IS_VALID)
        return { isValid: FORMDATA_IS_VALID, formData: this.billingInfoData }
      }
    },

    restore() {
      this.billingInfoData = { ...this.restoreData }

      // Update cart invoice data
      this.updateInvoiceAddressDetails({ billingInfo: this.billingInfoData })

      this.runSilentValidation(true)
    }
  }
}
</script>