import axios from '@/helpers/axios'
import store from '@/store'

let loginTokenCancel = null
let resetPasswordToken = null
let checkAuthPinCancel = null

export default {
  authorizeUser({
    Email,
    Password
  }) {
    // Cancel previous request
    loginTokenCancel && loginTokenCancel('Request cancelled')

    return axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/v2/login`, {
      Email,
      Password,
      MachineToken: store.getters['authStore/getMachineToken']
    },
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        loginTokenCancel = c;
      })
    })
  },

  async SignOut() {
    return await axios.delete(`logoff`)
  },

  verifyProfileToken({
    profileToken
  }) {
    return axios.post(
      `/login/verify/token`, '', {
      headers: {
        profileToken
        },
        cancelToken: resetPasswordToken.Token,
    })
  },

  /**
   * Check user pin authentication
   * @param {String} VerificationKey 
   * @param {String} Pin 
   */
  async checkAuthPin({ VerificationKey, Pin }) {
    checkAuthPinCancel && checkAuthPinCancel('Request cancelled')
    return axios.post('/login/pin', {
      VerificationKey,
      Pin
    },
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        checkAuthPinCancel = c;
      })
    })
  },

  /**
   * Reset password request
   * @param {String} Email 
   * @param {Number} ChannelId 
   * @param {String} Language 
   */
  resetPassword({
    Email,
    ChannelId,
    Language
  }) {
    return axios.post(`/login/reset`, {
      ChannelId,
      Email,
      Language
    })
  },

  /**
   * Change password with new password
   * @param {String} Ping 
   * @param {String} Password 
   */
  changePassword({
    token,
    pin,
    password
  }) {
    resetPasswordToken && resetPasswordToken.cancel('Request cancelled')
    
    resetPasswordToken = axios.CancelToken.source()
    return axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/v2/login/password`,
      {
        token,
        pin,
        password
      },
      {
        cancelToken: resetPasswordToken.Token,
      }
    )
  }
}