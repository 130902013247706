import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import appStore from './modules/app'
import modalStore from './modules/modal'
import countryStore from './modules/country'
import widgetStore from './modules/widget'
import searchStore from './modules/search'
import cartStore from './modules/cart'
import availabilityStore from './modules/availability'
import authStore from './modules/auth'
import billingStore from './modules/billing'
import locationmanagers from './modules/locationmanagers'

export default new Vuex.Store({
  state: {
    viewportMode: '',
    showMobileFooterBar: false,
    fireCustomEventAllowed: false,
    windowWidth: 0
  },
  // getters: {
  // },
  mutations: {
    setFireCustomEventAllowed(state, newState) {
      state.fireCustomEventAllowed = newState
    },

    setViewportMode(state, windowWidth) {
      state.windowWidth = windowWidth
      if (state.viewportMode !== 'mobile' && windowWidth <= 768) {
        // Mobile
        // Up to 768px
        state.viewportMode = 'mobile'
      } else if (state.viewportMode !== 'tablet' && windowWidth >= 769 && windowWidth <= 1023) {
        // Tablet
        // Between 769px and 1023px
        state.viewportMode = 'tablet'
      } else if (state.viewportMode !== 'desktop' && windowWidth >= 1024) {
        // Desktop
        // Between 1024px and 1215px
        state.viewportMode = 'desktop'
      }
    },
    setShowMobileFooterBarState(state, newState) {
      state.showMobileFooterBar = newState
    }
  },
  // actions: {},
  modules: {
    appStore,
    modalStore,
    countryStore,
    widgetStore,
    searchStore,
    availabilityStore,
    cartStore,
    authStore,
    billingStore,
    locationmanagers,
  },
})
