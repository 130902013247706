<script>
import BaseField from './BaseBulmaField'

export default {
  name: 'PhoneNumberField',
  extends: BaseField,

  props: {
    name: {
      type: String,
      default: 'phone',
      note: 'The input data name'
    },
    label: {
      type: String,
      default: 'Phone number',
      note: 'The field label and/or instruction text.'
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: 'tel'
    }
  }
}
</script>
