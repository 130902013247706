<template>
  <div :class="`${$classPrefix}field`">
    <label
      ref="label"
      :for="labelId"
      :class="`${$classPrefix}label`"
    >
      {{ label }}
    </label>
    <div
      class="has-icons-right"
      :class="[`${$classPrefix}control`, { 'has-icons-left': inputIcon }]"
    >
      <textarea
        :value="fieldValue"
        :class="[`${$classPrefix}textarea`, { 'is-danger': error }]"
        :placeholder="label"
        :disabled="disabledStatus"
        :id="inputId"
        :rows="rows"
        autocomplete="none"
        @blur="onBlur"
        @keyup="onChange"
        @focus="onFocus"
        @input="onInput"
      ></textarea>
      <span
        v-if="inputIcon"
        :class="`${$classPrefix}icon`"
        class="is-small is-left"
      >
        <font-awesome-icon :icon="inputIcon" />
      </span>
      <span
        v-if="error"
        key="error"
        :class="`${$classPrefix}icon`"
        class="is-small is-right"
      >
        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
      </span>
      <span
        v-if="hasPassedValidation"
        key="success"
        :class="`${$classPrefix}icon`"
        class="is-small is-right"
      >
        <font-awesome-icon :icon="['fal', 'check']" />
      </span>
    </div>
    <p v-if="error">
      <span
        :class="`${$classPrefix}help`"
        class="is-danger"
      >
        {{ error }}
      </span>
    </p>
  </div>
</template>

<script>
import BaseField from './BaseBulmaField'
import { maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'TextareaField',
  extends: BaseField,

  props: {
    name: {
      type: String,
      default: 'textarea',
      note: 'The input data name'
    },
    rows: {
      type: Number,
      default: 5
    },
    label: {
      type: String,
      default: 'Textarea',
      note: 'The field label and/or instruction text.'
    },
    validationRules: {
      type: Object,
      default: () => {
        return {
          maxLength: maxLength(1000)
        }
      },
      note: 'The validation rule(s). Preset to required.'
    },
    errorMessages: {
      type: Object,
      default: () => {
        return {
          maxLength: 'De invoer mag maximaal 1000 tekens lang zijn.'
        }
      },
      note: 'The validation error messages'
    }
  },
  data() {
    return {
      type: 'textarea'
    }
  }
}
</script>
