<template>
  <div class="login-fields-component">
    <div
      :class="`${$classPrefix}title`"
      class="is-4 "
      v-html="$t('Components.ForgotLoginRegister.ForgotConfirmation.Main_Title')"
    >
    </div>
    <Message class="is-success">
      <font-awesome-icon
        :icon="['fal', 'check']"
        class="has-margin-right"
      />
      {{$t('Components.ForgotLoginRegister.ForgotConfirmation.Message_Success')}}
    </Message>
    <div
      class="has-margin-bottom"
      v-html="$t('Components.ForgotLoginRegister.ForgotConfirmation.Text_Notification')"
    >
    </div>
    <a
      :class="`${$classPrefix}button is-success`"
      @click="handleAuthNavigate({ page: 'login' })"
      v-html="$t('Components.ForgotLoginRegister.General.Link_BackToLogin')"
    >
    </a>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Message from '@/components/UI/Message'

export default {
  name: 'ForgotConfirm',

  components: {
    Message
  },

  methods: {
    ...mapMutations('authStore', ['goToAuthPage']),
    handleAuthNavigate({ page }) {
      return this.goToAuthPage({ page })
    },
  }
}
</script>


