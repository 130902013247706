import axiosInstance from '@/helpers/axios'

export const getWidgetStateByKey = (widgetKey, searchKey = '') => {
  return axiosInstance.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/${widgetKey}/state`,
    {
      searchKey: searchKey,
    }
  )
}
