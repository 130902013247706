<template>
  <div
    v-show="showPane"
    :id="`tab_${index}`"
    class="TabPane"
  >
    <slot />
  </div>
</template>

<script>
/**
 * Note: re-uses integration app component, with different class name
 */
export default {
  name: 'TabPane',
  // Inherited from Tabs parent component
  inject: ['getActivePaneIndex', 'registerPane'],
  props: {
    // The label will appear as text on the tab
    label: {
      type: String,
      required: true
    },
    // A Font Awesome icon in array notation (e.g. ['fal', 'times'])
    // Note: The icon needs to be registered in the library
    // Note: Either provide all tabs with an icon or none. Otherwise the tab height will differ
    icon: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      index: 0
    }
  },
  computed: {
    /**
     * Compare index of this pane with the activated index
     */
    showPane() {
      return this.index === this.getActivePaneIndex[0]
    }
  },
  /**
   * Upon creating, register the pane with the tabs container
   *  and store the index of the added tab
   */
  created() {
    // Register the pane in the container component
    this.index = this.registerPane({
      label: this.label,
      icon: this.icon || false
    })
  }
}
</script>
<style scoped lang="scss">
.TabPane {
  // display: none;
  animation: fadeEffect 1s; /* Fading effect takes 500ms second */
}
/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>