<template>
  <div>
    <div :class="`${$classPrefix}content`" v-html="confirmText"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'confirmation-general',

  computed: {
    ...mapGetters('cartStore', ['Cart']),
    ...mapGetters('authStore', ['profile']),
    ...mapGetters('widgetStore', ['channel']),
    ...mapGetters('locationmanagers', ['activeManager']),
    ...mapGetters('availabilityStore', ['selectedLocation']),

    confirmText() {
      if (
        this.profile &&
        this.activeManager &&
        this.selectedLocation &&
        this.Cart
      ) {

        let text = this.Cart.MeetingTypeId === 2
          ? this.$t(
              'Components.Confirmation.General.MeetingType_2.Text_confirmation'
            )
          : this.$t('Components.Confirmation.General.MeetingType_1.Text_confirmation')

        if (this.Cart.StatusId === 0) {
          // INFO: Booking need to be accpeted by location
           text = this.$t('Components.Confirmation.General.Text_ConfirmationLocationNeedToAccept')
        }

        return text
          .replace(/##firstName##/g, this.profile.FirstName)
          .replace(/##cancelPeriod##/g, this.channel.CancelPeriod || '')
          .replace(
            /##phone##/g,
            this.activeManager.Phone || this.selectedLocation.Phone
          )
          .replace(
            /##email##/g,
            this.activeManager.Email || this.selectedLocation.Email
          )
          .replace(/##hostName##/g, this.activeManager.Name)
          .replace(/##locationName##/g, this.selectedLocation.Name)
          .replace(
            /##date##/g,
            this.$options.filters.dateFormat(
              this.Cart.StartDate,
              this.$i18n.locale
            )
          )
          .replace(
            /##startTime##/g,
            this.$options.filters.minutesToTime(this.Cart.StartMinutes)
          )
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
