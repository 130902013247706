var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDrawer)?_c('UiComponentDrawer',{attrs:{"drawerTitle":_vm.selectedOption.OptionName,"showDrawer":_vm.showDrawer,"onClickClose":_vm.onClickClose,"position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"wrapper-images"},[_c('figure',{staticClass:"image is-3by2 has-background-grey-lighter"},[_c('img',{style:({
            backgroundImage: `url('${_vm.$options.filters.getLocationImage(
              _vm.selectedOption.OptionImage,
              _vm.selectedOption.LocationId,
              '640'
            )}')`,
          }),attrs:{"src":require("@/assets/images/spacer1_1.gif")}})])]),_c('div',{class:`${_vm.$classPrefix}content`,domProps:{"innerHTML":_vm._s(_vm.selectedOption.OptionDescription)}})]},proxy:true}],null,false,1073324490)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }