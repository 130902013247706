var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"LocationOption"},[_c('figure',{staticClass:"LocationOption__image has-background-grey-light",style:({
      backgroundImage: `url('${_vm.$options.filters.getLocationImage(
        _vm.availablePackage.Image,
        _vm.locationId,
        '320'
      )}')`,
    }),on:{"click":_vm.showInformation}}),_c('div',{staticClass:"LocationOption__detail_price"},[_c('div',{staticClass:"LocationOption__details has-padding"},[_c('div',{class:`title-option is-size-5 has-text-weight-bold`,domProps:{"innerHTML":_vm._s(_vm.name)},on:{"click":_vm.showInformation}}),_c('div',{class:`${_vm.$classPrefix}content`},[(_vm.description)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}}):_c('ul',_vm._l((_vm.availablePackage.Options),function(option){return _c('li',{key:`o_${option.OptionId}`,domProps:{"textContent":_vm._s(option.Name)}})}),0)]),_c('a',{staticClass:"has-icon is-info",on:{"click":_vm.showInformation}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'info-circle'],"size":"lg"}})],1),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('General.Text_MoreInfo'))}})])]),_c('div',{staticClass:"LocationOption__price_control has-background-info-light has-padding has-text-centered"},[(_vm.widgetState.ShowPrices)?_c('div',{staticClass:"has-margin-bottom"},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.availablePackage.VoucherPriceExclTax,'nl', _vm.cart.CurrencyIso))+" ")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('App.AvailablePackages.Text_PerPerson'))}}),_c('div',{staticClass:"has-text-grey has-margin-bottom",domProps:{"textContent":_vm._s(
              `${_vm.$options.filters.toCurrency(
                _vm.availablePackage.VoucherPriceExclTax * _vm.cart.TotalSeats,
                'nl-NL',
                'EUR'
              )} total`
            )}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isSelected)?_c('button',{key:"selected",staticClass:"is-success is-fullwidth",class:[`${_vm.$classPrefix}button`, { 'is-loading': _vm.isBusy }],domProps:{"textContent":_vm._s(_vm.$t('General.Text_Add'))},on:{"click":_vm.applyVoucher}}):_c('a',{key:"removeSelected",staticClass:"has-icon has-text-danger",on:{"click":_vm.revertVoucher}},[_c('span',{class:`${_vm.$classPrefix}icon`},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'trash']}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('General.Text_Remove'))}})])])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }