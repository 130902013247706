<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <Loader v-if="isBusy" />
      <component
        v-else
        :is="confirmationComponentType"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Loader from '../UI/Loader.vue'
export default {
  name: 'ConfirmationController',

  components: {
    Loader
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapGetters('cartStore', ['isBusy']),
    ...mapGetters('searchStore', ['ChannelId', 'MeetingtypeId']),

    confirmationComponentType() {
      let component = null
      switch (this.ChannelId) {
        case 1:
          if (this.currentSearch.MeetingtypeId === 1) {
            component = () =>
              import(`@/components/Confirmation/Channel_${this.ChannelId}`)
          } else {
            component = () =>
              import(`@/components/Confirmation/WS_Channel_${this.ChannelId}`)
          }
          break

        case 1031:
          if (this.currentSearch.MeetingtypeId === 1) {
            component = () =>
              import(`@/components/Confirmation/Channel_${this.ChannelId}`)
          } else {
            component = () =>
              import(`@/components/Confirmation/WS_Channel_${this.ChannelId}`)
          }
          break

        default:
          component = () => import(`@/components/Confirmation/General`)
          break
      }
      return component
    },
  },
}
</script>