/**
 * Import Dependency
 */
import { getCookie, eraseCookie } from '@/helpers/browserStorage'
import { generateGuid } from '@/helpers/stringHelper'

/**
 * Import API
 */
import profileProvider from '../../providers/profile'
import authProvider from '../../providers/auth'

const validAuthPages = [
  'login',
  'registration',
  'forgot',
  'set-new-password',
  'forgot-confirm',
]

/**
 * Declare Variable
 */
const state = {
  profile: null,
  profileKey: null,
  profileToken: null,
  machineToken: '',
  authPage: 'login',
  loading: false,
}

const getters = {
  isLoggedIn: (state) => state.profileToken !== null,
  getProfileToken: (state) => state.profileToken,
  getMachineToken: (state) => state.machineToken,
  profile: (state) => state.profile,
  profileId: (state) => (state.profile ? state.profile.Id : null),
  authPage: (state) => state.authPage,
  isLoading: (state) => state.loading,
}

const mutations = {
  /**
   * 'navigate' to the specified auth page
   */
  goToAuthPage: (state, { page }) => {
    if (validAuthPages.indexOf(page) !== -1) {
      state.authPage = page
    }
  },

  /**
   * Set machine token
   */
  setMachineToken: (state, machineToken) => {
    state.machineToken = machineToken
  },

  /**
   * Set profile key
   */
  setProfileKey: (state, { profileKey }) => {
    state.profileKey = profileKey
  },

  /**
   * Set profile token
   */
  setProfileToken: (state, { profileToken }) => {
    state.profileToken = profileToken || null
  },

  /**
   * Set profle
   */
  setProfile: (state, { profile }) => {
    state.profile = profile
  },
}

const actions = {
  /**
   * Check logged in user and compare profile token with lt cookie if set!
   */
  async checkProfileLoginState({ state, dispatch }) {
    let lt = getCookie('lt')
    if (lt && !state.profileToken) {
      // Set site logged in user
      await dispatch('checkUserCookies')
    } else if (lt !== state.profileToken) {
      // User is not the same site logged in user
      // Clear logged in data
      // This will mostly be the case on seats2meet.com domain
      await dispatch('SignOut')
    }
  },

  /**
   * Navigate to a specific step
   */
  getProfile: async ({ state, commit, dispatch }) => {
    state.loading = true
    // Check overlap
    await dispatch('availabilityStore/checkWorkspaceOverlap', null, {
      root: true,
    })

    let response = await profileProvider.getProfile()
    if (
      response &&
      response.status &&
      response.status === 200 &&
      response.data
    ) {
      await dispatch('cartStore/saveCartProfile', response.data.Id, {
        root: true,
      })
      await dispatch('cartStore/setCartCommentProfileId', response.data.Id, { root: true })
      commit('setProfile', {
        profile: response.data,
      })
    }
    state.loading = false
  },

  loginByPin: async({ commit, dispatch }, data) => {
    let response = await authProvider.checkAuthPin({ VerificationKey: data.VerificationKey, Pin: data.Pin })
    if (response && response.status === 200) {
      // Store profile token and profile key
      commit('setProfileToken', { profileToken: response.data.ProfileToken })
      commit('setProfileKey', { ProfileKey: response.data.ProfileKey })

      // Get profile and add profile to cart
      await dispatch('getProfile')
    }
  },

  /**
   * Set machine token
   */
  setMachineToken: async ({ commit }) => {
    let mt = getCookie('mt')
    if (mt) {
      commit('setMachineToken', mt)
    } else {
      mt = generateGuid()
      commit('setMachineToken', mt)
    }
  },

  /**
   * Check if user cookies exits
   */
  checkUserCookies: async ({ rootGetters, commit, dispatch }) => {
    let ap = getCookie('ap')
    let lt = getCookie('lt')
    if (ap && lt) {
      commit('setProfileKey', { profileKey: ap })
      commit('setProfileToken', { profileToken: lt })
    } else {
      // Delete cookies
      let cartKey = rootGetters['cartStore/CartKey']
      if (cartKey && cartKey.length > 0) {
        dispatch('SignOut')
      }
    }
  },

  SignOut: async ({ commit, dispatch, rootGetters }) => {
    let ap = getCookie('ap')
    let lt = getCookie('lt')
    if (ap !== '' || lt !== '') {
      await authProvider
        .SignOut()
        .then()
        .catch()
        .finally(() => {
          eraseCookie('ap')
          eraseCookie('lt')
          commit('setProfileKey', { profileKey: '' })
          commit('setProfileToken', { profileToken: '' })
        })
    }

    let cart = JSON.parse(JSON.stringify(rootGetters['cartStore/Cart']))
    if (cart && cart.ProfileId > 0) {
      await dispatch('cartStore/deleteCartProfile', null, {
        root: true,
      })
    }
    dispatch('cartStore/setCartCommentProfileId', 0, {
      root: true,
    })
    commit('cartStore/updateTags', '', { root: true })

    commit('setProfile', { profile: null })
    commit('setProfileToken', { profileToken: null })
    commit('setProfileKey', { profileKey: null })
    dispatch('cartStore/clearCompany', null, { root: true })
    dispatch('billingStore/clearStoreData', null, { root: true })
    dispatch('fireSignInEvent')
  },

  fireSignInEvent: ({ state, rootState }) => {
    if (rootState.fireCustomEventAllowed) {
      let signInCustomEvent = new CustomEvent('cb-w-loggedInState', {
        detail: {
          ap: state.profileKey,
          lt: state.profileToken,
          mt: state.machineToken,
        },
      })
      document.dispatchEvent(signInCustomEvent)
      signInCustomEvent = null
    }
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
