<template>
  <div class="ResultSidebar">
    <keep-alive>
      <LocationMap :markerIcons="false" />
    </keep-alive>
    <transition name="SidebarCardTransition">
      <LocationContact v-if="selectedLocation && this.widgetState.ShowBookingDetailsStep && this.widgetState.ShowOptionsStep && LastStep !== 6 && LastStep !== 1 || selectedLocation && !this.widgetState.ShowBookingDetailsStep && this.widgetState.ShowOptionsStep && LastStep !== 5 && LastStep !== 1|| selectedLocation && this.widgetState.ShowBookingDetailsStep && !this.widgetState.ShowOptionsStep && LastStep !== 5 && LastStep !== 1 || selectedLocation && !this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep && LastStep !== 4 && LastStep !== 1" />
    </transition>
    <transition name="SidebarCardTransition">
      <LocationCard
        class="has-background-white"
        v-if="selectedLocation && !CartKey && this.LastStep !== 1"
        :location="selectedLocation"
        :interactive="false"
        :horizontal="true"
        :showPricing="false"
        :showRating="false"
        :showLocationImage="false"
      />
    </transition>
    <transition name="CartTransition">
      <Cart
        :displayMode="LastStep >= 5 ||  selectedLocation && !this.widgetState.ShowBookingDetailsStep && LastStep >= 4 || selectedLocation && !this.widgetState.ShowOptionsStep && LastStep >= 4 || selectedLocation && !this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep && LastStep >= 3 ? 'full' : 'short'"
        :class="{ 'is-marginless': LastStep === 6 || selectedLocation && !this.widgetState.ShowBookingDetailsStep && LastStep === 5 || selectedLocation && !this.widgetState.ShowOptionsStep && LastStep === 5 || selectedLocation && !this.widgetState.ShowOptionsStep && !this.widgetState.ShowBookingDetailsStep && LastStep === 4}"
      />
    </transition>
  </div>
</template>

<script>
import LocationCard from '@/components/SearchResults/LocationCard'
import LocationMap from '@/components/Sidebar/LocationMap'

import Cart from '@/components/Sidebar/Cart'
import LocationContact from '@/components/Sidebar/LocationContact'
// import TransitionExpand from '@/components/TransitionExpand'

import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    LocationMap,
    LocationCard,
    Cart,
    LocationContact,
    // TransitionExpand,
  },

  computed: {
    ...mapGetters('availabilityStore', ['selectedLocation', 'selectedSpace']),
    ...mapGetters('cartStore', ['CartKey']),
    ...mapGetters('searchStore', ['LastStep']),
    ...mapState('widgetStore', ['widgetState']),

  },
}
</script>

<style lang="scss">
</style>
